import { InspectionPostBody, SectionFlowCode } from '@drainify/types';
import { Box, Button, Buttons, Icons, Motion, Text } from 'preshape';
import React from 'react';
import Card from '../Card/Card';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import SymbolTemplate from '../Symbol/SymbolTemplate';

type Props = {
  onChange: (flow: SectionFlowCode) => void;
  inspection: InspectionPostBody;
  value?: SectionFlowCode;
};

const InspectionDirectionInput = ({ onChange, inspection, value }: Props) => {
  const { reportEditor } = useReportEditorContext();
  const section = reportEditor.getSectionByUid(inspection.sectionUid);
  const [key, setKey] = React.useState(0);

  if (!section) {
    return null;
  }
  const start = reportEditor.getNodeByUid(
    section.attributes.flow === SectionFlowCode.STE
      ? section.nodeStartUid
      : section.nodeEndUid
  );
  const end = reportEditor.getNodeByUid(
    section.attributes.flow === SectionFlowCode.STE
      ? section.nodeEndUid
      : section.nodeStartUid
  );
  const isDownstream = inspection.inspectionDirection === SectionFlowCode.STE;

  return (
    <Box alignChildrenVertical="middle" flex="vertical" gap="x8" grow>
      <Card flex="horizontal">
        {inspection.inspectionDirection !== undefined && (
          <Box flex="horizontal" alignChildrenHorizontal="around">
            <Box flex="vertical" alignChildren="middle">
              <SymbolTemplate>
                {isDownstream ? start?.code : end?.code}
              </SymbolTemplate>
              <Text>
                {reportEditor.getNodeName(isDownstream ? start : end)}
              </Text>
            </Box>

            <Box>
              <Motion
                key={key}
                animate={{ x: 'calc(100%)' }}
                initial={{ x: -20 }}
                transition={{
                  type: 'tween',
                  ease: 'easeInOut',
                  repeat: Infinity,
                  duration: 3,
                  repeatType: 'loop',
                }}
              >
                {
                  <Icons.Video
                    size="1.5rem"
                    style={{ transform: 'rotate(0deg)' }}
                  />
                }
              </Motion>
              <Text strong>
                <Box flex="horizontal" alignChildrenHorizontal="middle">
                  <Box
                    flex="vertical"
                    alignChildrenHorizontal="middle"
                    textColor="accent-shade-5"
                  >
                    <Box flex="horizontal">
                      {isDownstream ? (
                        <Icons.ArrowRight />
                      ) : (
                        <Icons.ArrowLeft />
                      )}
                      {isDownstream ? (
                        <Icons.ArrowRight />
                      ) : (
                        <Icons.ArrowLeft />
                      )}
                      {isDownstream ? (
                        <Icons.ArrowRight />
                      ) : (
                        <Icons.ArrowLeft />
                      )}
                      {isDownstream ? (
                        <Icons.ArrowRight />
                      ) : (
                        <Icons.ArrowLeft />
                      )}
                      {isDownstream ? (
                        <Icons.ArrowRight />
                      ) : (
                        <Icons.ArrowLeft />
                      )}
                    </Box>
                    <Text size="x1">Flow</Text>
                  </Box>
                </Box>
              </Text>
            </Box>
            <Box flex="vertical" alignChildren="middle">
              <SymbolTemplate>
                {isDownstream ? end?.code : start?.code}
              </SymbolTemplate>
              <Text>
                {reportEditor.getNodeName(isDownstream ? end : start)}
              </Text>
            </Box>
          </Box>
        )}
      </Card>

      <Box alignChildren="middle" flex="vertical" gap="x4">
        <Buttons joined>
          <Button
            active={value === SectionFlowCode.STE}
            grow
            onClick={() => {
              setKey((prev) => prev + 1);
              onChange(SectionFlowCode.STE);
            }}
            size="x3"
          >
            Downstream
          </Button>

          <Button
            active={value === SectionFlowCode.ETS}
            grow
            onClick={() => {
              setKey((prev) => prev + 1);
              onChange(SectionFlowCode.ETS);
            }}
            size="x3"
          >
            Upstream
          </Button>
        </Buttons>
      </Box>
    </Box>
  );
};

export default InspectionDirectionInput;
