import {
  BlockPlanTemplate,
  BlockPlanTemplateType,
  DrawingPostBody,
} from '@drainify/types';
import destination from '@turf/destination';
import React from 'react';
import { v4 } from 'uuid';
import DropdownMenu, { DropdownOption } from '../DropdownMenu/DropdownMenu';

type Props = {
  template: BlockPlanTemplate;
  setTemplate: (template: BlockPlanTemplate) => void;
};

const templates: DropdownOption<BlockPlanTemplateType>[] = [
  {
    name: 'Blank',
    value: BlockPlanTemplateType.CUSTOM,
  },
  {
    name: 'Detached house',
    value: BlockPlanTemplateType.DETACHED_HOUSE,
  },
  {
    name: 'Semi detached x 2',
    value: BlockPlanTemplateType.SEMI_DETACHED_HOUSE_2,
  },
  {
    name: 'Terraced house x 3',
    value: BlockPlanTemplateType.TERRACED_HOUSE_3,
  },
];

const templateDimension: Record<
  BlockPlanTemplateType,
  { x: number; y: number }
> = {
  [BlockPlanTemplateType.CUSTOM]: {
    x: 20,
    y: 20,
  },
  [BlockPlanTemplateType.TERRACED_HOUSE_3]: {
    x: 45,
    y: 33,
  },
  [BlockPlanTemplateType.SEMI_DETACHED_HOUSE_2]: {
    x: 50,
    y: 40,
  },
  [BlockPlanTemplateType.DETACHED_HOUSE]: {
    x: 50,
    y: 40,
  },
};

const PlanBlockPlanTemplateSelector = ({ template, setTemplate }: Props) => {
  const handleBlockPlanTemplateType = (templateType: BlockPlanTemplateType) => {
    setTemplate({
      template: templateType,
      ...templateDimension[templateType],
    });
  };

  return (
    <>
      <DropdownMenu
        options={templates}
        onChange={handleBlockPlanTemplateType}
        value={template.template}
      />
    </>
  );
};

export const getDrawingsForTemplate = (
  template: BlockPlanTemplateType,
  topLeft: GeoJSON.Position
): DrawingPostBody[] => {
  switch (template) {
    case BlockPlanTemplateType.CUSTOM:
      return [];
    case BlockPlanTemplateType.TERRACED_HOUSE_3:
      let terracedHouseOneStartPoisition = destination(topLeft, 7, 90, {
        units: 'meters',
      });
      terracedHouseOneStartPoisition = destination(
        terracedHouseOneStartPoisition,
        10,
        180,
        { units: 'meters' }
      );

      const terracedHouseTwoPosition = destination(
        terracedHouseOneStartPoisition,
        10,
        90,
        { units: 'meters' }
      );
      const terracedHouseThreePosition = destination(
        terracedHouseTwoPosition,
        10,
        90,
        { units: 'meters' }
      );
      return [
        drawSquare(terracedHouseOneStartPoisition.geometry, 10, 11),
        drawSquare(terracedHouseTwoPosition.geometry, 10, 11),
        drawSquare(terracedHouseThreePosition.geometry, 10, 11),
      ];

    case BlockPlanTemplateType.SEMI_DETACHED_HOUSE_2:
      let semiDetachedHouseOneStartPosition = destination(topLeft, 12, 90, {
        units: 'meters',
      });
      semiDetachedHouseOneStartPosition = destination(
        semiDetachedHouseOneStartPosition,
        12,
        180,
        { units: 'meters' }
      );

      const semiDetachedHouseTwoStartPosition = destination(
        semiDetachedHouseOneStartPosition,
        12,
        90,
        { units: 'meters' }
      );
      return [
        drawSquare(semiDetachedHouseOneStartPosition.geometry, 12, 14),
        drawSquare(semiDetachedHouseTwoStartPosition.geometry, 12, 14),
      ];
    case BlockPlanTemplateType.DETACHED_HOUSE:
      let detachedHouseStartPosition = destination(topLeft, 16, 90, {
        units: 'meters',
      });
      detachedHouseStartPosition = destination(
        detachedHouseStartPosition,
        13,
        180,
        { units: 'meters' }
      );
      return [drawSquare(detachedHouseStartPosition.geometry, 18, 15)];
  }
};

const drawSquare = (
  startPoint: GeoJSON.Point,
  x: number,
  y: number
): DrawingPostBody => {
  const startId = v4();
  const ne = destination(startPoint, x, 90, { units: 'meters' });
  const se = destination(ne, y, 180, { units: 'meters' });
  const sw = destination(startPoint, y, 180, { units: 'meters' });
  return {
    name: 'house',
    showlabel: true,
    showMeasurement: true,
    points: [
      {
        uid: startId,
        point: startPoint,
      },
      {
        uid: v4(),
        point: ne.geometry,
      },
      {
        uid: v4(),
        point: se.geometry,
      },
      {
        uid: v4(),
        point: sw.geometry,
      },
      {
        uid: startId,
        point: startPoint,
      },
    ],
  };
};

export default PlanBlockPlanTemplateSelector;
