import { ReportElementType } from '@drainify/utils';
import { useCallback, useMemo } from 'react';
import { useAppContext } from '../../../Bootstrap/Bootstrap';
import useDrawGeometry from '../../../Map/useDrawGeometry';
import { MapLayerUpdateFn, MapLayerLine } from '../../../Map/useMap';
import { reportMapLayerOrder } from './ReportMapElements';

type Props = {
  points?: GeoJSON.Point[];
  visible?: boolean;
  scale?: number;
};

export const PIPE_WIDTH = 10;
export const PIPE_BORDER_WIDTH = 1;

const useDrawMeasurementMapGhost = ({
  points,
  visible = true,
}: Props): MapLayerUpdateFn<MapLayerLine> => {
  const { theme } = useAppContext();

  const pointss =
    points === undefined || !visible ? [] : points.map((e) => e.coordinates);

  const geometry = useMemo<GeoJSON.LineString | undefined | null>(
    () => ({
      type: 'LineString',
      coordinates: pointss,
    }),
    [points, visible]
  );

  const updateMeasurementFill = useDrawGeometry<MapLayerLine>(
    useMemo(
      () => ({
        geometry,
        style: {
          zIndex: reportMapLayerOrder[ReportElementType.MEASUREMENT],
          strokeColor: 'black',
        },
      }),
      [geometry, theme]
    )
  );

  return useCallback(
    (layer) => {
      updateMeasurementFill?.(layer);
    },
    [updateMeasurementFill]
  );
};

export default useDrawMeasurementMapGhost;
