import {
  MinaXMLImport,
  Node,
  ObservationCode,
  Ownership,
  PENDING_IMAGE_UPLOAD,
  PipeType,
  SectionFlowCode,
  TranscodedVideo,
  WincanXMLImport,
  isCircular,
} from '@drainify/types';
import { getObservationSchema } from '@drainify/utils';
import { DateTime } from 'luxon';
import {
  Alert,
  Box,
  BulletPoints,
  Button,
  Buttons,
  Icons,
  BulletPoint,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from 'preshape';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { parseString } from 'xml2js';
import useFileUpload from '../../../../hooks/useFileUpload';
import {
  extractNodeInformation,
  extractSectionInspectionInformation,
  extractObservationInformation,
  addImageUploadJob,
  addVideoUploadJob,
  refToNodeCode,
  getDomesticCodeMSCC5,
} from '../../../../utils/xml-mapping/util';
import useFetch from '../../../Api/useFetch';
import { FULL_SCREEN_MODAL_WIDTH } from '../../../App/App';
import resizeImage from '../../../Image/ImageCompressor';
import { useProjectContext } from '../../../Project/ProjectProvider';
import { useUsageContext } from '../../../Usage/UsageProvider';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportContext } from '../../ReportProvider';

const WincanXMLImport = () => {
  const [visible, setVisible] = React.useState(false);
  const { reportEditor } = useReportEditorContext();
  const { activeBookingId, project } = useProjectContext();
  const observationFileUpload = useFileUpload();
  const nodeFileUpload = useFileUpload();
  const fetch = useFetch();
  const [importHelpVisible, setImportHelpVisible] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [sectionReportUploaded, setSectionReportsUploaded] = React.useState(0);
  const [sectionReports, setTotalSectionReports] = React.useState(0);
  const [imagesUploaded, setImagesUploaded] = React.useState(0);
  const [totalImageUploads, setTotalImageUploads] = React.useState(0);
  const [videosUploaded, setVideoesUploaded] = React.useState(0);
  const [totalVideoUploads, setTotalVideoUploads] = React.useState(0);
  const [overLimit, setOverLimit] = React.useState(false);
  const [finished, setFinished] = React.useState(false);
  const { usage } = useUsageContext();
  const { query } = useReportContext();

  const closeModal = () => {
    setSectionReportsUploaded(0);
    setTotalSectionReports(0);

    setTotalVideoUploads(0);
    setVideoesUploaded(0);

    setTotalImageUploads(0);
    setImagesUploaded(0);

    setVisible(false);
    setOverLimit(false);
    setFinished(false);
  };

  React.useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const onDrop = (acceptedFiles: File[]) => {
    const imageUploads: (() => Promise<void>)[] = [];
    const videoUploads: (() => Promise<void>)[] = [];
    let mediaSize = 0;

    const reader = new FileReader();
    setError(false);
    reader.onloadend = function () {
      parseString(
        reader.result!,
        {
          trim: true,
          ignoreAttrs: true,
          explicitArray: false,
          normalizeTags: true,
        },
        async (_: any, result: WincanXMLImport | MinaXMLImport) => {
          try {
            if ('exportdata' in result) {
              const nodes: Record<string, Node> = extractNodeInformation(
                reportEditor,
                result
              );

              setTotalSectionReports([result.exportdata.section].flat().length);

              for (const [inspectionIndex, section] of [
                result.exportdata.section,
              ]
                .flat()
                .entries()) {
                const { uid: inspectionUid } =
                  extractSectionInspectionInformation(
                    reportEditor,
                    nodes,
                    section,
                    activeBookingId
                  );

                const withVid = (
                  section.sectioninspection.sectionobservation || []
                ).filter((e) => !!e.podmovie);
                const videoName =
                  withVid.length > 0
                    ? withVid[withVid.length - 1].podmovie
                    : undefined;

                const continuous: {
                  observationId: string;
                  continuousRef: string;
                }[] = [];

                if (section.sectioninspection.sectionobservation) {
                  for (const observation of [
                    section.sectioninspection.sectionobservation,
                  ].flat()) {
                    const { uid: observationId } =
                      extractObservationInformation(
                        observation,
                        reportEditor,
                        inspectionUid,
                        continuous,
                        videoName
                      );

                    mediaSize += await addImageUploadJob(
                      observation,
                      observationId,
                      reportEditor,
                      imageUploads,
                      observationFileUpload,
                      project?.uid!,
                      acceptedFiles
                    );
                  }
                }

                for (const startRef of continuous) {
                  if (startRef.continuousRef.startsWith('S')) {
                    const finishRef = continuous.find(
                      (e) =>
                        e.continuousRef === 'F' + e.continuousRef.substring(1)
                    );

                    reportEditor.updateObservationNoAsync(
                      startRef.observationId,
                      {
                        observationContinuousState: 'start',
                        linkedObservationUid: finishRef?.observationId,
                      }
                    );

                    if (finishRef) {
                      reportEditor.updateObservationNoAsync(
                        finishRef!.observationId,
                        {
                          observationContinuousState: 'finish',
                          linkedObservationUid: startRef?.observationId,
                        }
                      );
                    }
                  }
                }

                mediaSize += addVideoUploadJob(
                  section,
                  inspectionUid,
                  videoUploads,
                  fetch,
                  reportEditor,
                  nodeFileUpload,
                  project?.uid!,
                  acceptedFiles
                );
                setSectionReportsUploaded(inspectionIndex + 1);
              }
            } else if ('surveygroup' in result) {
              const nodes: Record<string, Node> = {};
              for (const { header } of [result.surveygroup.survey].flat()) {
                const startNodeRef = header.startnoderef;
                const depth = header.depthatstartnode;
                const finishNodeRef = header.finishnoderef;

                if (!nodes[startNodeRef]) {
                  nodes[startNodeRef] = reportEditor.addNodeNoUpdate({
                    code: refToNodeCode(startNodeRef),
                    name: startNodeRef.substring(0, 10),
                    attributes: {},
                    depth: depth,
                    createdAt: DateTime.now().toISO(),
                  });
                } else if (depth) {
                  reportEditor.updateNode(nodes[startNodeRef].uid, {
                    depth: depth,
                  });
                }
                if (!nodes[finishNodeRef]) {
                  const finishDepth = header.depthatfinishnode;
                  nodes[finishNodeRef] = reportEditor.addNodeNoUpdate({
                    code: refToNodeCode(finishNodeRef),
                    name: finishNodeRef.substring(0, 6),
                    depth: finishDepth,
                    attributes: {},
                    createdAt: DateTime.now().toISO(),
                  });
                }
              }

              setTotalSectionReports(result.surveygroup.survey.length);

              for (const [inspectionIndex, survey] of [
                result.surveygroup.survey,
              ]
                .flat()
                .entries()) {
                const { header } = survey;
                const startNodeRef = header.startnoderef;
                const finishNodeRef = header.finishnoderef;
                const { material, shape, direction } = header;

                // which direction does the water flow
                // which direction is the inspection

                const diameter = header.heightdiameter;
                const use = header.useofdrainsewer;

                const startNodeUid = nodes[startNodeRef].uid;
                const endNodeUid = nodes[finishNodeRef].uid;
                const { uid: sectionUid } = reportEditor.addSectionNoUpdate({
                  nodeStartUid: startNodeUid,
                  nodeEndUid: endNodeUid,
                  attributes: {
                    sectionOwnership: Ownership.Z,
                    material: material,
                    use: use,
                    flow:
                      direction === 'U'
                        ? SectionFlowCode.ETS
                        : SectionFlowCode.STE,
                    diameter: isCircular(shape) ? diameter / 1000 : undefined,
                    height: isCircular(shape) ? undefined : diameter / 1000,
                    width: isCircular(shape)
                      ? undefined
                      : header.width
                      ? header.width / 1000
                      : undefined,
                    shape: shape,
                    nodeOneRef: header.node1ref || header.startnoderef,
                    nodeTwoRef: header.finishnoderef,
                    pipeLengthRef: header.pipelinelengthref,
                    drainSewerType: header.typeofdrainsewer,
                    drainageArea: header.drainagearea,
                    pipeUnitLength: header.pipeunitlength,
                    yearConstructed: header.yearconstructed,
                    expectedLength: header.expectedlength,
                    divisionOrDistrict: header.divisionDistrict,
                    landOwnership: header.landownership,
                    liningType: header.liningmaterial,
                    pipeType: PipeType.SECTION,
                    locationTypeCode: header.locationtypecode,
                  },
                  createdAt: DateTime.now().toISO(),
                });

                const { uid: inspectionUid } =
                  reportEditor.addInspectionNoAsync({
                    sectionUid: sectionUid,
                    jobId: activeBookingId!,
                    notes: header.generalremarks,
                    inspectionDirection:
                      direction === 'U'
                        ? SectionFlowCode.ETS
                        : SectionFlowCode.STE,
                    attributes: {
                      preCleaned: header.precleaned,
                      date: header.date,
                      time: header.time,
                      flowControlMeasures: header.flowcontrolmeasures,
                      methodOfInspection: header.methodofinspection,
                      weather: header.weather,
                      videoImageFileName: header.videoimagefilename,
                      videoImageLocationSystem: header.videoimagelocationsystem,
                      videoImageStorageMedia: header.videoimagestorage,
                      videoVolumeRef: header.videoimagevolume,
                      photographImageStorageFormat: header.photographicstorage,
                      photographVolumeReference: header.photographicvolume,
                      purposeOfInspection: header.purposeofinspection,
                      critcatalityGrade: header.criticaldrainsewer,
                      temperature: header.temperature,

                      // videoImageFormat?: VideoImageFormat;
                      // longitudinalLocationOfStartPoint?: string;
                      // circumferentialLocationOfStartPoint?: string;
                    },
                    createdAt: DateTime.now().toISO(),
                  });

                const continuous: {
                  observationId: string;
                  continuousRef: string;
                }[] = [];

                for (const observation of [
                  survey.observations.observation,
                ].flat()) {
                  if (observation === undefined) {
                    continue;
                  }
                  const schema = getObservationSchema(
                    getDomesticCodeMSCC5(observation.code)
                  );

                  if (schema) {
                    const attributes: Record<string, any> = {};
                    const expectedAttributes =
                      schema?.options?.map((e) => e.type) || [];
                    if (schema?.path && schema.path.length > 1) {
                      for (const parent of schema.path.filter(
                        (e) => e !== schema.code
                      )) {
                        const schema = getObservationSchema(parent);
                        for (const newAttr of schema.options || []) {
                          expectedAttributes?.push(newAttr.type);
                        }
                      }
                    }

                    attributes['distance'] = observation.distance;

                    if (observation.percentage) {
                      attributes['percentage'] = observation.percentage;
                    } else if (expectedAttributes?.includes('percentage')) {
                      attributes['percentage'] = 0;
                    }

                    if (expectedAttributes?.includes('angleRange')) {
                      attributes['angle'] = [
                        parseInt(observation.clockrefatfrom?.toString()!),
                        parseInt(
                          observation.clockrefto?.toString() ||
                            observation.clockrefatfrom?.toString()!
                        ),
                      ];
                    } else if (expectedAttributes?.includes('angle')) {
                      attributes['angleAt'] = observation.clockrefatfrom;
                    }

                    if (observation.dimension1) {
                      attributes['number'] = observation.dimension1 / 1000;
                      attributes['diameter'] = observation.dimension1 / 1000;
                    }

                    let timeStamp = undefined;
                    let imageUrl = undefined;
                    if (observation.videoref) {
                      const split = observation.videoref
                        .split(':')
                        .map((e: string) => Number(e));
                      timeStamp = split[0] * 60 * 60 + split[1] * 60 + split[2];
                      imageUrl = PENDING_IMAGE_UPLOAD;
                    }

                    const { uid: observationId } =
                      reportEditor.addObservationNoUpdate({
                        code: schema.path || [schema.code],
                        inspectionUid: inspectionUid,
                        remarks: observation.remarks,
                        distance: observation.distance || 0,
                        timeStamp: timeStamp,
                        imageUrl: imageUrl,
                        attributes: attributes,
                        inspectionMarker: observation.drainifyinspectionmarker,
                      });

                    if (observation.continuousdefect) {
                      attributes['continuous'] = true;
                      continuous.push({
                        observationId: observationId,
                        continuousRef: observation.continuousdefect,
                      });
                    }

                    if (observation?.photographrefs?.photographref) {
                      const photoRef = observation.photographrefs.photographref;
                      let file = undefined;
                      const dirDepth = photoRef.split('\\').length;
                      if (dirDepth === 1) {
                        // @ts-ignore
                        file = acceptedFiles[photoRef.split('\\')[0]];
                      } else if (dirDepth === 2) {
                        // @ts-ignore
                        file =
                          // @ts-ignore
                          acceptedFiles[photoRef.split('\\')[0]][
                            photoRef.split('\\')[1]
                          ];
                      }

                      if (
                        !file &&
                        // @ts-ignore
                        acceptedFiles['Picture'] &&
                        // @ts-ignore
                        acceptedFiles['Picture']['Sec']
                      ) {
                        // @ts-ignore
                        file = acceptedFiles['Picture']['Sec'][photoRef];
                      }
                      const compressedBlob = (await resizeImage({
                        file: file,
                        maxSize: 500,
                      })) as Blob;
                      const compressedImage = new File(
                        [compressedBlob],
                        file.name,
                        {
                          type: compressedBlob.type,
                        }
                      );
                      reportEditor.updateObservationNoAsync(observationId, {
                        imageUrl: PENDING_IMAGE_UPLOAD,
                      });
                      mediaSize += compressedBlob.size;
                      imageUploads.push(async () => {
                        await observationFileUpload.upload(
                          generatePath(
                            '/projects/:projectId/report/observations/:observationId/image',
                            {
                              projectId: project?.uid,
                              observationId: observationId,
                            }
                          ),
                          compressedImage
                        );
                      });
                    }
                  } else {
                    const code = observation.code;
                    const photoRef = observation.photographrefs?.photographref;
                    if (code && photoRef) {
                      let timeStamp = undefined;
                      const attributes: Record<string, any> = {};

                      attributes['distance'] = observation.distance;

                      if (observation.videoref) {
                        const split = observation.videoref
                          .split(':')
                          .map((e: string) => Number(e));
                        timeStamp =
                          split[0] * 60 * 60 + split[1] * 60 + split[2];
                      }

                      const { uid: observationId } =
                        reportEditor.addObservationNoUpdate({
                          code: [ObservationCode.GP],
                          inspectionMarker: code.endsWith('F')
                            ? 'finish'
                            : 'start',
                          inspectionUid: inspectionUid,
                          distance: observation.distance || 0,
                          timeStamp: timeStamp,
                          imageUrl: PENDING_IMAGE_UPLOAD,
                          attributes: attributes,
                        });
                      if (photoRef) {
                        let file = undefined;
                        const dirDepth = photoRef.split('\\').length;
                        if (dirDepth === 1) {
                          // @ts-ignore
                          file = acceptedFiles[photoRef.split('\\')[0]];
                        } else if (dirDepth === 2) {
                          // @ts-ignore
                          file =
                            // @ts-ignore
                            acceptedFiles[photoRef.split('\\')[0]][
                              photoRef.split('\\')[1]
                            ];
                        }

                        if (
                          !file &&
                          // @ts-ignore
                          acceptedFiles['Picture'] &&
                          // @ts-ignore
                          acceptedFiles['Picture']['Sec']
                        ) {
                          // @ts-ignore
                          file = acceptedFiles['Picture']['Sec'][photoRef];
                        }

                        const compressedBlob = (await resizeImage({
                          file: file,
                          maxSize: 500,
                        })) as Blob;
                        const compressedImage = new File(
                          [compressedBlob],
                          file.name,
                          {
                            type: compressedBlob.type,
                          }
                        );
                        mediaSize += compressedBlob.size;
                        reportEditor.updateObservationNoAsync(observationId, {
                          imageUrl: PENDING_IMAGE_UPLOAD,
                        });
                        imageUploads.push(async () => {
                          await observationFileUpload.upload(
                            generatePath(
                              '/projects/:projectId/report/observations/:observationId/image',
                              {
                                projectId: project?.uid,
                                observationId: observationId,
                              }
                            ),
                            compressedImage
                          );
                        });
                      }
                    }
                  }
                  setSectionReportsUploaded(inspectionIndex + 1);
                }

                try {
                  const video = header.videoimagefilename;

                  if (video) {
                    let vid =
                      // @ts-ignore
                      acceptedFiles[video.split('\\')[0]][video.split('\\')[1]];

                    if (
                      !vid &&
                      // @ts-ignore
                      acceptedFiles['Video'] &&
                      // @ts-ignore
                      acceptedFiles['Video']['Sec']
                    ) {
                      // @ts-ignore
                      vid = acceptedFiles['Video']['Sec'][photoRef];
                    }
                    if (vid) {
                      mediaSize += vid.size;
                      videoUploads.push(async () => {
                        const signedUrl = await fetch(
                          '/video/:projectId/:inspectionId/preupload',
                          {
                            method: 'POST',
                            body: {
                              fileSize: vid.size,
                            },
                            params: {
                              projectId: project!.uid,
                              inspectionId: inspectionUid,
                            },
                          }
                        );
                        await nodeFileUpload.uploadSignedUrl(signedUrl, vid);
                        (await fetch(
                          '/video/:projectId/:inspectionId/postupload',
                          {
                            method: 'POST',
                            body: {
                              useBuddy: true,
                              frames:
                                reportEditor
                                  .getInspectionObservations(inspectionUid)
                                  .filter(
                                    (e) =>
                                      e.imageUrl === PENDING_IMAGE_UPLOAD &&
                                      e.timeStamp !== undefined
                                  )
                                  .map((e) => ({
                                    observationId: e.uid!,
                                    timeStamp: e.timeStamp!,
                                  })) || [],
                            },
                            params: {
                              projectId: project!.uid,
                              inspectionId: inspectionUid,
                            },
                          }
                        )) as unknown as TranscodedVideo;
                      });
                    }
                  }
                } catch (e) {
                  if (process.env.NODE_ENV === 'development') {
                    console.error('Failed uploading video');
                    console.error(e);
                  }
                }
                const observations = [survey.observations.observation]
                  .flat()
                  .filter((e) => e !== undefined);

                const maxLength = Math.max(
                  ...observations
                    .filter((e) => !!e.distance)
                    .map((e) => e.distance!)
                );

                for (const startRef of continuous) {
                  if (startRef.continuousRef.startsWith('S')) {
                    const finishRef = continuous.find(
                      (e) =>
                        e.continuousRef === 'F' + e.continuousRef.substring(1)
                    );

                    reportEditor.updateObservationNoAsync(
                      startRef.observationId,
                      {
                        observationContinuousState: 'start',
                        linkedObservationUid: finishRef?.observationId,
                      }
                    );

                    reportEditor.updateObservationNoAsync(
                      finishRef!.observationId,
                      {
                        observationContinuousState: 'finish',
                        linkedObservationUid: startRef?.observationId,
                      }
                    );
                  }
                }
                reportEditor.updateSectionNoUpdate(sectionUid, {
                  attributes: {
                    ...reportEditor.getSectionByUid(sectionUid)?.attributes,
                    length: survey.header.expectedlength || maxLength,
                  },
                });
              }
            }
            await reportEditor.updateAsync?.(reportEditor.report);
            if (
              usage &&
              usage?.usedStorage + mediaSize >
                usage?.totalStorage + usage.additionalStorage
            ) {
              setOverLimit(true);
              setFinished(true);
            } else {
              setTotalImageUploads(imageUploads.length);
              setTotalVideoUploads(videoUploads.length);
              for (const [index, media] of videoUploads.entries()) {
                await media();
                setVideoesUploaded(index + 1);
              }

              for (const [index, media] of imageUploads.entries()) {
                await media();
                setImagesUploaded(index + 1);
              }
              query.refetch();
              setFinished(true);
              closeModal();
            }
          } catch (e) {
            if (process.env.NODE_ENV === 'development') {
              console.error(e);
            }
            setFinished(true);
            setVisible(false);
            setImportHelpVisible(true);
            setError(true);
          }
        }
      );
    };

    const found = Object.keys(acceptedFiles).find((e) => e.endsWith('.xml'));
    if (found) {
      setVisible(true);
      // @ts-ignore
      reader.readAsText(acceptedFiles[found]);
    }
  };

  // @ts-ignore
  async function handleDirectoryEntry(dirHandle, out) {
    for await (const entry of dirHandle.values()) {
      if (entry.kind === 'file') {
        const file = await entry.getFile();
        out[file.name] = file;
      }
      if (entry.kind === 'directory') {
        const newHandle = await dirHandle.getDirectoryHandle(entry.name, {
          create: false,
        });
        const newOut = (out[entry.name] = {});
        await handleDirectoryEntry(newHandle, newOut);
      }
    }
    return out;
  }

  return (
    <Box flex="vertical" gap="x2">
      {reportEditor.report.nodes.length +
        reportEditor.report.sections.length ===
        0 && (
        <Button
          onClick={() => setImportHelpVisible(true)}
          variant="tertiary"
          color="accent"
          flex="horizontal"
          gap="x3"
          grow
          alignChildrenHorizontal="start"
        >
          <Icons.Folder />
          <Text>Import project</Text>
        </Button>
      )}
      <Modal visible={visible} maxWidth={FULL_SCREEN_MODAL_WIDTH}>
        <ModalHeader>Importing project</ModalHeader>
        <ModalBody>
          <Box flex="vertical" gap="x3">
            <Alert color={'accent'} backgroundColor="accent-shade-2">
              <Text strong align="middle">
                Please do not refresh or close window
              </Text>
            </Alert>
            <Box flex="vertical" gap="x2" alignChildrenVertical="middle">
              <Text strong>Reports</Text>
              <Box
                backgroundColor="accent-shade-1"
                borderRadius="10px"
                borderColor="text-shade-4"
                basis="0"
                grow
              >
                <Box
                  borderRadius="10px"
                  height="6px"
                  backgroundColor="accent-shade-5"
                  width={`${(sectionReportUploaded / sectionReports) * 100}%`}
                />
              </Box>
            </Box>

            <Box flex="vertical" gap="x2" alignChildrenVertical="middle">
              <Text strong>Videos</Text>
              {overLimit ? (
                <Box flex="horizontal" alignChildrenVertical="middle" gap="x2">
                  <Icons.AlertTriangle textColor="negative-shade-5" />
                </Box>
              ) : (
                <Box
                  backgroundColor="accent-shade-1"
                  borderRadius="10px"
                  borderColor="text-shade-4"
                  grow
                >
                  <Box
                    borderRadius="10px"
                    height="6px"
                    backgroundColor="accent-shade-5"
                    width={`${(videosUploaded / totalVideoUploads) * 100}%`}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box flex="vertical" gap="x2" alignChildrenVertical="middle">
            <Text strong>Images</Text>
            {overLimit ? (
              <Box flex="horizontal" alignChildrenVertical="middle" gap="x2">
                <Icons.AlertTriangle textColor="negative-shade-5" />
              </Box>
            ) : (
              <Box
                backgroundColor="accent-shade-1"
                borderRadius="10px"
                borderColor="text-shade-4"
                grow
              >
                <Box
                  borderRadius="10px"
                  height="6px"
                  backgroundColor="accent-shade-5"
                  width={`${(imagesUploaded / totalImageUploads) * 100}%`}
                />
              </Box>
            )}
          </Box>
          {overLimit && (
            <Alert
              margin="x3"
              color="negative"
              padding="x3"
              backgroundColor="negative-shade-2"
            >
              <Text margin="x2" size="x2" strong align="middle">
                You have gone over your storage allowance. Photos/videoes not
                uploaded
              </Text>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Buttons>
            <Button
              grow
              disabled={!finished}
              variant="primary"
              color="accent"
              onClick={closeModal}
            >
              close
            </Button>
          </Buttons>
        </ModalFooter>
      </Modal>
      <Modal
        visible={importHelpVisible}
        onClose={() => {
          setImportHelpVisible(false);
          setError(false);
        }}
      >
        <ModalHeader>To successfully import follow these steps</ModalHeader>
        <ModalBody>
          <Box flex="vertical" gap="x4">
            <BulletPoints>
              <BulletPoint>
                Take folder from Control Unit and put on laptop/computer
              </BulletPoint>
              <BulletPoint>Click the button below</BulletPoint>
              <BulletPoint>
                Navigate to project (Usually ending with .sur, .wcs, or .wcx)
              </BulletPoint>
              <BulletPoint>
                Once inside. Click "Open" on the dialog box
              </BulletPoint>
              <BulletPoint>
                Click "View files" on the browser prompt to grant access
              </BulletPoint>
            </BulletPoints>
            {error && (
              <Alert color="negative" backgroundColor="negative-shade-1">
                <Text strong color="negative-shade-5" align="middle">
                  Invalid project.
                </Text>
                <Text strong color="negative-shade-5" align="middle" size="x1">
                  Please check the instructions above and try again.
                </Text>
              </Alert>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Buttons>
            <Button
              grow
              variant="primary"
              color="accent"
              onClick={async () => {
                // @ts-ignore
                const handle = await window.showDirectoryPicker();
                const entries = await handleDirectoryEntry(handle, {});
                onDrop(entries);
                setImportHelpVisible(false);
              }}
            >
              Import
            </Button>
          </Buttons>
        </ModalFooter>
      </Modal>
    </Box>
  );
};

export default WincanXMLImport;
