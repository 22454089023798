"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBoundaryLine = exports.getBoundaryLineValidations = void 0;
const yup_1 = require("yup");
const getBoundaryLineValidations = () => {
    return (0, yup_1.object)().shape({
        points: (0, yup_1.array)().of((0, yup_1.object)()).min(2).required(),
        length: (0, yup_1.number)(),
        name: (0, yup_1.string)().max(40),
    });
};
exports.getBoundaryLineValidations = getBoundaryLineValidations;
const isBoundaryLine = (s) => !!s && 'uid' in s;
exports.isBoundaryLine = isBoundaryLine;
