import { Drawing } from '@drainify/types';
import { ReportElementType } from '@drainify/utils';
import { Icons, Button, Box, Appear } from 'preshape';
import React from 'react';
import { useMapLayersContext } from '../../../Map/MapLayers/MapLayers';
import MapMarker from '../../../Map/MapMarker/MapMarker';
import MapMarkerToolbar from '../../../Map/MapMarker/MapMarkerToolbar';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';
import ReportMapDrawingAdd from './ReportMapDrawingAdd';
import ReportMapDrawingSegment from './ReportMapDrawingSegment';
import ReportMapDrawingShow from './ReportMapDrawingShow';

type Props = {
  drawing: Drawing;
};

const ReportMapDrawingCreating = ({ drawing }: Props) => {
  const { isLayerVisible } = useMapLayersContext();
  const { reportEditor } = useReportEditorContext();
  const { focusedDrawingUid } = useReportMapContext();
  const [overLink, setOverLink] = React.useState(false);

  const visible = isLayerVisible(ReportElementType.DRAWING, drawing.uid);

  if (!visible) {
    return null;
  }

  return (
    <>
      {focusedDrawingUid === drawing.uid &&
        drawing.points.map((point, i) => (
          <Box key={i + `drawing`}>
            {i < drawing.points.length - 1 && (
              <ReportMapDrawingSegment
                key={drawing.uid + '-' + i + '-segment'}
                points={[drawing.points[i].point, drawing.points[i + 1].point]}
              />
            )}
            <MapMarker
              key={drawing.uid + '-' + i + '-marker'}
              point={point.point}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <MapMarkerToolbar>
                {i === 0 && (
                  <Button
                    backgroundColor="black"
                    borderRadius="full"
                    textColor="white"
                    backgroundColorHover="accent-shade-5"
                    borderSize="x1"
                    borderColor="accent-shade-5"
                    size="x1"
                    textColorHover="white"
                    paddingHorizontal="x1"
                    paddingVertical="x1"
                    variant="primary"
                    alignChildrenVertical="middle"
                    onMouseEnter={() => setOverLink(true)}
                    onMouseLeave={() => setOverLink(false)}
                    onClick={() => {
                      reportEditor.updateDrawing(drawing.uid, {
                        points: [...drawing.points, point],
                      });
                    }}
                  >
                    {overLink && (
                      <Appear animation="FadeSlideUp">
                        <Icons.CheckCircle />
                      </Appear>
                    )}
                    {!overLink && <Icons.Link2 size="1.25rem" />}
                  </Button>
                )}
              </MapMarkerToolbar>
            </MapMarker>
          </Box>
        ))}

      {overLink && <ReportMapDrawingShow drawing={drawing} />}
      {!overLink && <ReportMapDrawingAdd drawing={drawing} />}
    </>
  );
};

export default ReportMapDrawingCreating;
