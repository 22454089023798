import { Box } from 'preshape';
import React from 'react';

type Props = {
  size?: string;
};

const JobBoardIcon = ({ size = '1.5rem' }: Props) => {
  return (
    <Box
      className="Icon"
      tag="svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      borderRadius="full"
      fill="currentColor"
    >
      {
        <svg
          id="Layer_2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 80.09 88.57"
        >
          <g id="Layer_1-2">
            <path
              className="cls-1"
              d="m15.71,31.64c.42,0,.85-.01,1.29-.04,2.27-.15,4.34-.77,6.16-1.73,5.37,5.06,21.84,19.55,23.35,19.55.04,0,.08-.01.09-.04.6-.86-12.03-17.26-17.56-24.9,1.56-2.39,2.47-5.14,2.57-7.86.06-1.62-.12-3.19-.5-4.66C29.68,6.24,25.19,1.82,18.7.31c-.27-.06-.52-.21-.77-.31h-4.25C9.47,1.07,5.75,2.88,3.06,6.49-.46,11.2-1.07,18.32,1.89,23.35c2.04,3.47,4.67,5.91,7.93,7.21,1.77.71,3.73,1.08,5.89,1.08Z"
            />
            <path
              className="cls-1"
              d="m94,73.94l-.04-.27c-.04-.32-.09-.63-.15-.95-.3-1.59-.82-3.21-1.65-5.09-.62-1.41-1.38-2.86-2.26-4.4v-3.9s0-.69,0-.99c0-13.5,0-26.99,0-40.49,0-3.09-1.46-5.12-4.19-5.77-.43-.1-.88-.13-1.32-.13-3.67-.02-3.36.02-6.88.02-1.03,0-23.89,0-42.3-.01.31,1.54.46,3.14.4,4.8-.09,2.54-.71,5.06-1.83,7.43,1.17,1.6,2.53,3.42,3.93,5.31,2.98,4.01,6.06,8.15,8.44,11.51,3.98,5.62,5.63,7.94,3.73,10.67-.76,1.09-2.03,1.75-3.37,1.75-1.6,0-2.49,0-12.83-8.84-4.17-3.57-8.45-7.37-11.34-10.02-1.63.56-3.34.91-5.1,1.02-.52.03-1.04.05-1.55.05-2.08,0-4.05-.29-5.89-.86v47.08c.34.94.54,1.97,1.07,2.79,1.1,1.69,2.8,2.28,4.79,2.27,13.72-.02,27.44,0,41.16,0h5.51s.37-.06,1-.08c2.64,2.97,6.07,4.91,10.07,5.61,1.02.18,2.05.27,3.08.27,7.79,0,14.74-5.26,16.9-12.78.32-1.11.52-2.24.61-3.39l.06-.32v-1.93l-.05-.36Zm-4.95,1.83c0,.05-.03.11-.03.16-.05.89-.2,1.77-.45,2.63-.68,2.38-2.01,4.42-3.73,5.96-1.12,1-2.41,1.79-3.81,2.33-1.43.56-2.96.87-4.55.87-.73,0-1.47-.06-2.21-.19-.92-.16-1.78-.41-2.59-.73-2.33-.93-4.24-2.5-5.72-4.69-1.21-1.78-1.91-3.86-2.09-6-.05-.61-.06-1.22.01-1.83.12-1.04.41-2.03.76-3.01.76-2.09,1.79-4.06,2.91-5.97,2.14-3.65,4.59-7.08,7.2-10.41.28-.35,1.64-2.16,1.64-2.16,0,0,1.4,1.75,1.73,2.17,2.01,2.56,3.92,5.19,5.68,7.94,1.41,2.19,2.72,4.44,3.78,6.82.35.8.67,1.61.92,2.45.15.5.29,1.01.39,1.53.06.34.1.68.15,1.02v1.12Z"
            />
            <path
              className="cls-1"
              d="m76.22,81.51c-.84,0-1.66-.15-2.42-.51-2.34-1.11-3.56-2.97-3.66-5.57-.03-.7-.55-1.23-1.22-1.23,0,0,0,0-.01,0-.68,0-1.22.56-1.2,1.26.02.54.06,1.09.16,1.62.74,3.89,4.2,6.79,8.18,6.86.04,0,.08,0,.12,0,.02,0,.05,0,.07,0,.8-.01,1.37-.77,1.16-1.54-.14-.52-.62-.89-1.18-.9Z"
            />
          </g>
        </svg>
      }
    </Box>
  );
};

export default JobBoardIcon;
