import {
  BlockPlanTemplate,
  BlockPlanTemplateType,
  PENDING_IMAGE_UPLOAD,
  PlanType,
} from '@drainify/types';
import {
  getBoundsFromPoint,
  getProjectBounds,
  getProjectLocation,
} from '@drainify/utils';
import destination from '@turf/destination';
import {
  Alert,
  Appear,
  Box,
  Button,
  Buttons,
  CheckBox,
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
  Text,
  useResizeObserver,
} from 'preshape';
import React, { ChangeEvent, useState } from 'react';
import { generatePath } from 'react-router-dom';
import useFileUpload from '../../hooks/useFileUpload';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import FileUpload from '../FileUpload/FileUpload';
import ImageFromFile from '../Image/ImageFromFile';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import PlanBlockPlanTemplateSelector, {
  getDrawingsForTemplate,
} from './PlanBlockPlanTemplateSelector';

type Props = ModalProps & {
  onClose: () => void;
  visible: boolean;
};

const PlanUploadModal = ({ onClose, visible, ...rest }: Props) => {
  const [sizeImageArea, refImageArea] = useResizeObserver<HTMLDivElement>();
  const nodeFileUpload = useFileUpload();
  const { project } = useProjectContext();
  const { reportEditor } = useReportEditorContext();

  const [fileToUpload, setFileToUpload] = useState<File>();
  const [originalHeight, setOriginalHeight] = useState<number>();
  const [originalWidth, setOriginalWidth] = useState<number>();
  const [name, setName] = useState<string>();
  const [isToScale, setIsToScale] = useState<boolean | undefined>(undefined);
  const [planType, setPlanType] = React.useState<PlanType | undefined>(
    reportEditor.report.planType
  );

  const [blockPlanTemplate, setBlockPlanTemplate] =
    React.useState<BlockPlanTemplate>({
      template: BlockPlanTemplateType.CUSTOM,
      x: 20,
      y: 20,
    });

  const handleSubmit = async () => {
    if (
      planType === PlanType.UPLOADED_PLAN &&
      isToScale !== undefined &&
      fileToUpload !== undefined
    ) {
      reportEditor.addPlan({
        imageUrl: PENDING_IMAGE_UPLOAD,
        originalWidth: 10,
        originalHeight: 10,
        name: 'plan',
        isToScale,
      });
      await nodeFileUpload.upload(
        generatePath('/projects/:projectId/report/plans/image', {
          projectId: project?.uid,
        }),
        fileToUpload,
        {
          originalWidth: originalWidth,
          originalHeight: originalHeight,
          name: name,
          isToScale: isToScale,
        }
      );
    } else if (planType === PlanType.BLOCK_PLAN) {
      const bounds = getProjectBounds(project);

      const origin = bounds?.coordinates[0].reduce(
        (nw, coord) => {
          const [lon, lat] = coord;
          if (lat > nw[1] || (lat === nw[1] && lon < nw[0])) {
            return coord;
          }
          return nw;
        },
        [-Infinity, -Infinity]
      );

      if (!origin) {
        return;
      }
      if (blockPlanTemplate.template === BlockPlanTemplateType.CUSTOM) {
        reportEditor.updateAsync?.({
          ...reportEditor.report,
          plan: null,
          gridMode: {
            isToScale: true,
          },
          planType: PlanType.BLOCK_PLAN,
        });
      } else {
        const ne = destination(origin, blockPlanTemplate.x || 10, 90, {
          units: 'meters',
        });
        const se = destination(ne, blockPlanTemplate.y || 10, 180, {
          units: 'meters',
        });
        const sw = destination(origin, blockPlanTemplate.y || 10, 180, {
          units: 'meters',
        });
        getDrawingsForTemplate(blockPlanTemplate.template, origin).forEach(
          (e) => reportEditor.addDrawingNoAsync(e)
        );

        reportEditor.updateAsync?.({
          ...reportEditor.report,
          bounds: {
            type: 'Polygon',
            coordinates: [
              [
                origin,
                ne.geometry.coordinates,
                se.geometry.coordinates,
                sw.geometry.coordinates,
              ],
            ],
          },
          plan: null,
          gridMode: {
            isToScale: true,
          },
          planType: PlanType.BLOCK_PLAN,
        });
      }
    } else if (planType === PlanType.GOOGLE_MAPS) {
      const location = getProjectLocation(project);
      if (location) {
        reportEditor.updateAsync?.({
          ...reportEditor.report,
          bounds: getBoundsFromPoint(location.point, 0.05),
          plan: null,
          gridMode: {
            isToScale: true,
          },
          planType: PlanType.GOOGLE_MAPS,
        });
      }
    } else if (planType === PlanType.NO_PLAN) {
      const location = getProjectLocation(project);
      if (location) {
        reportEditor.updateAsync?.({
          ...reportEditor.report,
          plan: null,
          planType: PlanType.NO_PLAN,
        });
      }
    }
    onClose();
  };

  const handleSelectFile = async (file: File) => {
    if (!name) {
      setName(file.name);
    }
    setFileToUpload(file);
  };

  React.useEffect(() => {
    if (!visible) {
      setName(undefined);
      setOriginalHeight(undefined);
      setOriginalWidth(undefined);
      setFileToUpload(undefined);
      setIsToScale(undefined);
    }
  }, [visible]);

  return (
    <Modal
      {...rest}
      animation="FadeSlideUp"
      maxWidth={FULL_SCREEN_MODAL_WIDTH}
      onClose={onClose}
      overlayBackgroundCloseOnClick={false}
      visible={visible}
    >
      <ModalHeader>
        <ModalTitle>plan</ModalTitle>
      </ModalHeader>

      <ModalBody flex="vertical">
        <Box flex="vertical" grow gap="x3">
          <Box padding="x2" flex="vertical" gap="x2">
            <CheckBox
              borderSize="x1"
              checked={planType === PlanType.GOOGLE_MAPS}
              onChange={() => {
                setPlanType(PlanType.GOOGLE_MAPS);
                setIsToScale(false);
              }}
            >
              <Text strong>Maps (default)</Text>
              <Text weak size="x1">
                Create plan over google maps. Measurements are calibrated
                correctly.
              </Text>
            </CheckBox>
            <CheckBox
              borderSize="x1"
              checked={
                planType === PlanType.UPLOADED_PLAN && isToScale === false
              }
              onChange={() => {
                setPlanType(PlanType.UPLOADED_PLAN);
                setIsToScale(false);
              }}
            >
              <Text strong>Uploaded: Focused plan</Text>
              <Text weak size="x1">
                {' '}
                Enlarges and isolates your plan. Use scale tool to calibrate
                measurements.
              </Text>
            </CheckBox>
            {planType === PlanType.UPLOADED_PLAN && isToScale === false && (
              <Appear animation="FadeSlideDown">
                <Box grow minHeight="200px" ref={refImageArea}>
                  <ImageFromFile
                    file={fileToUpload}
                    height={sizeImageArea.height}
                    onLoad={(e: ChangeEvent<HTMLImageElement>) => {
                      setOriginalHeight(e.target.naturalHeight);
                      setOriginalWidth(e.target.naturalWidth);
                    }}
                  />
                </Box>
                <FileUpload
                  accept="image/*"
                  maxWidth="300px"
                  onChange={handleSelectFile}
                  value={fileToUpload}
                >
                  <Icons.Image />
                  <Text
                    style={{
                      WebkitUserSelect: 'none',
                      msUserSelect: 'none',
                      userSelect: 'none',
                      WebkitTouchCallout: 'none',
                      KhtmlUserSelect: 'none',
                      MozUserSelect: 'none',
                    }}
                  >
                    Select image
                  </Text>
                </FileUpload>
              </Appear>
            )}
            <CheckBox
              borderSize="x1"
              checked={
                planType === PlanType.UPLOADED_PLAN && isToScale === true
              }
              onChange={() => {
                setPlanType(PlanType.UPLOADED_PLAN);
                setIsToScale(true);
              }}
            >
              <Text strong>Uploaded: Map overlay</Text>
              <Text weak size="x1">
                Plan will overlayed on the map. Scales are set to the map. Set
                bounds for image before uploaded, or change later
              </Text>
            </CheckBox>
            {planType === PlanType.UPLOADED_PLAN && isToScale === true && (
              <Appear animation="FadeSlideDown">
                <Box grow minHeight="200px" ref={refImageArea}>
                  <ImageFromFile
                    file={fileToUpload}
                    height={sizeImageArea.height}
                    onLoad={(e: ChangeEvent<HTMLImageElement>) => {
                      setOriginalHeight(e.target.naturalHeight);
                      setOriginalWidth(e.target.naturalWidth);
                    }}
                  />
                </Box>
                <FileUpload
                  accept="image/*"
                  maxWidth="300px"
                  onChange={handleSelectFile}
                  value={fileToUpload}
                >
                  <Icons.Image />
                  <Text
                    style={{
                      WebkitUserSelect: 'none',
                      msUserSelect: 'none',
                      userSelect: 'none',
                      WebkitTouchCallout: 'none',
                      KhtmlUserSelect: 'none',
                      MozUserSelect: 'none',
                    }}
                  >
                    Select image
                  </Text>
                </FileUpload>
              </Appear>
            )}
            <CheckBox
              borderSize="x1"
              checked={planType === PlanType.BLOCK_PLAN}
              onChange={() => {
                setPlanType(PlanType.BLOCK_PLAN);
              }}
            >
              <Text strong>Block plan</Text>
              <Text weak size="x1">
                Add block plan. You can alter size, set scales once enabled.
              </Text>
              {planType === PlanType.BLOCK_PLAN && (
                <>
                  <Appear>
                    <PlanBlockPlanTemplateSelector
                      template={blockPlanTemplate}
                      setTemplate={setBlockPlanTemplate}
                    />
                  </Appear>
                </>
              )}
            </CheckBox>
            <CheckBox
              borderSize="x1"
              checked={planType === PlanType.NO_PLAN}
              onChange={() => {
                setPlanType(PlanType.NO_PLAN);
              }}
            >
              <Text strong>No plan</Text>
              <Text weak size="x1">
                No plan. No interactive report will generated.
              </Text>
            </CheckBox>
            {planType === PlanType.UPLOADED_PLAN && isToScale === undefined && (
              <Alert
                padding="x3"
                color="negative"
                backgroundColor="negative-shade-1"
              >
                <Text size="x1" strong align="middle">
                  {' '}
                  You must select a plan type{' '}
                </Text>
              </Alert>
            )}
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Buttons alignChildrenHorizontal="around" flex="horizontal">
          <Button
            color="accent"
            disabled={
              planType === undefined ||
              (planType === PlanType.UPLOADED_PLAN &&
                fileToUpload === undefined)
            }
            grow
            onClick={handleSubmit}
            variant="primary"
            size="x3"
          >
            <Text>Confirm</Text>
          </Button>
          <Button size="x3" grow onClick={onClose} variant="secondary">
            Cancel
          </Button>
        </Buttons>
      </ModalFooter>
    </Modal>
  );
};

export default PlanUploadModal;
