import getNearestPointOnLine, {
  NearestPointOnLine,
} from '@turf/nearest-point-on-line';
import React, { PropsWithChildren } from 'react';
import { useMapContext } from '../../../Map/Map';
import MapBoundsAddVectorMarker from '../../../Map/MapBounds/MapBoundsAddVectorMarker';
import useDrawMeasurementMap from '../ReportMapElements/useDrawMeasurementMap';
import { useReportMapContext } from '../ReportMapProvider';

type Props = {
  ids: [string, string];
  points: [GeoJSON.Point, GeoJSON.Point];
  onHover?: (ids: [string, string]) => void;
  onHoverOut?: (ids: [string, string]) => void;
  active?: boolean;
  onAdd?: (
    point: NearestPointOnLine['geometry'],
    points: [string, string]
  ) => void;
};

const ReportMapDrawingSegmentEditable = ({
  ids,
  points,
  onAdd,
  onHover,
  onHoverOut,
  active,
}: PropsWithChildren<Props>) => {
  const { addPointerMoveListener, getNearestPoint, isInteractive } =
    useMapContext();
  const { focusedMeasurementUid } = useReportMapContext();
  const EDGE_NEAR_DISTANCE = 25;

  useDrawMeasurementMap({
    points: points,
    visible: true,
    strokeColor: active ? 'red' : 'black',
    strokeWeight: active ? 3 : 1,
  });

  const [addVectorMarkerPosition, setAddVectorMarkerPosition] = React.useState<
    NearestPointOnLine | undefined | false
  >();

  React.useEffect(() => {
    return addPointerMoveListener((point) => {
      if (!isInteractive) {
        return null;
      }

      const nearestPointOnLine = getNearestPointOnLine(
        {
          type: 'MultiLineString',
          coordinates: [[points[0].coordinates, points[1].coordinates]],
        },
        point
      );

      if (
        !getNearestPoint(
          point,
          [nearestPointOnLine.geometry],
          EDGE_NEAR_DISTANCE
        )
      ) {
        onHoverOut?.(ids);
        return setAddVectorMarkerPosition(undefined);
      }

      const handlePoints: GeoJSON.Point[] = points;

      if (getNearestPoint(point, handlePoints, EDGE_NEAR_DISTANCE)) {
        onHoverOut?.(ids);
        return setAddVectorMarkerPosition(undefined);
      }

      onHover?.(ids);
      if (onAdd) {
        setAddVectorMarkerPosition(nearestPointOnLine);
      }
    });
  }, [
    addPointerMoveListener,
    isInteractive,
    getNearestPoint,
    focusedMeasurementUid,
    points,
  ]);

  if (
    addVectorMarkerPosition === false ||
    addVectorMarkerPosition === undefined
  ) {
    return null;
  }

  return (
    <MapBoundsAddVectorMarker
      onClick={() => {
        onAdd?.(addVectorMarkerPosition?.geometry, ids);
        setAddVectorMarkerPosition(undefined);
      }}
      point={addVectorMarkerPosition?.geometry}
    />
  );
};

export default ReportMapDrawingSegmentEditable;
