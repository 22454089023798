import { BoundaryLine } from '@drainify/types';
import { Box, Icons, Text, TextProps } from 'preshape';
import React from 'react';

type Props = TextProps & {
  boundaryLine?: BoundaryLine;
};

const BoundaryLineView = ({ boundaryLine, ...rest }: Props) => {
  return (
    <Text {...rest} size="x3">
      <Box
        alignChildrenVertical="middle"
        flex="horizontal"
        gap="x4"
        grow
        margin="x2"
      >
        <Box alignChildrenVertical="middle" flex="horizontal" gap="x2" grow>
          <Box flex="horizontal" alignChildrenVertical="middle">
            <Icons.ChevronLeft size="0.5rem" />
            <Text>-</Text>
            <Icons.ChevronRight size="0.5rem" />
          </Box>
          <Box grow>
            <Text strong>{`Boudary line ${boundaryLine?.index}`}</Text>
          </Box>
        </Box>
      </Box>
    </Text>
  );
};

export default BoundaryLineView;
