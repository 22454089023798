import { PlanType } from '@drainify/types';
import { getProjectBounds, shouldBlackOut } from '@drainify/utils';
import { Appear, Box, Icons } from 'preshape';
import React, { useContext } from 'react';
import { v4 } from 'uuid';
import { useMapContext } from '../Map/Map';
import MapBounds from '../Map/MapBounds/MapBounds';
import MapLine from '../Map/MapMarker/MapLine';
import MapLineGhost from '../Map/MapMarker/MapLineGhost';
import MapMarker from '../Map/MapMarker/MapMarker';
import ReportMap from '../Report/ReportMap/ReportMap';
import ReportMapDrawingShow from '../Report/ReportMap/ReportMapDrawing/ReportMapDrawingShow';
import ReportMapGrid from '../Report/ReportMap/ReportMapElements/ReportMapGrid';
import ReportMapNodeIcon from '../Report/ReportMap/ReportMapElements/ReportMapNodeIcon';
import MobileNodeAppMiddleCrosshair from './MobileNodeAppMiddleCrosshair';
import NodeMapButtons from './NodeMapButtons';
import { NodeMapContext } from './NodeMapProvider';
import NodeMapUserMarker from './NodeMapUserMarker';

const MobileNodeAppElements = () => {
  const {
    visible,
    project,
    report,
    onChange,
    onChangePoints,
    markType,
    isCustomerReport,
    startPoint,
    endPoint,
    initialPoints,
    setInitialPoints,
  } = useContext(NodeMapContext);
  const { mapCenter, getProjection, getZoom } = useMapContext();
  const [selectedPoint, setSelectedPoint] =
    React.useState<GeoJSON.Point | null>(null);
  const [localNode, setLocalNode] = React.useState<GeoJSON.Point | null>(null);
  const [measurementPoints, setMeasurementPoints] = React.useState<
    GeoJSON.Point[]
  >([]);
  const [drawingPoints, setDrawingPoints] = React.useState<GeoJSON.Point[]>([]);
  const [routePoints, setRoutePoints] = React.useState<GeoJSON.Point[]>([]);
  const [rectanglePoints, setRectanglePoints] = React.useState<GeoJSON.Point[]>(
    []
  );

  const closed = (): boolean => {
    return (
      drawingPoints.length > 2 &&
      drawingPoints[0] === drawingPoints[drawingPoints.length - 1]
    );
  };

  const handleOnAddPoint = (point: GeoJSON.Point) => {
    if (markType === 'node') {
      setLocalNode(point);
      onChange?.(point);
    } else if (markType === 'measurement-point') {
      setMeasurementPoints([...measurementPoints, point]);
      onChangePoints?.([...measurementPoints, point]);
    } else if (markType === 'drawing-point') {
      if (
        drawingPoints.length > 1 &&
        drawingPoints[0] === drawingPoints[drawingPoints.length - 1]
      ) {
        return;
      }
      setDrawingPoints([...drawingPoints, point]);
      onChangePoints?.([...drawingPoints, point]);
    } else if (markType === 'route') {
      setRoutePoints([...routePoints, point]);
      onChangePoints?.([...routePoints, point]);
    } else if (markType === 'rectangle') {
      setRectanglePoints([...rectanglePoints, point]);
      onChangePoints?.([...routePoints, point]);
    }
  };

  // const handleOnRemovePoint = (point: GeoJSON.Point) => {
  // if (markType === 'measurement-point') {
  // setMeasurementPoints(
  // measurementPoints.filter((i) => i.coordinates !== point.coordinates)
  // );
  // } else if (markType === 'drawing-point') {
  // setDrawingPoints(
  // drawingPoints.filter((i) => i.coordinates !== point.coordinates)
  // );
  // } else if (markType === 'route') {
  // setDrawingPoints(
  // routePoints.filter((i) => i.coordinates !== point.coordinates)
  // );
  // }
  // };

  const handleLinkPoint = () => {
    setDrawingPoints([...drawingPoints, drawingPoints[0]]);
    onChangePoints?.([...drawingPoints, drawingPoints[0]]);
  };

  const handleOnClear = () => {
    if (markType === 'measurement-point') {
      setMeasurementPoints([]);
    } else if (markType === 'drawing-point') {
      setDrawingPoints([]);
    } else if (markType === 'route') {
      setRoutePoints([]);
      onChangePoints?.([]);
    } else if (markType === 'rectangle') {
      setRectanglePoints([]);
      onChangePoints?.([]);
    }
  };

  React.useEffect(() => {
    recalculateSelectedPoint();
  }, [measurementPoints, drawingPoints, mapCenter]);

  const recalculateSelectedPoint = () => {
    if (!mapCenter) {
      return;
    }
    const scale = Math.pow(2, getZoom() || 1);

    const centerPixel = getProjection()?.fromLatLngToPoint(
      new google.maps.LatLng({
        lat: mapCenter.coordinates[1],
        lng: mapCenter.coordinates[0],
      })
    );
    if (!centerPixel) {
      return;
    }
    centerPixel.x = centerPixel.x * scale;
    centerPixel.y = centerPixel.y * scale;
    let set: GeoJSON.Point[] = [];
    if (markType === 'drawing-point') {
      set = drawingPoints;
    } else if (markType === 'measurement-point') {
      set = measurementPoints;
    }

    const point =
      set.find((e) => {
        if (!e) {
          return;
        }
        const pixelCoord2 = getProjection()?.fromLatLngToPoint(
          new google.maps.LatLng({
            lat: e.coordinates[1],
            lng: e.coordinates[0],
          })
        );
        if (!pixelCoord2 || !centerPixel) {
          return;
        }
        pixelCoord2.x = pixelCoord2.x * scale;
        pixelCoord2.y = pixelCoord2.y * scale;

        // Calculate the distance in pixels between the two points
        const pixelDistance = Math.sqrt(
          Math.pow(pixelCoord2.x - centerPixel.x, 2) +
            Math.pow(pixelCoord2.y - centerPixel.y, 2)
        );

        return pixelDistance <= 5;
      }) || null;
    setSelectedPoint(point);
  };

  React.useEffect(() => {
    if (markType === 'route') {
      setRoutePoints(initialPoints);
    }
  }, [initialPoints]);

  React.useEffect(() => {
    if (!visible) {
      setLocalNode(null);
      setMeasurementPoints([]);
      setDrawingPoints([]);
      setInitialPoints([]);
    }
  }, [visible]);

  const pb = getProjectBounds(project, report);

  if (markType === 'drawing-point') {
    return (
      <ReportMap
        featureOpts={{ 'Elements:move': true, 'Elements:edit': false }}
      >
        {report?.planType === PlanType.BLOCK_PLAN && (
          <ReportMapGrid polygon={pb} />
        )}
        <MobileNodeAppMiddleCrosshair />
        {drawingPoints.length > 0 && !closed() && (
          <MapLineGhost
            points={[drawingPoints[drawingPoints.length - 1], mapCenter]}
          />
        )}
        {!closed() &&
          drawingPoints.length > 1 &&
          drawingPoints.map(
            (e, i) =>
              drawingPoints.length > i &&
              i < drawingPoints.length - 1 && (
                <MapLine
                  key={i}
                  points={[drawingPoints[i], drawingPoints[i + 1]]}
                />
              )
          )}

        {!closed() && drawingPoints.length > 0 && (
          <MapMarker point={drawingPoints[0]}>
            <Box
              borderRadius="full"
              height={20}
              width={20}
              textColor="white"
              flex="horizontal"
              alignChildrenHorizontal="middle"
              backgroundColor="accent-shade-5"
              alignChildrenVertical="middle"
            >
              <Icons.Link2 />
            </Box>
          </MapMarker>
        )}

        {drawingPoints.length > 2 &&
          closed() &&
          drawingPoints[0] === drawingPoints[drawingPoints.length - 1] && (
            <ReportMapDrawingShow
              drawing={{
                uid: 'temp',
                index: 3,
                points: drawingPoints.map((e) => ({
                  uid: 'nope',
                  point: e,
                })),
              }}
            />
          )}
        {!closed() &&
          drawingPoints.length > 2 &&
          selectedPoint === drawingPoints[0] && (
            <ReportMapDrawingShow
              drawing={{
                uid: 'temp',
                index: 3,
                points: [...drawingPoints, drawingPoints[0]].map((e) => ({
                  uid: 'nope',
                  point: e,
                })),
              }}
            />
          )}
        {pb && <MapBounds bounds={pb} blackout={shouldBlackOut(report)} />}
        <NodeMapUserMarker showAccuracyPadding />
        <NodeMapButtons
          bounds={pb?.bbox}
          visible={visible}
          isCustomerReport={isCustomerReport}
          markType={markType}
          handleOnAddPoint={handleOnAddPoint}
          // handleOnRemovePoint={handleOnRemovePoint}
          handleOnClear={handleOnClear}
          selectedPoint={selectedPoint}
          measuringPoints={measurementPoints}
          drawingPoints={drawingPoints}
          rectanglePoints={rectanglePoints}
          handleLinkPoint={handleLinkPoint}
          closed={closed}
        />
      </ReportMap>
    );
  }

  if (markType === 'measurement-point') {
    return (
      <ReportMap
        featureOpts={{ 'Elements:move': true, 'Elements:edit': false }}
      >
        {report?.planType === PlanType.BLOCK_PLAN && (
          <ReportMapGrid polygon={pb} />
        )}
        <MobileNodeAppMiddleCrosshair />;
        {measurementPoints.map((measurementPoint, index) => (
          <MapMarker
            point={measurementPoint}
            key={index}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Box
              borderRadius="full"
              height={measurementPoint === selectedPoint ? 15 : 10}
              width={measurementPoint === selectedPoint ? 15 : 10}
              backgroundColor={
                measurementPoint === selectedPoint
                  ? 'negative-shade-3'
                  : 'accent-shade-3'
              }
              flex="horizontal"
              alignChildrenHorizontal="middle"
              alignChildrenVertical="middle"
            />
          </MapMarker>
        ))}
        {measurementPoints.length > 1 &&
          measurementPoints.map(
            (e, i) =>
              measurementPoints.length > i &&
              i < measurementPoints.length - 1 && (
                <MapLine
                  key={i}
                  points={[measurementPoints[i], measurementPoints[i + 1]]}
                />
              )
          )}
        {!closed() && measurementPoints.length > 0 && (
          <MapLineGhost
            points={[
              measurementPoints[measurementPoints.length - 1],
              mapCenter,
            ]}
          />
        )}
        {report?.planType === PlanType.BLOCK_PLAN && (
          <ReportMapGrid polygon={pb} />
        )}
        {pb && <MapBounds bounds={pb} blackout={shouldBlackOut(report)} />}
        <NodeMapUserMarker showAccuracyPadding />
        <NodeMapButtons
          bounds={pb?.bbox}
          visible={visible}
          isCustomerReport={isCustomerReport}
          markType={markType}
          handleOnAddPoint={handleOnAddPoint}
          // handleOnRemovePoint={handleOnRemovePoint}
          handleOnClear={handleOnClear}
          selectedPoint={selectedPoint}
          measuringPoints={measurementPoints}
          drawingPoints={drawingPoints}
          rectanglePoints={rectanglePoints}
          handleLinkPoint={handleLinkPoint}
          closed={closed}
        />
      </ReportMap>
    );
  }

  if (markType === 'node') {
    return (
      <ReportMap
        featureOpts={{ 'Elements:move': true, 'Elements:edit': false }}
      >
        {localNode && (
          <MapMarker point={localNode}>
            <Appear animation="Pop">
              <ReportMapNodeIcon />
            </Appear>
          </MapMarker>
        )}
        <MobileNodeAppMiddleCrosshair />
        {report?.planType === PlanType.BLOCK_PLAN && (
          <ReportMapGrid polygon={pb} />
        )}
        {pb && <MapBounds bounds={pb} blackout={shouldBlackOut(report)} />}
        <NodeMapUserMarker showAccuracyPadding />
        <NodeMapButtons
          bounds={pb?.bbox}
          visible={visible}
          isCustomerReport={isCustomerReport}
          markType={markType}
          handleOnAddPoint={handleOnAddPoint}
          // handleOnRemovePoint={handleOnRemovePoint}
          handleOnClear={handleOnClear}
          selectedPoint={selectedPoint}
          measuringPoints={measurementPoints}
          drawingPoints={drawingPoints}
          rectanglePoints={rectanglePoints}
          handleLinkPoint={handleLinkPoint}
          closed={closed}
        />
      </ReportMap>
    );
  }

  if (markType === 'plan') {
    return (
      <ReportMap
        featureOpts={{ 'Elements:move': true, 'Elements:edit': false }}
      >
        {pb && <MapBounds bounds={pb} blackout={shouldBlackOut(report)} />}
        {report?.planType === PlanType.BLOCK_PLAN && (
          <ReportMapGrid polygon={pb} />
        )}
        <NodeMapUserMarker showAccuracyPadding />
        <NodeMapButtons
          bounds={pb?.bbox}
          visible={visible}
          isCustomerReport={isCustomerReport}
          markType={markType}
          handleOnAddPoint={handleOnAddPoint}
          // handleOnRemovePoint={handleOnRemovePoint}
          handleOnClear={handleOnClear}
          selectedPoint={selectedPoint}
          measuringPoints={measurementPoints}
          drawingPoints={drawingPoints}
          rectanglePoints={rectanglePoints}
          handleLinkPoint={handleLinkPoint}
          closed={closed}
        />
      </ReportMap>
    );
  }

  if (markType === 'route') {
    return (
      <>
        <MobileNodeAppMiddleCrosshair />;
        <MapMarker point={startPoint}>
          <Box
            borderRadius="full"
            backgroundColor={'accent-shade-3'}
            flex="horizontal"
            alignChildrenHorizontal="middle"
            alignChildrenVertical="middle"
            width="10px"
            height="10px"
          />
        </MapMarker>
        {report?.planType === PlanType.BLOCK_PLAN && (
          <ReportMapGrid polygon={pb} />
        )}
        <MapMarker point={endPoint}>
          <Box
            borderRadius="full"
            backgroundColor={'accent-shade-3'}
            flex="horizontal"
            width="10px"
            height="10px"
            alignChildrenHorizontal="middle"
            alignChildrenVertical="middle"
          />
        </MapMarker>
        {[startPoint, ...routePoints, endPoint]
          .slice(0, routePoints.length)
          .map((e, i) => (
            <MapLine
              key={i}
              points={[e, [startPoint, ...routePoints, endPoint][i + 1]]}
            />
          ))}
        {routePoints.map((e, i) => (
          <MapMarker point={e} key={`${i}-routepoint`}>
            <Box
              borderRadius="full"
              backgroundColor={'accent-shade-3'}
              flex="horizontal"
              alignChildrenHorizontal="middle"
              alignChildrenVertical="middle"
              width="10px"
              height="10px"
            />
          </MapMarker>
        ))}
        <MapLine
          points={[
            [startPoint, ...routePoints, endPoint][routePoints.length],
            mapCenter,
          ]}
        />
        <MapLineGhost points={[mapCenter, endPoint]} />
        {pb && <MapBounds bounds={pb} blackout={shouldBlackOut(report)} />}
        <NodeMapUserMarker showAccuracyPadding />
        <NodeMapButtons
          bounds={pb?.bbox}
          visible={visible}
          isCustomerReport={isCustomerReport}
          markType={markType}
          handleOnAddPoint={handleOnAddPoint}
          // handleOnRemovePoint={handleOnRemovePoint}
          handleOnClear={handleOnClear}
          selectedPoint={selectedPoint}
          measuringPoints={measurementPoints}
          drawingPoints={drawingPoints}
          rectanglePoints={rectanglePoints}
          handleLinkPoint={handleLinkPoint}
          closed={closed}
        />
      </>
    );
  }

  if (markType === 'rectangle') {
    return (
      <ReportMap
        featureOpts={{ 'Elements:move': true, 'Elements:edit': false }}
      >
        <MobileNodeAppMiddleCrosshair />;
        {rectanglePoints.length == 1 && (
          <>
            <MapMarker point={rectanglePoints[0]}>
              <Box
                borderRadius="full"
                width="10px"
                height="10px"
                backgroundColor="text-shade-1"
                flex="horizontal"
                alignChildrenHorizontal="middle"
                alignChildrenVertical="middle"
              />
            </MapMarker>
            <ReportMapDrawingShow
              drawing={{
                uid: '',
                // @ts-ignore
                points: [
                  rectanglePoints[0],
                  {
                    type: 'Point',
                    coordinates: [
                      rectanglePoints[0].coordinates[0],
                      mapCenter.coordinates[1],
                    ],
                  },
                  mapCenter,
                  {
                    type: 'Point',
                    coordinates: [
                      mapCenter.coordinates[0],
                      rectanglePoints[0].coordinates[1],
                    ],
                  },
                ].map((e) => ({
                  uid: v4(),
                  point: e,
                })),
              }}
            />
          </>
        )}
        {rectanglePoints.length === 2 && (
          <ReportMapDrawingShow
            drawing={{
              uid: '',
              // @ts-ignore
              points: [
                rectanglePoints[0],
                {
                  type: 'Point',
                  coordinates: [
                    rectanglePoints[0].coordinates[0],
                    rectanglePoints[1].coordinates[1],
                  ],
                },
                rectanglePoints[1],
                {
                  type: 'Point',
                  coordinates: [
                    rectanglePoints[1].coordinates[0],
                    rectanglePoints[0].coordinates[1],
                  ],
                },
              ].map((e) => ({
                uid: v4(),
                point: e,
              })),
            }}
          />
        )}
        {report?.planType === PlanType.BLOCK_PLAN && (
          <ReportMapGrid polygon={pb} />
        )}
        {pb && <MapBounds bounds={pb} blackout={shouldBlackOut(report)} />}
        <NodeMapUserMarker showAccuracyPadding />
        <NodeMapButtons
          bounds={pb?.bbox}
          visible={visible}
          isCustomerReport={isCustomerReport}
          markType={markType}
          handleOnAddPoint={handleOnAddPoint}
          // //handleOnRemovePoint={handleOnRemovePoint}
          handleOnClear={handleOnClear}
          selectedPoint={selectedPoint}
          measuringPoints={measurementPoints}
          drawingPoints={drawingPoints}
          rectanglePoints={rectanglePoints}
          handleLinkPoint={handleLinkPoint}
          closed={closed}
        />
      </ReportMap>
    );
  }

  return <></>;
};

export default MobileNodeAppElements;
