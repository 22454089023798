import { Drawing, DrawingPoint } from '@drainify/types';
import { getMidPointBetweenPoints } from '@drainify/utils';
import { Appear, Box, Button, Text } from 'preshape';
import React from 'react';
import MapMarker from '../../../../Map/MapMarker/MapMarker';
import MapNotification from '../../../../Map/MapNotification/MapNotification';
import { useReportEditorContext } from '../../../ReportEditorProvider';
import ReportMapDrawingSegmentEditable from '../ReportMapDrawingSegmentEditable';

type Props = {
  drawing: Drawing;
  updateDimensions: (points: DrawingPoint[]) => void;
  onCancel: () => void;
};

const ReportMapDrawingEditOperationDivergeConverge = ({
  drawing,
  updateDimensions,
  onCancel,
}: Props) => {
  const [activeLine, setActiveLine] =
    React.useState<[DrawingPoint, DrawingPoint]>();
  const { reportEditor } = useReportEditorContext();

  const [selectedLine, setSelectedLine] = React.useState<
    [DrawingPoint, DrawingPoint] | undefined
  >(undefined);

  const selectLine = () => selectedLine === undefined;
  const movePoints = () => selectedLine !== undefined;

  function handleDivergeConverge(point: GeoJSON.Point, movedPointId: string) {
    if (!selectedLine || selectedLine.length !== 2) {
      throw new Error('Invalid selected line. Must have exactly two points.');
    }

    const movedPoint = selectedLine.find((p) => p.uid === movedPointId);
    const staticPoint = selectedLine.find((p) => p.uid !== movedPointId);

    if (!movedPoint || !staticPoint) {
      throw new Error('Could not find moved or static point.');
    }

    const staticCoords = staticPoint.point.coordinates;
    const movedCoords = movedPoint.point.coordinates;

    const projectPointOntoLine = (
      lineStart: [number, number],
      lineEnd: [number, number],
      target: [number, number]
    ): [number, number] => {
      const dx = lineEnd[0] - lineStart[0];
      const dy = lineEnd[1] - lineStart[1];
      const lengthSquared = dx * dx + dy * dy;

      if (lengthSquared === 0) {
        return lineStart;
      }

      const t =
        ((target[0] - lineStart[0]) * dx + (target[1] - lineStart[1]) * dy) /
        lengthSquared;

      return [lineStart[0] + t * dx, lineStart[1] + t * dy];
    };

    const constrainedMovedPointCoords = projectPointOntoLine(
      staticCoords as [number, number],
      movedCoords as [number, number],
      point.coordinates as [number, number]
    );

    const newMovedPoint = {
      uid: movedPoint.uid,
      point: {
        type: 'Point',
        coordinates: constrainedMovedPointCoords,
      },
    };

    // Calculate the mirrored position for the static point
    const midpoint = getMidPointBetweenPoints(
      staticPoint.point,
      movedPoint.point
    );
    if (!midpoint) {
      return null;
    }
    const newStaticPointCoords: [number, number] = [
      midpoint.coordinates[0] -
        (constrainedMovedPointCoords[0] - midpoint.coordinates[0]), // Mirror X relative to midpoint
      midpoint.coordinates[1] -
        (constrainedMovedPointCoords[1] - midpoint.coordinates[1]), // Mirror Y relative to midpoint
    ];

    const newStaticPoint = {
      uid: staticPoint.uid,
      point: {
        type: 'Point',
        coordinates: newStaticPointCoords,
      },
    };

    // Update dimensions for the drawing points
    updateDimensions(
      drawing.points.map((e) => {
        if (e.uid === selectedLine[0].uid) {
          return {
            ...e,
            point: {
              type: 'Point',
              coordinates:
                e.uid === movedPointId
                  ? newMovedPoint.point.coordinates
                  : newStaticPoint.point.coordinates,
            },
          };
        } else if (e.uid === selectedLine[1].uid) {
          return {
            ...e,
            point: {
              type: 'Point',
              coordinates:
                e.uid === movedPointId
                  ? newMovedPoint.point.coordinates
                  : newStaticPoint.point.coordinates,
            },
          };
        } else return e;
      })
    );
  }

  return (
    <>
      <MapNotification color={'black'} typePosition="top-right">
        {selectLine() && (
          <Appear animation="FadeSlideUp">
            {' '}
            <Text>Select a line</Text>{' '}
          </Appear>
        )}
        {movePoints() && (
          <Appear animation="FadeSlideUp">
            {' '}
            <Text>Manipulate points</Text>{' '}
          </Appear>
        )}

        <Button onClick={onCancel}>Cancel</Button>
        {movePoints() && (
          <Button
            variant="primary"
            color="positive"
            onClick={() => {
              reportEditor.updateDrawing(drawing.uid, {
                points: drawing.points,
              });
              onCancel();
            }}
          >
            Save
          </Button>
        )}
      </MapNotification>
      {selectLine() &&
        drawing.points.map((_, i) => (
          <Box key={i + `drawing`}>
            {i < drawing.points.length - 1 && (
              <ReportMapDrawingSegmentEditable
                key={drawing.uid + '-' + i + '-segment'}
                ids={[drawing.points[i].uid, drawing.points[i + 1].uid]}
                points={[drawing.points[i].point, drawing.points[i + 1].point]}
                onAdd={() =>
                  setSelectedLine([drawing.points[i], drawing.points[i + 1]])
                }
                active={
                  activeLine &&
                  activeLine[0].uid === drawing.points[i].uid &&
                  activeLine[1].uid === drawing.points[i + 1].uid
                }
                onHover={() =>
                  setActiveLine([drawing.points[i], drawing.points[i + 1]])
                }
                onHoverOut={(e) => {
                  if (
                    activeLine &&
                    e[0] === activeLine[0].uid &&
                    e[1] === activeLine[1].uid
                  ) {
                    setActiveLine(undefined);
                  }
                }}
              />
            )}
          </Box>
        ))}

      {selectedLine !== undefined && (
        <>
          <ReportMapDrawingSegmentEditable
            ids={[selectedLine[0].uid, selectedLine[1].uid]}
            points={[
              drawing.points.find((e) => e.uid === selectedLine[0].uid)!.point,
              drawing.points.find((e) => e.uid === selectedLine[1].uid)!.point,
            ]}
          />
          <MapMarker
            point={
              drawing.points.find((e) => e.uid === selectedLine[0].uid)?.point
            }
            onDrag={(p) => handleDivergeConverge(p, selectedLine[0].uid)}
          >
            <Box
              borderRadius="full"
              width={10}
              height={10}
              textColor="light-shade-1"
              backgroundColor="negative-shade-5"
              padding="x1"
            ></Box>
          </MapMarker>
          <MapMarker
            point={
              drawing.points.find((e) => e.uid === selectedLine[1].uid)?.point
            }
            onDrag={(p) => handleDivergeConverge(p, selectedLine[1].uid)}
          >
            <Box
              borderRadius="full"
              textColor="light-shade-1"
              width={10}
              height={10}
              backgroundColor="negative-shade-5"
              padding="x1"
            ></Box>
          </MapMarker>
        </>
      )}
    </>
  );
};

export default ReportMapDrawingEditOperationDivergeConverge;
