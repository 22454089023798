import { isSection } from '@drainify/utils';
import { DateTime } from 'luxon';
import { Box, Button, Buttons, Icons, Text } from 'preshape';
import React from 'react';
import HiddenContent from '../../../HiddenContent/HiddenContent';
import InspectionAdvancedActions from '../../../Inspection/InspectionAdvancedActions';
import InspectionCreateButton from '../../../Inspection/InspectionCreateButton';
import InspectionList from '../../../Inspection/InspectionList';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import { useHasPermission } from '../../../Permissions/Permissions';
import { useProjectContext } from '../../../Project/ProjectProvider';
import SectionAdditionalSummary from '../../../Section/SectionAdditionalSummary';
import SectionDeleteButton from '../../../Section/SectionDeleteButton';
import SectionSummary from '../../../Section/SectionSummary';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { ReportErrorMessageInteractive } from '../../ReportErrorMessage';
import { useReportMapContext } from '../ReportMapProvider';

const ReportMapSidebarSection = () => {
  const { reportEditor, editSection } = useReportEditorContext();
  const {
    activeSidebar,
    focusedSectionUid,
    focusNode,
    focusInspection,
    setActiveSidebar,
    unfocus,
    isEditingSectionShape,
    toggleEditSectionShape,
  } = useReportMapContext();
  const { activeBooking } = useProjectContext();
  const section = reportEditor.getSectionByUid(focusedSectionUid);
  const error = reportEditor.validateSection(section);
  const hasPermission = useHasPermission('projects:update');

  const handleBack = () => {
    unfocus();
    setActiveSidebar('sections');
  };

  return (
    <MapSidebarContentItem
      active={activeSidebar === 'info' && !!focusedSectionUid}
      onBack={handleBack}
      title={reportEditor.getSectionName(section)}
    >
      <Text weak size="x1" padding="x4">
        {section &&
          'Created at ' +
            DateTime.fromISO(section?.createdAt).toFormat('DDDD @ HH:mm ')}
      </Text>

      {error && isSection(section) && (
        <MapSidebarContentItemSection>
          {
            <ReportErrorMessageInteractive
              errors={error}
              onEdit={(step) => editSection(section?.uid, step)}
            />
          }
        </MapSidebarContentItemSection>
      )}

      {hasPermission &&
        section?.uid &&
        reportEditor.getNodeByUid(section?.nodeStartUid)?.point &&
        reportEditor.getNodeByUid(section?.nodeEndUid) &&
        (!activeBooking?.completedAt || activeBooking.completedAt === null) && (
          <MapSidebarContentItemSection title="Utils">
            <Box flex="horizontal" alignChildrenHorizontal="around">
              <Button
                active={isEditingSectionShape}
                onClick={() => toggleEditSectionShape()}
                gap="x1"
                color="accent"
                variant="tertiary"
              >
                <Icons.Edit3 />
                <Text>Edit pipe route</Text>
              </Button>
            </Box>
          </MapSidebarContentItemSection>
        )}

      <MapSidebarContentItemSection
        action={
          reportEditor.getSectionInspections(section?.uid, activeBooking?.uid)
            .length === 0 ? (
            <InspectionCreateButton
              variant="tertiary"
              onCreate={focusInspection}
              sectionUid={focusedSectionUid}
            />
          ) : undefined
        }
        title="Inspections"
      >
        <InspectionList
          onItemClick={focusInspection}
          sectionUid={section?.uid}
        />
        <InspectionAdvancedActions sectionUid={section?.uid} />
      </MapSidebarContentItemSection>

      <MapSidebarContentItemSection>
        <SectionSummary onNodeClick={focusNode} section={section} />
      </MapSidebarContentItemSection>

      <MapSidebarContentItemSection>
        <HiddenContent>
          <SectionAdditionalSummary section={section} />
        </HiddenContent>
      </MapSidebarContentItemSection>

      <MapSidebarContentItemSection>
        <Buttons>
          <SectionDeleteButton
            grow
            sectionUid={section?.uid}
            variant="tertiary"
          />
        </Buttons>
      </MapSidebarContentItemSection>
    </MapSidebarContentItem>
  );
};

export default ReportMapSidebarSection;
