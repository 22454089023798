import { hasPotentialRepairs } from '@drainify/utils';
import {
  Button,
  ButtonProps,
  Buttons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'preshape';
import React, { useContext } from 'react';
import useBooking from '../../hooks/useBooking';
import { AuthenticationContext } from '../Authentication/Authenticate';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import JobRepairReviewWizard from './JobRepairWizard';

type Props = ButtonProps & {
  onSubmit: () => void;
};

const JobFinishButton = ({ onSubmit, ...rest }: Props) => {
  const { activeBookingId } = useProjectContext();
  const { query, update } = useBooking(activeBookingId);
  const [finishModalVisible, setFinishModalVisible] = React.useState(false);
  const [repairWizardVisible, setRepairWizardVisible] = React.useState(false);
  const { reportEditor } = useReportEditorContext();
  const { user } = useContext(AuthenticationContext);

  const handleJobComplete = async () => {
    if (query.data) {
      await update.mutateAsync({
        ...query.data,
        completedAt: new Date(),
      });
      onSubmit();
    }
  };

  if (!query.data || !activeBookingId) {
    return null;
  }

  return (
    <>
      <Button
        onClick={() => {
          if (
            hasPotentialRepairs(reportEditor, activeBookingId) &&
            (user?.email?.endsWith('drainify.io') ||
              process.env.NODE_ENV === 'development')
          ) {
            setFinishModalVisible(true);
          } else {
            handleJobComplete();
          }
        }}
        color="positive"
        variant="primary"
        {...rest}
      >
        Finish & publish
      </Button>
      {finishModalVisible && (
        <Modal
          visible
          onClose={() => {
            setFinishModalVisible(false);
          }}
        >
          <ModalHeader>Suggested repairs</ModalHeader>
          <ModalBody>
            We have found some potenital repairs over X inspections.
          </ModalBody>
          <ModalFooter>
            <Buttons>
              <Button
                variant="primary"
                color="positive"
                grow
                onClick={() => setRepairWizardVisible(true)}
              >
                Review repair suggestions
              </Button>
              <Button
                variant="primary"
                color="accent"
                grow
                onClick={() => {
                  handleJobComplete();
                  setFinishModalVisible(false);
                }}
              >
                Finish and publish
              </Button>
            </Buttons>
          </ModalFooter>
        </Modal>
      )}
      <JobRepairReviewWizard
        visible={repairWizardVisible}
        onClose={() => {
          setFinishModalVisible(false);
          setFinishModalVisible(false);
          handleJobComplete();
        }}
      />
    </>
  );
};

export default JobFinishButton;
