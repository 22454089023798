import { PlanType, ReportView } from '@drainify/types';
import {
  ReportEditor,
  ReportElementType,
  shouldBlackOut,
} from '@drainify/utils';
import getBbox from '@turf/bbox';
import { Alert, Box, Button, Buttons, Icons, Label, Text } from 'preshape';
import React from 'react';
import { isMobile } from '../../../../utils/client';
import FeatureFlag from '../../../FeatureFlag/FeatureFlag';
import { useMapContext } from '../../../Map/Map';
import MapBounds from '../../../Map/MapBounds/MapBounds';
import { useMapLayersContext } from '../../../Map/MapLayers/MapLayers';
import MapContentArea from '../../../Map/MapLayout/MapContentArea';
import MapNotification from '../../../Map/MapNotification/MapNotification';
import ReportMapMeasurement from '../../../Measurement/ReportMapMeasurement';
import ReportMapMeasurementScale from '../../../Measurement/ReportMapMeasurementScale';
import { useReportCustomerContext } from '../../../ReportAccessPage/ReportCustomerProvider';
import ReportMapDrawing from '../ReportMapDrawing/ReportMapDrawing';
import { useReportMapContext } from '../ReportMapProvider';
import ReportMapAnnotation from './ReportMapAnnotation';
import ReportMapBoundaryLine from './ReportMapBoundaryLine';
import ReportMapGrid from './ReportMapGrid';
import ReportMapNodeCustomer from './ReportMapNodeCustomer';
import ReportMapObservation from './ReportMapObservation';
import ReportMapPlanCustomer from './ReportMapPlanCustomer';
import ReportMapSectionCustomer from './ReportMapSectionCustomer';
import ReportMapSectionSimple from './ReportMapSectionSimple';

export const reportMapLayerOrder = {
  [ReportElementType.PLAN]: 0,
  [ReportElementType.DRAWING]: 0,
  [ReportElementType.SECTION]: 1,
  [ReportElementType.NODE]: 2,
  [ReportElementType.OBSERVATION]: 3,
  [ReportElementType.MEASUREMENT]: 4,
};

type Props = {
  reportView: ReportView;
};

const PERFORMANCE_MODE_ITEMS = 10;

const ReportMapElements = ({ reportView }: Props) => {
  const { bounds } = reportView.report;
  const reportEditor = new ReportEditor(reportView.report, () => {});
  const { focusedElementType, focusedElementUid } = useReportMapContext();
  const [showAll, setShowAll] = React.useState(false);
  const { fitToBounds } = useMapContext();
  const { isLayerVisible } = useMapLayersContext();

  const {
    showSimpleSections,
    showSectionLabels,
    showObservations,
    showNodeLabels,
  } = useReportCustomerContext();

  const [sectionPageOffset, setSecitonPageOffset] = React.useState(
    PERFORMANCE_MODE_ITEMS
  );

  const [performanceMode, setPerformanceMode] = React.useState(
    reportEditor.report.nodes.filter((e) => e.point !== undefined).length > 30
  );

  const showFullPlan = () => {
    setShowAll(true);
    if (bounds) {
      const sectionsToView = reportEditor.report.nodes
        .filter((e) => e.point !== undefined)
        .map((e) => e.point!);

      const bendyGeo: GeoJSON.MultiLineString = {
        type: 'MultiLineString',
        coordinates: [sectionsToView.map((e) => e.coordinates)],
      };
      bendyGeo && fitToBounds({ bbox: getBbox(bendyGeo) });
    }
  };

  const handleSetSectionOffset = (offset: number) => {
    setShowAll(false);
    setSecitonPageOffset(offset);
    const sectionsToView = reportEditor.report.sections
      .slice(offset, offset + PERFORMANCE_MODE_ITEMS)
      .map((e) => [
        reportEditor.getNodeByUid(e.nodeStartUid)?.point!,
        reportEditor.getNodeByUid(e.nodeEndUid)?.point!,
      ])
      .filter((e) => e[0]! && e[1]!);

    const bendyGeo: GeoJSON.MultiLineString = {
      type: 'MultiLineString',
      coordinates: sectionsToView.map((e) => e.map((e) => e.coordinates)),
    };
    if (bendyGeo.coordinates.length > 0) {
      bendyGeo && fitToBounds({ bbox: getBbox(bendyGeo) });
    }
  };

  if (performanceMode && focusedElementType === undefined && !isMobile()) {
    return (
      <>
        {bounds && (
          <MapBounds
            bounds={bounds}
            onChange={undefined}
            blackout={shouldBlackOut(reportEditor.report)}
          />
        )}
        {reportEditor.report.scale && (
          <ReportMapMeasurementScale
            key={reportEditor.report.scale.uid} // TODO
            scale={reportEditor.report.scale}
          />
        )}

        {reportEditor.report.sections
          .filter(
            (e, i) =>
              !(
                i > sectionPageOffset &&
                i < sectionPageOffset + PERFORMANCE_MODE_ITEMS
              )
          )
          .map((section) => (
            <ReportMapSectionSimple
              key={section.uid + '-simple'}
              section={section}
              showLabel={showAll}
            />
          ))}

        {reportEditor.report.sections
          .slice(sectionPageOffset, sectionPageOffset + PERFORMANCE_MODE_ITEMS)
          .map((section) =>
            showAll ? (
              <ReportMapSectionSimple
                key={section.uid + '-simple'}
                section={section}
                showLabel
              />
            ) : (
              <ReportMapSectionCustomer
                key={section.uid}
                section={section}
                shouldRenderObservations={true}
                showLabels={true}
              />
            )
          )}

        {reportEditor.report.drawings.map((drawing) => (
          <ReportMapDrawing key={drawing.uid} drawing={drawing} />
        ))}

        {reportEditor.report.plan && (
          <ReportMapPlanCustomer
            plan={reportEditor.report.plan}
            key={reportEditor.report.plan.uid}
          />
        )}
        {reportEditor.report.boundaryLines.map((boundaryLine) => (
          <ReportMapBoundaryLine
            boundaryLine={boundaryLine}
            key={boundaryLine.uid}
          />
        ))}
        {reportEditor.report.annotations.map((annotation) => (
          <ReportMapAnnotation annotation={annotation} key={annotation.uid} />
        ))}

        <MapContentArea position={'bottom'}>
          <MapNotification color={'black'} typePosition="top-right">
            <Box flex="vertical" gap="x3">
              <Alert
                color={'positive'}
                backgroundColor="positive-shade-1"
                padding="x3"
              >
                <Box flex="horizontal" alignChildrenVertical="middle">
                  <Text strong>Performance mode</Text>
                </Box>
                <Text size="x2">We noticed your project is very big!</Text>

                <Text size="x2">
                  We've simplified the graphic for some of your sections
                </Text>
                <Text size="x2">
                  You can only interact with the most recent element
                </Text>
                <Text size="x2">Use the sidebar to add/edit older nodes</Text>
              </Alert>

              <Buttons flex="horizontal" alignChildrenHorizontal="around">
                <Button
                  onClick={() =>
                    handleSetSectionOffset(
                      sectionPageOffset - PERFORMANCE_MODE_ITEMS
                    )
                  }
                  disabled={sectionPageOffset === PERFORMANCE_MODE_ITEMS}
                >
                  <Icons.ArrowLeft />
                </Button>
                <Label
                  flex="vertical"
                  alignChildrenVertical="middle"
                  backgroundColor="dark-shade-1"
                  textColor="accent-shade-5"
                >
                  {sectionPageOffset / PERFORMANCE_MODE_ITEMS}/
                  {Math.floor(
                    reportEditor.report.sections.length / PERFORMANCE_MODE_ITEMS
                  )}
                </Label>
                <Button
                  onClick={() =>
                    handleSetSectionOffset(
                      sectionPageOffset + PERFORMANCE_MODE_ITEMS
                    )
                  }
                  disabled={
                    sectionPageOffset + PERFORMANCE_MODE_ITEMS >
                    reportEditor.report.sections.length
                  }
                >
                  <Icons.ArrowRight />
                </Button>
                <FeatureFlag>
                  <Button onClick={() => setPerformanceMode(false)}>
                    Override
                  </Button>
                </FeatureFlag>
                <Button onClick={() => showFullPlan()}>Full plan</Button>
              </Buttons>
            </Box>
          </MapNotification>
        </MapContentArea>
      </>
    );
  } else if (
    performanceMode &&
    !isMobile() &&
    focusedElementUid !== undefined
  ) {
    return (
      <>
        {bounds && (
          <MapBounds
            bounds={bounds}
            blackout={shouldBlackOut(reportEditor.report)}
          />
        )}
        {reportEditor.report.planType === PlanType.BLOCK_PLAN && (
          <ReportMapGrid polygon={bounds} />
        )}
        {reportEditor.report.plan && (
          <ReportMapPlanCustomer
            plan={reportEditor.report.plan}
            key={reportEditor.report.plan.uid}
          />
        )}

        {focusedElementType === ReportElementType.DRAWING &&
          reportEditor.report.drawings
            .filter((e) => e.uid === focusedElementUid)
            .map((drawing) => (
              <ReportMapDrawing key={drawing.uid} drawing={drawing} />
            ))}
        {focusedElementType === ReportElementType.SECTION &&
          reportEditor.report.sections
            .filter((e) => e.uid === focusedElementUid)
            .map((section) => (
              <>
                <ReportMapSectionCustomer
                  key={section.uid}
                  section={section}
                  shouldRenderObservations={showObservations}
                  showLabels={showSectionLabels}
                />
                {section.nodeStartUid && (
                  <ReportMapNodeCustomer
                    key={section.nodeStartUid}
                    node={reportEditor.getNodeByUid(section.nodeStartUid)!}
                    showLabel={showNodeLabels}
                  />
                )}
                {section.nodeEndUid && (
                  <ReportMapNodeCustomer
                    key={section.nodeEndUid}
                    node={reportEditor.getNodeByUid(section.nodeEndUid)!}
                    showLabel={showNodeLabels}
                  />
                )}
              </>
            ))}

        {focusedElementType === ReportElementType.INSPECTION &&
          reportEditor.report.sections
            .filter(
              (e) =>
                e.uid ===
                reportEditor.getInspectionByUid(focusedElementUid)?.sectionUid
            )
            .map((section) => (
              <>
                <ReportMapSectionCustomer
                  key={section.uid}
                  section={section}
                  shouldRenderObservations={showObservations}
                  showLabels={showSectionLabels}
                />
                {section.nodeStartUid && (
                  <ReportMapNodeCustomer
                    key={section.nodeStartUid}
                    node={reportEditor.getNodeByUid(section.nodeStartUid)!}
                    showLabel={showNodeLabels}
                  />
                )}
                {section.nodeEndUid && (
                  <ReportMapNodeCustomer
                    key={section.nodeEndUid}
                    node={reportEditor.getNodeByUid(section.nodeEndUid)!}
                    showLabel={showNodeLabels}
                  />
                )}
              </>
            ))}

        {focusedElementType === ReportElementType.OBSERVATION &&
          reportEditor.report.sections
            .filter(
              (e) =>
                e.uid ===
                reportEditor.getInspectionByUid(
                  reportEditor.getObservationByUid(focusedElementUid)
                    ?.inspectionUid
                )?.sectionUid
            )
            .map((section) => (
              <>
                <ReportMapSectionCustomer
                  key={section.uid}
                  section={section}
                  shouldRenderObservations={false}
                  showLabels={true}
                />
                <ReportMapObservation
                  observation={
                    reportEditor.getObservationByUid(focusedElementUid)!
                  }
                />
                {section.nodeStartUid && (
                  <ReportMapNodeCustomer
                    key={section.nodeStartUid}
                    node={reportEditor.getNodeByUid(section.nodeStartUid)!}
                    showLabel={showNodeLabels}
                  />
                )}
                {section.nodeEndUid && (
                  <ReportMapNodeCustomer
                    key={section.nodeEndUid}
                    node={reportEditor.getNodeByUid(section.nodeEndUid)!}
                    showLabel={showNodeLabels}
                  />
                )}
              </>
            ))}

        {focusedElementType === ReportElementType.MEASUREMENT &&
          reportEditor.report.measurements
            .filter((e) => e.uid === focusedElementUid)
            .map((measurement) => (
              <ReportMapMeasurement
                key={measurement.uid}
                measurement={measurement}
                points={measurement.points}
              />
            ))}
        {focusedElementType === ReportElementType.NODE &&
          reportEditor.report.nodes
            .filter((e) => e.uid === focusedElementUid)
            .map((node) => (
              <>
                <ReportMapNodeCustomer
                  key={node.uid}
                  node={node}
                  showLabel={showNodeLabels}
                />
                {reportEditor.report.sections
                  .filter(
                    (e) =>
                      e.nodeStartUid === node.uid || e.nodeEndUid === node.uid
                  )
                  .map((section) => (
                    <ReportMapSectionCustomer
                      key={section.uid}
                      section={section}
                      shouldRenderObservations={true}
                      showLabels={true}
                    />
                  ))}
              </>
            ))}

        {focusedElementType === ReportElementType.SCALE}
      </>
    );
  }
  return (
    <>
      {bounds && (
        <MapBounds
          bounds={bounds}
          onChange={undefined}
          blackout={shouldBlackOut(reportEditor.report)}
        />
      )}
      {reportEditor.report.planType === PlanType.BLOCK_PLAN && (
        <ReportMapGrid polygon={bounds} />
      )}
      {reportEditor.report.boundaryLines.map((boundaryLine) => (
        <ReportMapBoundaryLine
          boundaryLine={boundaryLine}
          key={boundaryLine.uid}
        />
      ))}
      {reportEditor.report.annotations.map((annotation) => (
        <ReportMapAnnotation annotation={annotation} key={annotation.uid} />
      ))}
      {reportEditor.report.plan && (
        <ReportMapPlanCustomer
          plan={reportEditor.report.plan}
          key={reportEditor.report.plan.uid}
        />
      )}

      {focusedElementType === undefined &&
        reportEditor.report.nodes.map((node) => (
          <ReportMapNodeCustomer
            key={node.uid}
            node={node}
            showLabel={showNodeLabels}
          />
        ))}

      {focusedElementType !== undefined &&
        reportEditor.report.nodes
          .filter((e) => isLayerVisible(ReportElementType.NODE, e.uid))
          .map((node) => (
            <ReportMapNodeCustomer
              key={node.uid}
              node={node}
              showLabel={showNodeLabels}
            />
          ))}

      {!showSimpleSections &&
        focusedElementType === undefined &&
        reportEditor.report.sections.map((section) => (
          <ReportMapSectionCustomer
            key={section.uid}
            section={section}
            showLabels={showSectionLabels}
            shouldRenderObservations={showObservations}
          />
        ))}

      {showSimpleSections &&
        focusedElementType === undefined &&
        reportEditor.report.sections.map((section) => (
          <ReportMapSectionSimple
            key={section.uid}
            section={section}
            showLabel={showSectionLabels}
          />
        ))}

      {focusedElementType !== undefined &&
        reportEditor.report.sections.map((section) => (
          <ReportMapSectionCustomer
            key={section.uid}
            section={section}
            showLabels={true}
            shouldRenderObservations={true}
          />
        ))}

      {reportEditor.report.measurements.map((measurement) => (
        <ReportMapMeasurement
          key={measurement.uid}
          measurement={measurement}
          points={measurement.points}
        />
      ))}

      {reportEditor.report.drawings.map((drawing) => (
        <ReportMapDrawing key={drawing.uid} drawing={drawing} />
      ))}

      {reportEditor.report.scale && (
        <ReportMapMeasurementScale
          key={reportEditor.report.scale.uid}
          scale={reportEditor.report.scale}
        />
      )}
    </>
  );
};

export default ReportMapElements;
