import { MethodOfInspection } from '@drainify/types';
import { DateTime } from 'luxon';
import {
  Box,
  Button,
  Icons,
  Placement,
  PlacementContent,
  PlacementManager,
  PlacementReference,
  Text,
} from 'preshape';
import React from 'react';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useReportMapContext } from '../Report/ReportMap/ReportMapProvider';

type Props = {
  sectionUid?: string;
};

const InspectionAdvancedActions = ({ sectionUid }: Props) => {
  const [visible, setVisible] = React.useState(false);
  const { createNewInspection, reportEditor } = useReportEditorContext();
  const { activeBookingId } = useProjectContext();
  const { focusInspection } = useReportMapContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');

  if (!hasUpdateProjectPermission || !sectionUid) {
    return null;
  }

  if (reportEditor.getSectionByUid(sectionUid)?.attributes.flow === undefined) {
    return (
      <Text
        strong
        size="x1"
        textColor="text-shade-4"
        padding="x2"
        align="middle"
      >
        Add section flow to add new inspections
      </Text>
    );
  }

  return (
    <PlacementManager>
      <PlacementReference>
        {(props) => (
          <Box {...props}>
            <Box flex="horizontal" alignChildrenHorizontal="end">
              <Button
                variant="tertiary"
                onClick={() => setVisible(!visible)}
                flex="horizontal"
                alignChildrenVertical="end"
              >
                <Text size="x1" textColor="accent-shade-3">
                  More...
                </Text>
              </Button>
            </Box>
          </Box>
        )}
      </PlacementReference>

      <Placement
        visible={visible}
        animation="FadeSlideDown"
        width="reference"
        zIndex={1}
      >
        <PlacementContent backgroundColor="background-shade-2">
          <Box flex="vertical" gap="x2" padding="x2">
            <Text size="x1" strong align="middle">
              Additional inspections
            </Text>
            <Button
              variant="tertiary"
              color="accent"
              grow
              onClick={() => {
                setVisible(false);
                createNewInspection(sectionUid);
              }}
            >
              <Box flex="horizontal" gap="x2">
                <Icons.Plus />
                <Text>Additional inspection</Text>
              </Box>
            </Button>
            <Button
              grow
              variant="tertiary"
              color="accent"
              onClick={() => {
                const s = reportEditor.getSectionByUid(sectionUid);
                if (s) {
                  const section = reportEditor.addSectionNoUpdate({
                    ...s,
                    additionalPoints: undefined,
                  });
                  const inspection = reportEditor.addInspection({
                    sectionUid: section.uid,
                    jobId: activeBookingId!,
                    createdAt: DateTime.now().toISO(),
                    attributes: {
                      date: DateTime.now().toISODate(),
                      time: DateTime.now().toISOTime(),
                      methodOfInspection: MethodOfInspection.C,
                    },
                  });

                  focusInspection(inspection.uid);
                }
              }}
            >
              <Box flex="horizontal" gap="x2">
                <Icons.ArrowUp />
                <Text> parrelel run </Text>
              </Box>
            </Button>
          </Box>
        </PlacementContent>
      </Placement>
    </PlacementManager>
  );
};

export default InspectionAdvancedActions;
