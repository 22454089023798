"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isScale = exports.isMeasurement = exports.getMeasurementValidations = void 0;
const yup_1 = require("yup");
const getMeasurementValidations = () => {
    return (0, yup_1.object)().shape({
        points: (0, yup_1.array)().of((0, yup_1.object)()).min(2).required(),
        length: (0, yup_1.number)(),
        name: (0, yup_1.string)().max(40),
    });
};
exports.getMeasurementValidations = getMeasurementValidations;
/**
 *
 */
// export type SectionPoints = { // a: GeoJSON.Point | null; // b: GeoJSON.Point | null; // bbox: GeoJSON.BBox | null; // distance: number | null; // line: GeoJSON.LineString | null; // poi: GeoJSON.Point | null; };
/**
 *
 */
// export const emptySectionPoints: SectionPoints = { a: null, b: null, bbox: null, distance: null, line: null, poi: null, };
const isMeasurement = (s) => !!s && 'uid' in s;
exports.isMeasurement = isMeasurement;
const isScale = (s) => !!s && 'uid' in s;
exports.isScale = isScale;
