import { OrgRole } from '@drainify/types';
import {
  Box,
  BoxProps,
  Button,
  Icons,
  Modal,
  ModalBody,
  ModalHeader,
  Text,
  Tooltip,
} from 'preshape';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import ClassroomIcon from '../../../components/IconsMisc/ClassroomIcon';
import JobBoardIcon from '../../../components/IconsMisc/JobBoardIcon';
import MembersInsightList from '../../../components/Insights/MembersInsightList';
import Logo from '../../../components/Logo/Logo';
import { useHasPermission } from '../../../components/Permissions/Permissions';
import UsageDetails from '../../../components/Usage/UsageDetails';
import useMeOrgMember from '../../../hooks/useMeOrgMember';
import AdminPage from '../Pages/AdminPage';
import ClassroomPage from '../Pages/ClassroomPage';
import AdminMenu from './AdminMenu';
import UserMenu from './UserMenu';

const LayoutHeader = ({ children, ...rest }: PropsWithChildren<BoxProps>) => {
  const [adminPageVisible, setAdminPageVisible] = React.useState(false);
  const [classroomPageVisible, setClassroomPageVisible] = React.useState(false);
  const [insigtsPageVisible, setInsightsPageVisible] = React.useState(false);
  const { query } = useMeOrgMember();

  return (
    <>
      <Box
        {...rest}
        alignChildrenHorizontal="between"
        alignChildrenVertical="middle"
        backgroundColor="background-shade-1"
        flex="horizontal"
        gap="x8"
        paddingHorizontal="x4"
        paddingVertical="x2"
        textColor="text-shade-1"
      >
        <Box>
          <Link to="/">
            <Text
              backgroundColor="accent-shade-4"
              borderRadius="x1"
              padding="x2"
              textColor="white"
            >
              <Logo height={24} />
            </Text>
          </Link>
        </Box>

        <Box grow flex="horizontal">
          {children}
        </Box>

        <Box flex="horizontal" gap="x2">
          <UsageDetails />

          {useHasPermission('admin-page:view') && (
            <Tooltip content={'Job board'} placement="bottom">
              {(props) => (
                <Button
                  {...props}
                  borderColor="background-shade-3"
                  onClick={() => setAdminPageVisible(true)}
                  size="x1"
                >
                  <Box
                    paddingLeft="x1"
                    paddingRight="x1"
                    textColor="accent-shade-3"
                  >
                    <JobBoardIcon size="1.5rem" />
                  </Box>
                </Button>
              )}
            </Tooltip>
          )}

          <Tooltip content={'Classroom'} placement="bottom">
            {(props) => (
              <Button
                {...props}
                borderColor="background-shade-3"
                onClick={() => setClassroomPageVisible(true)}
                size="x1"
                textColor="accent-shade-3"
              >
                <ClassroomIcon size="2rem" />
              </Button>
            )}
          </Tooltip>

          {query.data?.role !== OrgRole.ENGINEER && (
            <Tooltip content={'Insights'} placement="bottom">
              {(props) => (
                <Button
                  {...props}
                  borderColor="background-shade-3"
                  onClick={() => setInsightsPageVisible(true)}
                  size="x1"
                  textColor="accent-shade-3"
                >
                  <Icons.Activity size="1.5rem" />
                </Button>
              )}
            </Tooltip>
          )}
        </Box>

        <Box flex="horizontal" gap="x2" alignChildrenVertical="middle">
          <AdminMenu />
          <UserMenu />
        </Box>
      </Box>
      <Modal
        visible={adminPageVisible}
        onClose={() => setAdminPageVisible(false)}
      >
        <ModalHeader>Job board</ModalHeader>
        <ModalBody height={'650px'}>
          <AdminPage onClose={() => setAdminPageVisible(false)} />
        </ModalBody>
      </Modal>
      <Modal
        visible={classroomPageVisible}
        onClose={() => setClassroomPageVisible(false)}
        maxWidth={'1000px'}
      >
        <ModalHeader></ModalHeader>
        <ModalBody height={'650px'}>
          <ClassroomPage />
        </ModalBody>
      </Modal>
      <Modal
        visible={insigtsPageVisible}
        onClose={() => setInsightsPageVisible(false)}
        maxWidth={'1000px'}
      >
        <ModalHeader>Insights</ModalHeader>
        <ModalBody height={'650px'}>
          <Box>
            <Text strong>Engineer performance indicator</Text>
            <MembersInsightList onClose={() => setInsightsPageVisible(false)} />
          </Box>
        </ModalBody>
      </Modal>
    </>
  );
};

export default LayoutHeader;
