import { GeoJSONPolygonBlank } from '@drainify/utils';
import bboxPolygon from '@turf/bbox-polygon';
import bearing from '@turf/bearing';
import difference from '@turf/difference';
import distance from '@turf/distance';
import getNearestPointOnLine, {
  NearestPointOnLine,
} from '@turf/nearest-point-on-line';
import transformScale from '@turf/transform-scale';
import transformTranslate from '@turf/transform-translate';
import uniqBy from 'lodash.uniqby';
import { colorBlack, colorDarkShade1, colorLightShade1 } from 'preshape';
import React, {
  useCallback,
  useRef,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useReportMapContext } from '../../Report/ReportMap/ReportMapProvider';
import { useMapContext } from '../Map';
import useDrawGeometry from '../useDrawGeometry';
import { MapBounds } from '../useMap';
import MapBoundsAddVectorMarker from './MapBoundsAddVectorMarker';
import MapBoundsHandle from './MapBoundsHandle';

type Props = {
  bounds: GeoJSON.Polygon;
  onChange?: (bounds: GeoJSON.Polygon) => void;
  blackout: boolean;
};

const getMaskForGeometry = (
  maskBounds: GeoJSON.Polygon,
  mapBounds?: GeoJSON.BBox
) => {
  try {
    if (!mapBounds || !maskBounds || !maskBounds.coordinates.length) {
      return undefined;
    }

    const bbox = transformScale(bboxPolygon(mapBounds), 1.25);
    const mask = difference(bbox, maskBounds) || undefined;

    if (!mask || mask?.geometry.type === 'MultiPolygon') {
      return undefined;
    }

    return mask.geometry;
  } catch (e) {
    console.error(e);
  }
};

const EDGE_NEAR_DISTANCE = 25;

const MapBounds = ({ bounds: geometry, onChange, blackout }: Props) => {
  const { focusedElementType } = useReportMapContext();
  const {
    addBoundsChangeListener,
    addPointerMoveListener,
    getBounds,
    getNearestPoint,
    isInteractive,
  } = useMapContext();

  const [shiftingBounds, setShiftingBounds] = React.useState<
    number | undefined
  >();
  const [cachedBounds, setCachedBounds] = React.useState<GeoJSON.Polygon>();
  const refGeometry = useRef(geometry);
  const movement = useRef<GeoJSON.Point>();
  const refIsDragging = useRef(false);
  const [addVectorMarkerPosition, setAddVectorMarkerPosition] = useState<
    NearestPointOnLine | undefined | false
  >();

  const coordinates = useMemo(
    // NOTE : Before 4/11/24 there was atime where bounds were being stored incorrectly. Update those when you have time
    // It expects [[lat, lon], [lat,lon], [lat,lon], [lat,lon]]
    // For a time, you were storing [lat, lon], [lat,lon], [lat,lon], [lat,lon]
    () =>
      uniqBy(
        // @ts-ignore
        geometry.coordinates.length === 1
          ? geometry.coordinates[0]
          : [geometry.coordinates],
        ([lng, lat]) => `${lng}:${lat}`
      ),
    [geometry]
  );

  const maskGeometry = useMemo(
    () => getMaskForGeometry(geometry, getBounds()?.bbox),
    [geometry, getBounds]
  );

  const [hasMaskGeometry, setHasMaskGeometry] = useState(!!maskGeometry);

  const updateMaskFill = useDrawGeometry(
    useMemo(
      () => ({
        geometry: GeoJSONPolygonBlank,
        style: {
          fillColor: colorDarkShade1,
          fillOpacity: blackout ? 1 : 0.5,
          strokeColor: colorDarkShade1,
          strokePosition: google.maps.StrokePosition.INSIDE,
          strokeWeight: 1,
          zIndex: 9,
        },
      }),
      []
    )
  );

  const updateMaskOuterLine = useDrawGeometry(
    useMemo(
      () => ({
        geometry: GeoJSONPolygonBlank,
        style: {
          fillColor: colorBlack,
          fillOpacity: blackout ? 1 : 0.5,
          strokeColor: colorLightShade1,
          strokePosition: google.maps.StrokePosition.OUTSIDE,
          strokeWeight: 1,
          zIndex: 9,
        },
      }),
      [blackout]
    )
  );

  const update = useCallback(() => {
    // setHasMaskGeometry(!!geometry);
    setHasMaskGeometry(true);
    if (cachedBounds) {
      updateMaskFill?.({ geometry: cachedBounds });
    } else {
      const bounds = getBounds();
      const geometry = getMaskForGeometry(refGeometry.current, bounds?.bbox);

      if (geometry) {
        updateMaskFill?.({ geometry });
        updateMaskOuterLine?.({ geometry });
      }
    }
  }, [getBounds, updateMaskFill, updateMaskOuterLine, cachedBounds]);

  const createHandlers = useCallback(
    (
      cb: (polygon: GeoJSON.Polygon) => void,
      predicate: (index: number, point?: GeoJSON.Point) => GeoJSON.Position[]
    ) =>
      coordinates.map((_, index) => (point?: GeoJSON.Point) => {
        refGeometry.current = {
          type: 'Polygon',
          coordinates: [predicate(index, point)],
        };

        cb(refGeometry.current);
      }),
    [coordinates]
  );

  const createDragHandlers = useCallback(
    (cb: (polygon: GeoJSON.Polygon) => void) =>
      createHandlers(cb, (index, point) => {
        return refGeometry.current.coordinates[0].map((p, i, { length }) =>
          point && (i === index || (index === 0 && i === length - 1))
            ? point.coordinates
            : p
        );
      }),
    [createHandlers]
  );

  const dragHandlers = useMemo(
    () =>
      onChange
        ? createDragHandlers(() => {
            refIsDragging.current = true;
            update();
          })
        : [],
    [createDragHandlers, onChange, update]
  );

  const shiftDragHandler = (point: GeoJSON.Point) => {
    if (!movement.current) {
      movement.current = point;
    }
    const d = distance(movement.current, point);
    const b = bearing(movement.current, point);
    const translatedPolygon = transformTranslate(geometry, d, b);
    setCachedBounds({
      type: 'Polygon',
      coordinates: translatedPolygon.coordinates,
    });
  };

  const shiftDragHandlerEnd = (point: GeoJSON.Point) => {
    if (movement.current) {
      const d = distance(movement.current, point);
      const b = bearing(movement.current, point);
      const translatedPolygon = transformTranslate(geometry, d, b);
      onChange?.({
        type: 'Polygon',
        coordinates: translatedPolygon.coordinates,
      });
      movement.current = undefined;
    }
    setCachedBounds(undefined);
    setShiftingBounds(undefined);
  };

  const dragEndHandlers = useMemo(
    () =>
      onChange
        ? createDragHandlers((polygon) => {
            refIsDragging.current = false;
            onChange(polygon);
          })
        : [],
    [createDragHandlers, onChange]
  );

  const removeHandles = useMemo(
    () =>
      onChange
        ? createHandlers(onChange, (index) => {
            const points = refGeometry.current.coordinates[0].filter(
              (_, i) => index !== i
            );

            if (index === 0) {
              points.splice(-1);
              points.push(points[0]);
            }

            return points;
          })
        : [],
    [createHandlers, onChange]
  );

  const handleAddPoint = () => {
    if (addVectorMarkerPosition) {
      const {
        properties: { index },
      } = addVectorMarkerPosition;

      if (index !== undefined) {
        setAddVectorMarkerPosition(false);
        onChange?.({
          ...refGeometry.current,
          coordinates: [
            [
              ...refGeometry.current.coordinates[0].slice(0, index + 1),
              addVectorMarkerPosition.geometry.coordinates,
              ...refGeometry.current.coordinates[0].slice(index + 1),
            ],
          ],
        });
      }
    }
  };

  useEffect(() => {
    return addPointerMoveListener((point) => {
      // We don't want to do anything while we're dragging stuff about
      if (!isInteractive) {
        return null;
      }

      const nearestPointOnLine = getNearestPointOnLine(
        {
          type: 'MultiLineString',
          coordinates: refGeometry.current.coordinates,
        },
        point
      );

      if (
        !getNearestPoint(
          point,
          [nearestPointOnLine.geometry],
          EDGE_NEAR_DISTANCE
        )
      ) {
        return setAddVectorMarkerPosition(undefined);
      }

      const handlePoints: GeoJSON.Point[] =
        refGeometry.current.coordinates[0].map((coordinates) => ({
          type: 'Point',
          coordinates,
        }));

      if (getNearestPoint(point, handlePoints, EDGE_NEAR_DISTANCE)) {
        return setAddVectorMarkerPosition(undefined);
      }

      setAddVectorMarkerPosition(nearestPointOnLine);
    });
  }, [addPointerMoveListener, isInteractive, getNearestPoint]);

  useEffect(() => {
    refGeometry.current = geometry;
    update();
  }, [geometry, update]);

  useEffect(() => {
    return addBoundsChangeListener(() => {
      update();
    });
  }, [addBoundsChangeListener, update]);

  if (focusedElementType) {
    return null;
  }

  return (
    <>
      {coordinates.map((position, index) => (
        <MapBoundsHandle
          index={index}
          key={position.join(',')}
          onDrag={
            shiftingBounds !== undefined
              ? shiftDragHandler
              : dragHandlers[index]
          }
          onDragEnd={
            shiftingBounds ? shiftDragHandlerEnd : dragEndHandlers[index]
          }
          onRemove={coordinates.length > 3 ? removeHandles[index] : undefined}
          isShifting={shiftingBounds !== undefined}
          recenter={(i) => setShiftingBounds(i)}
          position={position}
          visible={
            hasMaskGeometry &&
            !blackout &&
            (shiftingBounds === undefined || shiftingBounds === index)
          }
        />
      ))}

      {!blackout && addVectorMarkerPosition !== false && onChange && (
        <MapBoundsAddVectorMarker
          onClick={handleAddPoint}
          point={addVectorMarkerPosition?.geometry}
        />
      )}
    </>
  );
};

export default MapBounds;
