import { Box } from 'preshape';
import React, { useContext } from 'react';
import { MapBounds } from '../Map/useMap';
import { NodeMapContext } from './NodeMapProvider';

type Props = {
  onChange: (point: GeoJSON.Point) => void;
  onUpdateBounds: (mb: MapBounds) => void;
};

const PlanLocationHack = ({ onChange, onUpdateBounds }: Props) => {
  const { setVisible, setOnChange, setMarkType, setOnUpdateBounds } =
    useContext(NodeMapContext);
  React.useEffect(() => {
    setOnChange(onChange);
    setOnUpdateBounds(onUpdateBounds);
    setMarkType('plan');
    setVisible(true);
    return () => {
      setOnChange(() => {});
      setVisible(false);
      setMarkType(undefined);
    };
  }, []);

  return <Box width="calc(100vw - 100px)" height="calc(100vh - 380px)"></Box>;
};

export default PlanLocationHack;
