import { Button, Buttons, Icons, Text } from 'preshape';
import React from 'react';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import SummaryCard from '../../../SummaryCard/SummaryCard';
import SummaryCardSection from '../../../SummaryCard/SummaryCardSection';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';

const ReportMapSidebarAnnotation = () => {
  const { activeSidebar, focusedAnnotationUid, unfocus, setActiveSidebar } =
    useReportMapContext();
  const { reportEditor, editAnnotation } = useReportEditorContext();
  const annotation = reportEditor.getAnnotationByUid(focusedAnnotationUid);

  const handleBack = () => {
    unfocus();
    setActiveSidebar('plan');
  };

  if (!annotation) {
    return null;
  }

  const handleRemove = () => {
    reportEditor.removeAnnotation(annotation.uid);
    handleBack();
  };

  return (
    <>
      <MapSidebarContentItem
        active={activeSidebar === 'info' && !!focusedAnnotationUid}
        onBack={handleBack}
        title={'Annotation'}
      >
        <MapSidebarContentItemSection>
          <SummaryCard title="Annotations">
            <SummaryCardSection
              title="Annotation"
              value={annotation.name}
              onEdit={() => editAnnotation(annotation.uid, 'name')}
            >
              {annotation.name}
            </SummaryCardSection>
          </SummaryCard>
        </MapSidebarContentItemSection>

        <MapSidebarContentItemSection>
          <SummaryCard title="Icon">
            <SummaryCardSection
              title="Icon"
              value={annotation.icon}
              onEdit={() => editAnnotation(annotation.uid, 'icon')}
            >
              {annotation.icon}
            </SummaryCardSection>
          </SummaryCard>
        </MapSidebarContentItemSection>

        <MapSidebarContentItemSection>
          <Buttons>
            <Button
              basis="0"
              grow
              color="negative"
              variant="tertiary"
              flex="horizontal"
              gap="x2"
              onClick={handleRemove}
            >
              <Text>Delete annotation</Text>
              <Icons.Trash2 />
            </Button>
          </Buttons>
        </MapSidebarContentItemSection>
      </MapSidebarContentItem>
    </>
  );
};

export default ReportMapSidebarAnnotation;
