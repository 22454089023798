export type AnnotationPostBody = {
  jobId: string;
  point?: GeoJSON.Point;
  name?: string;
  icon?: string;
};

export type Annotation = AnnotationPostBody & {
  uid: string;
  index: number;
};
