import { Drawing, DrawingPoint } from '@drainify/types';
import { isClosed } from '@drainify/utils';
import { NearestPointOnLine } from '@turf/nearest-point-on-line';
import { Box, Button, Icons } from 'preshape';
import React from 'react';
import { v4 } from 'uuid';
import MapMarker from '../../../../Map/MapMarker/MapMarker';
import MapMarkerToolbar from '../../../../Map/MapMarker/MapMarkerToolbar';
import { useReportEditorContext } from '../../../ReportEditorProvider';
import { useReportMapContext } from '../../ReportMapProvider';
import ReportMapDrawingSegment from '../ReportMapDrawingSegment';
import ReportMapDrawingSegmentEditable from '../ReportMapDrawingSegmentEditable';

type Props = {
  drawing: Drawing;
  updateDimensions: (points: DrawingPoint[]) => void;
};

const ReportMapDrawingEditOperationPoints = ({
  drawing,
  updateDimensions,
}: Props) => {
  const { hasFeature } = useReportMapContext();
  const { reportEditor } = useReportEditorContext();

  const handleDrag = (pointId: string) => (point: GeoJSON.Point) => {
    updateDimensions(
      drawing.points.map((e) => {
        if (e.uid !== pointId) {
          return e;
        } else {
          return {
            uid: pointId,
            point,
          };
        }
      })
    );
  };
  const handleDragEnd = (pointId: string) => (point: GeoJSON.Point) => {
    reportEditor.updateDrawing(drawing.uid, {
      points: drawing.points.map((e) => {
        if (e.uid !== pointId) {
          return e;
        } else {
          return {
            uid: pointId,
            point,
          };
        }
      }),
    });
  };

  function handleOnEdit(
    point: NearestPointOnLine['geometry'],
    end: [string, string]
  ): void {
    const index = drawing.points.indexOf(
      drawing.points.find((e) => e.uid === end[1])!
    );
    if (index === 0) {
      const newPoint = { uid: v4(), point: point };
      reportEditor.updateDrawing(drawing.uid, {
        ...drawing,
        points: [
          newPoint,
          ...drawing.points.slice(0, drawing.points.length - 1),
          newPoint,
        ],
      });
    } else {
      reportEditor.updateDrawing(drawing.uid, {
        ...drawing,
        points: [
          ...drawing.points.slice(0, index),
          { uid: v4(), point: point },
          ...drawing.points.slice(index),
        ],
      });
    }
  }
  const handleDeletePoint = (uid: string) => {
    const index = drawing.points.indexOf(
      drawing.points.find((e) => e.uid === uid)!
    );
    if (index !== 0) {
      reportEditor.updateDrawing(drawing.uid, {
        points: drawing.points.filter((e) => e.uid !== uid),
      });
    } else {
      reportEditor.updateDrawing(drawing.uid, {
        points: [
          ...drawing.points.filter((e) => e.uid !== uid),
          drawing.points[1],
        ],
      });
    }
  };

  return (
    <>
      {drawing.points.map((point, i) => (
        <Box key={i + `drawing`}>
          {i < drawing.points.length - 1 && isClosed(drawing) && (
            <ReportMapDrawingSegmentEditable
              key={drawing.uid + '-' + i + '-segment'}
              ids={[drawing.points[i].uid, drawing.points[i + 1].uid]}
              points={[drawing.points[i].point, drawing.points[i + 1].point]}
              onAdd={handleOnEdit}
            />
          )}
          {i < drawing.points.length - 1 && !isClosed(drawing) && (
            <ReportMapDrawingSegment
              key={drawing.uid + '-' + i + '-segment'}
              points={[drawing.points[i].point, drawing.points[i + 1].point]}
            />
          )}
          <MapMarker
            key={drawing.uid + '-' + i + '-marker'}
            point={point.point}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onDrag={handleDrag(point.uid)}
            onDragEnd={
              hasFeature('Elements:move') ? handleDragEnd(point.uid) : undefined
            }
          >
            <MapMarkerToolbar
              enabled={true}
              showOnHover={isClosed(drawing)}
              tools={
                isClosed(drawing) && drawing.points.length > 4
                  ? [
                      {
                        icon: <Icons.Trash2 />,
                        onClick: () => handleDeletePoint(point.uid),
                      },
                    ]
                  : undefined
              }
            >
              {isClosed(drawing) && (
                <Box backgroundColor="black" borderRadius="full" padding="x1">
                  <Box backgroundColor={'black'} borderRadius="full" />
                </Box>
              )}
              {!isClosed(drawing) && i === 0 && (
                <Button
                  backgroundColor="black"
                  borderRadius="full"
                  textColor="white"
                  backgroundColorHover="accent-shade-5"
                  borderSize="x1"
                  borderColor="accent-shade-5"
                  size="x1"
                  textColorHover="white"
                  paddingHorizontal="x1"
                  paddingVertical="x1"
                  variant="primary"
                  alignChildrenVertical="middle"
                  onClick={() =>
                    reportEditor.updateDrawing(drawing.uid, {
                      points: [...drawing.points, point],
                    })
                  }
                >
                  <Icons.Link2 size="1.25rem" />
                </Button>
              )}
              {!isClosed(drawing) &&
                i !== drawing.points.length - 1 &&
                i !== 0 && (
                  <Box backgroundColor={'black'} borderRadius="full" />
                )}
            </MapMarkerToolbar>
          </MapMarker>
        </Box>
      ))}
    </>
  );
};

export default ReportMapDrawingEditOperationPoints;
