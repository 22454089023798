import { Box, Text } from 'preshape';
import React from 'react';
import { v4 } from 'uuid';
import { useMapContext } from '../../../Map/Map';
import MapNotification from '../../../Map/MapNotification/MapNotification';
import { useProjectContext } from '../../../Project/ProjectProvider';
import { useReportEditorContext } from '../../ReportEditorProvider';
import useDrawMeasurementMap from '../ReportMapElements/useDrawMeasurementMap';
import { useReportMapContext } from '../ReportMapProvider';

type Props = {
  toggled: boolean;
  activate: () => void;
  deactivate: () => void;
};

const ReportMapPolygon = ({ toggled, activate, deactivate }: Props) => {
  const {
    addPointerDownListener,
    disableInteractivity,
    addPointerMoveListener,
    addPointerUpListener,
    enableInteractivity,
  } = useMapContext();
  const { activeBookingId } = useProjectContext();

  const { reportEditor } = useReportEditorContext();
  const { focusDrawing } = useReportMapContext();
  const [startPoint, setStartPoint] = React.useState<GeoJSON.Point>();
  const [endPoint, setEndPoint] = React.useState<GeoJSON.Point>();
  const [staged, setStaged] = React.useState<GeoJSON.Point[]>([]);

  React.useEffect(() => {
    if (startPoint && endPoint) {
      setStaged([startPoint, endPoint]);
    } else {
      setStaged([]);
    }
  }, [startPoint, endPoint]);

  React.useEffect(() => {
    if (toggled) {
      return addPointerDownListener((point) => {
        setStartPoint(point);
        disableInteractivity();
      });
    }
  }, [toggled]);

  useDrawMeasurementMap({
    points: staged,
    visible: true,
  });

  React.useEffect(() => {
    if (toggled) {
      return addPointerMoveListener((point) => {
        setEndPoint(point);
      });
    }
  }, [toggled]);

  React.useEffect(() => {
    if (toggled && startPoint) {
      return addPointerUpListener((point) => {
        enableInteractivity();

        const { uid } = reportEditor.addDrawing({
          points: [
            { point: startPoint, uid: v4() },
            { point, uid: v4() },
          ],
          jobId: activeBookingId,
        });

        setStartPoint(undefined);
        setEndPoint(undefined);
        deactivate();
        focusDrawing(uid);
      });
    }
  }, [toggled, startPoint]);

  return (
    <>
      <Box
        flex="vertical"
        alignChildrenHorizontal="middle"
        gap="x1"
        borderSize="x1"
        shrink
        name={'Name'}
        backgroundColor={toggled ? 'accent-shade-3' : 'accent-shade-1'}
        style={{
          cursor: 'pointer',
        }}
        borderColor={'black'}
        padding="x2"
        textColor={toggled ? 'white' : 'black'}
        onClick={() => (toggled ? deactivate() : activate())}
        title={'Draw'}
      >
        <Box
          tag="svg"
          width="1.75rem"
          fill={toggled ? 'white' : 'black'}
          height="1.75rem"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
        >
          <path
            d="M32.5 10.95c-6.89 0-12.55 5.66-12.55 12.55c0 4.02 1.935 7.613 4.91 9.916L14.815 54.172a12.354 12.354 0 0 0-2.316-.223C5.61 53.95-.05 59.61-.05 66.5c0 6.89 5.66 12.55 12.55 12.55c5.13 0 9.54-3.151 11.463-7.603l51.277 7.71c1.232 5.629 6.281 9.894 12.26 9.894c6.656 0 12.114-5.297 12.48-11.867a3.5 3.5 0 0 0 .07-.684a3.5 3.5 0 0 0-.071-.7c-.375-6.562-5.829-11.85-12.479-11.85c-.134 0-.264.015-.396.019L80.242 43.05c3.275-2.127 5.509-5.746 5.738-9.867a3.5 3.5 0 0 0 .07-.684a3.5 3.5 0 0 0-.071-.7c-.375-6.562-5.829-11.85-12.479-11.85c-5.062 0-9.452 3.06-11.43 7.415l-17.082-4.517a3.5 3.5 0 0 0-.01-.047c-.374-6.563-5.828-11.852-12.478-11.852zm0 7c3.107 0 5.55 2.443 5.55 5.55c0 3.107-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.55 5.55-5.55zm41 9c3.107 0 5.55 2.443 5.55 5.55c0 3.107-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.55 5.55-5.55zm-30.137 2.708l17.739 4.69C62.007 40.37 67.239 45.05 73.5 45.05l.033-.002l6.92 21.092a12.688 12.688 0 0 0-4.705 6.015l-50.916-7.654a12.611 12.611 0 0 0-3.787-7.13l10.342-21.378c.368.033.737.057 1.113.057c4.652 0 8.71-2.592 10.863-6.393zM12.5 60.95c3.107 0 5.55 2.444 5.55 5.551s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.55 5.55-5.55zm75 10c3.107 0 5.55 2.444 5.55 5.551s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.55 5.55-5.55z"
            fill={toggled ? 'white' : 'black'}
          ></path>
        </Box>
      </Box>
      {toggled && (
        <MapNotification color="negative-shade-4" typePosition="top-right">
          <Text size="x4">Drawing polygon</Text>
          <Box
            tag="svg"
            width="1.75rem"
            fill={toggled ? 'white' : 'black'}
            height="1.75rem"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="img"
          >
            <path d="M32.5 10.95c-6.89 0-12.55 5.66-12.55 12.55c0 4.02 1.935 7.613 4.91 9.916L14.815 54.172a12.354 12.354 0 0 0-2.316-.223C5.61 53.95-.05 59.61-.05 66.5c0 6.89 5.66 12.55 12.55 12.55c5.13 0 9.54-3.151 11.463-7.603l51.277 7.71c1.232 5.629 6.281 9.894 12.26 9.894c6.656 0 12.114-5.297 12.48-11.867a3.5 3.5 0 0 0 .07-.684a3.5 3.5 0 0 0-.071-.7c-.375-6.562-5.829-11.85-12.479-11.85c-.134 0-.264.015-.396.019L80.242 43.05c3.275-2.127 5.509-5.746 5.738-9.867a3.5 3.5 0 0 0 .07-.684a3.5 3.5 0 0 0-.071-.7c-.375-6.562-5.829-11.85-12.479-11.85c-5.062 0-9.452 3.06-11.43 7.415l-17.082-4.517a3.5 3.5 0 0 0-.01-.047c-.374-6.563-5.828-11.852-12.478-11.852zm0 7c3.107 0 5.55 2.443 5.55 5.55c0 3.107-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.55 5.55-5.55zm41 9c3.107 0 5.55 2.443 5.55 5.55c0 3.107-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.55 5.55-5.55zm-30.137 2.708l17.739 4.69C62.007 40.37 67.239 45.05 73.5 45.05l.033-.002l6.92 21.092a12.688 12.688 0 0 0-4.705 6.015l-50.916-7.654a12.611 12.611 0 0 0-3.787-7.13l10.342-21.378c.368.033.737.057 1.113.057c4.652 0 8.71-2.592 10.863-6.393zM12.5 60.95c3.107 0 5.55 2.444 5.55 5.551s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.55 5.55-5.55zm75 10c3.107 0 5.55 2.444 5.55 5.551s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.55 5.55-5.55z"></path>
          </Box>
        </MapNotification>
      )}
    </>
  );
};

export default ReportMapPolygon;
