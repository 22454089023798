import { ReportView, SectionUseCode } from '@drainify/types';
import { getUsesSchema } from '@drainify/utils';
import { Box, CheckBox, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';
import { useMapContext } from '../../Map/Map';
import MapContentArea from '../../Map/MapLayout/MapContentArea';
import MapNotification from '../../Map/MapNotification/MapNotification';
import GeometryStore from '../../Map/useGeometryStore/GeometryStore';
import { useLanguageContext } from '../../Usage/LanguageProvider';
import ReportMapElementsCustomer from './ReportMapElements/ReportMapElementsCustomer';
import { reportMapBorderColorMap } from './ReportMapElements/ReportMapSectionSimple';
import ReportMapProvider, { ReportMapProviderProps } from './ReportMapProvider';
import ReportMapSidebarCustomer from './ReportMapSidebar/ReportMapSidebarCustomer';

type Props = ReportMapProviderProps & {
  withSidebar?: boolean;
  reportView: ReportView;
};

const ReportMapCustomer = ({
  children,
  withSidebar,
  reportView,
  ...rest
}: PropsWithChildren<Props>) => {
  const { language } = useLanguageContext();
  const [showLegend, setShowLegend] = React.useState(true);
  const { mapType } = useMapContext();

  return (
    <>
      <ReportMapProvider {...rest}>
        {
          // <MapWhat3WordsGrid />
        }

        {withSidebar && (
          <>
            <MapContentArea
              grow
              height="100%"
              position="top-right"
              width="400px"
              flex="vertical"
            >
              <ReportMapSidebarCustomer />
              {mapType === google.maps.MapTypeId.HYBRID && (
                <MapNotification typePosition="top-right" color={'white'}>
                  Satellite view is intended for reference only, Elements on the
                  map may appear displaced from where they were intended
                </MapNotification>
              )}
              <MapNotification
                color="black"
                withColor={false}
                typePosition="bottom-right"
              >
                <Box flex="vertical">
                  <CheckBox
                    borderSize="x1"
                    checked={showLegend}
                    onChange={() => setShowLegend(!showLegend)}
                  >
                    <Text style={{ userSelect: 'none' }}>
                      {showLegend ? 'Hide legend' : 'Show legend'}
                    </Text>
                  </CheckBox>
                  {showLegend && (
                    <Box padding="x3">
                      <Box flex="vertical" gap="x3">
                        {Object.keys(SectionUseCode)
                          .filter(
                            (e) =>
                              reportView.job.projectType &&
                              getUsesSchema(e).projectTypes?.includes(
                                reportView.job.projectType
                              )
                          )
                          .map((e) => (
                            <Box
                              key={e}
                              flex="horizontal"
                              alignChildrenVertical="middle"
                              gap="x3"
                            >
                              <Box
                                borderSize="x3"
                                borderRadius="1px"
                                height="0.5rem"
                                width="1.5rem"
                                backgroundColor="white"
                                style={{
                                  // @ts-ignore
                                  borderColor: reportMapBorderColorMap[e],
                                  borderLeft: 'none',
                                  borderRight: 'none',
                                }}
                              />
                              <Box
                                style={{
                                  position: 'absolute',
                                  left: '40px',
                                }}
                                flex="horizontal"
                                alignChildrenVertical="middle"
                              >
                                <Box
                                  style={{
                                    border: 'solid black',
                                    borderWidth: '0 2px 2px 0',
                                    display: 'inline-block',
                                    padding: '3px',
                                    width: '12px',
                                    height: '12px',
                                    // @ts-ignore
                                    borderColor: reportMapBorderColorMap[e],
                                    transform: 'rotate(135deg)',
                                  }}
                                />
                                <Box
                                  style={{
                                    border: 'solid black',
                                    borderWidth: '0 2px 2px 0',
                                    display: 'inline-block',
                                    padding: '3px',
                                    width: '12px',
                                    height: '12px',
                                    // @ts-ignore
                                    borderColor: reportMapBorderColorMap[e],
                                    transform: 'rotate(135deg)',
                                  }}
                                />
                              </Box>
                              <Text strong>
                                {getUsesSchema(e).name[language]}
                              </Text>
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  )}
                </Box>
              </MapNotification>
            </MapContentArea>
          </>
        )}

        <GeometryStore>
          <ReportMapElementsCustomer reportView={reportView} />
          {children}
        </GeometryStore>
      </ReportMapProvider>
    </>
  );
};

export default ReportMapCustomer;
