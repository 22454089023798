import { Drawing, DrawingPoint } from '@drainify/types';
import centroid from '@turf/centroid';
import { Box, Icons } from 'preshape';
import React from 'react';
import MapMarker from '../../../../Map/MapMarker/MapMarker';
import { useReportEditorContext } from '../../../ReportEditorProvider';

type Props = {
  drawing: Drawing;
  updateDimensions: (points: DrawingPoint[]) => void;
};

const ReportMapDrawingEditOperationTranslation = ({
  drawing,
  updateDimensions,
}: Props) => {
  const { reportEditor } = useReportEditorContext();
  const polygon: GeoJSON.Feature<GeoJSON.Polygon> = {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [drawing.points.map((e) => e.point.coordinates)],
    },
    properties: {},
  };

  const performTranslation = (point: GeoJSON.Point) => {
    const currentMidpoint = centroid(polygon).geometry;
    const [currentX, currentY] = currentMidpoint.coordinates;
    const [targetX, targetY] = point.coordinates;
    const translationX = targetX - currentX;
    const translationY = targetY - currentY;
    const translatedCoordinates = polygon.geometry.coordinates[0].map(
      ([x, y]) => [x + translationX, y + translationY]
    );

    const translatedPolygon = {
      ...polygon,
      geometry: {
        ...polygon.geometry,
        coordinates: [translatedCoordinates],
      },
    };

    const newCache = drawing.points.map((pointObj, index) => {
      const newCoordinates = translatedCoordinates[index];
      return {
        ...pointObj,
        point: {
          ...pointObj.point,
          coordinates: newCoordinates, // Update point with new translated coordinates
        },
      };
    });

    updateDimensions(newCache);

    return translatedPolygon;
  };

  return (
    <MapMarker
      point={centroid(polygon).geometry}
      onDrag={performTranslation}
      onDragEnd={() => {
        reportEditor.updateDrawing(drawing.uid, {
          points: drawing.points,
        });
      }}
    >
      <Box>
        <Icons.Move size="2rem" />
      </Box>
    </MapMarker>
  );
};

export default ReportMapDrawingEditOperationTranslation;
