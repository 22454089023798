import { Drawing } from '@drainify/types';
import { ReportElementType, isClosed } from '@drainify/utils';
import React, { PropsWithChildren } from 'react';
import { useMapLayersContext } from '../../../Map/MapLayers/MapLayers';
import { useReportMapContext } from '../ReportMapProvider';
import ReportMapDrawingCreating from './ReportMapDrawingCreating';
import ReportMapDrawingEdit from './ReportMapDrawingEdit';
import ReportMapDrawingShow from './ReportMapDrawingShow';

type Props = {
  drawing: Drawing;
};

const ReportMapDrawing = ({ drawing }: PropsWithChildren<Props>) => {
  const { isLayerVisible } = useMapLayersContext();
  const { focusedDrawingUid } = useReportMapContext();

  const visible = isLayerVisible(ReportElementType.DRAWING, drawing.uid);

  if (!visible) {
    return null;
  }

  const isCreating = !isClosed(drawing) && focusedDrawingUid === drawing.uid;
  const isDisplayingPolygon =
    focusedDrawingUid === drawing.uid && isClosed(drawing);
  const isDisplaying = isClosed(drawing) && focusedDrawingUid !== drawing.uid;

  if (isCreating) {
    return <ReportMapDrawingCreating drawing={drawing} />;
  }

  if (isDisplayingPolygon) {
    return <ReportMapDrawingEdit drawing={drawing} />;
  }

  if (isDisplaying) {
    return <ReportMapDrawingShow drawing={drawing} />;
  }

  return null;
};

export default ReportMapDrawing;
