import { PlanType } from '@drainify/types';
import { Box, Button, CheckBox, Grid } from 'preshape';
import React from 'react';
import DataTable from '../../../DataTable/DataTable';
import DataTableGroup from '../../../DataTable/DataTableGroup';
import DataTableItem from '../../../DataTable/DataTableItem';
import DataTableMessage from '../../../DataTable/DataTableMessage';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import AnnotationList from '../../../Measurement/AnnoationsList';
import BoundaryLinesList from '../../../Measurement/BoundaryLinesList';
import DrawingList from '../../../Measurement/DrawingList';
import MeasurementsList from '../../../Measurement/MeasurementsList';
import PlanUploadModal from '../../../Plan/PlanUploadModal';
import { useProjectContext } from '../../../Project/ProjectProvider';
import SitePhotoCreateButton from '../../../SitePhotos/SitePhotoCreateButton';
import SitePhotoView from '../../../SitePhotos/SitePhotoView';
import { useReportEditorContext } from '../../ReportEditorProvider';
import ReportMapGridResize from '../ReportMapElements/ReportMapGridResize';
import { useReportMapContext } from '../ReportMapProvider';
import ReportMapMeasure from '../ReportMapTools/ReportMapMeasure';
import ReportMapMeasureScale from '../ReportMapTools/ReportMapMeasureScale';
import ReportMapPolygon from '../ReportMapTools/ReportMapPolygon';
import ReportMapSquare from '../ReportMapTools/ReportMapSquare';
import ReportMapToolAddAnnotation from '../ReportMapTools/ReportMapToolAddAnnotation';
import ReportMapToolAddPlan from '../ReportMapTools/ReportMapToolAddPlan';
import ReportMapToolBoundaryLine from '../ReportMapTools/ReportMapToolBoundaryLine';
import ReportMapSidebarPlanEdit from './ReportMapSidebarPlanEdit';

type ActiveTool = 'measure' | 'polygon' | 'square' | 'scale' | 'boundaryline';

const planTypeSettingsName: Record<PlanType, string> = {
  [PlanType.GOOGLE_MAPS]: 'Map',
  [PlanType.UPLOADED_PLAN]: 'Upload',
  [PlanType.NO_PLAN]: 'None',
  [PlanType.BLOCK_PLAN]: 'Block plan',
};

const ReportMapSidebarPlan = () => {
  const { reportEditor } = useReportEditorContext();
  const { activeSidebar } = useReportMapContext();
  const { activeBooking } = useProjectContext();
  const [planModalVisible, setPlanModalVisible] = React.useState(false);
  const { focusMeasurement, focusDrawing, focusAnnotation, focusBoundaryLine } =
    useReportMapContext();
  const { report } = reportEditor;
  const [activeTool, setActiveTool] = React.useState<ActiveTool>();

  return (
    <>
      <MapSidebarContentItem
        active={activeSidebar === 'plan'}
        title={'Plan'}
        action={
          <Button
            color="accent"
            gap="x2"
            disabled={!!activeBooking?.completedAt}
            variant="tertiary"
            onClick={() => setPlanModalVisible(true)}
          >
            {planTypeSettingsName[report.planType || PlanType.GOOGLE_MAPS]} type
          </Button>
        }
      >
        {report.plan && (
          <MapSidebarContentItemSection title="Uploaded plan">
            <ReportMapToolAddPlan plan={report?.plan} />
            <ReportMapSidebarPlanEdit />
          </MapSidebarContentItemSection>
        )}

        {report.planType === PlanType.BLOCK_PLAN && report.gridMode && (
          <MapSidebarContentItemSection title="Block plan config">
            <Box flex="horizontal" alignChildrenHorizontal="around">
              <Box flex="horizontal" gap="x2" alignChildrenVertical="middle">
                <CheckBox
                  checked={report.gridMode?.isToScale}
                  onChange={() => {
                    reportEditor.updateGridMode({
                      ...report.gridMode,
                      isToScale: !report.gridMode!.isToScale,
                    });
                  }}
                >
                  To scale
                </CheckBox>
              </Box>
              <Box
                flex="horizontal"
                gap="x2"
                alignChildrenVertical="middle"
                backgroundColor="accent-shade-1"
                borderRadius="10px"
              >
                <ReportMapGridResize />
              </Box>
            </Box>
          </MapSidebarContentItemSection>
        )}
        {!!!activeBooking?.completedAt && (
          <>
            <MapSidebarContentItemSection title="Drawing Tools">
              <Box flex="horizontal" alignChildrenHorizontal="between" wrap>
                <ReportMapMeasure
                  toggled={activeTool === 'measure'}
                  activate={() => setActiveTool('measure')}
                  deactivate={() => setActiveTool(undefined)}
                />
                {report?.plan?.isToScale === false && (
                  <ReportMapMeasureScale
                    toggled={activeTool === 'scale'}
                    activate={() => setActiveTool('scale')}
                    deactivate={() => setActiveTool(undefined)}
                  />
                )}
                <ReportMapSquare
                  toggled={activeTool === 'square'}
                  activate={() => setActiveTool('square')}
                  deactivate={() => setActiveTool(undefined)}
                />
                <ReportMapPolygon
                  toggled={activeTool === 'polygon'}
                  activate={() => setActiveTool('polygon')}
                  deactivate={() => setActiveTool(undefined)}
                />
                <ReportMapToolBoundaryLine
                  toggled={activeTool === 'boundaryline'}
                  activate={() => setActiveTool('boundaryline')}
                  deactivate={() => setActiveTool(undefined)}
                />
              </Box>
            </MapSidebarContentItemSection>

            <MapSidebarContentItemSection title="Plan items">
              <Box flex="horizontal" alignChildrenHorizontal="around" wrap>
                <ReportMapToolAddAnnotation />
              </Box>
            </MapSidebarContentItemSection>
          </>
        )}
        <MapSidebarContentItemSection
          title="Site photography"
          action={<SitePhotoCreateButton variant="tertiary" gap="x2" />}
        >
          {report.sitePhotos.length === 0 && (
            <DataTable>
              <DataTableGroup>
                <DataTableItem>
                  <DataTableMessage>
                    There are no site photos to show here
                  </DataTableMessage>
                </DataTableItem>
              </DataTableGroup>
            </DataTable>
          )}
          <Grid margin="x2" repeat="2" repeatWidth="1fr">
            {reportEditor.report.sitePhotos
              .filter((e) => e.jobId === activeBooking?.uid)
              .map((e) => (
                <SitePhotoView sitePhoto={e} key={e.uid} />
              ))}
          </Grid>
        </MapSidebarContentItemSection>
        <MapSidebarContentItemSection title="Polygons">
          <DrawingList onItemClick={(id) => focusDrawing(id)} />
        </MapSidebarContentItemSection>
        <MapSidebarContentItemSection title="Measurements">
          <MeasurementsList onItemClick={(id) => focusMeasurement(id)} />
        </MapSidebarContentItemSection>
        <MapSidebarContentItemSection title="Annotations">
          <AnnotationList onItemClick={(id) => focusAnnotation(id)} />
        </MapSidebarContentItemSection>
        <MapSidebarContentItemSection title="Boundary lines">
          <BoundaryLinesList onItemClick={(id) => focusBoundaryLine(id)} />
        </MapSidebarContentItemSection>
      </MapSidebarContentItem>
      {planModalVisible && (
        <PlanUploadModal
          onClose={() => setPlanModalVisible(false)}
          visible={planModalVisible}
        />
      )}
    </>
  );
};

export default ReportMapSidebarPlan;
