import {
  JobDocument,
  ResponseError,
  ResponseMultipleEntities,
} from '@drainify/types';
import { getFullFilePath, getFullVideoFilePath } from '@drainify/utils';
import { Box, Button, Icons, Link, Text } from 'preshape';
import React from 'react';
import { useQuery } from 'react-query';
import useReportAccessToken from '../../hooks/useReportAccessToken';
import { useFetchReport } from '../Api/useFetch';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

const DocumentListCustomer = () => {
  const { token } = useReportAccessToken();
  const fetch = useFetchReport(token);
  const a = useQuery<ResponseMultipleEntities<JobDocument>, ResponseError>(
    [],
    () => fetch('/bookings/documents/customer', { method: 'GET' })
  );
  const { reportEditor } = useReportEditorContext();
  const videos = reportEditor.report.inspections
    .map((e) => ({
      sectionId: e.sectionUid,
      video: e.video,
      inspectionId: e.uid,
    }))
    .filter((e) => !!e.video);

  if (a.data?.data.length === 0 && videos.length === 0) {
    return <Text>No documents attached</Text>;
  }

  return (
    <Box>
      {a.data?.data.map((document) => (
        <Box
          flex="horizontal"
          alignChildrenHorizontal="between"
          key={document.uid}
        >
          <Link
            href={getFullFilePath(document.path)}
            download={document.filename}
            target="_blank"
            rel="noreferrer"
            grow
            basis="0"
          >
            <Button
              variant="tertiary"
              color="accent"
              flex="horizontal"
              gap="x2"
              width="100%"
              alignChildrenHorizontal="start"
            >
              <Icons.Download />
              <Text size="x1" strong>
                {document.filename.substr(0, 35) +
                  (document.filename.length > 35 ? '...' : '')}
              </Text>
            </Button>
          </Link>
        </Box>
      ))}
      {videos.map(({ video, sectionId, inspectionId }) => (
        <Box
          flex="horizontal"
          alignChildrenHorizontal="between"
          key={video?.jobId}
        >
          <Button
            variant="tertiary"
            color="accent"
            flex="horizontal"
            gap="x2"
            width="100%"
            onClick={() => {
              window
                .fetch(getFullVideoFilePath(video?.videoUrl)!)
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                  return response.blob(); // Get file as a blob
                })
                .then((blob) => {
                  // Create a temporary URL for the blob
                  const name =
                    reportEditor.getSectionName(
                      reportEditor.getSectionByUid(sectionId)
                    ) +
                    ' ' +
                    reportEditor.getInspectionName(
                      reportEditor.getInspectionByUid(inspectionId)
                    );
                  const blobUrl = window.URL.createObjectURL(blob);
                  const anchor = document.createElement('a');
                  anchor.download = name.replace(/ /g, '_');
                  anchor.href = blobUrl;
                  anchor.click();
                  window.URL.revokeObjectURL(blobUrl); // Clean up URL
                })
                .catch((error) =>
                  console.error('Error downloading file:', error)
                );
            }}
            alignChildrenHorizontal="start"
          >
            <Icons.Download />
            <Text size="x1">
              {`${reportEditor.getSectionName(
                reportEditor.getSectionByUid(sectionId)
              )}.mp4`}
            </Text>
          </Button>
        </Box>
      ))}
    </Box>
  );
};

export default DocumentListCustomer;
