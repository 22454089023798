import { PlanType } from '@drainify/types';
import {
  getAddressStringInformal,
  getProjectLocation,
  hasPotentialRepairs,
} from '@drainify/utils';
import { DateTime } from 'luxon';
import { Box, Button, Grid, Icons, Text } from 'preshape';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import BookingModal from '../../../components/Booking/BookingModal';
import BookingStatus from '../../../components/Booking/BookingStatus';
import JobActionButtons from '../../../components/Booking/JobActionButtons';
import JobRepairReviewWizard from '../../../components/Booking/JobRepairWizard';
import NodesIcon from '../../../components/IconsMisc/NodesIcon';
import SectionsIcon from '../../../components/IconsMisc/SectionsIcon';
import { NodeMapContext } from '../../../components/Node/NodeMapProvider';
import PageContext from '../../../components/Page/PageContext';
import { useHasPermission } from '../../../components/Permissions/Permissions';
import PlanTypeSelector from '../../../components/PlanTypeSelector/PlanTypeSelector';
import { useProjectContext } from '../../../components/Project/ProjectProvider';
import { useReportEditorContext } from '../../../components/Report/ReportEditorProvider';
import useBooking from '../../../hooks/useBooking';
import useProjectBookings from '../../../hooks/useProjectBookings';
import useProjectReport from '../../../hooks/useProjectReport';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageFooter from '../Layout/PageFooter';
import PageHeader from '../Layout/PageHeader';
import Tile from './Tile';

const ProjectPageSnapshot = () => {
  const { project, activeBooking, setActiveBookingId } = useProjectContext();
  const { reportEditor } = useReportEditorContext();
  const { setProject, setReport } = useContext(NodeMapContext);
  const [bookingModalVisible, setBookingModalVisible] = React.useState(false);
  const [repairWizardVisible, setRepairWizardVisible] = React.useState(false);

  const { query: queryReport } = useProjectReport({
    projectId: project?.uid,
  });
  const navigate = useNavigate();

  const { query: bookingQuery } = useProjectBookings(project?.uid);
  const { update: updateBooking, remove } = useBooking(activeBooking?.uid);
  const hasUpdateProjectPermission = useHasPermission('bookings:update');
  const setSearchParams = useSearchParams()[1];

  React.useEffect(() => {
    if (project) {
      setProject(project);
    }
    if (queryReport.data) {
      setReport(queryReport.data);
    }
  }, [project, queryReport.data]);

  const handleRemove = async () => {
    await remove.mutateAsync();
    navigate('/');
  };
  const location = getProjectLocation(project);

  return (
    <>
      <PageContext backToLink="/" backToText="Jobs" query={bookingQuery}>
        <Page>
          <PageHeader
            title=" "
            action={
              hasUpdateProjectPermission ? (
                <Button
                  variant="tertiary"
                  onClick={() => setBookingModalVisible(true)}
                  flex="horizontal"
                  gap="x2"
                  textColor="white"
                >
                  Edit
                  <Icons.Edit2 />
                </Button>
              ) : undefined
            }
          />

          {activeBooking && (
            <Box flex="vertical" alignChildrenHorizontal="middle">
              <Text strong size="x6">
                {activeBooking.jobId}
              </Text>
              <BookingStatus booking={activeBooking} />
            </Box>
          )}
          <Box
            flex="vertical"
            gap="x2"
            padding="x2"
            alignChildrenHorizontal="middle"
          >
            <Text textColor="text-shade-4" strong size="x2" wrap>
              {location
                ? getAddressStringInformal(location)
                : 'Unknown address'}
            </Text>
          </Box>
          <PageBody
            flex="vertical"
            overflow="auto"
            basis="0"
            grow
            paddingTop="x0"
          >
            <Box flex="vertical" gap="x6" padding="x4" paddingTop="x0">
              <Box
                backgroundColor="background-shade-3"
                padding="x4"
                borderRadius="10px"
              >
                <Text strong paddingBottom="x3">
                  Plan
                </Text>
                <Grid
                  repeat={window.innerWidth < 650 ? 2 : 3}
                  repeatWidthMax="1fr"
                  repeatWidthMin="120px"
                  gap="x4"
                >
                  <Tile
                    icon={<SectionsIcon size="2rem" />}
                    onClick={() =>
                      navigate(
                        `report/sections?activeBookingId=${activeBooking?.uid}`
                      )
                    }
                    text="Sections"
                    count={queryReport.data?.sections.length}
                  />
                  <Tile
                    onClick={() =>
                      navigate(
                        `report/nodes?activeBookingId=${activeBooking?.uid}`
                      )
                    }
                    icon={<NodesIcon size="2rem" />}
                    text={`Nodes`}
                    count={queryReport.data?.nodes.length}
                  />
                  {queryReport.data?.planType !== PlanType.NO_PLAN && (
                    <Tile
                      onClick={() =>
                        navigate(
                          `report/site?activeBookingId=${activeBooking?.uid}`
                        )
                      }
                      icon={<Icons.MapPin size="2rem" />}
                      text="View plan"
                    />
                  )}
                </Grid>
              </Box>
              <Box
                backgroundColor="background-shade-3"
                padding="x4"
                borderRadius="10px"
              >
                <Text strong paddingBottom="x3">
                  Tools
                </Text>
                <Grid
                  repeat={window.innerWidth < 650 ? 2 : 3}
                  repeatWidthMax="1fr"
                  repeatWidthMin="70px"
                  gap="x4"
                  grow
                >
                  <Tile
                    onClick={() =>
                      navigate(
                        `report/site-items?activeBookingId=${activeBooking?.uid}`
                      )
                    }
                    icon={<Icons.Tool size="2rem" />}
                    text="Site items"
                    count={
                      (queryReport.data?.measurements.length || 0) +
                      (queryReport.data?.annotations.length || 0) +
                      (queryReport.data?.boundaryLines.length || 0) +
                      (queryReport.data?.drawings.length || 0)
                    }
                  />
                  <Tile
                    onClick={() =>
                      navigate(
                        `report/site-photos?activeBookingId=${activeBooking?.uid}`
                      )
                    }
                    icon={<Icons.Camera size="2rem" />}
                    count={queryReport.data?.sitePhotos.length}
                    text="Site photos"
                  />
                  {window.innerWidth > 650 && <Box />}
                  <Tile
                    onClick={() =>
                      navigate(
                        `report/video-management?activeBookingId=${activeBooking?.uid}`
                      )
                    }
                    icon={<Icons.Film size="2rem" />}
                    text="Video & AI"
                    count={
                      queryReport.data?.inspections.map((e) => !!e.video).length
                    }
                  />
                  {activeBooking?.completedAt &&
                    hasPotentialRepairs(reportEditor, activeBooking.uid) && (
                      <Tile
                        icon={<Icons.Tool size="2rem" />}
                        onClick={() => setRepairWizardVisible(true)}
                        text="Repairs"
                        count={
                          reportEditor
                            .getJobInspections(activeBooking.uid)
                            .flatMap((e) => e.repairs).length
                        }
                      />
                    )}
                </Grid>
              </Box>
              <Box
                backgroundColor="background-shade-3"
                padding="x4"
                borderRadius="10px"
              >
                <Text strong paddingBottom="x3">
                  Details
                </Text>
                <Grid
                  repeat={window.innerWidth < 650 ? 2 : 3}
                  repeatWidthMax="1fr"
                  repeatWidthMin="120px"
                  gap="x4"
                >
                  {project?.customer && (
                    <Tile
                      onClick={() =>
                        navigate(
                          `report/customer/${project?.customer?.uid}?activeBookingId=${activeBooking?.uid}`
                        )
                      }
                      icon={<Icons.User size="2rem" />}
                      text="Customer"
                    />
                  )}
                  <PlanTypeSelector report={queryReport.data} />
                </Grid>
                <JobRepairReviewWizard
                  visible={repairWizardVisible}
                  onClose={() => setRepairWizardVisible(false)}
                  inititalStep="review"
                />
              </Box>
              {(activeBooking?.childJobId || activeBooking?.parentJobId) && (
                <Box
                  backgroundColor="background-shade-3"
                  padding="x4"
                  borderRadius="10px"
                >
                  <Text strong paddingBottom="x3">
                    History
                  </Text>
                  <Grid
                    repeat={3}
                    repeatWidthMax="1fr"
                    repeatWidthMin="100px"
                    gap="x4"
                  >
                    {activeBooking?.parentJobId && (
                      <Tile
                        icon={<Icons.Upload size="2rem" />}
                        onClick={() => {
                          setActiveBookingId(activeBooking.parentJobId);
                          setSearchParams({
                            ['activeBookingId']: activeBooking.parentJobId!,
                          });
                        }}
                        text="Go to parent"
                      />
                    )}

                    {activeBooking?.childJobId && (
                      <Tile
                        icon={<Icons.Download size="1.5rem" />}
                        onClick={() => {
                          setActiveBookingId(activeBooking.childJobId);
                          setSearchParams({
                            ['activeBookingId']: activeBooking.childJobId!,
                          });
                        }}
                        text="Go to child"
                      />
                    )}
                  </Grid>
                </Box>
              )}
            </Box>
          </PageBody>
          <PageFooter
            flex="vertical"
            gap="x2"
            paddingBottom="x16"
            alignChildrenHorizontal="around"
            backgroundColor="background-shade-3"
          >
            {!!!activeBooking?.completedAt && (
              <Box padding="x4">
                <Button
                  color="accent"
                  variant="primary"
                  size="x3"
                  to={`report/sections/snapshot?activeBookingId=${activeBooking?.uid}`}
                >
                  <Text size="x3" align="middle">
                    {queryReport.data &&
                    queryReport.data?.inspections.filter(
                      (e) => e.jobId === activeBooking?.uid
                    ).length > 0
                      ? 'Continue'
                      : 'Start'}
                  </Text>
                </Button>
              </Box>
            )}
            {activeBooking?.completedAt && (
              <Box flex="horizontal" alignChildrenHorizontal="middle">
                <Text size="x1">
                  {`Completed ${DateTime.fromJSDate(
                    new Date(activeBooking.completedAt)
                  ).toFormat('dd/M/yy HH:mm')}`}
                </Text>
              </Box>
            )}

            <Box
              flex="horizontal"
              alignChildrenHorizontal="around"
              wrap
              gap="x2"
            >
              <JobActionButtons />
            </Box>
          </PageFooter>
        </Page>
      </PageContext>
      {activeBooking && (
        <BookingModal
          booking={activeBooking}
          onClose={() => setBookingModalVisible(false)}
          visible={bookingModalVisible}
          onDone={updateBooking}
          onRemove={handleRemove}
        />
      )}
    </>
  );
};

export default ProjectPageSnapshot;
