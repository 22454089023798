import { PlanType } from '@drainify/types';
import { shouldBlackOut } from '@drainify/utils';
import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import centroid from '@turf/centroid';
import distance from '@turf/distance';
import { Box, Button, Buttons, Icons, Text, Appear } from 'preshape';
import React, { useContext } from 'react';
import { useMapContext } from '../Map/Map';
import MobileMapButtons from '../Map/MapNotification/MobileMapButtons';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { MarkType, NodeMapContext } from './NodeMapProvider';

type Props = {
  bounds?: GeoJSON.BBox;
  visible: boolean;
  isCustomerReport: boolean;
  markType: MarkType;
  handleOnAddPoint: (point: GeoJSON.Point) => void;
  handleLinkPoint: () => void;
  handleOnClear: () => void;
  selectedPoint: GeoJSON.Point | null;
  measuringPoints: GeoJSON.Point[];
  drawingPoints: GeoJSON.Point[];
  rectanglePoints: GeoJSON.Point[];
  closed: () => boolean;
};

const NodeMapButtons = ({
  isCustomerReport,
  handleOnAddPoint,
  handleOnClear,
  handleLinkPoint,
  markType,
  selectedPoint,
  measuringPoints,
  drawingPoints,
  rectanglePoints,
  closed,
}: Props) => {
  const { reportEditor } = useReportEditorContext();
  const { onUpdateBounds } = useContext(NodeMapContext);
  const { toggleMapType, getBounds, mapCenter, mapType } = useMapContext();

  const isBlackout = shouldBlackOut(reportEditor.report);

  if (!bbox) {
    return null;
  }

  if (isCustomerReport) {
    return (
      <MobileMapButtons>
        placeholder for view toggle (customer)
      </MobileMapButtons>
    );
  }

  if (
    markType === 'plan' &&
    reportEditor.report.planType === PlanType.BLOCK_PLAN
  ) {
    return (
      <MobileMapButtons>
        <Buttons flex="horizontal" alignChildrenHorizontal="around" margin="x3">
          <Button
            color="accent"
            size="x1"
            onClick={() => {
              const bounds = getBounds();
              if (onUpdateBounds && bounds) {
                onUpdateBounds(bounds);
              }
            }}
          >
            <Icons.Maximize size="1.5rem" />
          </Button>
        </Buttons>
      </MobileMapButtons>
    );
  }

  if (markType === 'plan' && isBlackout) {
    return null;
  }

  if (markType === 'plan') {
    return (
      <MobileMapButtons>
        <Buttons flex="horizontal" alignChildrenHorizontal="around" grow>
          <Button
            color="accent"
            size="x1"
            onClick={() => {
              const bounds = getBounds();
              if (onUpdateBounds && bounds) {
                onUpdateBounds(bounds);
              }
            }}
          >
            <Text size="x1">Update bounds</Text>
          </Button>

          <Box>
            <Button size="x1" onClick={toggleMapType}>
              {mapType === google.maps.MapTypeId.HYBRID ? (
                <Text size="x1">Map</Text>
              ) : (
                <Text size="x1">Satellite</Text>
              )}
            </Button>
          </Box>
        </Buttons>
      </MobileMapButtons>
    );
  }

  return (
    <MobileMapButtons>
      <Box flex="horizontal" alignChildrenHorizontal="around">
        {markType === 'measurement-point' && (
          <Text strong textColor="white">
            {(measuringPoints.length > 0
              ? [
                  ...measuringPoints.map((e, i) =>
                    i === measuringPoints.length - 1
                      ? 0
                      : distance(e, measuringPoints[i + 1], {
                          units: 'meters',
                        })
                  ),
                  distance(
                    mapCenter,
                    measuringPoints[measuringPoints.length - 1],
                    {
                      units: 'meters',
                    }
                  ),
                ].reduce((sum, current) => sum + current, 0)
              : 0.0
            ).toFixed(2)}
            m
          </Text>
        )}
      </Box>

      <Buttons flex="horizontal" alignChildrenHorizontal="around">
        {selectedPoint &&
        !closed() &&
        selectedPoint === drawingPoints[0] &&
        markType === 'drawing-point' &&
        drawingPoints.length > 2 ? (
          <Appear animation="FadeSlideDown">
            <Button
              color="positive"
              variant="primary"
              size="x1"
              onClick={handleLinkPoint}
            >
              <Icons.CheckCircle size="1.5rem" />
            </Button>
          </Appear>
        ) : (
          <Button
            color="positive"
            variant="primary"
            disabled={
              (markType === 'drawing-point' && closed()) ||
              (markType === 'rectangle' && rectanglePoints.length > 1) ||
              selectedPoint !== null
            }
            size="x1"
            onClick={() => {
              // Dispatch/Trigger the event on the document
              const bbox = getBounds()?.bbox;
              if (bbox) {
                const polygon = bboxPolygon(bbox);

                // Get the centroid of the polygon
                const center = centroid(polygon);

                handleOnAddPoint(center.geometry);
              }
            }}
          >
            <Icons.PlusCircle size="1.5rem" />
          </Button>
        )}
        {markType !== 'node' && (
          <Button
            size="x1"
            onClick={handleOnClear}
            disabled={
              (markType === 'drawing-point' && drawingPoints.length === 0) ||
              (markType === 'measurement-point' && measuringPoints.length === 0)
            }
          >
            <Icons.Trash2 size="1.5rem" />
          </Button>
        )}
      </Buttons>
    </MobileMapButtons>
  );
};

export default NodeMapButtons;
