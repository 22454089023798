import {
  Box,
  FormProvider,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
} from 'preshape';
import React, { ChangeEvent } from 'react';
import { v4 } from 'uuid';
import useDrawingForm from '../../hooks/forms/useDrawingForm';
import { isDesktop, isMobile } from '../../utils/client';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import DrawingLocationhack from '../Measurement/DrawingLocationHack';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useReportContext } from '../Report/ReportProvider';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardStep from '../Wizard/WizardStep';
import WizardStepError from '../Wizard/WizardStepError';
import DrawingModalSummary from './DrawingModalSummary';

type Props = ModalProps & {
  initialActiveStepId?: string;
  drawingUid?: string;
  onClose: () => void;
  onCreate?: (uid: string) => void;
};

const DrawingModal = ({
  initialActiveStepId,
  onClose,
  onCreate,
  drawingUid,
  visible,
  ...rest
}: Props) => {
  const { isError, isLoading, isSuccess } = useReportContext();
  const { reportEditor } = useReportEditorContext();
  const { activeBookingId } = useProjectContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');

  const drawing = reportEditor.getDrawingByUid(drawingUid);

  React.useEffect(() => {
    form.reset();
  }, [visible]);

  const form = useDrawingForm(activeBookingId, drawing);
  if (!activeBookingId) {
    return null;
  }

  const handleRemove = async () => {
    if (drawingUid) {
      reportEditor.removeDrawing(drawingUid);
    }
    onClose();
  };

  const handleSave = async () => {
    if (drawingUid) {
      reportEditor.updateDrawing(drawingUid, form.state);
      onCreate?.(drawingUid);
    } else {
      const createdId = reportEditor.addDrawing(form.state).uid;
      onCreate?.(createdId);
    }

    onClose();
  };

  return (
    <FormProvider form={form}>
      <Wizard
        flow={drawingUid ? 'update' : 'create'}
        initialActiveStepId={initialActiveStepId}
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onSave={hasUpdateProjectPermission ? handleSave : undefined}
        onRemove={hasUpdateProjectPermission ? handleRemove : undefined}
        onCancel={onClose}
        reset={visible}
      >
        <Modal
          {...rest}
          animation="FadeSlideUp"
          margin="x4"
          maxWidth={isMobile() ? '100vw' : FULL_SCREEN_MODAL_WIDTH}
          onClose={onClose}
          overlayBackgroundCloseOnClick={false}
          fullscreen={!isDesktop()}
          visible={visible}
        >
          <ModalHeader>
            <ModalTitle>
              {drawing ? drawing?.name || 'Unnamed drawing' : 'New drawing'}
            </ModalTitle>
          </ModalHeader>

          <ModalBody flex="vertical" alignChildrenVertical="middle">
            <Box maxWidth={isDesktop() ? FULL_SCREEN_MODAL_WIDTH : '100vw'}>
              <WizardStep id="name" title="Drawing Name">
                <Input
                  size={isDesktop() ? 'x3' : 'x8'}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    form.setState({
                      ...form.state,
                      name: event.target.value,
                    })
                  }
                  value={form.state.name || ''}
                />
              </WizardStep>
              <WizardStep id="points">
                <DrawingLocationhack
                  onChange={(point) => {
                    if (
                      point.length > 3 &&
                      point[0] === point[point.length - 1]
                    ) {
                      form.setState((s) => ({
                        ...s,
                        points: [...s.points, s.points[0]],
                      }));
                    } else {
                      form.setState((s) => ({
                        ...s,
                        points: point.map((e) => ({ uid: v4(), point: e })),
                      }));
                    }
                  }}
                />
              </WizardStep>

              <WizardReviewStep>
                <DrawingModalSummary drawing={form.state} />
              </WizardReviewStep>
            </Box>
          </ModalBody>

          <ModalFooter>
            <WizardStepError />
            <WizardControls />
          </ModalFooter>
        </Modal>
      </Wizard>
    </FormProvider>
  );
};

export default DrawingModal;
