import { Annotation } from '@drainify/types';
import { Appear, Box, Label, Text, sizeX6Px } from 'preshape';
import React, { PropsWithChildren, useState } from 'react';
import MapLabel from '../../../Map/MapMarker/MapLabel';
import MapMarker from '../../../Map/MapMarker/MapMarker';
import MapMarkerToolbar from '../../../Map/MapMarker/MapMarkerToolbar';
import useGeometryStoreRegister from '../../../Map/useGeometryStore/useGeometryStoreRegister';
import { iconMap } from '../../../Node/AnnotationModal';
import { useReportMapContext } from '../ReportMapProvider';

type Props = {
  annotation: Annotation;
};

const ReportMapAnnotation = ({ annotation }: PropsWithChildren<Props>) => {
  // const { isLayerVisible } = useMapLayersContext();
  const { focusAnnotation, focusedAnnotationUid } = useReportMapContext();

  const [mouseOver, setIsMouseOver] = useState(false);

  // const visible = isLayerVisible(ReportElementType.NODE, annotation.uid);

  const handlePointerEnter = () => {
    setIsMouseOver(true);
    // if (isInteractive) {
    // setIsConnectionHandleVisible(true);
    // }
  };

  const handlePointerLeave = () => {
    setIsMouseOver(false);
    // if (!isLinkingStart) {
    // setIsConnectionHandleVisible(false);
    // }
  };

  useGeometryStoreRegister({
    geometry: annotation.point,
    opts: {
      anchor: 'center',
      id: annotation.uid,
      padding: sizeX6Px,
      type: 'ReportMapAnnotation',
    },
  });

  if (!annotation.point) {
    return null;
  }

  return (
    <>
      <MapLabel
        id={annotation.uid}
        point={annotation.point}
        onClick={() => focusAnnotation(annotation.uid)}
        visible={
          (mouseOver || focusedAnnotationUid === annotation.uid) &&
          annotation.name !== undefined
        }
      >
        <Label size="x1" backgroundColor="highlight">
          <Box flex="vertical" textColor="dark-shade-1">
            <Text ellipsis>{annotation.name}</Text>
          </Box>
        </Label>
      </MapLabel>
      <MapMarker point={annotation.point}>
        <MapMarkerToolbar enabled={false}>
          <Text
            container
            display="block"
            onPointerOver={handlePointerEnter}
            onPointerLeave={handlePointerLeave}
          >
            {/* Default black dot state */}
            {mouseOver || focusedAnnotationUid === annotation.uid ? (
              <Box
                backgroundColor="highlight"
                borderRadius="full"
                flex="vertical"
                elevate
                alignChildren="middle"
                borderSize="x1"
                onClick={() => focusAnnotation(annotation.uid)}
                borderColor="negative-shade-5"
              >
                <Appear animation="Pop">
                  {(() => {
                    const IconComponent = iconMap.get(
                      annotation.icon || 'default'
                    );
                    return IconComponent ? <IconComponent size="3rem" /> : null;
                  })()}
                </Appear>
              </Box>
            ) : (
              <Appear animation="Pop">
                <Box
                  backgroundColor="white"
                  borderRadius="full"
                  elevate
                  flex="vertical"
                  alignChildren="middle"
                >
                  {(() => {
                    const IconComponent = iconMap.get(
                      annotation.icon || 'default'
                    );
                    return IconComponent ? (
                      <IconComponent size="1.5rem" />
                    ) : null;
                  })()}
                </Box>
              </Appear>
            )}
          </Text>
        </MapMarkerToolbar>
      </MapMarker>
    </>
  );
};

export default ReportMapAnnotation;
