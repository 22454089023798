import { Drawing, DrawingPoint } from '@drainify/types';
import { getMidPointBetweenPoints } from '@drainify/utils';
import bearing from '@turf/bearing';
import destination from '@turf/destination';
import distance from '@turf/distance';
import { pointToLineDistance } from '@turf/point-to-line-distance';
import { Appear, Box, Button, Text } from 'preshape';
import React from 'react';
import { v4 } from 'uuid';
import MapMarker from '../../../../Map/MapMarker/MapMarker';
import MapNotification from '../../../../Map/MapNotification/MapNotification';
import { useReportEditorContext } from '../../../ReportEditorProvider';
import ReportMapDrawingSegmentEditable from '../ReportMapDrawingSegmentEditable';

type Props = {
  drawing: Drawing;
  updateDimensions: (points: DrawingPoint[]) => void;
  onCancel: () => void;
};

const ReportMapDrawingEditOperationIntrustionExcrusion = ({
  drawing,
  updateDimensions,
  onCancel,
}: Props) => {
  const [activeLine, setActiveLine] = React.useState<[string, string]>();
  const { reportEditor } = useReportEditorContext();

  const [thing, setThing] = React.useState<
    [GeoJSON.Point | undefined, GeoJSON.Point | undefined]
  >([undefined, undefined]);
  const [dragPoint, setDragPoint] = React.useState<GeoJSON.Point>();

  const selectingFirstPoint = () => thing[0] === undefined;
  const selectingSecondPoint = () =>
    activeLine && thing[0] !== undefined && thing[1] === undefined;
  const doingIntructionExcrusion = () =>
    thing[0] !== undefined && thing[1] !== undefined;

  const handleThing = (point: GeoJSON.Point) => {
    if (thing[0] === undefined) {
      setThing([point, undefined]);
    } else if (thing[1] === undefined) {
      setThing([thing[0], point]);
    }
  };

  function patchExtrusionPoints() {
    const [startId, endId] = activeLine!;
    const drawingThing = reportEditor.getDrawingByUid(drawing.uid)!;
    const startPoint = drawingThing.points.find((p) => p.uid === startId);
    const endPoint = drawingThing.points.find((p) => p.uid === endId);

    if (!startPoint || !endPoint) {
      console.error('Could not find start or end points in drawing.');
      return;
    }

    const distanceToStart0 = distance(thing[0]!, startPoint.point, {
      units: 'meters',
    });
    const distanceToStart1 = distance(thing[1]!, startPoint.point, {
      units: 'meters',
    });

    const closestToStart =
      distanceToStart0 < distanceToStart1 ? thing[0]! : thing[1]!;
    const closestToEnd =
      distanceToStart0 < distanceToStart1 ? thing[1]! : thing[0]!;

    const rawExtrudedPoints = calculateExtrusionPoints(
      closestToStart,
      closestToEnd,
      dragPoint || closestToStart
    );

    const distanceToStartEx0 = distance(rawExtrudedPoints[0], closestToStart, {
      units: 'meters',
    });
    const distanceToStartEx1 = distance(rawExtrudedPoints[1], closestToStart, {
      units: 'meters',
    });

    const extrudedPoints =
      distanceToStartEx0 < distanceToStartEx1
        ? rawExtrudedPoints
        : [rawExtrudedPoints[1], rawExtrudedPoints[0]];

    const newSegments: DrawingPoint[] = [
      startPoint,
      { point: closestToStart, uid: v4() },
      { point: extrudedPoints[0], uid: v4() },
      { point: extrudedPoints[1], uid: v4() },
      { point: closestToEnd, uid: v4() },
    ];
    let updatedPoints: DrawingPoint[] = [];

    let added = false;
    updatedPoints = drawingThing.points
      .map((drawingPoint) => {
        if (drawingPoint.uid === startId && !added) {
          added = true;
          return newSegments.map((segment) => ({
            uid: segment.uid,
            point: segment.point,
          }));
        }
        return [drawingPoint]; // Leave other points unchanged
      })
      .flat();

    reportEditor.updateDrawing(drawingThing.uid, {
      points: updatedPoints,
    });
  }

  function patchExtrusionPointsDev() {
    const [startId, endId] = activeLine!;
    const drawingDev = reportEditor.getDrawingByUid(drawing.uid)!;
    const startPoint = drawingDev.points.find((p) => p.uid === startId);
    const endPoint = drawingDev.points.find((p) => p.uid === endId);

    if (!startPoint || !endPoint) {
      console.error('Could not find start or end points in drawing.');
      return;
    }

    const distanceToStart0 = distance(thing[0]!, startPoint.point, {
      units: 'meters',
    });
    const distanceToStart1 = distance(thing[1]!, startPoint.point, {
      units: 'meters',
    });

    const closestToStart =
      distanceToStart0 < distanceToStart1 ? thing[0]! : thing[1]!;
    const closestToEnd =
      distanceToStart0 < distanceToStart1 ? thing[1]! : thing[0]!;

    const rawExtrudedPoints = calculateExtrusionPoints(
      closestToStart,
      closestToEnd,
      dragPoint || closestToStart
    );

    const distanceToStartEx0 = distance(rawExtrudedPoints[0], closestToStart, {
      units: 'meters',
    });
    const distanceToStartEx1 = distance(rawExtrudedPoints[1], closestToStart, {
      units: 'meters',
    });

    const extrudedPoints =
      distanceToStartEx0 < distanceToStartEx1
        ? rawExtrudedPoints
        : [rawExtrudedPoints[1], rawExtrudedPoints[0]];

    const newSegments: DrawingPoint[] = [
      startPoint,
      { point: closestToStart, uid: v4() },
      { point: extrudedPoints[0], uid: v4() },
      { point: extrudedPoints[1], uid: v4() },
      { point: closestToEnd, uid: v4() },
    ];
    let updatedPoints: DrawingPoint[] = [];

    let added = false;
    updatedPoints = drawingDev.points
      .map((drawingPoint) => {
        if (drawingPoint.uid === startId && !added) {
          added = true;
          return newSegments.map((segment) => ({
            uid: segment.uid,
            point: segment.point,
          }));
        }
        return [drawingPoint]; // Leave other points unchanged
      })
      .flat();

    updateDimensions(updatedPoints);
  }

  const calculateExtrusionPoints = (
    start: GeoJSON.Point,
    end: GeoJSON.Point,
    dragPoint: GeoJSON.Point
  ): [GeoJSON.Point, GeoJSON.Point] => {
    const lineBearing = bearing(start, end);

    const perpendicularBearing1 = lineBearing + 90;
    const perpendicularBearing2 = lineBearing - 90;

    const perpendicularDistance = pointToLineDistance(
      dragPoint,
      { type: 'LineString', coordinates: [start.coordinates, end.coordinates] },
      { units: 'meters' }
    );

    const startCoords = start.coordinates;
    const endCoords = end.coordinates;
    const dragCoords = dragPoint.coordinates;

    const crossProduct =
      (endCoords[0] - startCoords[0]) * (dragCoords[1] - startCoords[1]) -
      (endCoords[1] - startCoords[1]) * (dragCoords[0] - startCoords[0]);

    const isExtruding = crossProduct > 0;

    const activeBearing = isExtruding
      ? perpendicularBearing2
      : perpendicularBearing1;

    const extrudedStart = destination(
      start,
      Math.abs(perpendicularDistance) / 1000,
      activeBearing
    ).geometry.coordinates;
    const extrudedEnd = destination(
      end,
      Math.abs(perpendicularDistance) / 1000,
      activeBearing
    ).geometry.coordinates;

    return [
      {
        type: 'Point',
        coordinates: extrudedStart,
      },
      {
        type: 'Point',
        coordinates: extrudedEnd,
      },
    ];
  };

  return (
    <>
      <MapNotification color={'black'} typePosition="top-right">
        {selectingFirstPoint() && (
          <Appear animation="FadeSlideUp">
            {' '}
            <Text>Select point A</Text>{' '}
          </Appear>
        )}

        {selectingSecondPoint() && (
          <Appear animation="FadeSlideUp">
            {' '}
            <Text>Select point B</Text>{' '}
          </Appear>
        )}
        {doingIntructionExcrusion() && (
          <Appear animation="FadeSlideUp">
            {' '}
            <Text>Drag intrusion/extrusion line</Text>{' '}
          </Appear>
        )}

        {doingIntructionExcrusion() && (
          <Button
            onClick={() => {
              patchExtrusionPoints();
              onCancel();
            }}
            variant="primary"
            color="positive"
          >
            save
          </Button>
        )}

        <Button onClick={onCancel}>Cancel</Button>
      </MapNotification>
      {selectingFirstPoint() &&
        drawing.points.map((_, i) => (
          <Box key={i + `drawing`}>
            {i < drawing.points.length - 1 && (
              <ReportMapDrawingSegmentEditable
                key={drawing.uid + '-' + i + '-segment'}
                ids={[drawing.points[i].uid, drawing.points[i + 1].uid]}
                points={[drawing.points[i].point, drawing.points[i + 1].point]}
                onAdd={handleThing}
                active={
                  activeLine &&
                  activeLine[0] === drawing.points[i].uid &&
                  activeLine[1] === drawing.points[i + 1].uid
                }
                onHover={(e) => setActiveLine(e)}
                onHoverOut={(e) => {
                  if (
                    activeLine &&
                    e[0] === activeLine[0] &&
                    e[1] === activeLine[1]
                  ) {
                    setActiveLine(undefined);
                  }
                }}
              />
            )}
          </Box>
        ))}

      {selectingSecondPoint() && (
        <>
          <ReportMapDrawingSegmentEditable
            ids={activeLine!}
            points={[
              drawing.points.find((e) => e.uid === activeLine![0])!.point,
              drawing.points.find((e) => e.uid === activeLine![1])!.point,
            ]}
            active={true}
            onAdd={(p) => handleThing(p)}
          />
          <MapMarker point={thing[0]}>
            <Box
              textColor="light-shade-1"
              backgroundColor="dark-shade-1"
              padding="x1"
            >
              A
            </Box>
          </MapMarker>
        </>
      )}
      {doingIntructionExcrusion() && (
        <>
          <ReportMapDrawingSegmentEditable
            ids={activeLine!}
            points={calculateExtrusionPoints(
              thing[0]!,
              thing[1]!,
              dragPoint || thing[0]! // Default to thing[0] if dragPoint isn't set
            )}
          />

          <MapMarker
            point={dragPoint || getMidPointBetweenPoints(thing[0]!, thing[1]!)}
            onDrag={(e) => {
              // Update the drag point and trigger a re-render
              setDragPoint(e);
              patchExtrusionPointsDev();
            }}
          >
            <Box
              height={10}
              width={10}
              borderRadius="full"
              backgroundColor="text-shade-1"
            ></Box>
          </MapMarker>
        </>
      )}
    </>
  );
};

export default ReportMapDrawingEditOperationIntrustionExcrusion;
