import { PlanType } from '@drainify/types';
import { isToScale } from '@drainify/utils';
import { Box, Icons, Text } from 'preshape';
import React from 'react';
import { v4 } from 'uuid';
import { useMapContext } from '../../../Map/Map';
import MapLineGhost from '../../../Map/MapMarker/MapLineGhost';
import MapNotification from '../../../Map/MapNotification/MapNotification';
import { useProjectContext } from '../../../Project/ProjectProvider';
import { useReportEditorContext } from '../../ReportEditorProvider';
import ReportMapLineMeasurementMarker from './ReportMapLineMeasurementMarker';

type Props = {
  toggled: boolean;
  activate: () => void;
  deactivate: () => void;
};

const ReportMapSquare = ({ toggled, deactivate, activate }: Props) => {
  const {
    addPointerDownListener,
    disableInteractivity,
    addPointerMoveListener,
    addPointerUpListener,
    enableInteractivity,
  } = useMapContext();
  const { activeBookingId } = useProjectContext();

  const { reportEditor, editDrawing } = useReportEditorContext();
  const [startPoint, setStartPoint] = React.useState<GeoJSON.Point>();
  const [endPoint, setEndPoint] = React.useState<GeoJSON.Point>();
  const [staged, setStaged] = React.useState<GeoJSON.Point[]>([]);

  React.useEffect(() => {
    if (startPoint && endPoint) {
      const a: GeoJSON.Point = {
        type: 'Point',
        coordinates: [startPoint.coordinates[0], endPoint.coordinates[1]],
      };
      const b: GeoJSON.Point = {
        type: 'Point',
        coordinates: [endPoint.coordinates[0], startPoint.coordinates[1]],
      };

      setStaged([startPoint, a, endPoint, b, startPoint]);
    } else {
      setStaged([]);
    }
  }, [startPoint, endPoint]);

  React.useEffect(() => {
    if (toggled) {
      return addPointerDownListener((point) => {
        setStartPoint(point);
        disableInteractivity();
      });
    }
  }, [toggled]);

  React.useEffect(() => {
    if (toggled) {
      return addPointerMoveListener((point) => {
        setEndPoint(point);
      });
    }
  }, [toggled]);

  React.useEffect(() => {
    if (toggled && startPoint) {
      return addPointerUpListener((point) => {
        enableInteractivity();
        const a: GeoJSON.Point = {
          type: 'Point',
          coordinates: [startPoint.coordinates[0], point.coordinates[1]],
        };
        const b: GeoJSON.Point = {
          type: 'Point',
          coordinates: [point.coordinates[0], startPoint.coordinates[1]],
        };

        const start = { point: startPoint, uid: v4() };
        const { uid } = reportEditor.addDrawing({
          points: [
            start,
            { point: a, uid: v4() },
            { point, uid: v4() },
            { point: b, uid: v4() },
            start,
          ],
          jobId: activeBookingId,
        });

        setStartPoint(undefined);
        setEndPoint(undefined);
        deactivate();
        editDrawing(uid, 'name');
      });
    }
  }, [toggled, startPoint]);

  return (
    <>
      <Box
        flex="vertical"
        alignChildrenHorizontal="middle"
        gap="x1"
        borderSize="x1"
        shrink
        name={'Name'}
        backgroundColor={toggled ? 'accent-shade-3' : 'accent-shade-1'}
        style={{
          cursor: 'pointer',
        }}
        padding="x2"
        textColor={toggled ? 'white' : 'black'}
        onClick={() => (toggled ? deactivate() : activate())}
        title={'Square'}
      >
        <Icons.Square size="1.75rem" fill={toggled ? 'white' : 'black'} />
      </Box>
      {toggled && (
        <MapNotification color="negative-shade-4" typePosition="top-right">
          <Text size="x4">Drawing Square</Text>
          <Icons.Square />
        </MapNotification>
      )}
      {staged[0] && (
        <>
          {reportEditor.report.planType === PlanType.BLOCK_PLAN &&
            isToScale(reportEditor.report) && (
              <>
                <ReportMapLineMeasurementMarker
                  points={[staged[0], staged[1]]}
                />
                <ReportMapLineMeasurementMarker
                  points={[staged[3], staged[0]]}
                />
              </>
            )}
          <MapLineGhost points={staged} />
        </>
      )}
    </>
  );
};

export default ReportMapSquare;
