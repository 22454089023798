"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAnnotation = exports.getAnnotationValidations = void 0;
const yup_1 = require("yup");
const getAnnotationValidations = () => {
    return (0, yup_1.object)().shape({
        points: (0, yup_1.object)().required(),
        name: (0, yup_1.string)().max(40).required(),
    });
};
exports.getAnnotationValidations = getAnnotationValidations;
const isAnnotation = (s) => !!s && 'uid' in s;
exports.isAnnotation = isAnnotation;
