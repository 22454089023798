import { getProjectBounds } from '@drainify/utils';
import destination from '@turf/destination';
import distance from '@turf/distance';
import {
  Box,
  Button,
  Placement,
  PlacementContent,
  PlacementManager,
  PlacementReference,
} from 'preshape';
import React from 'react';
import DistanceInput from '../../../DistanceInput/DistanceInput';
import { useProjectContext } from '../../../Project/ProjectProvider';
import { useReportEditorContext } from '../../ReportEditorProvider';

const ReportMapGridResize = () => {
  const [x, setX] = React.useState<number>();
  const [y, setY] = React.useState<number>();

  const { reportEditor } = useReportEditorContext();
  const { project } = useProjectContext();
  const bounds = getProjectBounds(project, reportEditor.report);

  if (!bounds) {
    return null;
  }

  const updateBoundsToDimensions = () => {
    if (!x || !y) {
      return null;
    }

    const origin = bounds?.coordinates[0].reduce(
      (nw, coord) => {
        const [lon, lat] = coord;
        if (lat > nw[1] || (lat === nw[1] && lon < nw[0])) {
          return coord;
        }
        return nw;
      },
      [-Infinity, -Infinity]
    );

    if (!origin) {
      return;
    }
    const ne = destination(origin, x, 90, { units: 'meters' });
    const se = destination(ne, y, 180, { units: 'meters' });
    const sw = destination(origin, y, 180, { units: 'meters' });
    reportEditor.updateBounds({
      type: 'Polygon',
      coordinates: [
        [
          origin,
          ne.geometry.coordinates,
          se.geometry.coordinates,
          sw.geometry.coordinates,
        ],
      ],
    });
  };

  return (
    <PlacementManager trigger="click">
      <PlacementReference>
        {(props) => (
          <Box>
            <Button
              {...props}
              variant="tertiary"
              flex="horizontal"
              gap="x1"
              borderSize="x1"
            >
              {`${getDistanceFromPoints(
                bounds.coordinates[0][0],
                bounds.coordinates[0][1]
              )} x ${getDistanceFromPoints(
                bounds.coordinates[0][1],
                bounds.coordinates[0][2]
              )}`}
            </Button>
          </Box>
        )}
      </PlacementReference>

      <Placement placement="bottom" zIndex={1}>
        <PlacementContent
          backgroundColor="dark-shade-1"
          borderColor="dark-shade-3"
          borderRadius="x3"
          borderSize="x2"
          overflow="hidden"
          textColor="light-shade-1"
        >
          <Box padding="x3" alignChildrenHorizontal="middle" flex="vertical">
            <DistanceInput
              label="x"
              theme="night"
              placeholder={getDistanceFromPoints(
                bounds?.coordinates[0][0],
                bounds?.coordinates[0][1]
              ).toString()}
              value={x}
              onChange={(e) => setX(e)}
              type={'long'}
            />
            <DistanceInput
              label="y"
              theme="night"
              placeholder={getDistanceFromPoints(
                bounds?.coordinates[0][1],
                bounds?.coordinates[0][2]
              ).toString()}
              value={y}
              onChange={(e) => setY(e)}
              type={'long'}
            />
            <Button
              disabled={!x || !y}
              color="positive"
              variant="primary"
              onClick={() => {
                updateBoundsToDimensions();
              }}
            >
              Apply
            </Button>
          </Box>
        </PlacementContent>
      </Placement>
    </PlacementManager>
  );
};

export const getDistanceFromPoints = (
  p1?: GeoJSON.Position,
  p2?: GeoJSON.Position
) => {
  if (!p1 || !p2) {
    return 0;
  }
  return distance(
    {
      type: 'Point',
      coordinates: p1,
    },
    {
      type: 'Point',
      coordinates: p2,
    },
    {
      units: 'meters',
    }
  ).toFixed(0);
};

export default ReportMapGridResize;
