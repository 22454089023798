import { Inspection, Repairs } from '@drainify/types';
import { getObservationSchema } from '@drainify/utils';
import { Button, Icons, Label } from 'preshape';
import React from 'react';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = {
  inspection?: Inspection;
};

const RepairTool = ({ inspection }: Props) => {
  const { reportEditor } = useReportEditorContext();

  const observations = reportEditor.getInspectionObservations(inspection?.uid);
  const [allRepairs, setAllRepairs] = React.useState<Repairs[]>([]);
  const [unactionFound, setUnactionedFound] = React.useState(true);

  React.useEffect(() => {
    const tmpAll: Repairs[] = [];
    for (const o of observations) {
      for (const k of o.code) {
        const repair = getObservationSchema(k).repairs;
        if (repair && repair(o).length > 0) {
          for (const r of repair(o)) {
            if (!inspection?.repairs?.includes(r)) {
              setUnactionedFound(true);
            }
            if (!tmpAll.includes(r)) {
              tmpAll.push(r);
            }
          }
        }
      }
    }
    setAllRepairs(tmpAll);
  }, [reportEditor]);

  if (!inspection || !unactionFound) {
    return null;
  }

  return (
    <Button
      variant="tertiary"
      color="accent"
      flex="horizontal"
      gap="x1"
      size="x1"
      onClick={() => {
        reportEditor.updateInspection(inspection?.uid, {
          ...inspection,
          repairs: allRepairs,
        });
        // setUnactionedFound(true);
      }}
    >
      <Label backgroundColor="accent-shade-4">Beta</Label>
      <Icons.Tool />
      Auto generate repairs
    </Button>
  );
};

export default RepairTool;
