import { getProjectBounds } from '@drainify/utils';
import getBbox from '@turf/bbox';
import { Box, sizeX2Px } from 'preshape';
import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import useProjectReport from '../../hooks/useProjectReport';
import Map, { MapProps } from '../Map/Map';
import GeometryStoreNodeMap from '../Map/useGeometryStore/GeometryStoreNodeMap';
import ReportEditorProvider from '../Report/ReportEditorProvider';
import { ReportContext } from '../Report/ReportProvider';
import MobileNodeAppElements from './MobileNodeAppElements';
import { NodeMapContext } from './NodeMapProvider';

type Props = MapProps & {};

const NodeMap = ({ ...rest }: Props) => {
  const { project, report, markType, visible } = useContext(NodeMapContext);

  const { update, query } = useProjectReport({
    projectId: project?.uid,
  });

  const [showSimpleSections, setShowSimpleSections] = React.useState(false);
  const [limitNodes, setLimitNodes] = React.useState(false);

  React.useEffect(() => {
    if (report) {
      setShowSimpleSections(report.sections.length > 15);
    }
  }, [report]);

  React.useEffect(() => {
    if (markType !== 'plan') {
      setLimitNodes(true);
    } else {
      setLimitNodes(false);
    }
  }, [markType]);

  const pb = getProjectBounds(project, report);
  const bounds = pb && getBbox(pb);

  const height = 'calc(100vh - 300px)';

  return ReactDOM.createPortal(
    <>
      <Box
        fixed="edge-to-edge"
        style={{
          maxWidth: 'calc(100vw - 70px)',
          height,
          left: visible ? '0px' : '-1000px',
          margin: '0 auto',
          zIndex: '1',
          top: visible ? '110px' : '-100%',
        }}
        id="google-map"
        flex="vertical"
      >
        <ReportContext.Provider
          value={{
            isError: update.isError,
            isLoading: update.isLoading,
            isSuccess: update.isSuccess,
            refetch: query.refetch,
            report: query.data,
            query: query,
            showSimpleSections,
            setShowSimpleSections,
            showObservations: false,
            setShowObservations: () => {},
            limitNodes,
            setLimitNodes,
          }}
        >
          <ReportEditorProvider report={report} update={update.mutate}>
            <Map
              {...rest}
              borderSize="x2"
              borderRadius="x3"
              padding={sizeX2Px}
              isInteractive
              minHeight={'80px'}
              maxHeight={height}
              width={'100%'}
              grow
            >
              <GeometryStoreNodeMap
                projectId={project?.uid}
                projectBounds={bounds}
              />
              {visible && <MobileNodeAppElements />}
            </Map>
          </ReportEditorProvider>
        </ReportContext.Provider>
      </Box>
    </>,
    document.body
  );
};

export default NodeMap;
