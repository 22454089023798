export type BoundaryLinePostBody = {
  points: GeoJSON.Point[];
  name?: string;
  description?: string;
  jobId?: string;
};

export type BoundaryLine = BoundaryLinePostBody & {
  uid: string;
  index: number;
};
