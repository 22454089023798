import { ObservationCode, PENDING_IMAGE_UPLOAD } from '@drainify/types';
import {
  FormProvider,
  InputLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
  TextArea,
} from 'preshape';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import useInspectionForm from '../../hooks/forms/useInspectionForm';
import useFileUpload from '../../hooks/useFileUpload';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import InspectionDirectionInput from '../FlowInput/InspectionDirectionInput';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useReportContext } from '../Report/ReportProvider';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardStep from '../Wizard/WizardStep';
import WizardStepError from '../Wizard/WizardStepError';
import InspectionSummary from './InspectionSummary';

type Props = ModalProps & {
  initialActiveStepId?: string;
  onClose: () => void;
  onCreate?: (inspectionUid: string) => void;
  sectionUid?: string;
  inspectionUid?: string;
};

const InspectionModal = ({
  initialActiveStepId,
  onClose,
  onCreate,
  sectionUid,
  inspectionUid,
  visible,
  ...rest
}: Props) => {
  const { isError, isLoading, isSuccess } = useReportContext();
  const { activeBooking } = useProjectContext();

  const { reportEditor } = useReportEditorContext();
  const inspection = reportEditor.getInspectionByUid(inspectionUid);
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const form = useInspectionForm(sectionUid, inspection, activeBooking?.uid);
  const fileToUpload = useState<File>()[0];
  const observationFileUpload = useFileUpload();
  const { projectId } = useParams<'projectId'>();

  const handleSave = async () => {
    if (inspectionUid) {
      reportEditor.updateInspection(inspectionUid, form.state);
      onClose();
    } else {
      const { uid } = reportEditor.addInspectionNoAsync(form.state);
      await reportEditor.updateAsync?.(reportEditor.report);

      onCreate?.(uid);
      if (fileToUpload) {
        const savedObservation = reportEditor.addObservationNoUpdate({
          attributes: {},
          code: [ObservationCode.GP],
          distance: 0,
          imageUrl: PENDING_IMAGE_UPLOAD,
          inspectionUid: uid,
        });
        await reportEditor.updateAsync?.(reportEditor.report);
        observationFileUpload.upload(
          generatePath(
            '/projects/:projectId/report/observations/:observationId/image',
            {
              projectId: projectId,
              observationId: savedObservation.uid,
            }
          ),
          fileToUpload
        );
      }
      onClose();
    }
  };

  useEffect(() => {
    if (!visible) {
      form.reset();
    }
  }, [visible]);

  return (
    <FormProvider form={form}>
      <Wizard
        flow={inspectionUid ? 'update' : 'create'}
        initialActiveStepId={initialActiveStepId}
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onSave={hasUpdateProjectPermission ? handleSave : undefined}
        onCancel={onClose}
        reset={visible}
      >
        <Modal
          {...rest}
          animation="FadeSlideUp"
          margin="x4"
          maxWidth={FULL_SCREEN_MODAL_WIDTH}
          onClose={onClose}
          overlayBackgroundCloseOnClick={false}
          visible={visible}
        >
          <ModalHeader>
            <ModalTitle>
              {inspection
                ? reportEditor.getInspectionName(inspection)
                : 'New inspection'}
            </ModalTitle>
          </ModalHeader>

          <ModalBody flex="vertical">
            <WizardStep id="notes" title="Private notes">
              <InputLabel value="Notes">
                <TextArea
                  name="notes"
                  placeholder="You can fill this out later if needs be!"
                  rows={6}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    form.setState((s) => ({
                      ...s,
                      notes: e.target.value,
                    }))
                  }
                  value={form.state.notes}
                />
              </InputLabel>
            </WizardStep>
            <WizardStep id="inspectionDirection" title="Inspection direction">
              <InspectionDirectionInput
                onChange={(e) =>
                  form.setState((s) => ({
                    ...s,
                    inspectionDirection: e,
                  }))
                }
                inspection={form.state}
                value={form.state.inspectionDirection}
              />
            </WizardStep>

            <WizardStep id="publicNotes" title="Public notes">
              <InputLabel value="Public notes">
                <TextArea
                  name="notes"
                  placeholder="You can fill this out later if needs be!"
                  rows={6}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    form.setState((s) => ({
                      ...s,
                      publicNotes: e.target.value,
                    }))
                  }
                  value={form.state.publicNotes}
                />
              </InputLabel>
            </WizardStep>

            <WizardReviewStep>
              <InspectionSummary inspection={form.state} image={fileToUpload} />
            </WizardReviewStep>
          </ModalBody>

          <ModalFooter>
            <WizardStepError />
            <WizardControls />
          </ModalFooter>
        </Modal>
      </Wizard>
    </FormProvider>
  );
};

export default InspectionModal;
