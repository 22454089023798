import { Appear, Box, Icons, Text } from 'preshape';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrecacheWrapper from '../../components/Authentication/PrecacheWrapper';
import { ServiceWorkerContext } from '../../components/Bootstrap/ServiceWorkerProvider';
import CustomerProvider from '../../components/Customer/CustomerProvider';
import Explainer from '../../components/Explainer/Explainer';
import InspectionProvider from '../../components/Inspection/InspectionProvider';
import NodeMapProvider from '../../components/Node/NodeMapProvider';
import PWAInstallPrompt from '../../components/PWAInstallPrompt/PWAInstallPrompt';
import ProjectProvider from '../../components/Project/ProjectProvider';
import ReportProvider from '../../components/Report/ReportProvider';
import Spinner from '../../components/Spinner/Spinner';
import Layout from './Layout/Layout';
import LayoutMain from './Layout/LayoutMain';
import Notifications from './Layout/Notifications';
import ClassroomPage from './Pages/ClassroomPage';
import CustomerPage from './Pages/CustomerPage';
import CustomersPage from './Pages/CustomersPage';
import Error404 from './Pages/Error404Page';
import HomePage from './Pages/HomePage';
import InspectionPage from './Pages/InspectionPage';
import MyPerformancePage from './Pages/MyPerformancePage';
import NodePage from './Pages/NodePage';
import NodesPage from './Pages/NodesPage';
import OrgMembersPage from './Pages/OrgMembersPage';
import OrgProfilePage from './Pages/OrgProfilePage';
import ProjectBookingsPage from './Pages/ProjectBookingsPage';
import ProjectPage from './Pages/ProjectPage';
import ProjectReturnPageContext from './Pages/ProjectReturnPageContext';
import SectionDefaultsPage from './Pages/SectionDefaultsPage';
import SectionPage from './Pages/SectionPage';
import SectionReturnPageContext from './Pages/SectionReturnPageContext';
import SectionsPage from './Pages/SectionsPage';
import SectionsSnapshotMobilePage from './Pages/SectionsSnapshotMobilePage';
import SettingsPageOrg from './Pages/SettingsPageOrg';
import SettingsPageUser from './Pages/SettingsPageUser';
import SiteDrawPolygonPage from './Pages/SiteDrawPolygonPage';
import SiteItemsPage from './Pages/SiteItemsPage';
import SiteMapPage from './Pages/SiteMapPage';
import SiteMeasurementsPage from './Pages/SiteMeasurementsPage';
import SitePhotosPage from './Pages/SitePhotosPage';
import UserProfilePage from './Pages/UserProfilePage';
import UserSecurityPage from './Pages/UserSecurityPage';
import VideoManagementPage from './Pages/VideoManagementPage';

const MobileApp = () => {
  const { filesTotal } = React.useContext(ServiceWorkerContext);
  const [showContent, setShowContent] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (filesTotal === 0 && timeoutId !== null) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
      setShowContent(false);
    } else if (filesTotal > 0) {
      const newTimeoutId = window.setTimeout(() => {
        setShowContent(true);
      }, 1000);

      setTimeoutId(newTimeoutId);
    }
  }, [filesTotal]);

  React.useEffect(() => {
    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <Layout>
      <PWAInstallPrompt />
      <LayoutMain>
        {showContent && (
          <Appear animation="FadeSlideDown">
            <Box
              padding="x1"
              backgroundColor="negative-shade-4"
              textColor="white"
              flex="horizontal"
              gap="x2"
            >
              <Icons.WifiOff textColor="accent-shade-5" />
              <Box>
                <Text size="x1" strong>
                  Please do not close - unpublished changes
                </Text>
                <Text size="x1">
                  Sync before closing to avoid conflicts across devices
                </Text>
              </Box>
              <Explainer title={'Syncing'}>
                <Text>Syncing will happen automatically</Text>
                <Text>
                  You need to find signal and changes will be published
                </Text>
                <Text>
                  Out of sync changes across devices can cause data conflicts
                  and potential loss
                </Text>
              </Explainer>
              <Box>
                <Spinner size="0.75rem" />
              </Box>
            </Box>
          </Appear>
        )}
        <Notifications />
        <NodeMapProvider isCustomerReport={false}>
          <PrecacheWrapper>
            <Routes>
              <Route index element={<HomePage />} />
              <Route path="/projects">
                <Route path=":projectId" element={<ProjectProvider />}>
                  <Route element={<ReportProvider />}>
                    <Route index element={<ProjectPage />} />
                    <Route element={<ProjectReturnPageContext />}>
                      <Route
                        path="bookings"
                        element={<ProjectBookingsPage />}
                      />

                      <Route path="report">
                        <Route path="nodes">
                          <Route index element={<NodesPage />} />

                          <Route path=":nodeId" element={<NodePage />} />
                        </Route>

                        <Route path="site" element={<SiteMapPage />} />
                        <Route
                          path="site-photos"
                          element={<SitePhotosPage />}
                        />
                        <Route
                          path="video-management"
                          element={<VideoManagementPage />}
                        />
                        <Route
                          path="measurements"
                          element={<SiteMeasurementsPage />}
                        />
                        <Route path="site-items" element={<SiteItemsPage />} />
                        <Route
                          path="polygon"
                          element={<SiteDrawPolygonPage />}
                        />
                        <Route
                          path="customer/:customerId"
                          element={
                            <CustomerProvider
                              backToLink="../../.."
                              backToText="Job"
                            />
                          }
                        >
                          <Route index element={<CustomerPage />} />
                        </Route>

                        <Route path="sections">
                          <Route index element={<SectionsPage />} />
                          <Route path=":sectionId" element={<SectionPage />} />

                          <Route
                            path="snapshot"
                            element={<SectionsSnapshotMobilePage />}
                          />

                          <Route
                            path="defaults"
                            element={<SectionDefaultsPage />}
                          />
                          <Route path="snapshot/:sectionId">
                            <Route element={<SectionReturnPageContext />}>
                              <Route path="inspections">
                                <Route
                                  path=":inspectionId"
                                  element={<InspectionProvider />}
                                >
                                  <Route index element={<InspectionPage />} />
                                </Route>
                              </Route>
                            </Route>
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>

              <Route path="/admin">
                <Route path="org/members" element={<OrgMembersPage />} />
                <Route path="org/profile" element={<OrgProfilePage />} />
                <Route path="org/classroom" element={<ClassroomPage />} />
                <Route path="org/customers">
                  <Route index element={<CustomersPage />} />

                  <Route
                    path=":customerId"
                    element={
                      <CustomerProvider
                        backToLink="/admin/org/customers"
                        backToText="Customers"
                      />
                    }
                  >
                    <Route index element={<CustomerPage />} />
                  </Route>
                </Route>
              </Route>

              <Route path="/settings">
                <Route path="user">
                  <Route index element={<SettingsPageUser />} />
                  <Route path="profile" element={<UserProfilePage />} />
                  <Route path="security" element={<UserSecurityPage />} />
                  <Route
                    path="my-performance"
                    element={<MyPerformancePage />}
                  />
                </Route>
                <Route path="org" element={<SettingsPageOrg />} />
              </Route>

              <Route path="*" element={<Error404 />} />
            </Routes>
          </PrecacheWrapper>
        </NodeMapProvider>
      </LayoutMain>
    </Layout>
  );
};

export default MobileApp;
