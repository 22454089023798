import { Appear, Box } from 'preshape';
import React, { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { useMapContext } from '../Map';

type Props = {};

const MobileMapButtons = ({ children }: PropsWithChildren<Props>) => {
  const { container } = useMapContext();
  const minimapContiner = document.getElementById('google-map')!;

  if (!container) {
    return null;
  }

  return createPortal(
    <Appear
      animation="Pop"
      padding="x4"
      style={{ position: 'absolute', top: 'calc(100% - 105px)' }}
      width="100%"
    >
      <Box
        alignChildren="middle"
        backgroundColor="dark-shade-1"
        borderRadius="x3"
        flex="horizontal"
        gap="x2"
        padding="x2"
        textColor="text-shade-1"
        theme="night"
      >
        {children}
      </Box>
    </Appear>,
    minimapContiner
  );
};

export default MobileMapButtons;
