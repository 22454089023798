"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldBlackOut = exports.isToScale = void 0;
const types_1 = require("@drainify/types");
const isToScale = (report) => {
    if (!report) {
        return false;
    }
    if (!(report === null || report === void 0 ? void 0 : report.plan) && (report === null || report === void 0 ? void 0 : report.gridMode) === undefined) {
        return true;
    }
    if (report.gridMode !== undefined) {
        return report.gridMode.isToScale;
    }
    if (report.plan) {
        return report.plan.isToScale;
    }
    else
        return true;
};
exports.isToScale = isToScale;
const shouldBlackOut = (report) => {
    var _a, _b;
    return ((((_a = report === null || report === void 0 ? void 0 : report.plan) === null || _a === void 0 ? void 0 : _a.isToScale) !== undefined &&
        ((_b = report.plan) === null || _b === void 0 ? void 0 : _b.isToScale) === false) ||
        (report === null || report === void 0 ? void 0 : report.planType) === types_1.PlanType.BLOCK_PLAN);
};
exports.shouldBlackOut = shouldBlackOut;
