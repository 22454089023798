import { PropsWithChildren } from 'react';
import useDrawDrawingLine from '../ReportMapElements/useDrawDrawingLine';

type Props = {
  points: GeoJSON.Point[];
};

const ReportMapDrawingSegment = ({ points }: PropsWithChildren<Props>) => {
  useDrawDrawingLine({
    points: points,
    visible: true,
  });

  return null;
};

export default ReportMapDrawingSegment;
