import { Button, Buttons, Icons, Text } from 'preshape';
import React from 'react';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';

const ReportMapSidebarBoundaryLine = () => {
  const { activeSidebar, focusedBoundaryLineUid, unfocus, setActiveSidebar } =
    useReportMapContext();
  const { reportEditor } = useReportEditorContext();

  const handleBack = () => {
    unfocus();
    setActiveSidebar('plan');
  };

  const handleRemove = () => {
    focusedBoundaryLineUid &&
      reportEditor.removeBoundaryLine(focusedBoundaryLineUid);
    handleBack();
  };

  return (
    <>
      <MapSidebarContentItem
        active={activeSidebar === 'info' && !!focusedBoundaryLineUid}
        onBack={handleBack}
        title={'Boundary line'}
      >
        <MapSidebarContentItemSection>
          <Buttons>
            <Button
              basis="0"
              grow
              color="negative"
              variant="tertiary"
              flex="horizontal"
              gap="x2"
              onClick={handleRemove}
            >
              <Text>Delete boundary line</Text>
              <Icons.Trash2 />
            </Button>
          </Buttons>
        </MapSidebarContentItemSection>
      </MapSidebarContentItem>
    </>
  );
};

export default ReportMapSidebarBoundaryLine;
