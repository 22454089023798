import { Box, Text } from 'preshape';
import React from 'react';
import Explainer from '../../../components/Explainer/Explainer';
import AnnotationList from '../../../components/Measurement/AnnoationsList';
import AnnotationCreateButton from '../../../components/Measurement/AnnotationCreateButton';
import BoundaryLineCreateButton from '../../../components/Measurement/BoundaryLineCreateButton';
import BoundaryLinesList from '../../../components/Measurement/BoundaryLinesList';
import DrawingCreateButton from '../../../components/Measurement/DrawingCreateButton';
import DrawingList from '../../../components/Measurement/DrawingList';
import MeasurementCreateButton from '../../../components/Measurement/MeasurementCreateButton';
import MeasurementsList from '../../../components/Measurement/MeasurementsList';
import { useReportEditorContext } from '../../../components/Report/ReportEditorProvider';
import Page from '../Layout/Page';
import PageBody from '../Layout/PageBody';
import PageHeader from '../Layout/PageHeader';

const SiteItemsPage = () => {
  const { editMeasurement, editDrawing, editAnnotation, editBoundaryLine } =
    useReportEditorContext();
  return (
    <Page>
      <PageHeader
        title="Site items"
        action={
          <Explainer title="Measurements">
            <Text>
              The measuring tool allows you to measure distances between two
              points on a map
            </Text>
            <Text>
              Particularly useful when conducting a build-over survey, such as
              measuring the distance from a building to a sewer
            </Text>
          </Explainer>
        }
      />
      <PageBody flex="vertical" gap="x3" overflow="auto" basis="0" grow>
        <Box backgroundColor="background-shade-1">
          <Box flex="horizontal" alignChildrenHorizontal="between" grow>
            <Text strong> Measurements </Text>
            <MeasurementCreateButton variant="tertiary" />
          </Box>
          <MeasurementsList onItemClick={(uid) => editMeasurement(uid)} />
        </Box>

        <Box backgroundColor="background-shade-1">
          <Box flex="horizontal" alignChildrenHorizontal="between">
            <Text strong>Drawings</Text>
            <DrawingCreateButton variant="tertiary" />
          </Box>
          <DrawingList onItemClick={(uid) => editDrawing(uid)} />
        </Box>

        <Box backgroundColor="background-shade-1">
          <Box flex="horizontal" alignChildrenHorizontal="between">
            <Text strong>Annotations</Text>
            <AnnotationCreateButton variant="tertiary" />
          </Box>
          <AnnotationList onItemClick={(uid) => editAnnotation(uid)} />
        </Box>

        <Box backgroundColor="background-shade-1">
          <Box flex="horizontal" alignChildrenHorizontal="between">
            <Text strong>Boundary lines</Text>
            <BoundaryLineCreateButton variant="tertiary" />
          </Box>
          <BoundaryLinesList onItemClick={(uid) => editBoundaryLine(uid)} />
        </Box>
      </PageBody>
    </Page>
  );
};

export default SiteItemsPage;
