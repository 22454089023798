import { ReportEditor, getFullFilePath, shouldBlackOut } from '@drainify/utils';
import {
  Box,
  Button,
  CheckBox,
  Grid,
  Icons,
  Modal,
  ModalBody,
  ModalHeader,
  Text,
} from 'preshape';
import React from 'react';
import JobAdditionalSummary from '../../../Booking/JobAdditionalSummary';
import DataTable from '../../../DataTable/DataTable';
import DataTableGroup from '../../../DataTable/DataTableGroup';
import DataTableItem from '../../../DataTable/DataTableItem';
import DocumentListCustomer from '../../../Document/DocumentListCustomer';
import HiddenContent from '../../../HiddenContent/HiddenContent';
import NodesIcon from '../../../IconsMisc/NodesIcon';
import SectionsIcon from '../../../IconsMisc/SectionsIcon';
import Address from '../../../Location/Address';
import { useMapContext } from '../../../Map/Map';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import NodeView from '../../../Node/NodeView';
import OrgView from '../../../Org/OrgView';
import { useProjectContext } from '../../../Project/ProjectProvider';
import { useReportCustomerContext } from '../../../ReportAccessPage/ReportCustomerProvider';
import SearchInput from '../../../Search/SearchInput';
import SearchProvider from '../../../Search/SearchProvider';
import SearchResult from '../../../Search/SearchResult';
import SearchResultGroup from '../../../Search/SearchResultGroup';
import SectionView from '../../../Section/SectionView';
import SitePhotoView from '../../../SitePhotos/SitePhotoView';
import SummaryCard from '../../../SummaryCard/SummaryCard';
import { useReportMapContext } from '../ReportMapProvider';

const ReportMapSidebarHomeCustomer = () => {
  const { toggleMapType, mapType } = useMapContext();
  const { activeSidebarCustomer, focusSection, focusNode } =
    useReportMapContext();
  const { activeBooking, customerReportView } = useProjectContext();
  const {
    showSimpleSections,
    setShowSimpleSections,
    showObservations,
    setShowObservations,
    showSectionLabels,
    setShowSectionLabels,
    showNodeLabels,
    setShowNodeLabels,
  } = useReportCustomerContext();

  const reportEditor = new ReportEditor(customerReportView!.report, () => {});

  const [sectionModalVisable, setSectionModalVisable] = React.useState(false);
  const [nodeModalVisable, setNodeModalVisable] = React.useState(false);
  const [documentsModalVisable, setDocumentsModalVisable] =
    React.useState(false);
  const [sitePhotosModal, setSitePhotosModal] = React.useState(false);

  const [toggled, setToggled] = React.useState(
    mapType === google.maps.MapTypeId.ROADMAP
  );

  React.useEffect(() => {
    toggleMapType();
  }, []);

  React.useEffect(() => {
    setToggled(mapType === google.maps.MapTypeId.ROADMAP);
  }, [mapType]);

  return (
    <>
      <MapSidebarContentItem
        active={activeSidebarCustomer === 'home'}
        title={activeBooking?.jobId}
      >
        <MapSidebarContentItemSection>
          <Text size="x1"> © 2024, Drainify, All rights reserved.</Text>
          <Box flex="vertical" gap="x3">
            <OrgView org={customerReportView?.project.org} large />
            <Box flex="horizontal" alignChildrenHorizontal="between">
              <Text size="x1">
                Tel: {customerReportView?.project.org.contactNumber}
              </Text>
              <Text size="x1">
                Email: {customerReportView?.project.org.contactEmail}{' '}
              </Text>
            </Box>
            <Button
              variant="primary"
              href={getFullFilePath(activeBooking!.pdfReport!)}
              tag="a"
              target="_blank"
              color="accent"
              flex="horizontal"
              gap="x2"
              grow
              alignChildrenHorizontal="middle"
            >
              <Icons.Download />
              pdf report
            </Button>
          </Box>
        </MapSidebarContentItemSection>
        <MapSidebarContentItemSection title="Map view options">
          <HiddenContent content="Show map view options">
            <Box flex="vertical" gap="x2">
              <CheckBox
                onChange={() => {
                  setShowSimpleSections(!showSimpleSections);
                }}
                checked={showSimpleSections}
              >
                Show simplified pipes
              </CheckBox>

              <CheckBox
                onChange={() => {
                  setShowSectionLabels(!showSectionLabels);
                }}
                checked={!showSectionLabels}
              >
                {' '}
                Hide section/lateral labels{' '}
              </CheckBox>

              <CheckBox
                onChange={() => {
                  setShowNodeLabels(!showNodeLabels);
                }}
                checked={!showNodeLabels}
              >
                {' '}
                Hide node labels
              </CheckBox>

              {!showSimpleSections && (
                <CheckBox
                  onChange={() => {
                    setShowObservations(!showObservations);
                  }}
                  checked={!showObservations}
                >
                  {' '}
                  Hide observations
                </CheckBox>
              )}
              {!shouldBlackOut(reportEditor.report) && (
                <CheckBox
                  checked={!toggled}
                  onChange={() => {
                    toggleMapType();
                    setToggled(!toggled);
                  }}
                >
                  Show satillite view
                </CheckBox>
              )}
            </Box>
          </HiddenContent>
        </MapSidebarContentItemSection>
        <MapSidebarContentItemSection title="Site location">
          <SummaryCard>
            <Address location={customerReportView?.location} />
          </SummaryCard>
        </MapSidebarContentItemSection>

        <MapSidebarContentItemSection
          title={`Sections (${customerReportView?.report.sections.length})`}
          action={
            <Button
              color="accent"
              variant="tertiary"
              onClick={() => setSectionModalVisable(true)}
            >
              <Box flex="horizontal" gap="x2">
                <SectionsIcon />
                <Text>View sections list</Text>
              </Box>
            </Button>
          }
        ></MapSidebarContentItemSection>

        <MapSidebarContentItemSection
          title={`Nodes (${customerReportView?.report.nodes.length})`}
          action={
            <Button
              color="accent"
              variant="tertiary"
              onClick={() => setNodeModalVisable(true)}
            >
              <Box flex="horizontal" gap="x2">
                <NodesIcon />
                <Text>View node list</Text>
              </Box>
            </Button>
          }
        ></MapSidebarContentItemSection>

        <MapSidebarContentItemSection
          title={`Documents & Videos`}
          action={
            <Button
              color="accent"
              variant="tertiary"
              onClick={() => setDocumentsModalVisable(true)}
            >
              <Box flex="horizontal" gap="x2">
                <Icons.FileText />
                <Text>View documents/videos</Text>
              </Box>
            </Button>
          }
        ></MapSidebarContentItemSection>

        {customerReportView &&
          customerReportView?.report.sitePhotos.length > 0 && (
            <MapSidebarContentItemSection
              title={`General site photography (${customerReportView?.report.sitePhotos.length})`}
              action={
                <Button
                  color="accent"
                  variant="tertiary"
                  onClick={() => setSitePhotosModal(true)}
                >
                  <Box flex="horizontal" gap="x2">
                    <Icons.Image />
                    <Text>View site photos</Text>
                  </Box>
                </Button>
              }
            ></MapSidebarContentItemSection>
          )}

        <HiddenContent>
          <MapSidebarContentItemSection>
            <JobAdditionalSummary job={activeBooking} />
          </MapSidebarContentItemSection>
        </HiddenContent>
      </MapSidebarContentItem>

      {sectionModalVisable && (
        <Modal visible onClose={() => setSectionModalVisable(false)}>
          <ModalHeader>Sections</ModalHeader>
          <ModalBody>
            <SearchProvider>
              <SearchInput />
              <SearchResultGroup>
                <DataTable>
                  <DataTableGroup>
                    {customerReportView?.report.sections.map((e) => (
                      <SearchResult
                        key={e.uid}
                        keywords={reportEditor.getSectionName(e)}
                      >
                        <DataTableItem
                          onClick={() => {
                            focusSection(e.uid);
                            setSectionModalVisable(false);
                          }}
                        >
                          <SectionView section={e} validate={false} />
                        </DataTableItem>
                      </SearchResult>
                    ))}
                  </DataTableGroup>
                </DataTable>
              </SearchResultGroup>
            </SearchProvider>
          </ModalBody>
        </Modal>
      )}

      {nodeModalVisable && (
        <Modal visible onClose={() => setNodeModalVisable(false)}>
          <ModalHeader>Nodes</ModalHeader>
          <ModalBody>
            <SearchProvider>
              <SearchInput />
              <SearchResultGroup>
                <DataTable>
                  <DataTableGroup>
                    {customerReportView?.report.nodes.map((e) => (
                      <SearchResult
                        key={e.uid}
                        keywords={reportEditor.getNodeName(e)}
                      >
                        <DataTableItem
                          onClick={() => {
                            focusNode(e.uid);
                            setSectionModalVisable(false);
                          }}
                        >
                          <NodeView node={e} validate={false} />
                        </DataTableItem>
                      </SearchResult>
                    ))}
                  </DataTableGroup>
                </DataTable>
              </SearchResultGroup>
            </SearchProvider>
          </ModalBody>
        </Modal>
      )}

      {documentsModalVisable && (
        <Modal visible onClose={() => setDocumentsModalVisable(false)}>
          <ModalHeader>Documents/videos</ModalHeader>
          <ModalBody>
            <DocumentListCustomer />
          </ModalBody>
        </Modal>
      )}

      {sitePhotosModal && (
        <Modal visible onClose={() => setSitePhotosModal(false)}>
          <ModalHeader>Site photos</ModalHeader>
          <ModalBody style={{ overflow: 'scroll' }}>
            <Grid margin="x2" repeat="2" repeatWidth="1fr">
              {customerReportView?.report?.sitePhotos.map((e) => (
                <SitePhotoView sitePhoto={e} key={e.uid} canRemove={false} />
              ))}
            </Grid>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default ReportMapSidebarHomeCustomer;
