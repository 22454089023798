import { PlanType, Report } from '@drainify/types';
import { Icons } from 'preshape';
import React from 'react';
import Tile from '../../pages/mobile/Pages/Tile';
import PlanUploadModal from '../Plan/PlanUploadModal';

type Props = {
  report?: Report;
};

const planTypeSettingsName: Record<PlanType, string> = {
  [PlanType.GOOGLE_MAPS]: 'Map',
  [PlanType.UPLOADED_PLAN]: 'Upload',
  [PlanType.NO_PLAN]: 'None',
  [PlanType.BLOCK_PLAN]: 'Block plan',
};

const PlanTypeSelector = ({ report }: Props) => {
  const [selectorVisible, setSelectorVisible] = React.useState(false);
  if (!report) {
    return null;
  }

  return (
    <>
      <Tile
        onClick={() => setSelectorVisible(true)}
        icon={<Icons.Map size="1.5rem" />}
        text="Plan"
        label={planTypeSettingsName[report.planType || PlanType.GOOGLE_MAPS]}
      />
      {selectorVisible && (
        <PlanUploadModal
          visible={selectorVisible}
          onClose={() => setSelectorVisible(false)}
        />
      )}
    </>
  );
};

export default PlanTypeSelector;
