import { Plan, PlanType } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import { Box, Icons, Text, Button, Buttons } from 'preshape';
import React from 'react';
import ImageFromUrl from '../../../Image/ImageFromUrl';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';

type Props = {
  plan?: Plan | null;
};

const ReportMapToolAddPlan = ({ plan }: Props) => {
  const { reportEditor } = useReportEditorContext();
  const { focusPlan } = useReportMapContext();

  if (reportEditor.report.planType !== PlanType.UPLOADED_PLAN) {
    return null;
  }

  if (!plan) {
    return (
      <Box
        padding="x6"
        flex="horizontal"
        alignChildrenHorizontal="middle"
        backgroundColor="background-shade-2"
      >
        <Text textColor="text-shade-4" size="x2">
          No plan set
        </Text>
      </Box>
    );
  }
  return (
    <Box flex="vertical">
      <ImageFromUrl src={getFullFilePath(plan.imageUrl)} height="200px" />
      <Buttons flex="horizontal" alignChildrenHorizontal="around">
        {reportEditor.report.plan?.isToScale && (
          <Button
            color="accent"
            variant="tertiary"
            onClick={() => focusPlan(plan.uid)}
            flex="horizontal"
            gap="x1"
          >
            <Text>Edit</Text>
            <Icons.Edit2 size="1rem" color="accent" />
          </Button>
        )}
      </Buttons>
    </Box>
  );
};

export default ReportMapToolAddPlan;
