import { PlanType } from '@drainify/types';
import {
  Box,
  Button,
  FormProvider,
  Icons,
  Input,
  InputLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
} from 'preshape';
import React, { ChangeEvent } from 'react';
import useAnnotationForm from '../../hooks/forms/useAnnotationForm';
import { isDesktop, isMobile } from '../../utils/client';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import { useHasPermission } from '../Permissions/Permissions';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import SearchProvider from '../Search/SearchProvider';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardSearch from '../Wizard/WizardSearch';
import WizardStep from '../Wizard/WizardStep';
import WizardStepError from '../Wizard/WizardStepError';
import NodeLocationHack from './NodeLocationHack';

type Props = ModalProps & {
  initialActiveStep?: string;
  annotationUid?: string;
  onClose: () => void;
  onCreate?: (annotationUid: string) => void;
};

export const iconMap: Map<string, React.ElementType> = new Map([
  ['default', Icons.Info],
  ['alert', Icons.AlertTriangle],
  ['feather', Icons.Feather],
  ['eye', Icons.Eye],
  ['eye-off', Icons.EyeOff],
  ['from', Icons.Frown],
  ['home', Icons.Home],
  ['key', Icons.Key],
  ['link', Icons.Link],
  ['meh', Icons.Meh],
  ['phone', Icons.Phone],
  ['radio', Icons.Radio],
  ['shopping-cart', Icons.ShoppingCart],
  ['targe', Icons.Target],
  ['trash', Icons.Trash],
  ['cloud', Icons.Cloud],
]);

const AnnotationModal = ({
  initialActiveStep,
  annotationUid,
  onClose,
  onCreate,
  visible,
  ...rest
}: Props) => {
  const { reportEditor } = useReportEditorContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');

  const annotation = reportEditor.getAnnotationByUid(annotationUid);

  const form = useAnnotationForm(annotation);

  React.useEffect(() => {
    form.reset();
  }, [visible]);

  const handleRemove = async () => {
    if (annotationUid) {
      reportEditor.removeAnnotation(annotationUid);
    }

    onClose();
  };

  const handleSave = async () => {
    if (annotationUid) {
      reportEditor.updateAnnotation(annotationUid, {
        ...form.state,
      });
    } else {
      const { uid } = reportEditor.addAnnotation({
        ...form.state,
      });
      await reportEditor.updateAsync?.(reportEditor.report);
      onCreate?.(uid);
    }

    onClose();
  };

  const handleSetName = (name: string) => {
    form.setState((s) => ({ ...s, name }));
  };

  const handleSetIcon = (icon: string) => {
    form.setState((s) => ({ ...s, icon }));
  };

  const handleSetPoint = (point: GeoJSON.Point) => {
    form.setState((s) => ({
      ...s,
      point,
    }));
  };

  return (
    <SearchProvider>
      <FormProvider form={form}>
        <Wizard
          flow={annotationUid ? 'update' : 'create'}
          initialActiveStepId={initialActiveStep}
          onSave={hasUpdateProjectPermission ? handleSave : undefined}
          onRemove={hasUpdateProjectPermission ? handleRemove : undefined}
          // onCancel={onClose}
          reset={visible}
        >
          <Modal
            {...rest}
            animation="FadeSlideUp"
            margin="x4"
            fullscreen={!isDesktop()}
            maxWidth={isMobile() ? '100vw' : FULL_SCREEN_MODAL_WIDTH}
            onClose={onClose}
            overlayBackgroundCloseOnClick={false}
            visible={visible}
          >
            <ModalHeader>
              <ModalTitle>
                {annotation ? annotation.name : 'New annotation'}
              </ModalTitle>
            </ModalHeader>

            <ModalBody flex="vertical">
              <WizardStep id="name" title="Annotation name">
                <InputLabel>
                  <Input
                    size={isDesktop() ? 'x3' : 'x8'}
                    name="name"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleSetName(event.target.value)
                    }
                    placeholder={''}
                    value={form.state.name || ''}
                  />
                </InputLabel>
              </WizardStep>

              <WizardStep id="icon" title="Icon">
                <Box flex="horizontal" wrap gap="x3">
                  {Array.from(iconMap.entries()).map(([key, IconComponent]) => (
                    <Button
                      key={key || 'undefined'}
                      borderSize="x1"
                      flex="vertical"
                      onClick={() => handleSetIcon(key!)}
                      maxWidth="70px"
                      padding="x3"
                      alignChildrenHorizontal="middle"
                      backgroundColorActive="accent-shade-5"
                      textColorActive="light-shade-3"
                      active={key === form.state.icon}
                    >
                      <IconComponent size="2rem" />
                    </Button>
                  ))}
                </Box>
              </WizardStep>

              {isMobile() && reportEditor.report.planType !== PlanType.NO_PLAN && (
                <WizardStep id="point">
                  <NodeLocationHack onChange={handleSetPoint} />
                </WizardStep>
              )}

              <WizardReviewStep></WizardReviewStep>
            </ModalBody>

            <ModalFooter>
              <WizardSearch />
              <WizardStepError />
              <WizardControls />
            </ModalFooter>
          </Modal>
        </Wizard>
      </FormProvider>
    </SearchProvider>
  );
};

export default AnnotationModal;
