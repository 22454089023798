import { Inspection, Node, Section } from '@drainify/types';
import { getFullFilePath, ReportElementType } from '@drainify/utils';
import { Appear, Box, Label, sizeX1Px, Text } from 'preshape';
import React, { PropsWithChildren, useState } from 'react';
import ImageFromUrl from '../../../Image/ImageFromUrl';
import { useMapLayersContext } from '../../../Map/MapLayers/MapLayers';
import MapLabel from '../../../Map/MapMarker/MapLabel';
import MapMarker from '../../../Map/MapMarker/MapMarker';
import MapMarkerToolbar from '../../../Map/MapMarker/MapMarkerToolbar';
import useGeometryStoreRegister from '../../../Map/useGeometryStore/useGeometryStoreRegister';
import NodeView from '../../../Node/NodeView';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';
import ReportMapNodeRenderer from './ReportMapNodeRenderer';

type Props = {
  isHidden?: boolean;
  node: Node;
  showLabel: boolean;
};

const ReportMapNodeCustomer = ({
  isHidden,
  node,
  showLabel,
}: PropsWithChildren<Props>) => {
  const { isLayerVisible } = useMapLayersContext();
  const { toggleFocusNode, focusedInspectionUid } = useReportMapContext();
  const { reportEditor } = useReportEditorContext();

  const [mouseOver, setIsMouseOver] = useState(false);

  const visible = isLayerVisible(ReportElementType.NODE, node.uid);

  const handlePointerEnter = () => {
    setIsMouseOver(true);
  };

  const handlePointerLeave = () => {
    setIsMouseOver(false);
  };

  const [, ref] = useGeometryStoreRegister({
    geometry: node.point,
    opts: {
      anchor: 'center',
      id: node.uid,
      keepInFocus: true,
      padding: sizeX1Px,
      type: 'ReportMapNode',
    },
  });

  if (!node.point) {
    return null;
  }
  function getLabel(
    nodeUid: string,
    section?: Section,
    inspection?: Inspection
  ) {
    if (
      !section ||
      !inspection ||
      !section.attributes.flow ||
      !inspection.inspectionDirection
    ) {
      return '';
    }
    if (section.attributes.flow === inspection.inspectionDirection) {
      return section.nodeStartUid === nodeUid ? 'Start' : 'Finish';
    } else {
      return section.nodeStartUid === nodeUid ? 'Finish' : 'Start';
    }
  }

  return (
    <>
      <MapLabel
        id={node.uid}
        point={node.point}
        visible={!isHidden && visible && showLabel}
      >
        <Label size="x1" flex="vertical">
          <Text ellipsis>{reportEditor.getNodeName(node)}</Text>
          {reportEditor.getInspectionByUid(focusedInspectionUid) && (
            <Text size="x1" uppercase align="middle">
              {getLabel(
                node.uid,

                reportEditor.getSectionByUid(
                  reportEditor.getInspectionByUid(focusedInspectionUid)
                    ?.sectionUid
                ),
                reportEditor.getInspectionByUid(focusedInspectionUid)
              )}
            </Text>
          )}
        </Label>
      </MapLabel>

      <MapMarker point={node.point}>
        <MapMarkerToolbar
          enabled={visible}
          showOnHover
          information={
            <Box flex="vertical" gap="x3">
              <NodeView node={node} />
              {node.imageUrl && (
                <ImageFromUrl
                  src={getFullFilePath(node.imageUrl)}
                  height="150px"
                />
              )}
              {node.remarks && (
                <Text wrap maxWidth="150px" size="x1">
                  {node.remarks.length > 30
                    ? node.remarks.substring(0, 30) + '...'
                    : node.remarks}
                </Text>
              )}
            </Box>
          }
        >
          <Text
            container
            display="block"
            onPointerOver={handlePointerEnter}
            onPointerLeave={handlePointerLeave}
            onClick={() => toggleFocusNode(node.uid)}
          >
            {/* Default black dot state */}
            {!mouseOver && (
              <Appear
                animation="Pop"
                ref={ref}
                visible={visible && !isHidden}
                padding="x4"
              >
                <ReportMapNodeRenderer locked={true} code={node.code} />
              </Appear>
            )}

            {/* Hidden state */}
            {isHidden && (
              <Box absolute="center">
                <Box
                  backgroundColor="text-shade-1"
                  borderRadius="full"
                  textColor="text-shade-1"
                ></Box>
              </Box>
            )}
            {mouseOver && (
              <Appear animation="Pop" ref={ref} visible={visible && !isHidden}>
                <ReportMapNodeRenderer
                  locked={true}
                  code={node.code}
                  size="3rem"
                />
              </Appear>
            )}
          </Text>
        </MapMarkerToolbar>
      </MapMarker>
    </>
  );
};

export default ReportMapNodeCustomer;
