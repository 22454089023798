import { Box, Text } from 'preshape';
import React from 'react';
import RulerIcon from '../../../IconsMisc/Ruler';
import { useMapContext } from '../../../Map/Map';
import MapNotification from '../../../Map/MapNotification/MapNotification';
import { useProjectContext } from '../../../Project/ProjectProvider';
import { useReportEditorContext } from '../../ReportEditorProvider';
import useDrawMeasurementMap from '../ReportMapElements/useDrawMeasurementMap';
import { useReportMapContext } from '../ReportMapProvider';

type Props = {
  toggled: boolean;
  activate: () => void;
  deactivate: () => void;
};

const ReportMapMeasure = ({ toggled, activate, deactivate }: Props) => {
  const {
    addPointerDownListener,
    enableInteractivity,
    disableInteractivity,
    addPointerMoveListener,
    addPointerUpListener,
  } = useMapContext();

  const { reportEditor } = useReportEditorContext();
  const { activeBooking } = useProjectContext();

  const [isDrawing, setIsDrawing] = React.useState(false);
  const [startPoint, setStartPoint] = React.useState<GeoJSON.Point>();
  const [endPoint, setEndPoint] = React.useState<GeoJSON.Point>();
  const [staged, setStaged] = React.useState<GeoJSON.Point[]>([]);
  const { focusMeasurement } = useReportMapContext();

  React.useEffect(() => {
    if (startPoint && endPoint) {
      setStaged([startPoint, endPoint]);
    } else {
      setStaged([]);
    }
  }, [startPoint, endPoint]);

  React.useEffect(() => {
    if (toggled) {
      return addPointerDownListener((point) => {
        setStartPoint(point);
        disableInteractivity();
        setIsDrawing(true);
      });
    }
  }, [toggled]);

  useDrawMeasurementMap({
    points: staged,
    visible: true,
    strokeColor: 'green',
  });

  React.useEffect(() => {
    if (toggled && isDrawing) {
      return addPointerMoveListener((point) => {
        setEndPoint(point);
      });
    }
  }, [toggled, isDrawing]);

  React.useEffect(() => {
    if (toggled && startPoint) {
      return addPointerUpListener((point) => {
        enableInteractivity();
        setIsDrawing(false);

        const { uid } = reportEditor.addMeasurement({
          points: [startPoint, point],
          jobId: activeBooking?.uid,
        });

        setStartPoint(undefined);
        setEndPoint(undefined);
        deactivate();
        focusMeasurement(uid);
      });
    }
  }, [toggled, startPoint]);

  return (
    <>
      <Box
        flex="vertical"
        alignChildrenHorizontal="middle"
        gap="x1"
        borderSize="x1"
        shrink
        backgroundColor={toggled ? 'accent-shade-3' : 'accent-shade-1'}
        style={{
          cursor: 'pointer',
        }}
        padding="x2"
        borderColor={'black'}
        textColor={toggled ? 'white' : 'black'}
        onClick={() => (toggled ? deactivate() : activate())}
        title={'Measurements'}
      >
        <RulerIcon size="1.75rem" fill={toggled ? 'white' : 'black'} />
      </Box>
      {toggled && (
        <MapNotification color="negative-shade-4" typePosition="top-right">
          <Text size="x4">Drawing measurement</Text>
          <RulerIcon />
        </MapNotification>
      )}
    </>
  );
};

export default ReportMapMeasure;
