import { Button, ButtonProps, Icons, Text } from 'preshape';
import React from 'react';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = ButtonProps & {
  onCreate?: (nodeUid: string) => void;
  sectionUid?: string;
};

const InspectionCreateButton = ({ onCreate, sectionUid, ...rest }: Props) => {
  const { createNewInspection } = useReportEditorContext();
  const { activeBooking } = useProjectContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const { reportEditor, editSection } = useReportEditorContext();

  if (!hasUpdateProjectPermission || !sectionUid) {
    return null;
  }

  if (reportEditor.getSectionByUid(sectionUid)?.attributes.flow === undefined) {
    return (
      <Button
        variant="tertiary"
        onClick={() => editSection(sectionUid, 'attributes.flow')}
        color="accent"
        size="x1"
        gap="x1"
      >
        <Icons.Plus size="0.75rem" /> <Text>Add pipe flow</Text>
      </Button>
    );
  }

  return (
    <Button
      variant="secondary"
      {...rest}
      color="accent"
      gap="x2"
      onClick={() => createNewInspection(sectionUid, onCreate)}
      type="button"
      disabled={!!activeBooking?.completedAt}
    >
      <Text>New inspection</Text>
      <Icons.Plus size="1rem" />
    </Button>
  );
};

export default InspectionCreateButton;
