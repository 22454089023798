import React from 'react';
import useDrawMeasurementMap from '../../Report/ReportMap/ReportMapElements/useDrawMeasurementMap';

export type Props = {
  points: GeoJSON.Point[];
  strokeColor?: string;
  layer?: number;
};

const MapLine = ({ points, strokeColor = 'black', layer }: Props) => {
  useDrawMeasurementMap({ points, visible: true, strokeColor, layer });

  return <></>;
};

export default MapLine;
