import { getMidPointBetweenPoints } from '@drainify/utils';
import transformRotate from '@turf/transform-rotate';
import transformScale from '@turf/transform-scale';
import transformTranslate from '@turf/transform-translate';
import { Buttons, Button, Range, Icons, Text } from 'preshape';
import React, { ChangeEvent } from 'react';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import SummaryCard from '../../../SummaryCard/SummaryCard';
import SummaryCardSection from '../../../SummaryCard/SummaryCardSection';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';

const ReportMapSidebarPlanEdit = () => {
  const { reportEditor } = useReportEditorContext();
  const { activeSidebar, focusedPlanUid, unfocus, setActiveSidebar } =
    useReportMapContext();
  const plan = reportEditor.report.plan;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cachedOpacity, setCachedOpacity] = React.useState(plan?.opacity || 1);

  const handleBack = () => {
    unfocus();
    setActiveSidebar('plan');
  };

  const performBlockingUpdate = async () => {
    setLoading(true);
    reportEditor.updateBounds({
      type: 'Polygon',
      coordinates: [
        [
          plan?.point?.coordinates[0][0]!,
          plan?.point?.coordinates[0][3]!,
          plan?.point?.coordinates[0][1]!,
          plan?.point?.coordinates[0][2]!,
        ],
      ],
    });

    reportEditor.updateAsync?.(reportEditor.report);

    setLoading(false);
  };

  if (!plan || !plan.point) {
    return null;
  }

  return (
    <>
      <MapSidebarContentItem
        active={activeSidebar === 'info' && !!focusedPlanUid}
        onBack={handleBack}
        title={plan?.name}
      >
        <MapSidebarContentItemSection>
          <SummaryCard>
            <SummaryCardSection title={'Rotate'}>
              <Buttons flex="horizontal" alignChildrenHorizontal="between">
                <Button
                  disabled={loading}
                  onClick={() => {
                    reportEditor.updatePlanNoUpdate(plan.uid, {
                      point: transformRotate(plan.point!, -1, {
                        pivot: getMidPointBetweenPoints(
                          {
                            type: 'Point',
                            coordinates: plan.point!.coordinates[0][0],
                          },
                          {
                            type: 'Point',
                            coordinates: plan.point!.coordinates[0][1],
                          }
                        )!,
                      }),
                    });
                    performBlockingUpdate();
                  }}
                >
                  <Icons.RotateCcw />
                </Button>
                <Button
                  disabled={loading}
                  onClick={() => {
                    reportEditor.updatePlanNoUpdate(plan.uid, {
                      point: transformRotate(plan.point!, 1, {
                        pivot: getMidPointBetweenPoints(
                          {
                            type: 'Point',
                            coordinates: plan.point!.coordinates[0][0],
                          },
                          {
                            type: 'Point',
                            coordinates: plan.point!.coordinates[0][1],
                          }
                        )!,
                      }),
                    });
                    performBlockingUpdate();
                  }}
                >
                  <Icons.RotateCw />
                </Button>
              </Buttons>
            </SummaryCardSection>
            <SummaryCardSection title={'Move'}>
              <Buttons flex="horizontal" alignChildrenHorizontal="between">
                <Button
                  disabled={loading}
                  onClick={() => {
                    reportEditor.updatePlanNoUpdate(plan.uid, {
                      point: transformTranslate(plan.point!, 0.001, 0),
                    });
                    performBlockingUpdate();
                  }}
                >
                  <Icons.ArrowUp />
                </Button>
                <Button
                  disabled={loading}
                  onClick={() => {
                    reportEditor.updatePlanNoUpdate(plan.uid, {
                      point: transformTranslate(plan.point!, 0.001, 180),
                    });
                    performBlockingUpdate();
                  }}
                >
                  <Icons.ArrowDown />
                </Button>
                <Button
                  disabled={loading}
                  onClick={() => {
                    reportEditor.updatePlanNoUpdate(plan.uid, {
                      point: transformTranslate(plan.point!, 0.001, 90),
                    });
                    performBlockingUpdate();
                  }}
                >
                  <Icons.ArrowRight />
                </Button>
                <Button
                  disabled={loading}
                  onClick={() => {
                    reportEditor.updatePlanNoUpdate(plan.uid, {
                      point: transformTranslate(plan.point!, 0.001, 270),
                    });
                    performBlockingUpdate();
                  }}
                >
                  <Icons.ArrowLeft />
                </Button>
              </Buttons>
            </SummaryCardSection>
            <SummaryCardSection title={'Scale'}>
              <Buttons flex="horizontal" alignChildrenHorizontal="between">
                <Button
                  disabled={loading}
                  onClick={() => {
                    reportEditor.updatePlanNoUpdate(plan.uid, {
                      point: transformScale(plan.point!, 1.01),
                    });
                    performBlockingUpdate();
                  }}
                >
                  <Icons.Maximize />
                </Button>
                <Button
                  disabled={loading}
                  onClick={() => {
                    reportEditor.updatePlanNoUpdate(plan.uid, {
                      point: transformScale(plan.point!, 0.99),
                    });
                    performBlockingUpdate();
                  }}
                >
                  <Icons.Minimize />
                </Button>
              </Buttons>
            </SummaryCardSection>
          </SummaryCard>
        </MapSidebarContentItemSection>

        <MapSidebarContentItemSection>
          <SummaryCard>
            <SummaryCardSection title={'Opacity'}>
              <Range
                min="0"
                max="1"
                addonEnd={<Icons.Eye />}
                step="0.1"
                value={cachedOpacity}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setCachedOpacity(parseFloat(e.target.value));
                }}
                onMouseUp={(e) => {
                  reportEditor.updatePlan({
                    opacity: parseFloat(e.currentTarget.value),
                  });
                }}
              />
            </SummaryCardSection>
          </SummaryCard>
        </MapSidebarContentItemSection>

        <MapSidebarContentItemSection>
          <Buttons>
            <Button
              grow
              variant="tertiary"
              color="negative"
              gap="x2"
              onClick={() => reportEditor.removePlan()}
            >
              <Text>Delete plan</Text>
              <Icons.Trash2 size="1rem" />
            </Button>
          </Buttons>
        </MapSidebarContentItemSection>
      </MapSidebarContentItem>
    </>
  );
};

export default ReportMapSidebarPlanEdit;
