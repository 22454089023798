import { ReportElementType } from '@drainify/utils';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHasPermission } from '../../Permissions/Permissions';

export type ReportMapSidebar =
  | 'bookings'
  | 'tools'
  | 'nodes'
  | 'sections'
  | 'info'
  | 'plan'
  | 'job';

export type ReportMapSidebarCustomer = 'home' | 'info';

export type ReportMapFeatureOpts = {
  'Bounds:change': boolean;
  'Elements:add': boolean;
  'Elements:edit': boolean;
  'Elements:inspect': boolean;
  'Elements:move': boolean;
  'Elements:remove': boolean;
};

const ReportMapContext = createContext<{
  activeSidebar?: ReportMapSidebar;
  activeSidebarCustomer?: ReportMapSidebarCustomer;
  focusedElementType?: ReportElementType;
  focusedElementUid?: string;
  focusedNodeUid?: string;
  focusedMeasurementUid?: string;
  focusedDrawingUid?: string;
  focusedPlanUid?: string;
  focusedObservationUid?: string;
  focusedSectionUid?: string;
  focusedInspectionUid?: string;
  focusedAnnotationUid?: string;
  focusedBoundaryLineUid?: string;
  hasFeature: (feature: keyof ReportMapFeatureOpts) => boolean;
  focusNode: (nodeUid: string) => void;
  focusScale: () => void;
  focusMeasurement: (measurementUid: string) => void;
  focusDrawing: (measurementUid: string) => void;
  focusObservation: (observationUid: string) => void;
  focusSection: (sectionUid: string) => void;
  focusInspection: (inspectionUid: string) => void;
  focusPlan: (planUid: string) => void;
  focusAnnotation: (annotationUid: string) => void;
  focusBoundaryLine: (annotationUid: string) => void;
  isFocused: boolean;
  isNodeFocused: (nodeUid: string) => boolean | undefined;
  isObservationFocused: (observationUid: string) => boolean | undefined;
  isSectionFocused: (sectionUid: string) => boolean | undefined;
  isEditingSectionShape: boolean | undefined;
  toggleEditSectionShape: () => void;
  isInspectionFocused: (inspectionUid: string) => boolean | undefined;
  setActiveSidebar: (sidebar: ReportMapSidebar) => void;
  setActiveSidebarCustomer: (sidebar: ReportMapSidebarCustomer) => void;
  toggleFocusNode: (nodeUid: string) => void;
  toggleFocusMeasurement: (measurementUid: string) => void;
  toggleFocusDrawing: (drawingUid: string) => void;
  toggleFocusObservation: (observationUid: string) => void;
  toggleFocusSection: (sectionUid: string) => void;
  toggleFocusInspection: (inspectionUid: string) => void;
  toggleFocusPlan: (planUid: string) => void;
  toggleFocusBoundaryLine: (boundaryLineUid: string) => void;
  toggleFocusAnnotation: (annotationUid: string) => void;
  unfocus: () => void;
}>({
  hasFeature: () => true,
  focusNode: () => {},
  focusScale: () => {},
  focusMeasurement: () => {},
  focusDrawing: () => {},
  focusObservation: () => {},
  focusSection: () => {},
  focusInspection: () => {},
  focusPlan: () => {},
  focusAnnotation: () => {},
  focusBoundaryLine: () => {},
  isFocused: false,
  isNodeFocused: () => false,
  isObservationFocused: () => false,
  isSectionFocused: () => false,
  isInspectionFocused: () => false,
  setActiveSidebar: () => {},
  setActiveSidebarCustomer: () => {},
  toggleFocusNode: () => {},
  toggleFocusMeasurement: () => {},
  toggleFocusDrawing: () => {},
  toggleFocusObservation: () => {},
  toggleFocusSection: () => {},
  toggleFocusInspection: () => {},
  toggleFocusPlan: () => {},
  toggleFocusAnnotation: () => {},
  toggleFocusBoundaryLine: () => {},
  unfocus: () => {},
  isEditingSectionShape: false,
  toggleEditSectionShape: () => {},
});

export const useReportMapContext = () => useContext(ReportMapContext);

export type ReportMapProviderProps = {
  focusedElementType?: ReportElementType;
  focusedElementUid?: string;
  featureOpts?: Partial<ReportMapFeatureOpts>;
  readOnly?: boolean;
  onFocusMeasurement?: (uid: string) => void;
  onFocusDrawing?: (uid: string) => void;
  onFocusScale?: () => void;
  onFocusNode?: (uid: string) => void;
  onFocusObservation?: (uid: string) => void;
  onFocusSection?: (uid: string) => void;
  onFocusInspection?: (uid: string) => void;
  onFocusPlan?: (uid: string) => void;
  onFocusBoundaryLine?: (uid: string) => void;
  onFocusAnnotation?: (uid: string) => void;
  onUnfocus?: () => void;
};

const ReportMapProvider = ({
  focusedElementType,
  focusedElementUid,
  featureOpts,
  onFocusMeasurement,
  onFocusDrawing,
  onFocusScale,
  onFocusNode,
  onFocusObservation,
  onFocusSection,
  onFocusInspection,
  onFocusAnnotation,
  onFocusBoundaryLine,
  onFocusPlan,
  onUnfocus,
  readOnly,
  ...rest
}: PropsWithChildren<ReportMapProviderProps>) => {
  const hasUpdateProjectPermission = useHasPermission('projects:update');

  const focusedNodeUid =
    focusedElementType === ReportElementType.NODE
      ? focusedElementUid
      : undefined;

  const focusedMeasurementUid =
    focusedElementType === ReportElementType.MEASUREMENT
      ? focusedElementUid
      : undefined;

  const focusedDrawingUid =
    focusedElementType === ReportElementType.DRAWING
      ? focusedElementUid
      : undefined;

  const focusedObservationUid =
    focusedElementType === ReportElementType.OBSERVATION
      ? focusedElementUid
      : undefined;

  const focusedSectionUid =
    focusedElementType === ReportElementType.SECTION
      ? focusedElementUid
      : undefined;

  const focusedInspectionUid =
    focusedElementType === ReportElementType.INSPECTION
      ? focusedElementUid
      : undefined;

  const focusedPlanUid =
    focusedElementType === ReportElementType.PLAN
      ? focusedElementUid
      : undefined;

  const focusedAnnotationUid =
    focusedElementType === ReportElementType.ANNOTATION
      ? focusedElementUid
      : undefined;

  const focusedBoundaryLineUid =
    focusedElementType === ReportElementType.BOUNDARY_LINE
      ? focusedElementUid
      : undefined;

  const isFocused = !!focusedElementUid;

  const [activeSidebar, setActiveSidebar] = useState<ReportMapSidebar>(
    isFocused ? 'info' : 'job'
  );

  const [activeSidebarCustomer, setActiveSidebarCustomer] =
    useState<ReportMapSidebarCustomer>(isFocused ? 'info' : 'home');

  const [isEditingSectionShape, setIsEditingSectionShape] =
    React.useState(false);

  const hasFeature = (feature: keyof ReportMapFeatureOpts) => {
    return (
      hasUpdateProjectPermission &&
      !readOnly &&
      (!featureOpts || !!featureOpts?.[feature])
    );
  };

  const handleSetActiveSidebar = (sidebar: ReportMapSidebar) => {
    setActiveSidebar(sidebar);

    if (sidebar !== 'info' && isFocused) {
      unfocus();
    }
  };

  const handleSetActiveSidebarCustomer = (
    sidebar: ReportMapSidebarCustomer
  ) => {
    setActiveSidebarCustomer(sidebar);

    if (sidebar !== 'info' && isFocused) {
      unfocus();
    }
  };

  const focusNode = (uid: string) => {
    setActiveSidebar('info');
    setActiveSidebarCustomer('info');
    onFocusNode?.(uid);
  };

  const focusScale = () => {
    setActiveSidebar('info');
    setActiveSidebarCustomer('info');
    onFocusScale?.();
  };

  const focusMeasurement = (uid: string) => {
    setActiveSidebar('info');
    setActiveSidebarCustomer('info');
    onFocusMeasurement?.(uid);
  };

  const focusDrawing = (uid: string) => {
    setActiveSidebar('info');
    setActiveSidebarCustomer('info');
    onFocusDrawing?.(uid);
  };

  const focusObservation = (uid: string) => {
    setActiveSidebar('info');
    setActiveSidebarCustomer('info');
    onFocusObservation?.(uid);
  };

  const focusSection = (uid: string) => {
    setActiveSidebar('info');
    setActiveSidebarCustomer('info');
    onFocusSection?.(uid);
  };

  const focusInspection = (uid: string) => {
    setActiveSidebar('info');
    setActiveSidebarCustomer('info');
    onFocusInspection?.(uid);
  };

  const focusPlan = (uid: string) => {
    setActiveSidebar('info');
    setActiveSidebarCustomer('info');
    onFocusPlan?.(uid);
  };

  const focusAnnotation = (uid: string) => {
    setActiveSidebar('info');
    setActiveSidebarCustomer('info');
    onFocusAnnotation?.(uid);
  };

  const focusBoundaryLine = (uid: string) => {
    setActiveSidebar('info');
    setActiveSidebarCustomer('info');
    onFocusBoundaryLine?.(uid);
  };

  const unfocus = () => {
    setIsEditingSectionShape(false);
    setActiveSidebar((activeSidebar) =>
      activeSidebar === 'info' ? 'job' : activeSidebar
    );
    setActiveSidebarCustomer((activeSidebar) =>
      activeSidebar === 'info' ? 'home' : activeSidebar
    );
    onUnfocus?.();
  };

  const isNodeFocused = (uid: string) =>
    focusedNodeUid ? focusedNodeUid === uid : undefined;
  const isMeasurementFocused = (uid: string) =>
    focusedMeasurementUid ? focusedMeasurementUid === uid : undefined;
  const isDrawingFocused = (uid: string) =>
    focusedDrawingUid ? focusedDrawingUid === uid : undefined;
  const isScaleFocused = () => focusedElementType === ReportElementType.SCALE;
  const isObservationFocused = (uid: string) =>
    focusedObservationUid ? focusedObservationUid === uid : undefined;
  const isSectionFocused = (uid: string) =>
    focusedSectionUid ? focusedSectionUid === uid : undefined;
  const isInspectionFocused = (uid: string) =>
    focusedInspectionUid ? focusedInspectionUid === uid : undefined;
  const isPlanFocused = (uid: string) =>
    focusedPlanUid ? focusedPlanUid === uid : undefined;
  const isAnnotationFocused = (uid: string) =>
    focusedAnnotationUid ? focusedAnnotationUid === uid : undefined;
  const isBoundaryLineFocused = (uid: string) =>
    focusedBoundaryLineUid ? focusedBoundaryLineUid === uid : undefined;

  const toggleFocusNode = (uid: string) =>
    isNodeFocused(uid) ? unfocus() : focusNode(uid);

  const toggleFocusMeasurement = (uid: string) =>
    isMeasurementFocused(uid) ? unfocus() : focusMeasurement(uid);

  const toggleFocusDrawing = (uid: string) =>
    isDrawingFocused(uid) ? unfocus() : focusDrawing(uid);

  const toggleFocusScale = () => (isScaleFocused() ? unfocus() : focusScale());

  const toggleFocusObservation = (uid: string) =>
    isObservationFocused(uid) ? unfocus() : focusObservation(uid);

  const toggleFocusSection = (uid: string) =>
    isSectionFocused(uid) ? unfocus() : focusSection(uid);

  const toggleEditSectionShape = () =>
    setIsEditingSectionShape(!isEditingSectionShape);

  const toggleFocusInspection = (uid: string) =>
    isInspectionFocused(uid) ? unfocus() : focusInspection(uid);

  const toggleFocusPlan = (uid: string) =>
    isPlanFocused(uid) ? unfocus() : focusPlan(uid);

  const toggleFocusAnnotation = (uid: string) =>
    isAnnotationFocused(uid) ? unfocus() : focusAnnotation(uid);

  const toggleFocusBoundaryLine = (uid: string) =>
    isBoundaryLineFocused(uid) ? unfocus() : focusBoundaryLine(uid);

  useEffect(() => {
    if (!isFocused && activeSidebar === 'info') {
      setActiveSidebar('job');
    } else if (isFocused && activeSidebar !== 'info') {
      setActiveSidebar('info');
    }
  }, [activeSidebar, isFocused]);

  useEffect(() => {
    if (!isFocused && activeSidebarCustomer === 'info') {
      setActiveSidebarCustomer('home');
    } else if (isFocused && activeSidebarCustomer !== 'info') {
      setActiveSidebarCustomer('info');
    }
  }, [activeSidebarCustomer, isFocused]);

  const value = {
    activeSidebar,
    activeSidebarCustomer,
    focusedElementType,
    focusedElementUid,
    focusedNodeUid,
    focusedMeasurementUid,
    focusedDrawingUid,
    focusedObservationUid,
    focusedSectionUid,
    focusedInspectionUid,
    focusedPlanUid,
    focusedAnnotationUid,
    focusedBoundaryLineUid,
    hasFeature,
    focusNode,
    focusMeasurement,
    focusDrawing,
    focusScale,
    focusObservation,
    focusSection,
    focusInspection,
    focusPlan,
    focusAnnotation,
    focusBoundaryLine,
    isFocused,
    isNodeFocused,
    isObservationFocused,
    isSectionFocused,
    isInspectionFocused,
    isBoundaryLineFocused,
    isAnnotationFocused,
    setActiveSidebar: handleSetActiveSidebar,
    setActiveSidebarCustomer: handleSetActiveSidebarCustomer,
    toggleFocusNode,
    toggleFocusMeasurement,
    toggleFocusDrawing,
    toggleFocusScale,
    toggleFocusObservation,
    toggleFocusSection,
    toggleFocusInspection,
    toggleFocusPlan,
    toggleFocusAnnotation,
    toggleFocusBoundaryLine,
    unfocus,
    isEditingSectionShape,
    toggleEditSectionShape,
  };

  return (
    <>
      <ReportMapContext.Provider {...rest} value={value} />
    </>
  );
};

export default ReportMapProvider;
