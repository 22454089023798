"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInspection = exports.getInspectionValidations = void 0;
const types_1 = require("@drainify/types");
const yup_1 = require("yup");
/**
 *
 */
const getInspectionValidations = (reportEditor, inspectionUid) => {
    const inspectionNames = reportEditor.report.inspections
        .filter(({ uid }) => uid !== inspectionUid)
        .map((inspection) => reportEditor.getInspectionName(inspection));
    return (0, yup_1.object)().shape({
        name: (0, yup_1.string)()
            .unique(inspectionNames, 'Name already exists')
            .test('Length', 'Max character length is 50', (e) => !e || e.length <= 50),
        notes: (0, yup_1.string)().max(1000),
        sectionUid: (0, yup_1.string)().required(),
        inspectionDirection: (0, yup_1.string)()
            .oneOf(Object.values(types_1.SectionFlowCode))
            .required(),
        attributes: (0, yup_1.object)().shape({
            weather: (0, yup_1.mixed)().oneOf(Object.values(types_1.Weather)),
            temperature: (0, yup_1.mixed)().oneOf(Object.values(types_1.Temperature)),
            preCleaned: (0, yup_1.mixed)().oneOf(Object.values(types_1.PreCleaned)),
            videoImageStorageMedia: (0, yup_1.mixed)().oneOf(Object.values(types_1.VideoImageStorageMedia)),
            videoImageFormat: (0, yup_1.mixed)().oneOf(Object.values(types_1.VideoImageFormat)),
            photographImageStorageFormat: (0, yup_1.mixed)().oneOf(Object.values(types_1.PhotographImageStorageFormat)),
            methodOfInspection: (0, yup_1.mixed)().oneOf(Object.values(types_1.MethodOfInspection)),
            critcatalityGrade: (0, yup_1.mixed)().oneOf(Object.values(types_1.CritcatalityGrade)),
            flowControlMeasures: (0, yup_1.mixed)().oneOf(Object.values(types_1.FlowControlMeasures)),
            purposeOfInspection: (0, yup_1.mixed)().oneOf(Object.values(types_1.PurposeOfInspection)),
            videoImageLocationSystem: (0, yup_1.string)().test('Length', 'Max character length is 25', (e) => !e || e.length <= 25),
            videoImageFileName: (0, yup_1.string)().test('Length', 'Max character length is 200', (e) => !e || e.length <= 200),
            videoVolumeRef: (0, yup_1.string)().test('Length', 'Max character length is 25', (e) => !e || e.length <= 25),
            longitudinalLocationOfStartPoint: (0, yup_1.string)().test('Length', 'Max character length is 13', (e) => !e || e.length <= 13),
            circumferentialLocationOfStartPoint: (0, yup_1.string)().test('Length', 'Max character length is 13', (e) => !e || e.length <= 13),
            time: (0, yup_1.string)().test('Length', 'Max character length is 20', (e) => !e || e.length <= 20),
            date: (0, yup_1.string)().test('Length', 'Max character length is 20', (e) => !e || e.length <= 20),
        }),
    });
};
exports.getInspectionValidations = getInspectionValidations;
/**
 *
 */
const isInspection = (i) => !!i && 'uid' in i;
exports.isInspection = isInspection;
