import { Plan } from '@drainify/types';
import { getMidPointBetweenPoints } from '@drainify/utils';
import distance from '@turf/distance';
import transformRotate from '@turf/transform-rotate';
import transformScale from '@turf/transform-scale';
import { Box, Icons, Label, Text } from 'preshape';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import MapLabel from '../../../Map/MapMarker/MapLabel';
import MapMarker from '../../../Map/MapMarker/MapMarker';
import MapPlanMarker from '../../../Map/MapMarker/MapPlanMarker';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';

type Props = {
  plan: Plan;
};

const ReportMapPlan = ({ plan }: PropsWithChildren<Props>) => {
  const { focusPlan, focusedPlanUid } = useReportMapContext();

  const { reportEditor } = useReportEditorContext();
  const [cachedDimensions, setCachedDimensions] = useState<GeoJSON.Polygon>();
  const [cachedRotation, setCachedRotation] = useState<number>(0);

  React.useEffect(() => {
    if (cachedDimensions?.coordinates) {
      reportEditor.updateBoundsNoAsync({
        type: 'Polygon',
        coordinates: [
          [
            cachedDimensions.coordinates[0][0]!,
            cachedDimensions.coordinates[0][3]!,
            cachedDimensions.coordinates[0][1]!,
            cachedDimensions.coordinates[0][2]!,
          ],
        ],
      });
    }
  }, [cachedDimensions]);

  useEffect(() => {
    setCachedDimensions(plan.point);
    if (google.maps.geometry && plan.point) {
      setCachedRotation(
        google.maps.geometry.spherical.computeHeading(
          new google.maps.LatLng(
            plan.point.coordinates[0][0][1],
            plan.point.coordinates[0][0][0]
          ),
          new google.maps.LatLng(
            plan.point.coordinates[0][2][1],
            plan.point.coordinates[0][2][0]
          )
        )
      );
    }
  }, [plan.point]);

  useEffect(() => {
    if (cachedDimensions?.coordinates && google.maps.geometry) {
      setCachedRotation(
        google.maps.geometry.spherical.computeHeading(
          new google.maps.LatLng(
            cachedDimensions.coordinates[0][0][1],
            cachedDimensions.coordinates[0][0][0]
          ),
          new google.maps.LatLng(
            cachedDimensions.coordinates[0][2][1],
            cachedDimensions.coordinates[0][2][0]
          )
        )
      );
    }
  }, [cachedDimensions, google.maps.geometry]);

  if (!plan.point || !cachedDimensions) {
    return null;
  }

  const setRotation = (point: GeoJSON.Point) => {
    const midpoint = getMidPointBetweenPoints(
      {
        type: 'Point',
        coordinates: cachedDimensions.coordinates[0][0],
      },
      {
        type: 'Point',
        coordinates: cachedDimensions.coordinates[0][1],
      }
    )!;

    const heading = google.maps.geometry.spherical.computeHeading(
      new google.maps.LatLng(point.coordinates[1], point.coordinates[0]),
      new google.maps.LatLng(midpoint.coordinates[1], midpoint.coordinates[0])
    );

    const headingD = google.maps.geometry.spherical.computeHeading(
      new google.maps.LatLng(
        cachedDimensions?.coordinates[0][2][1],
        cachedDimensions?.coordinates[0][2][0]
      ),
      new google.maps.LatLng(midpoint?.coordinates[1], midpoint?.coordinates[0])
    );
    const rotationMoved = heading - headingD;
    setCachedDimensions(
      transformRotate(cachedDimensions, rotationMoved, {
        pivot: getMidPointBetweenPoints(
          {
            type: 'Point',
            coordinates: cachedDimensions?.coordinates[0][0],
          },
          {
            type: 'Point',
            coordinates: cachedDimensions?.coordinates[0][1],
          }
        )!,
      })
    );
  };

  const handleDragNW = (point: GeoJSON.Point) => {
    const pointerDistFromMid = distance(
      { type: 'Point', coordinates: cachedDimensions?.coordinates[0][0] },
      getMidPointBetweenPoints(
        {
          type: 'Point',
          coordinates: cachedDimensions.coordinates[0][0],
        },
        {
          type: 'Point',
          coordinates: cachedDimensions.coordinates[0][1],
        }
      )!
    );
    const swDistFromMid = distance(
      point,
      getMidPointBetweenPoints(
        {
          type: 'Point',
          coordinates: cachedDimensions.coordinates[0][0],
        },
        {
          type: 'Point',
          coordinates: cachedDimensions.coordinates[0][1],
        }
      )!
    );
    const expand = pointerDistFromMid > swDistFromMid;
    setCachedDimensions(
      transformScale(cachedDimensions, expand ? 0.99 : 1.01, {
        origin: {
          type: 'Point',
          coordinates: cachedDimensions?.coordinates[0][1],
        },
      })
    );
  };

  const handleDragSE = (point: GeoJSON.Point) => {
    const pointerDistFromMid = distance(
      { type: 'Point', coordinates: cachedDimensions?.coordinates[0][1] },
      getMidPointBetweenPoints(
        {
          type: 'Point',
          coordinates: cachedDimensions.coordinates[0][0],
        },
        {
          type: 'Point',
          coordinates: cachedDimensions.coordinates[0][1],
        }
      )!
    );
    const swDistFromMid = distance(
      point,
      getMidPointBetweenPoints(
        {
          type: 'Point',
          coordinates: cachedDimensions.coordinates[0][0],
        },
        {
          type: 'Point',
          coordinates: cachedDimensions.coordinates[0][1],
        }
      )!
    );
    const expand = pointerDistFromMid > swDistFromMid;
    setCachedDimensions(
      transformScale(cachedDimensions, expand ? 0.99 : 1.01, {
        origin: {
          type: 'Point',
          coordinates: cachedDimensions?.coordinates[0][0],
        },
      })
    );
  };

  const handleDragSEEnd = () => {
    reportEditor.updatePlan({ point: cachedDimensions });
  };

  const handleDragNWEnd = () => {
    reportEditor.updatePlan({ point: cachedDimensions });
  };

  const handleTranslation = (point: GeoJSON.Point) => {
    const current = {
      type: 'Point',
      coordinates: [
        cachedDimensions.coordinates[0][3][0],
        cachedDimensions.coordinates[0][3][1],
      ],
    };
    const diffX = point.coordinates[0] - current?.coordinates[0];
    const diffY = point.coordinates[1] - current?.coordinates[1];
    setCachedDimensions({
      type: 'Polygon',
      coordinates: [
        [
          [
            cachedDimensions.coordinates[0][0][0] + diffX,
            cachedDimensions.coordinates[0][0][1] + diffY,
          ],
          [
            cachedDimensions.coordinates[0][1][0] + diffX,
            cachedDimensions.coordinates[0][1][1] + diffY,
          ],
          [
            cachedDimensions.coordinates[0][2][0] + diffX,
            cachedDimensions.coordinates[0][2][1] + diffY,
          ],

          point.coordinates,
        ],
      ],
    });
  };

  const handleTranslationEnd = (point: GeoJSON.Point) => {
    const current = {
      type: 'Point',
      coordinates: [
        cachedDimensions.coordinates[0][3][0],
        cachedDimensions.coordinates[0][3][1],
      ],
    };
    const diffX = point.coordinates[0] - current?.coordinates[0];
    const diffY = point.coordinates[1] - current?.coordinates[1];
    reportEditor.updatePlan({
      point: {
        type: 'Polygon',
        coordinates: [
          [
            [
              cachedDimensions.coordinates[0][0][0] + diffX,
              cachedDimensions.coordinates[0][0][1] + diffY,
            ],
            [
              cachedDimensions.coordinates[0][1][0] + diffX,
              cachedDimensions.coordinates[0][1][1] + diffY,
            ],
            [
              cachedDimensions.coordinates[0][2][0] + diffX,
              cachedDimensions.coordinates[0][2][1] + diffY,
            ],
            point.coordinates,
          ],
        ],
      },
    });
  };

  return (
    <>
      <MapLabel
        id={plan.uid}
        point={{
          type: 'Point',
          coordinates: cachedDimensions.coordinates[0][0],
        }}
        visible={focusedPlanUid !== plan.uid}
      >
        <Label
          size="x1"
          onClick={() => focusPlan(plan.uid)}
          backgroundColor="accent-shade-5"
        >
          <Text ellipsis>{plan.name}</Text>
        </Label>
      </MapLabel>

      <MapPlanMarker
        points={cachedDimensions}
        plan={plan}
        opacity={plan.opacity}
      >
        {/** rotation */}
        {focusedPlanUid === plan.uid && (
          <>
            <MapMarker
              point={{
                type: 'Point',
                coordinates: cachedDimensions.coordinates[0][2],
              }}
              onDrag={setRotation}
              onDragEnd={handleDragNWEnd}
            >
              <Icons.RotateCcw size="2rem" />
            </MapMarker>

            {/** skew top left */}
            <MapMarker
              point={{
                type: 'Point',
                coordinates: cachedDimensions.coordinates[0][0],
              }}
              onDrag={handleDragNW}
              onDragEnd={handleDragNWEnd}
            >
              <Box style={{ transform: `rotate(${cachedRotation - 90}deg)` }}>
                <Icons.ArrowUpLeft size="2rem" />
              </Box>
            </MapMarker>

            {/** translate */}
            <MapMarker
              point={{
                type: 'Point',
                coordinates: cachedDimensions.coordinates[0][3],
              }}
              onDrag={handleTranslation}
              onDragEnd={handleTranslationEnd}
            >
              <Box>
                <Icons.Move size="2rem" />
              </Box>
            </MapMarker>

            {/** skew bottom right */}
            <MapMarker
              point={{
                type: 'Point',
                coordinates: cachedDimensions.coordinates[0][1],
              }}
              onDrag={handleDragSE}
              onDragEnd={handleDragSEEnd}
            >
              <Box style={{ transform: `rotate(${cachedRotation - 90}deg)` }}>
                <Icons.ArrowDownRight size="2rem" />
              </Box>
            </MapMarker>
          </>
        )}
      </MapPlanMarker>
    </>
  );
};

export default ReportMapPlan;
