import { getSectionPoints } from '@drainify/utils';
import { Box, Text } from 'preshape';
import React from 'react';
import Scale from '../../../IconsMisc/Scale';
import { useMapContext } from '../../../Map/Map';
import MapNotification from '../../../Map/MapNotification/MapNotification';
import { useReportEditorContext } from '../../ReportEditorProvider';
import useDrawMeasurementScaleMap from '../ReportMapElements/useDrawMeasurementScaleMap';
import { useReportMapContext } from '../ReportMapProvider';

type Props = {
  toggled: boolean;
  activate: () => void;
  deactivate: () => void;
};

const ReportMapMeasureScale = ({ toggled, activate, deactivate }: Props) => {
  const {
    addPointerDownListener,
    enableInteractivity,
    disableInteractivity,
    addPointerMoveListener,
    addPointerUpListener,
  } = useMapContext();

  const { reportEditor } = useReportEditorContext();
  const { focusScale } = useReportMapContext();

  const [isDrawing, setIsDrawing] = React.useState(false);
  const [startPoint, setStartPoint] = React.useState<GeoJSON.Point>();
  const [endPoint, setEndPoint] = React.useState<GeoJSON.Point>();

  React.useEffect(() => {
    if (toggled) {
      return addPointerDownListener((point) => {
        setStartPoint(point);
        disableInteractivity();
        setIsDrawing(true);
      });
    }
  }, [toggled]);

  useDrawMeasurementScaleMap({
    pointA: startPoint,
    pointB: endPoint,
    visible: isDrawing,
  });

  React.useEffect(() => {
    if (toggled && isDrawing) {
      return addPointerMoveListener((point) => {
        setEndPoint(point);
      });
    }
  }, [toggled, isDrawing]);

  React.useEffect(() => {
    if (toggled && startPoint) {
      return addPointerUpListener((point) => {
        enableInteractivity();
        setIsDrawing(false);
        const estimatedDistance = getSectionPoints(
          startPoint,
          point
        ).distance?.toFixed(2);
        reportEditor.addScale({
          geoLength: Number(estimatedDistance),
          startPoint: startPoint,
          endPoint: point,
          userLength: 1,
        });
        setStartPoint(undefined);
        setEndPoint(undefined);
        deactivate();
        focusScale();
      });
    }
  }, [toggled, startPoint]);

  return (
    <>
      <Box
        flex="vertical"
        alignChildrenHorizontal="middle"
        gap="x1"
        borderSize="x1"
      >
        <Box
          name={'Name'}
          style={{
            cursor: 'pointer',
          }}
          padding="x3"
          backgroundColor={toggled ? 'accent-shade-5' : 'accent-shade-1'}
          onClick={() => (toggled ? deactivate() : activate())}
        >
          <Box flex="horizontal" gap="x2">
            <Scale
              size="1.25rem"
              fill={toggled ? 'white' : 'black'}
              stroke={toggled ? 'white' : 'black'}
            />
          </Box>
        </Box>
      </Box>
      {toggled && (
        <MapNotification color="negative-shade-4" typePosition="top-right">
          <Text size="x4">Drawing scale</Text>
          <Scale
            size="1.25rem"
            fill={toggled ? 'white' : 'black'}
            stroke={toggled ? 'white' : 'black'}
          />
        </MapNotification>
      )}
    </>
  );
};

export default ReportMapMeasureScale;
