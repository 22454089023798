import React, { PropsWithChildren } from 'react';
import MapContentArea from '../../Map/MapLayout/MapContentArea';
import GeometryStore from '../../Map/useGeometryStore/GeometryStore';
import ReportMapElements from './ReportMapElements/ReportMapElements';
import ReportMapProvider, { ReportMapProviderProps } from './ReportMapProvider';
import ReportMapJobInfo from './ReportMapSidebar/ReportMapJobInfo';
import ReportMapSidebar from './ReportMapSidebar/ReportMapSidebar';

type Props = ReportMapProviderProps & {
  withSidebar?: boolean;
};

const ReportMap = ({
  children,
  withSidebar,
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <>
      <ReportMapProvider {...rest}>
        {
          // <MapWhat3WordsGrid />
        }

        {withSidebar && (
          <>
            <MapContentArea
              grow
              height="100%"
              position="top-right"
              width="400px"
              flex="vertical"
              alignChildrenVertical="start"
            >
              <ReportMapSidebar />
              <ReportMapJobInfo />
            </MapContentArea>
          </>
        )}

        <GeometryStore>
          <ReportMapElements />

          {children}
        </GeometryStore>
      </ReportMapProvider>
    </>
  );
};

export default ReportMap;
