import { Drawing, DrawingPoint } from '@drainify/types';
import { Box, Button, Icons, Labels, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';
import MapNotification from '../../../Map/MapNotification/MapNotification';
import { useReportEditorContext } from '../../ReportEditorProvider';
import ReportMapDrawingEditOperationDivergeConverge from './ReportMapDrawingEditOperations/ReportMapDrawingEditOperationDivergeConverge';
import ReportMapDrawingEditOperationIntrustionExcrusion from './ReportMapDrawingEditOperations/ReportMapDrawingEditOperationIntrustionExcrusion';
import ReportMapDrawingEditOperationLineMove from './ReportMapDrawingEditOperations/ReportMapDrawingEditOperationLineMove';
import ReportMapDrawingEditOperationPoints from './ReportMapDrawingEditOperations/ReportMapDrawingEditOperationPoints';
import ReportMapDrawingEditOperationRotation from './ReportMapDrawingEditOperations/ReportMapDrawingEditOperationRotation';
import ReportMapDrawingEditOperationScale from './ReportMapDrawingEditOperations/ReportMapDrawingEditOperationScale';
import ReportMapDrawingEditOperationTranslation from './ReportMapDrawingEditOperations/ReportMapDrawingEditOperationTranslation';
import ReportMapDrawingShow from './ReportMapDrawingShow';

type Props = {
  drawing: Drawing;
};

type EditingFeature =
  | 'rotation'
  | 'translation'
  | 'points'
  | 'scale'
  | 'intrusionextrusion'
  | 'divergeconverge'
  | 'linemove';

const ReportMapDrawingEdit = ({ drawing }: PropsWithChildren<Props>) => {
  const { reportEditor } = useReportEditorContext();
  const [cachedDimensions, setCachedDimensions] = React.useState<
    DrawingPoint[]
  >(drawing.points);
  const [editingFeature, setEditingFeature] = React.useState<EditingFeature>();

  const handleCloseAdvanceOperation = () => {
    setCachedDimensions(reportEditor.getDrawingByUid(drawing.uid)?.points!);
    setEditingFeature(undefined);
  };

  React.useEffect(() => {
    setCachedDimensions(drawing.points);
  }, [drawing]);

  return (
    <>
      <>
        {editingFeature !== 'intrusionextrusion' &&
          editingFeature !== 'divergeconverge' &&
          editingFeature !== 'linemove' && (
            <MapNotification typePosition="top-right" color={'black'}>
              <Labels
                backgroundColor="dark-shade-1"
                borderRadius="10px"
                textColor="light-shade-3"
                padding="x3"
              >
                <Box flex="vertical" gap="x2">
                  <Box flex="horizontal">
                    {' '}
                    <Text strong> Transform </Text>
                  </Box>

                  <Box flex="horizontal" gap="x2">
                    <Button
                      textColor="light-shade-1"
                      active={editingFeature === 'scale'}
                      onClick={() => setEditingFeature('scale')}
                      size="x1"
                    >
                      <Icons.Maximize2 size="0.75rem" />
                    </Button>
                    <Text>scale</Text>
                  </Box>

                  <Box flex="horizontal" gap="x2">
                    <Button
                      textColor="light-shade-1"
                      active={editingFeature === 'translation'}
                      onClick={() => setEditingFeature('translation')}
                      size="x1"
                    >
                      <Icons.Move size="0.75rem" />
                    </Button>
                    <Text>translation</Text>
                  </Box>
                  <Box flex="horizontal" gap="x2">
                    <Button
                      textColor="light-shade-1"
                      active={editingFeature === 'rotation'}
                      onClick={() => setEditingFeature('rotation')}
                      size="x1"
                    >
                      <Icons.RotateCw size="0.75rem" />
                    </Button>
                    <Text>Rotation</Text>
                  </Box>

                  <Box flex="horizontal" gap="x2">
                    <Button
                      textColor="light-shade-1"
                      active={editingFeature === 'points'}
                      onClick={() => setEditingFeature('points')}
                      size="x1"
                    >
                      <Icons.Grid size="0.75rem" />
                    </Button>
                    <Text>Points</Text>
                  </Box>

                  <Box flex="horizontal" gap="x2">
                    <Button
                      textColor="light-shade-1"
                      onClick={() => setEditingFeature('intrusionextrusion')}
                      size="x1"
                    >
                      <Icons.Download size="0.75rem" />
                    </Button>
                    <Text>intrude/extrude</Text>
                  </Box>

                  <Box flex="horizontal" gap="x2">
                    <Button
                      textColor="light-shade-1"
                      onClick={() => setEditingFeature('divergeconverge')}
                      size="x1"
                    >
                      <Icons.Maximize size="0.75rem" />
                    </Button>
                    <Text>diverge/converge</Text>
                  </Box>

                  <Box flex="horizontal" gap="x2">
                    <Button
                      textColor="light-shade-1"
                      onClick={() => setEditingFeature('linemove')}
                      size="x1"
                    >
                      |
                    </Button>
                    <Text>Line move</Text>
                  </Box>
                </Box>
              </Labels>
            </MapNotification>
          )}

        {editingFeature === 'points' && (
          <ReportMapDrawingEditOperationPoints
            drawing={{ ...drawing, points: cachedDimensions }}
            updateDimensions={setCachedDimensions}
          />
        )}
        {editingFeature === 'intrusionextrusion' && (
          <ReportMapDrawingEditOperationIntrustionExcrusion
            drawing={{ ...drawing, points: cachedDimensions }}
            updateDimensions={setCachedDimensions}
            onCancel={handleCloseAdvanceOperation}
          />
        )}
        {editingFeature === 'divergeconverge' && (
          <ReportMapDrawingEditOperationDivergeConverge
            drawing={{ ...drawing, points: cachedDimensions }}
            updateDimensions={setCachedDimensions}
            onCancel={handleCloseAdvanceOperation}
          />
        )}
        {editingFeature === 'linemove' && (
          <ReportMapDrawingEditOperationLineMove
            drawing={{ ...drawing, points: cachedDimensions }}
            updateDimensions={setCachedDimensions}
            onCancel={handleCloseAdvanceOperation}
          />
        )}

        {editingFeature === 'rotation' && (
          <ReportMapDrawingEditOperationRotation
            drawing={{ ...drawing, points: cachedDimensions }}
            updateDimensions={setCachedDimensions}
          />
        )}

        {editingFeature === 'translation' && (
          <ReportMapDrawingEditOperationTranslation
            drawing={{ ...drawing, points: cachedDimensions }}
            updateDimensions={setCachedDimensions}
          />
        )}

        {editingFeature === 'scale' && (
          <ReportMapDrawingEditOperationScale
            drawing={{
              ...drawing,
              points: cachedDimensions,
            }}
            updateDimensions={setCachedDimensions}
          />
        )}
      </>

      <ReportMapDrawingShow
        drawing={{
          ...drawing,
          points: cachedDimensions,
        }}
      />
    </>
  );
};

export default ReportMapDrawingEdit;
