import { Drawing, DrawingPoint } from '@drainify/types';
import bearing from '@turf/bearing';
import centroid from '@turf/centroid';
import transformRotate from '@turf/transform-rotate';
import { Icons } from 'preshape';
import React from 'react';
import MapMarker from '../../../../Map/MapMarker/MapMarker';
import { useReportEditorContext } from '../../../ReportEditorProvider';

type Props = {
  drawing: Drawing;
  updateDimensions: (points: DrawingPoint[]) => void;
};

const ReportMapDrawingEditOperationRotation = ({
  drawing,
  updateDimensions,
}: Props) => {
  const { reportEditor } = useReportEditorContext();
  const polygon: GeoJSON.Feature<GeoJSON.Polygon> = {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [drawing.points.map((e) => e.point.coordinates)],
    },
    properties: {},
  };

  const performRotation = (point: GeoJSON.Point) => {
    const midpoint = centroid(polygon).geometry;

    const headingToPoint = bearing(point.coordinates, midpoint.coordinates);

    const referencePoint = drawing.points[0].point; // First point as a reference
    const headingToReference = bearing(
      referencePoint.coordinates,
      midpoint.coordinates
    );

    const rotationAngle = headingToPoint - headingToReference;

    const rotatedPolygon = transformRotate(polygon, rotationAngle, {
      pivot: midpoint.coordinates, // Pivot point for rotation
    });

    const newCache = drawing.points.map((pointObj, index) => {
      const newCoordinates = rotatedPolygon.geometry.coordinates[0][index];
      return {
        ...pointObj,
        point: {
          ...pointObj.point,
          coordinates: newCoordinates, // Update point with new coordinates
        },
      };
    });

    updateDimensions(newCache);

    return rotatedPolygon;
  };

  return (
    <MapMarker
      point={{
        type: 'Point',
        coordinates: drawing.points[0].point.coordinates,
      }}
      onDrag={performRotation}
      onDragEnd={() => {
        reportEditor.updateDrawing(drawing.uid, {
          points: drawing.points,
        });
      }}
    >
      <Icons.RotateCw size="2rem" />
    </MapMarker>
  );
};

export default ReportMapDrawingEditOperationRotation;
