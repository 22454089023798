import { ReportElementType } from '@drainify/utils';
import React from 'react';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useProjectContext } from '../../../components/Project/ProjectProvider';
import ReportMap from '../../../components/Report/ReportMap/ReportMap';

export const typeParmToReportElementMap: Record<string, ReportElementType> = {
  measurements: ReportElementType.MEASUREMENT,
  drawings: ReportElementType.DRAWING,
  nodes: ReportElementType.NODE,
  observations: ReportElementType.OBSERVATION,
  sections: ReportElementType.SECTION,
  inspections: ReportElementType.INSPECTION,
  plans: ReportElementType.PLAN,
  scale: ReportElementType.SCALE,
  annotation: ReportElementType.ANNOTATION,
  boundaryline: ReportElementType.BOUNDARY_LINE,
};

const ProjectPage = () => {
  const navigate = useNavigate();
  const { projectId, type, uid } = useParams();
  const searchParams = useSearchParams()[0];
  const { setActiveBookingId } = useProjectContext();
  const activeBooking = searchParams.get('activeBookingId');

  React.useEffect(() => {
    if (activeBooking) {
      setActiveBookingId(activeBooking);
    } else {
      setActiveBookingId(undefined);
    }
  }, [activeBooking]);
  const elementType = type ? typeParmToReportElementMap[type] : undefined;

  const params = activeBooking ? `?activeBookingId=${activeBooking}` : '';

  return (
    <ReportMap
      focusedElementType={elementType}
      focusedElementUid={uid}
      key={projectId}
      onFocusMeasurement={(uid) => navigate(`measurements/${uid}${params}`)}
      onFocusDrawing={(uid) => navigate(`drawings/${uid}${params}`)}
      onFocusNode={(uid) => navigate(`nodes/${uid}${params}`)}
      onFocusObservation={(uid) => navigate(`observations/${uid}${params}`)}
      onFocusSection={(uid) => navigate(`sections/${uid}${params}`)}
      onFocusScale={() => navigate(`scale/scale${params}`)}
      onFocusInspection={(uid) => navigate(`inspections/${uid}${params}`)}
      onFocusPlan={(uid) => navigate(`plans/${uid}${params}`)}
      onFocusAnnotation={(uid) => navigate(`annotation/${uid}${params}`)}
      onFocusBoundaryLine={(uid) => navigate(`boundaryline/${uid}${params}`)}
      onUnfocus={() => navigate(`./${params}`)}
      withSidebar
    >
      {/* TODO(hhogg): Turn this back on and fix later */}
      {/* {queryMe.data && project && (
        <MapMultiCursors actorId={queryMe.data.uid} partyId={project.uid} />
      )} */}

      <Outlet />
    </ReportMap>
  );
};

export default ProjectPage;
