import { ProjectType } from './Booking';
import { Repairs } from './Repairs';
import { SectionFlowCode, YesNo } from './Section';
import { TranscodedVideo } from './TranscodedVideo';

export interface InspectionPostBody {
  sectionUid: string;
  inspectionDirection?: SectionFlowCode;
  video?: TranscodedVideo;
  jobId: string;
  createdAt: string;
  attributes: InspectionAttributes;
  notes?: string;
  publicNotes?: string;
  repairs?: Repairs[];
  thumbnail?: string;
}

export type InspectionAttributes = {
  weather?: Weather;
  temperature?: Temperature;
  preCleaned?: PreCleaned;

  videoImageStorageMedia?: VideoImageStorageMedia;
  videoImageLocationSystem?: string;
  videoImageFormat?: VideoImageFormat;
  videoImageFileName?: string;
  videoVolumeRef?: string;

  photographImageStorageFormat?: PhotographImageStorageFormat;
  photographVolumeReference?: string;

  methodOfInspection?: MethodOfInspection;
  critcatalityGrade?: CritcatalityGrade;
  flowControlMeasures?: FlowControlMeasures;
  purposeOfInspection?: PurposeOfInspection;
  longitudinalLocationOfStartPoint?: string;
  circumferentialLocationOfStartPoint?: string;
  time?: string;
  date?: string;

  technologyCCTV?: YesNo;
  technologyLaser?: YesNo;
  technologySonar?: YesNo;
  technologySidewall?: YesNo;
  technologyZoom?: YesNo;
  technologyOther?: YesNo;
  inspectionStatus?: InspectionStatus;
  dateCleaned?: string;
  pressureValue?: string;

  workOrder?: string;
  mediaLabel?: string;
  consequenceOfFailure?: string;
  pipeSegmentReference?: string;
  locationDetails?: string;
  MHCoordinateSystem?: string;
  verticalDatum?: string;
  GPSAccuracy?: string;
  additonalInfo?: string;
};

export type Inspection = InspectionPostBody & {
  uid: string;
  index: number;
};

export enum Weather {
  D = 'D',
  R = 'R',
  S = 'S',
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  FOUR = 'FOUR',
  FIVE = 'FIVE',
}

export enum Temperature {
  A = 'A',
  B = 'B',
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  FOUR = 'FOUR',
  FIVE = 'FIVE',
}

export enum PreCleaned {
  Y = 'Y',
  N = 'N',
  Z = 'Z',
  H = 'H',
  L = 'L',
  R = 'R',
  X = 'X',
  Z_PACP = 'Z_PACP',
}

export enum MethodOfInspection {
  A = 'A',
  B = 'B',
  C = 'C',
}

export enum CritcatalityGrade {
  A = 'A',
  B = 'B',
  C = 'C',
  Z = 'Z',
}

export enum InspectionStatus {
  CI = 'CI',
  BM = 'BM',
  NA = 'NA',
  NE = 'NE',
  NF = 'NF',
  NI = 'NI',
  NO = 'NO',
  SD = 'SD',
}

export enum FlowControlMeasures {
  N = 'N',
  BL = 'BL',
  PB = 'PB',
  X = 'X',
  B = 'B',
  D = 'D',
  L = 'L',
  P = 'P',
}

export enum PurposeOfInspection {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  R = 'R',
  X = 'X',
}

export enum VideoImageFormat {
  F = 'F',
  MPEG1 = 'MPEG1',
  MPEG2 = 'MPEG2',
  MPEG4 = 'MPEG4',
  AVI = 'AVI',
  VOB = 'VOB',
  DIVX = 'DIVX',
  Z = 'Z',
}

export enum VideoImageStorageMedia {
  VHS = 'VHS',
  CD = 'CD',
  DVD = 'DVD',
  PHD = 'PHD',
  X = 'X',
}

export enum PhotographImageStorageFormat {
  A = 'A',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  X = 'X',
}

export const inspectionAttributeApplicable = (
  key: string,
  projectType?: ProjectType
) => {
  if (!projectType) return false;
  switch (key) {
    case 'attributes.weather':
    case 'attributes.preCleaned':
    case 'attributes.time':
    case 'attributes.date':
    case 'attributes.flowControlMeasures':
    case 'attributes.temperature':
    case 'attributes.purposeOfInspection':
      return [
        ProjectType.MSCC5_COMMERERCIAL,
        ProjectType.MSCC5_DOMESTIC,
        ProjectType.PACP,
        ProjectType.LACP,
      ].includes(projectType);

    // MSCC5 only
    case 'attributes.methodOfInspection':
    case 'attributes.critcatalityGrade':
    case 'attributes.videoImageStorageMedia':
    case 'attributes.videoImageLocationSystem':
    case 'attributes.videoImageFormat':
    case 'attributes.videoImageFileName':
    case 'attributes.videoVolumeRef':
    case 'attributes.photographImageStorageFormat':
    case 'attributes.longitudinalLocationOfStartPoint':
    case 'attributes.circumferentialLocationOfStartPoint':
      return [
        ProjectType.MSCC5_COMMERERCIAL,
        ProjectType.MSCC5_DOMESTIC,
      ].includes(projectType);

    case 'attributes.technologyCCTV':
    case 'attributes.technologyLaser':
    case 'attributes.technologySonar':
    case 'attributes.technologySidewall':
    case 'attributes.technologyZoom':
    case 'attributes.technologyOther':
    case 'attributes.inspectionStatus':
    case 'attributes.dateCleaned':
    case 'attributes.pressureValue':
    case 'attributes.workOrder':
    case 'attributes.mediaLabel':
    case 'attributes.consequenceOfFailure':
    case 'attributes.pipeSegmentReference':
    case 'attributes.locationDetails':
    case 'attributes.MHCoordinateSystem':
    case 'attributes.verticalDatum':
    case 'attributes.GPSAccuracy':
    case 'attributes.additonalInfo':
      return [ProjectType.PACP, ProjectType.LACP].includes(projectType);
  }
};

export enum InspectionDirection {
  UPSTREAM = 'UPSTREAM',
  DOWNSTREAM = 'DOWNSTREAM',
}
