import { Box, Button, Icons, Text } from 'preshape';
import React from 'react';
import JobRepairReviewWizard from '../../../Booking/JobRepairWizard';
import { useProjectContext } from '../../../Project/ProjectProvider';
import { useReportEditorContext } from '../../ReportEditorProvider';

const ReportMapSidebarVideoReccomendedRepairs = () => {
  const { reportEditor } = useReportEditorContext();
  const { activeBookingId } = useProjectContext();
  const [modalVisable, setModalVisable] = React.useState(false);
  if (!activeBookingId) {
    return null;
  }

  return (
    <>
      <Box flex="vertical" gap="x2" alignChildrenHorizontal="middle">
        <Button
          onClick={() => setModalVisable(true)}
          borderSize="x1"
          disabled={
            reportEditor.getJobInspections(activeBookingId).length === 0
          }
        >
          <Icons.Tool size="1.5rem" />
        </Button>
        <Text size="x2" strong>
          Repairs
        </Text>
      </Box>
      {modalVisable && (
        <JobRepairReviewWizard
          visible={modalVisable}
          onClose={() => setModalVisable(false)}
        />
      )}
    </>
  );
};

export default ReportMapSidebarVideoReccomendedRepairs;
