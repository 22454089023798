import { shouldBlackOut } from '@drainify/utils';
import { Box, Button, Icons, Text } from 'preshape';
import React, { useContext } from 'react';
import useBooking from '../../../../hooks/useBooking';
import { NO_DROP_PROPS } from '../../../../hooks/useDrag';
import BookingStatus from '../../../Booking/BookingStatus';
import { ServiceWorkerContext } from '../../../Bootstrap/ServiceWorkerProvider';
import { useMapContext } from '../../../Map/Map';
import MapNotification from '../../../Map/MapNotification/MapNotification';
import { useProjectContext } from '../../../Project/ProjectProvider';
import Spinner from '../../../Spinner/Spinner';
import SyncLabel from '../../../SyncLabel/SyncLabel';
import { useReportContext } from '../../ReportProvider';
import { useReportMapContext } from '../ReportMapProvider';

const ReportMapJobInfo = () => {
  const { updating, pendingChanges } = useContext(ServiceWorkerContext);

  const { activeBookingId, activeBooking } = useProjectContext();
  const { focusedElementType } = useReportMapContext();
  const { mapType, toggleMapType } = useMapContext();
  const { query: projectQuery } = useReportContext();
  const { query } = useBooking(activeBookingId);

  if (focusedElementType || query.isLoading) {
    return null;
  }

  return (
    <MapNotification
      color={'transparent'}
      typePosition="bottom-right"
      withColor={false}
    >
      <Box flex="vertical" grow>
        <Box
          {...NO_DROP_PROPS}
          height="40px"
          flex="horizontal"
          alignChildrenVertical="middle"
          alignChildrenHorizontal="around"
          grow
        >
          <SyncLabel />

          <>
            {activeBooking && (
              <Box flex="horizontal" gap="x6" borderRadius="10px" padding="x2">
                <Box
                  flex="vertical"
                  alignChildrenHorizontal="middle"
                  alignChildrenVertical="middle"
                  gap="x1"
                >
                  <Text strong size="x2">
                    {activeBooking!.jobId}
                  </Text>
                  <BookingStatus
                    padding="x0"
                    margin="x0"
                    booking={activeBooking}
                  />
                </Box>
              </Box>
            )}

            <Button
              variant="tertiary"
              size="x1"
              onClick={() => projectQuery.refetch()}
              disabled={updating || pendingChanges}
            >
              {projectQuery.isFetching ? <Spinner /> : <Icons.RefreshCcw />}
            </Button>
            {!shouldBlackOut(projectQuery.data) && (
              <Button
                borderSize="x0"
                onClick={() => {
                  toggleMapType();
                }}
              >
                {mapType === google.maps.MapTypeId.ROADMAP
                  ? 'Satellite'
                  : 'Map'}
              </Button>
            )}
          </>
        </Box>
        {mapType === google.maps.MapTypeId.HYBRID && (
          <Text strong size="x1">
            {' '}
            Satellite view should be used for reference only{' '}
          </Text>
        )}
      </Box>
    </MapNotification>
  );
};

export default ReportMapJobInfo;
