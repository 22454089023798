import { BoundaryLine } from '@drainify/types';
import { getMidPointBetweenPoints } from '@drainify/utils';
import bearing from '@turf/bearing';
import { NearestPointOnLine } from '@turf/nearest-point-on-line';
import { Box, Icons, Text } from 'preshape';
import React from 'react';
import MapLine from '../../../Map/MapMarker/MapLine';
import MapMarker from '../../../Map/MapMarker/MapMarker';
import MapMarkerToolbar from '../../../Map/MapMarker/MapMarkerToolbar';
import ReportMapMeasurementSegment from '../../../Measurement/ReportMapMeasurementSegment';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';

type Props = {
  boundaryLine: BoundaryLine;
};

const ReportMapBoundaryLine = ({ boundaryLine }: Props) => {
  const { focusedBoundaryLineUid, focusBoundaryLine, hasFeature } =
    useReportMapContext();
  const { reportEditor } = useReportEditorContext();
  const [cachedLine, setCachedLine] = React.useState<GeoJSON.Point[]>(
    boundaryLine.points
  );

  if (focusedBoundaryLineUid !== boundaryLine.uid) {
    let angle = bearing(cachedLine[0], cachedLine[1]);
    angle += 90;

    if (angle > 90 && angle < 270) {
      angle += 180;
    }

    return (
      <>
        <MapLine points={cachedLine} strokeColor="red" layer={9} />
        <MapMarker
          point={getMidPointBetweenPoints(cachedLine[0], cachedLine[1])}
          onClick={() => focusBoundaryLine(boundaryLine.uid)}
        >
          <Text
            strong
            size="x3"
            textColor="negative-shade-3"
            style={{ transform: `rotate(${angle}deg)` }}
          >
            BOUNDARY
          </Text>
        </MapMarker>
      </>
    );
  }

  function handleOnAdd(
    point: NearestPointOnLine['geometry'],
    endIndex: number
  ): void {
    const copy = [
      ...cachedLine.slice(0, endIndex),
      point,
      ...cachedLine.slice(endIndex),
    ];
    setCachedLine(copy);
  }

  function handleOnRemove(point: NearestPointOnLine['geometry']): void {
    setCachedLine(cachedLine.filter((e) => e !== point));
  }

  const handleDrag = (origin: GeoJSON.Point) => (point: GeoJSON.Point) => {
    const copy = [...cachedLine];
    copy[cachedLine.indexOf(origin)] = point;

    setCachedLine(copy);
  };

  const handleDragEnd = (origin: GeoJSON.Point) => (point: GeoJSON.Point) => {
    const copy = [...cachedLine];
    copy[cachedLine.indexOf(origin)] = point;

    reportEditor.updateBoundaryLine(boundaryLine.uid, {
      ...boundaryLine,
      points: copy,
    });
    setCachedLine(copy);
  };

  return (
    <>
      {focusedBoundaryLineUid === boundaryLine.uid &&
        cachedLine.map((e, i) => (
          <Box key={i}>
            {i < cachedLine.length - 1 && (
              <ReportMapMeasurementSegment
                key={boundaryLine.uid + '-' + i + '-segment'}
                points={[cachedLine[i], cachedLine[i + 1]]}
                onAdd={(p) => handleOnAdd(p, i + 1)}
                strokeColor="red"
              />
            )}
            <MapMarker
              key={boundaryLine.uid + '-' + i + '-marker'}
              point={e}
              onDrag={hasFeature('Elements:move') ? handleDrag(e) : undefined}
              onDragEnd={
                hasFeature('Elements:move') ? handleDragEnd(e) : undefined
              }
            >
              <MapMarkerToolbar
                enabled={true}
                tools={[
                  {
                    icon: <Icons.Trash2 />,
                    onClick: () => handleOnRemove(e),
                  },
                ]}
              >
                <Box backgroundColor="dark-shade-3" borderRadius="full">
                  <Icons.Circle size="0.5rem" />
                </Box>
              </MapMarkerToolbar>
            </MapMarker>
          </Box>
        ))}
    </>
  );
};

export default ReportMapBoundaryLine;
