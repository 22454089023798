import { Inspection, Node, Section } from '@drainify/types';
import { ReportElementType } from '@drainify/utils';
import { Appear, Box, Button, Icons, Label, Text, sizeX1Px } from 'preshape';
import React, {
  PointerEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isMobile } from '../../../../utils/client';
import { useMapContext } from '../../../Map/Map';
import { useMapLayersContext } from '../../../Map/MapLayers/MapLayers';
import MapLabel from '../../../Map/MapMarker/MapLabel';
import MapMarker from '../../../Map/MapMarker/MapMarker';
import MapMarkerToolbar from '../../../Map/MapMarker/MapMarkerToolbar';
import { useGeometryStoreContext } from '../../../Map/useGeometryStore/GeometryStore';
import { GeoStoreEntry } from '../../../Map/useGeometryStore/types';
import useGeometryStoreRegister from '../../../Map/useGeometryStore/useGeometryStoreRegister';
import NodeCodeIcon from '../../../Node/NodeCodeIcon';
import NodeView from '../../../Node/NodeView';
import { useProjectContext } from '../../../Project/ProjectProvider';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';
import ReportMapNodeRenderer from './ReportMapNodeRenderer';
import useDrawReportMapPipe from './useDrawReportMapPipe';

const NODE_LINKING_DISTANCE = 50;

type Props = {
  isHidden?: boolean;
  isLinkingEnd?: boolean;
  isLinkingStart?: boolean;
  onLink: () => void;
  onLinkNodeFound: (nodeUid: string | null) => void;
  onLinkStart: () => void;
  node: Node;
  showLabel: boolean;
};

const ReportMapNode = ({
  isHidden,
  isLinkingEnd,
  isLinkingStart,
  onLink,
  onLinkNodeFound,
  onLinkStart,
  node,
  showLabel,
}: PropsWithChildren<Props>) => {
  const {
    addPointerMoveListener,
    addPointerUpListener,
    disableInteractivity,
    enableInteractivity,
    isInteractive,
    getNearestPoint,
  } = useMapContext();
  const { isLayerVisible } = useMapLayersContext();
  const {
    toggleFocusNode,
    hasFeature,
    focusedElementUid,
    focusNode,
    focusedElementType,
  } = useReportMapContext();
  const { store } = useGeometryStoreContext();
  const { reportEditor } = useReportEditorContext();

  const [isConnectionHandleVisible, setIsConnectionHandleVisible] =
    useState(false);
  const [mouseOver, setIsMouseOver] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const isLinking = isLinkingStart || isLinkingEnd;
  const [locked, setLocked] = useState(true);
  const { activeBooking } = useProjectContext();

  const toggleLock = () => {
    setLocked(!locked);
  };

  const visible = isLayerVisible(ReportElementType.NODE, node.uid);

  const drawReportMapPipe = useDrawReportMapPipe({});

  const timeoutRef = React.useRef<number | NodeJS.Timeout | null>(null);

  const resetTimeout = () => {
    if (locked) return;
    handleClearTimeout();

    timeoutRef.current = setTimeout(() => {
      setLocked(true);
    }, 5000);
  };

  const otherNodes = useMemo(() => {
    const entries: GeoStoreEntry<GeoJSON.Point>[] = [];

    for (const key of store.groups.Point) {
      const entry = store.map[key];

      if (entry.opts?.type === 'ReportMapNode' && entry.opts.id !== node.uid) {
        entries.push(entry);
      }
    }

    const points = entries.map((entry) => entry.geometry);

    return { entries, points };
  }, [store]);

  const getNearestNode = useCallback(
    (point: GeoJSON.Point) => {
      const nearestPoint = getNearestPoint(
        point,
        otherNodes.points,
        NODE_LINKING_DISTANCE
      );

      if (nearestPoint) {
        return otherNodes.entries[otherNodes.points.indexOf(nearestPoint)];
      }

      return null;
    },
    [getNearestPoint, otherNodes]
  );

  const handleDragStart = () => {
    handleClearTimeout();
    setIsDragging(true);
  };

  const handleDragEnd = (point: GeoJSON.Point) => {
    setIsDragging(false);

    if (!isLinkingStart) {
      reportEditor.updateNode(node.uid, { point });
    }
  };

  const handleClearTimeout = () => {
    if (typeof timeoutRef.current === 'number') {
      clearTimeout(timeoutRef.current);
    } else {
      clearTimeout(timeoutRef.current as NodeJS.Timeout);
    }
  };

  const handlePointerEnter = () => {
    setIsMouseOver(true);
    if (isInteractive) {
      setIsConnectionHandleVisible(true);
    }
  };

  const handlePointerLeave = () => {
    setIsMouseOver(false);
    if (!isLinkingStart) {
      setIsConnectionHandleVisible(false);
    }
    if (!isDragging) {
      resetTimeout();
    }
  };

  const handleStartLinkNodes = (event: PointerEvent) => {
    event.stopPropagation();
    disableInteractivity();
    onLinkStart?.();
  };

  const [, ref] = useGeometryStoreRegister({
    geometry: node.point,
    opts: {
      anchor: 'center',
      id: node.uid,
      padding: sizeX1Px,
      type: 'ReportMapNode',
    },
  });

  useEffect(() => {
    if (isLinkingStart) {
      return addPointerMoveListener((point) => {
        if (!node.point) {
          return;
        }

        drawReportMapPipe?.({
          geometry: {
            type: 'LineString',
            coordinates: [node.point?.coordinates, point.coordinates],
          },
        });

        const nearestNode = getNearestNode(point);

        onLinkNodeFound?.(nearestNode?.opts?.id || null);

        if (nearestNode) {
          drawReportMapPipe?.({
            geometry: {
              type: 'LineString',
              coordinates: [
                node.point?.coordinates,
                nearestNode.geometry.coordinates,
              ],
            },
          });
        }
      });
    }
  }, [
    addPointerMoveListener,
    drawReportMapPipe,
    getNearestNode,
    isLinkingStart,
    node,
  ]);

  useEffect(() => {
    if (isLinkingStart) {
      return addPointerUpListener(() => {
        enableInteractivity();
        setIsConnectionHandleVisible(false);
        drawReportMapPipe({ geometry: null });
        onLink?.();
      });
    }
  }, [addPointerUpListener, enableInteractivity, isLinkingStart]);

  if (!node.point) {
    return null;
  }

  if (isMobile()) {
    return (
      <>
        <MapLabel
          id={node.uid}
          point={node.point}
          visible={
            !isDragging &&
            !isHidden &&
            !isConnectionHandleVisible &&
            !isLinking &&
            visible &&
            showLabel
          }
        >
          <Label size="x1">
            <Box flex="vertical">
              <Text ellipsis>{reportEditor.getNodeName(node)}</Text>
            </Box>
          </Label>
        </MapLabel>
        <MapMarker point={node.point}>
          <Box
            backgroundColor="black"
            borderRadius="full"
            borderColor="white"
            textColor="background-shade-1"
            padding="x1"
          />
        </MapMarker>
      </>
    );
  }

  function getLabel(
    nodeUid: string,
    section?: Section,
    inspection?: Inspection
  ) {
    if (
      !section ||
      !inspection ||
      !section.attributes.flow ||
      !inspection.inspectionDirection
    ) {
      return '';
    }
    if (section.attributes.flow === inspection.inspectionDirection) {
      return section.nodeStartUid === nodeUid ? 'Start' : 'Finish';
    } else {
      return section.nodeStartUid === nodeUid ? 'Finish' : 'Start';
    }
  }

  return (
    <>
      <MapLabel
        id={node.uid}
        point={node.point}
        onClick={() => focusNode(node.uid)}
        visible={
          !isDragging &&
          !isHidden &&
          !isConnectionHandleVisible &&
          !isLinking &&
          visible &&
          showLabel
        }
      >
        <Label size="x1">
          <Box flex="vertical">
            <Text ellipsis>{reportEditor.getNodeName(node)}</Text>
            {focusedElementType === ReportElementType.INSPECTION && (
              // TODO _ this needs to be subjective to the inspection.
              <Text>
                {getLabel(
                  node.uid,
                  reportEditor.getInspectionSection(focusedElementUid),
                  reportEditor.getInspectionByUid(focusedElementUid)
                )}
              </Text>
            )}
          </Box>
        </Label>
      </MapLabel>
      <MapMarker
        onDragEnd={
          hasFeature('Elements:move') && visible && !isHidden && !locked
            ? handleDragEnd
            : undefined
        }
        onDragStart={
          hasFeature('Elements:move') && visible && !isHidden && !locked
            ? handleDragStart
            : undefined
        }
        point={node.point}
      >
        <MapMarkerToolbar
          enabled={visible}
          information={
            <Box flex="vertical" gap="x3">
              <NodeView node={node} validate={false} />
              {node.remarks && (
                <Text wrap maxWidth="150px" size="x1">
                  {node.remarks.length > 30
                    ? node.remarks.substring(0, 30) + '...'
                    : node.remarks}
                </Text>
              )}
            </Box>
          }
          tools={[
            {
              icon:
                focusedElementUid === node.uid ? (
                  <></>
                ) : (
                  <Icons.Info size="2rem" />
                ),
              onClick: () => toggleFocusNode(node.uid),
            },
            ...(!!activeBooking?.completedAt
              ? []
              : [
                  {
                    icon: locked ? (
                      <Icons.Unlock size="2rem" />
                    ) : (
                      <Icons.Lock size="2rem" />
                    ),
                    onClick: toggleLock,
                  },
                ]),
          ]}
        >
          <Text
            container
            display="block"
            onPointerOver={
              hasFeature('Elements:edit') ? handlePointerEnter : undefined
            }
            onPointerLeave={
              hasFeature('Elements:edit') ? handlePointerLeave : undefined
            }
          >
            {/* Default black dot state */}
            <Appear
              animation="Pop"
              ref={ref}
              visible={visible && (isLinking || (!isDragging && !isHidden))}
              flex="horizontal"
              alignChildrenHorizontal="middle"
            >
              {mouseOver && locked ? (
                <Appear animation="Pop">
                  <Box
                    backgroundColor="black"
                    borderRadius="full"
                    borderColor="white"
                    textColor="background-shade-1"
                    padding="x1"
                  >
                    <Icons.Lock size="1.5rem" />
                  </Box>
                </Appear>
              ) : (
                <ReportMapNodeRenderer
                  locked={locked}
                  code={node.code}
                  shrink
                />
              )}
            </Appear>

            {/* Hidden state */}
            {isHidden && (
              <Box absolute="center">
                <Box
                  backgroundColor="text-shade-1"
                  borderRadius="full"
                  textColor="text-shade-1"
                ></Box>
              </Box>
            )}

            {/* Dragging cross hair */}
            <Box absolute="center">
              <Appear animation="Pop" visible={isDragging && !isLinking}>
                <Box
                  backgroundColor="accent-shade-1"
                  borderRadius="full"
                  borderSize="x2"
                  padding="x2"
                >
                  <Icons.Crosshair size="1.5rem" />
                </Box>
              </Appear>
            </Box>

            {/* Hover over start linking ring */}
            <Box absolute="center">
              <Appear
                animation="Pop"
                visible={
                  isConnectionHandleVisible &&
                  !isLinking &&
                  !isDragging &&
                  visible
                }
              >
                <Box
                  backgroundColor="accent-shade-1"
                  borderRadius="full"
                  borderSize="x2"
                  padding="x6"
                >
                  <Button
                    absolute="bottom"
                    borderRadius="full"
                    onPointerDown={handleStartLinkNodes}
                    paddingHorizontal="x1"
                    paddingVertical="x1"
                    style={{ transform: 'translate(-50%, 50%)' }}
                    variant="primary"
                  >
                    <Icons.Link2 size="1rem" />
                  </Button>
                </Box>
              </Appear>
            </Box>

            {/* Node icon while in the process of linking */}
            <Box absolute="center">
              <Appear animation="Pop" visible={isLinking}>
                <Box
                  backgroundColor="accent-shade-3"
                  borderColor="text-shade-1"
                  borderRadius="full"
                  borderSize="x2"
                  padding="x4"
                  textColor="white"
                >
                  <NodeCodeIcon code={node.code} />
                </Box>
              </Appear>
            </Box>
          </Text>
        </MapMarkerToolbar>
      </MapMarker>
    </>
  );
};

export default ReportMapNode;
