import { Booking } from '@drainify/types';
import React from 'react';
import useJobDocuments from '../../hooks/useJobDocuments';
import DataTable from '../DataTable/DataTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import DataTableMessage from '../DataTable/DataTableMessage';
import { useProjectContext } from '../Project/ProjectProvider';
import Spinner from '../Spinner/Spinner';
import DocumentButton from './DocumentButton';

type Props = {
  job: Booking;
};

const DocumentList = ({ job }: Props) => {
  const { list } = useJobDocuments(job.uid);
  const { activeBookingId } = useProjectContext();

  if (list.isLoading) {
    return <Spinner />;
  }

  if (list.data?.data.length === 0) {
    return (
      <DataTable>
        <DataTableGroup>
          <DataTableItem>
            <DataTableMessage>
              There are no documents to show here
            </DataTableMessage>
          </DataTableItem>
        </DataTableGroup>
      </DataTable>
    );
  }

  return (
    <>
      {list.data?.data.map((e) => (
        <DocumentButton key={e.uid} document={e} jobId={activeBookingId} />
      ))}
    </>
  );
};

export default DocumentList;
