import { NodeCode, PENDING_IMAGE_UPLOAD, PlanType } from '@drainify/types';
import { getFullFilePath } from '@drainify/utils';
import {
  Box,
  FormProvider,
  Icons,
  Input,
  InputLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
  Text,
  useResizeObserver,
} from 'preshape';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import useNodeForm from '../../hooks/forms/useNodeForm';
import useFileUpload from '../../hooks/useFileUpload';
import { isDesktop } from '../../utils/client';
import DistanceInput from '../DistanceInput/DistanceInput';
import FileUpload from '../FileUpload/FileUpload';
import resizeImage from '../Image/ImageCompressor';
import ImageFromFile from '../Image/ImageFromFile';
import ImageFromUrl from '../Image/ImageFromUrl';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import RemarksInput from '../RemarksInput/RemarksInput';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useReportContext } from '../Report/ReportProvider';
import SearchProvider from '../Search/SearchProvider';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardSearch from '../Wizard/WizardSearch';
import WizardStep from '../Wizard/WizardStep';
import WizardStepError from '../Wizard/WizardStepError';
import NodeCodeList from './NodeCodeList';
import NodeLocationHack from './NodeLocationHack';
import NodeSummary from './NodeSummary';

type Props = ModalProps & {
  initialActiveStep?: string;
  nodeUid?: string;
  onClose: () => void;
  onCreate?: (nodeUid: string) => void;
};

const NodeModal = ({
  initialActiveStep,
  nodeUid,
  onClose,
  onCreate,
  visible,
  ...rest
}: Props) => {
  const { project } = useProjectContext();
  const { isError, isLoading, isSuccess } = useReportContext();
  const { reportEditor } = useReportEditorContext();
  const node = reportEditor.getNodeByUid(nodeUid);
  const [sizeImageArea, refImageArea] = useResizeObserver<HTMLDivElement>();
  const hasUpdateProjectPermission = useHasPermission('projects:update');

  const form = useNodeForm(node);
  const defaultName = reportEditor.getNodeName(reportEditor.toNode(form.state));
  const [fileToUpload, setFileToUpload] = useState<File>();
  const nodeFileUpload = useFileUpload();
  const isMobile = !isDesktop();

  const handleSelectFile = async (file: File) => {
    const compressedBlob = (await resizeImage({ file, maxSize: 500 })) as Blob;
    const compressedImage = new File([compressedBlob], 'node-snapshot.jpg', {
      type: compressedBlob.type,
    });
    setFileToUpload(compressedImage);
    form.setDirty('imageUrl');
  };

  const handleRemove = async () => {
    if (nodeUid) {
      reportEditor.removeNode(nodeUid);
    }

    onClose();
  };

  const handleSave = async () => {
    let savedNodeUid = nodeUid;

    if (nodeUid) {
      await reportEditor.updateNode(nodeUid, {
        ...form.state,
        imageUrl: fileToUpload ? PENDING_IMAGE_UPLOAD : form.state.imageUrl,
      });
    } else {
      const { uid } = reportEditor.addNodeNoUpdate({
        ...form.state,
        name: form.state.name ? form.state.name : defaultName,
        imageUrl: fileToUpload ? PENDING_IMAGE_UPLOAD : form.state.imageUrl,
      });
      savedNodeUid = uid;
      await reportEditor.updateAsync?.(reportEditor.report);
      onCreate?.(savedNodeUid);
    }

    if (fileToUpload) {
      nodeFileUpload.upload(
        generatePath('/projects/:projectId/report/nodes/:nodeId/image', {
          projectId: project?.uid,
          nodeId: savedNodeUid,
        }),
        fileToUpload
      );
    }

    onClose();
  };

  const handleSetName = (name: string) => {
    form.setState((node) => ({ ...node, name }));
  };

  const handleSelectCode = (code: NodeCode) => {
    form.setState((node) => ({
      ...node,
      code,
    }));
  };

  const handleSetPoint = (point: GeoJSON.Point) => {
    form.setState((node) => ({
      ...node,
      point,
    }));
  };

  const handleSetDepth = (depth?: number) => {
    form.setState((node) => ({
      ...node,
      depth,
    }));
  };

  const handleSetRemarks = (remarks?: string) => {
    form.setState((node) => ({
      ...node,
      remarks,
    }));
  };

  useEffect(() => {
    if (!visible) {
      form.reset();
      setFileToUpload(undefined);
    }
  }, [visible]);

  return (
    <SearchProvider>
      <FormProvider form={form}>
        <Wizard
          flow={nodeUid ? 'update' : 'create'}
          initialActiveStepId={initialActiveStep}
          isError={isError || nodeFileUpload.isError}
          isLoading={isLoading || nodeFileUpload.isLoading}
          isSuccess={isSuccess && nodeFileUpload.isLoading}
          onSave={hasUpdateProjectPermission ? handleSave : undefined}
          onRemove={hasUpdateProjectPermission ? handleRemove : undefined}
          // onCancel={onClose}
          reset={visible}
        >
          <Modal
            {...rest}
            fullscreen={!isDesktop()}
            animation="FadeSlideUp"
            margin="x4"
            onClose={onClose}
            overlayBackgroundCloseOnClick={false}
            visible={visible}
          >
            <ModalHeader>
              <ModalTitle>{node ? defaultName : 'New Node'}</ModalTitle>
            </ModalHeader>

            <ModalBody flex="vertical">
              <WizardStep id="code" title="Type of node" withSearch>
                <NodeCodeList
                  onSelect={handleSelectCode}
                  value={form.state.code}
                />
              </WizardStep>

              <WizardStep id="name" title="Node name">
                <InputLabel>
                  <Input
                    size={isDesktop() ? 'x3' : 'x8'}
                    name="name"
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleSetName(event.target.value)
                    }
                    placeholder={defaultName}
                    value={form.state.name || ''}
                  />
                </InputLabel>
              </WizardStep>

              {isMobile && reportEditor.report.planType !== PlanType.NO_PLAN && (
                <WizardStep id="point">
                  <NodeLocationHack onChange={handleSetPoint} />
                </WizardStep>
              )}

              <WizardStep id="depth" title="Depth">
                <DistanceInput
                  type="long"
                  onChange={handleSetDepth}
                  value={form.state.depth}
                />
              </WizardStep>

              <WizardStep id="imageUrl" title="Image (optional)">
                <Box flex="vertical" gap="x8" grow>
                  <Box maxWidth="300px">
                    <Text align="middle">
                      You can capture a picture of the node using this device's
                      viewfinder or add it later from a video.
                    </Text>
                  </Box>

                  <Box grow minHeight="200px" ref={refImageArea}>
                    {((!form.state.imageUrl && !fileToUpload) ||
                      fileToUpload) && (
                      <ImageFromFile
                        file={fileToUpload}
                        height={sizeImageArea.height}
                      />
                    )}

                    {form.state.imageUrl && !fileToUpload && (
                      <ImageFromUrl
                        src={getFullFilePath(form.state.imageUrl)}
                        height={sizeImageArea.height}
                      />
                    )}
                  </Box>

                  <Box>
                    <FileUpload
                      accept="image/png, image/jpeg"
                      maxWidth="300px"
                      onChange={handleSelectFile}
                      value={fileToUpload}
                    >
                      <Icons.Camera />
                      <Text
                        style={{
                          WebkitUserSelect: 'none',
                          msUserSelect: 'none',
                          userSelect: 'none',
                          WebkitTouchCallout: 'none',
                          KhtmlUserSelect: 'none',
                          MozUserSelect: 'none',
                        }}
                      >
                        {isDesktop() ? 'Upload picture' : 'Take/Upload picture'}
                      </Text>
                    </FileUpload>
                  </Box>
                </Box>
              </WizardStep>

              <WizardStep id="remarks" title="Remarks">
                <RemarksInput
                  onChange={handleSetRemarks}
                  value={form.state.remarks || ''}
                />
              </WizardStep>

              <WizardReviewStep>
                <NodeSummary
                  imageFile={fileToUpload}
                  node={form.state}
                  withMap
                />
              </WizardReviewStep>
            </ModalBody>

            <ModalFooter>
              <WizardSearch />
              <WizardStepError />
              <WizardControls />
            </ModalFooter>
          </Modal>
        </Wizard>
      </FormProvider>
    </SearchProvider>
  );
};

export default NodeModal;
