import { Drawing } from '@drainify/types';
import { ReportElementType } from '@drainify/utils';
import centroid from '@turf/centroid';
import { Box, Label, Text } from 'preshape';
import React, { useMemo } from 'react';
import MapLabel from '../../../Map/MapMarker/MapLabel';
import MapMarkerToolbar from '../../../Map/MapMarker/MapMarkerToolbar';
import useDrawGeometry from '../../../Map/useDrawGeometry';
import { MapLayerPolygon } from '../../../Map/useMap';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { reportMapLayerOrder } from '../ReportMapElements/ReportMapElements';
import { useReportMapContext } from '../ReportMapProvider';
import ReportMapLineMeasurementMarker from '../ReportMapTools/ReportMapLineMeasurementMarker';

type Props = {
  drawing: Drawing;
};

const ReportMapDrawingShow = ({ drawing }: Props) => {
  const { focusDrawing } = useReportMapContext();
  const { reportEditor } = useReportEditorContext();
  useDrawGeometry<MapLayerPolygon>(
    useMemo(
      () => ({
        geometry: {
          type: 'Polygon',
          coordinates: [drawing.points.map((e) => e.point.coordinates)],
        },
        style: {
          fillColor: drawing.backgroundColor || '#5c7cfa',
          fillOpacity: drawing.opacity || 1,
          strokeWeight: 1,
          zIndex: reportMapLayerOrder[ReportElementType.DRAWING],
        },
      }),
      [drawing]
    )
  );

  const points = drawing.points.map((e) => e.point.coordinates);

  const polygon: GeoJSON.Feature<GeoJSON.Polygon> = {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [points],
    },
    properties: {},
  };

  return (
    <>
      {drawing.showlabel && (
        <MapLabel
          point={centroid(polygon).geometry}
          onClick={() => focusDrawing(drawing.uid)}
        >
          <MapMarkerToolbar>
            <Label
              alignChildren="middle"
              flex="vertical"
              size="x1"
              backgroundColor="light-shade-3"
              borderColor="dark-shade-1"
              borderStyle="dashed"
            >
              <Box
                textColor="dark-shade-1"
                flex="vertical"
                alignChildrenHorizontal="middle"
              >
                <Text>{drawing.name}</Text>
              </Box>
            </Label>
          </MapMarkerToolbar>
        </MapLabel>
      )}
      {reportEditor.report.gridMode?.isToScale &&
        drawing.showMeasurement &&
        drawing.points.map((e, i) => {
          if (i !== drawing.points.length - 1) {
            return (
              <ReportMapLineMeasurementMarker
                points={[e.point, drawing.points[i + 1].point]}
                key={e.uid}
              />
            );
          }
        })}
    </>
  );
};

export default ReportMapDrawingShow;
