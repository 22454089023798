export type DrawingPostBody = {
  points: DrawingPoint[];
  name?: string;
  jobId?: string;
  opacity?: number;
  backgroundColor?: string;
  showlabel?: boolean;
  showMeasurement?: boolean;
};

export type Drawing = DrawingPostBody & {
  uid: string;
  index: number;
};

export type DrawingPoint = {
  point: GeoJSON.Point;
  uid: string;
};
