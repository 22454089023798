import {
  Inspection,
  InspectionPostBody,
  SectionFlowCode,
} from '@drainify/types';
import { Box, Text } from 'preshape';
import React from 'react';

type Props = {
  inspection?: Inspection | InspectionPostBody;
};

const InspectionDirectionVisualisation = ({ inspection }: Props) => {
  return (
    <Box
      padding="x1"
      borderSize="x1"
      borderRadius="10px"
      textColor="text-shade-1"
    >
      <Text size="x1">
        {inspection?.inspectionDirection === undefined && 'Unknown'}
        {inspection?.inspectionDirection &&
          inspection?.inspectionDirection === SectionFlowCode.STE &&
          'Downstream'}
        {inspection?.inspectionDirection &&
          inspection?.inspectionDirection === SectionFlowCode.ETS &&
          'Upstream'}
      </Text>
    </Box>
  );
};

export default InspectionDirectionVisualisation;
