import { getRepairableInspectionIds } from '@drainify/utils';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'preshape';
import React from 'react';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import JobRepairSummary from './JobRepairSummary';
import JobRepairWizardStep from './JobRepairWizardStep';

type Props = {
  visible: boolean;
  onClose: () => void;
  inititalStep?: string;
};

const JobRepairReviewWizard = ({ visible, onClose, inititalStep }: Props) => {
  const { reportEditor } = useReportEditorContext();
  const { activeBookingId } = useProjectContext();

  if (!visible || !activeBookingId) {
    return null;
  }
  const inspections = getRepairableInspectionIds(reportEditor, activeBookingId);

  return (
    <Wizard
      flow={'create'}
      onSave={async () => onClose()}
      initialActiveStepId={inititalStep}
    >
      <Modal visible>
        <ModalHeader>Repair wizard</ModalHeader>
        <ModalBody>
          {inspections.map((e) => (
            <JobRepairWizardStep key={e} inspectionId={e} />
          ))}
          <WizardReviewStep>
            <JobRepairSummary />
          </WizardReviewStep>
        </ModalBody>
        <ModalFooter>
          <WizardControls />
        </ModalFooter>
      </Modal>
    </Wizard>
  );
};

export default JobRepairReviewWizard;
