import { Box, Icons } from 'preshape';
import React, { useRef } from 'react';
import { useMapContext } from '../../../Map/Map';
import MapDroppable from '../../../Map/MapDroppable/MapDroppable';
import useMapDragAndDrop from '../../../Map/useMapDragAndDrop';
import { useProjectContext } from '../../../Project/ProjectProvider';
import { useReportEditorContext } from '../../ReportEditorProvider';

const ReportMapToolAddAnnotation = () => {
  const { reportEditor } = useReportEditorContext();
  const { container } = useMapContext();
  const refContainer = useRef<HTMLDivElement>(null);
  const { activeBookingId } = useProjectContext();
  const { editAnnotation } = useReportEditorContext();

  const handleCreateAnnotation = async (point: GeoJSON.Point) => {
    const { uid } = reportEditor.addAnnotation({
      jobId: activeBookingId || '',
      point: point,
    });
    editAnnotation(uid, 'name');
  };

  const { onPointerDown } = useMapDragAndDrop({
    onDrop: handleCreateAnnotation,
    ref: refContainer,
    target: container,
  });

  return (
    <MapDroppable
      onPointerDown={onPointerDown}
      ref={refContainer}
      name={''}
      title={'Annotation'}
    >
      <Box borderRadius="full" backgroundColor="white">
        <Icons.Info size="1.75rem" />
      </Box>
    </MapDroppable>
  );
};

export default ReportMapToolAddAnnotation;
