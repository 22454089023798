import { Alert, Box, Text } from 'preshape';
import React from 'react';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import { useProjectContext } from '../../../Project/ProjectProvider';
import XMLExport from '../../../XMLExport/XMLExport';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';
import ReportMapSidebarBatchVideoUploadButton from './ReportMapSidebarBatchVideoUploadButton';
import ReportMapSidebarVideoReccomendedRepairs from './ReportMapSidebarVideoReccomendedRepairs';
import WincanXMLImport from './WincanXMLImport';

const ReportMapSidebarTools = () => {
  const { reportEditor } = useReportEditorContext();
  const { activeBooking } = useProjectContext();
  const { activeSidebar } = useReportMapContext();

  if (activeSidebar !== 'tools') {
    return null;
  }

  return (
    <MapSidebarContentItem active={activeSidebar === 'tools'} title={'Tools'}>
      <MapSidebarContentItemSection title="Report">
        <Box flex="horizontal" alignChildrenHorizontal="around">
          <ReportMapSidebarBatchVideoUploadButton />
          <ReportMapSidebarVideoReccomendedRepairs />
        </Box>
      </MapSidebarContentItemSection>
      <MapSidebarContentItemSection title="Import project">
        <Box>
          <WincanXMLImport />
          {reportEditor.report.nodes.length +
            reportEditor.report.sections.length !==
            0 && (
            <Box flex="horizontal" alignChildrenHorizontal="middle">
              <Alert
                color={'accent'}
                padding="x2"
                backgroundColor="accent-shade-2"
              >
                <Text size="x1" strong>
                  You cannot upload an XML to projects with items.
                </Text>
                <Text size="x1" align="middle">
                  This will cause overwriting/duplication
                </Text>
                <Text size="x1" align="middle">
                  Please use a new project if unavoidable
                </Text>
              </Alert>
            </Box>
          )}
        </Box>
      </MapSidebarContentItemSection>
      {activeBooking?.completedAt && (
        <MapSidebarContentItemSection title="Reports">
          <XMLExport />
        </MapSidebarContentItemSection>
      )}
    </MapSidebarContentItem>
  );
};

export default ReportMapSidebarTools;
