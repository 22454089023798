import { GeoJSONPointBlank } from '@drainify/utils';
import { Box, BoxProps } from 'preshape';
import React, { createContext, PropsWithChildren, useContext } from 'react';
import MapLayers from './MapLayers/MapLayers';
import useMap, { UseMapProps, UseMapResult } from './useMap';
import './Map.css';

export type MapTheme = 'dark' | 'light' | 'satellite';

export type MapPadding = {
  bottom: number;
  left: number;
  right: number;
  top: number;
};

export type MapContextProps = Omit<UseMapResult, 'ref'> & {};
export type MapProps = Omit<BoxProps, 'padding'> & Partial<UseMapProps>;

const contextFnNoOp: any = () => {};

const MapContext = createContext<MapContextProps>({
  bounds: null,
  container: null,
  isInteractive: false,
  isMoving: false,
  isReady: false,
  offset: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  addPadding: contextFnNoOp,
  addBoundsChangeListener: contextFnNoOp,
  addPointerClickListener: contextFnNoOp,
  addPointerDownListener: contextFnNoOp,
  addPointerUpListener: contextFnNoOp,
  addPointerMoveListener: contextFnNoOp,
  createMarker: contextFnNoOp,
  createPlan: contextFnNoOp,
  drawData: contextFnNoOp,
  drawGeometry: contextFnNoOp,
  disableInteractivity: contextFnNoOp,
  enableInteractivity: contextFnNoOp,
  fitToBounds: contextFnNoOp,
  fromScreenToGeoJSONPoint: contextFnNoOp,
  fromGeoJSONPointToScreen: contextFnNoOp,
  getBounds: contextFnNoOp,
  getNearestPoint: contextFnNoOp,
  getScreenDist: contextFnNoOp,
  getZoom: contextFnNoOp,
  getProjection: contextFnNoOp,
  zoomLevel: 0,
  mapCenter: GeoJSONPointBlank,
  toggleMapType: contextFnNoOp,
  mapType: '',
});

export const useMapContext = () => useContext(MapContext);

const defaultCenter: GeoJSON.Point = {
  type: 'Point',
  coordinates: [54.223141, -4.538818],
};

const Map = ({
  padding,
  center = defaultCenter,
  isInteractive,
  onClick,
  zoom = center === defaultCenter ? 6 : 19,
  ...rest
}: PropsWithChildren<MapProps>) => {
  const { ref, ...useMapResult } = useMap({
    center,
    isInteractive,
    onClick,
    padding,
    zoom,
  });

  const value: MapContextProps = {
    ...useMapResult,
  };

  return (
    <MapContext.Provider value={value}>
      <MapLayers>
        <Box
          container
          flex="vertical"
          id="bonediddle"
          grow
          overflow="hidden"
          textColor="text-shade-1"
          // theme={mapStyle.theme}
        >
          <Box
            basis="0"
            backgroundColor="background-shade-1"
            {...rest}
            grow
            overflow="hidden"
            ref={ref}
            style={{ font: 'unset' }}
          />
        </Box>
      </MapLayers>
    </MapContext.Provider>
  );
};

export default Map;
