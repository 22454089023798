import { Button, ButtonProps, Icons, Text } from 'preshape';
import React from 'react';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';

type Props = ButtonProps & {
  onCreate?: (drawingUid: string) => void;
};

const DrawingCreateButton = ({ onCreate, ...rest }: Props) => {
  const { createNewBoundaryLine } = useReportEditorContext();
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const { activeBooking } = useProjectContext();

  if (!hasUpdateProjectPermission) {
    return null;
  }

  return (
    <Button
      {...rest}
      color="accent"
      gap="x2"
      disabled={!!activeBooking?.completedAt}
      onClick={() => createNewBoundaryLine(onCreate)}
      type="button"
    >
      <Text>New boundary line</Text>
      <Icons.Plus size="1rem" />
    </Button>
  );
};

export default DrawingCreateButton;
