import { AccountState, Usage } from '@drainify/types';
import { formatFileSize } from '@drainify/utils';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { DateTime } from 'luxon';
import {
  Modal,
  ModalBody,
  Box,
  Button,
  Text,
  ModalHeader,
  Label,
  Link,
  Icons,
} from 'preshape';
import React from 'react';
import useSubscriptionsDetails from '../../hooks/useSubscriptionsDetails';
import { labelColor } from '../../utils/subscriptionTiers';
import AILogo from '../IconsMisc/AILogo';
import Droplet from '../IconsMisc/Droplet';
import LogoTier from '../Logo/LogoTier';
import UsageAddBuddyTokens from './UsageAddBuddyTokens';
import UsageAddCredits from './UsageAddCredits';
import UsageAddStorage from './UsageAddStorage';
import UsageChangePlan from './UsageChangePlan';
import UsageDetailsAddOn from './UsageDetailsAddOn';
import UsageDetailsCancel from './UsageDetailsCancel';
import UsageDetailsCancelled from './UsageDetailsCancelled';
import UsageDetailsInvoice from './UsageDetailsInvoice';
import UsageDetailsOverdue from './UsageDetailsOverdue';
import UsageDetailsPlanTotal from './UsageDetailsPlanTotal';
import { useUsageContext } from './UsageProvider';
import UsageUpdateCard from './UsageUpdateCard';

const UsageDetails = () => {
  const [planDetailsVisible, setPlanDetailsVisible] = React.useState(false);
  const stripePromise = React.useState(() =>
    loadStripe(process.env.STRIPE_PUBLISHABLE_KEY!)
  )[0];

  const { query: subscriptionQuery } = useSubscriptionsDetails();
  const { usage } = useUsageContext();

  const userSubscription = subscriptionQuery.data?.data.find(
    (e) => e.tier === usage?.tier
  );

  if (!usage) {
    return null;
  }

  if (usage?.state === AccountState.OVERDUE) {
    return <UsageDetailsOverdue usage={usage} />;
  }

  if (usage?.state === AccountState.CANCELLED) {
    return <UsageDetailsCancelled usage={usage} />;
  }

  return (
    <>
      <Button
        onClick={() => setPlanDetailsVisible(true)}
        color="accent"
        variant="primary"
        flex="vertical"
      >
        <Text size="x1" style={labelColor(userSubscription!)}>
          {usage.state === AccountState.TRIAL
            ? 'Trial'
            : userSubscription && userSubscription.displayName}
        </Text>

        <Box flex="horizontal" gap="x2">
          <Box flex="horizontal" alignChildrenVertical="middle">
            <Text size="x1">
              {(usage.planCredits || 0) + (usage.additionalCredits || 0)}
            </Text>
            <Droplet height={8} />
          </Box>

          <Box flex="horizontal" alignChildrenVertical="middle">
            <Text size="x1">
              {' '}
              {formatFileSize(usage.usedStorage, true, 0)}{' '}
            </Text>

            <Icons.Database size="0.5rem" fill="white" />
          </Box>
        </Box>
      </Button>

      <Modal
        visible={planDetailsVisible}
        onClose={() => setPlanDetailsVisible(false)}
        maxWidth="600px"
      >
        <ModalHeader>Account details</ModalHeader>
        <ModalBody>
          <Box flex="vertical" gap="x3">
            {usage.state === AccountState.TRIAL && (
              <Label>
                <Text strong>FREE TRIAL MODE</Text>
              </Label>
            )}
            <Elements stripe={stripePromise}>
              <Box
                flex="vertical"
                alignChildrenVertical="between"
                grow
                gap="x3"
              >
                <Box flex="vertical" alignChildrenHorizontal="middle">
                  <Box flex="horizontal" gap="x8">
                    <LogoTier tier={userSubscription} usage={usage} />
                    <Box flex="vertical" gap="x1">
                      <UsageDetailsPlanTotal
                        icon={<Droplet height={14} />}
                        value={usage.planCredits}
                      />
                      <UsageDetailsPlanTotal
                        icon={<Icons.Database />}
                        value={formatUsage(usage)}
                      />
                      <UsageDetailsPlanTotal
                        icon={<Icons.RefreshCcw />}
                        value={DateTime.fromSeconds(usage.resetDate).toFormat(
                          'dd/MM'
                        )}
                      />
                      <UsageChangePlan />
                    </Box>
                  </Box>
                </Box>

                <Text
                  textColor="accent-shade-5"
                  size="x2"
                  strong
                  align="middle"
                >
                  {' '}
                  Add-ons{' '}
                </Text>
                <Box flex="horizontal" wrap alignChildrenHorizontal="around">
                  <UsageDetailsAddOn
                    icon={<Droplet height={50} />}
                    value={usage.additionalCredits}
                    purchaseComponent={<UsageAddCredits />}
                    state={usage.state}
                  />
                  <UsageDetailsAddOn
                    icon={<Icons.Database size="3rem" />}
                    value={`${formatFileSize(
                      usage.additionalStorage,
                      true,
                      0
                    )}`}
                    purchaseComponent={<UsageAddStorage />}
                    state={usage.state}
                  />
                  <UsageDetailsAddOn
                    icon={<AILogo size="3rem" />}
                    value={Math.round(usage.buddyTokens)}
                    purchaseComponent={<UsageAddBuddyTokens />}
                    state={usage.state}
                  />
                  <UsageDetailsAddOn
                    icon={<Icons.CreditCard size="3rem" />}
                    purchaseComponent={
                      <Elements stripe={stripePromise}>
                        {' '}
                        <UsageUpdateCard />{' '}
                      </Elements>
                    }
                    state={usage.state}
                  />
                  <UsageDetailsAddOn
                    icon={<Icons.File size="3rem" />}
                    purchaseComponent={<UsageDetailsInvoice />}
                    state={usage.state}
                  />
                  <UsageDetailsAddOn
                    icon={
                      <Icons.XCircle textColor="negative-shade-5" size="3rem" />
                    }
                    purchaseComponent={<UsageDetailsCancel />}
                    state={usage.state}
                  />
                </Box>
              </Box>
              {usage && usage.state === AccountState.TRIAL && (
                <Text strong textColor="accent-shade-5" align="middle">
                  Subscribe to Drainify plan to unlock Elastic Storage & ad-hoc
                  credits!
                </Text>
              )}
            </Elements>
          </Box>
          {usage?.upcomingInvoiceUrl && (
            <Link href={usage?.upcomingInvoiceUrl} target="_blank">
              <Button variant="primary" color="accent">
                Click here to resolve
              </Button>
            </Link>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default UsageDetails;

const formatUsage = (usage: Usage) =>
  `${formatFileSize(usage.usedStorage, true, 0)}/${formatFileSize(
    usage.totalStorage,
    true,
    0
  )}`;
