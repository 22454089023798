import { Measurement } from '@drainify/types';
import { getMidPointBetweenPoints } from '@drainify/utils';
import { NearestPointOnLine } from '@turf/nearest-point-on-line';
import round from 'lodash.round';
import { Box, Icons, Label, Text } from 'preshape';
import React, { PropsWithChildren } from 'react';
import Distance from '../DistanceInput/Distance';
import RulerIcon from '../IconsMisc/Ruler';
import MapLabel from '../Map/MapMarker/MapLabel';
import MapLine from '../Map/MapMarker/MapLine';
import MapMarker from '../Map/MapMarker/MapMarker';
import MapMarkerToolbar from '../Map/MapMarker/MapMarkerToolbar';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import { useReportMapContext } from '../Report/ReportMap/ReportMapProvider';
import ReportMapMeasurementSegment from './ReportMapMeasurementSegment';

type Props = {
  measurement: Measurement;
  points: GeoJSON.Point[];
};

const ReportMapMeasurement = ({
  measurement,
  points,
}: PropsWithChildren<Props>) => {
  // const { isLayerVisible } = useMapLayersContext();
  const { reportEditor } = useReportEditorContext();
  const { focusMeasurement, focusedMeasurementUid, hasFeature } =
    useReportMapContext();
  const [cachedLine, setCachedLine] = React.useState<GeoJSON.Point[]>(
    measurement.points
  );

  // const visible = isLayerVisible(
  // ReportElementType.MEASUREMENT,
  // measurement.uid
  // );

  // useDrawMeasurementMap({
  // points: cachedLine,
  // visible: visible,
  // strokeColor: 'green',
  // });

  const handleDrag = (i: number) => (point: GeoJSON.Point) => {
    const copy = [...cachedLine];
    copy[i] = point;

    setCachedLine(copy);
    // reportEditor.updateMeasurement(measurement.uid, {
    // ...measurement,
    // points: copy,
    // });
  };

  const [length, setLength] = React.useState(
    reportEditor.getMeasurementLength(measurement.uid)
  );

  const handleDragEnd = (i: number) => (point: GeoJSON.Point) => {
    const copy = [...cachedLine];
    copy[i] = point;

    reportEditor.updateMeasurement(measurement.uid, {
      ...measurement,
      points: copy,
    });
  };

  React.useEffect(() => {
    setLength(
      reportEditor.deriveMeasurementLength(reportEditor.report, {
        ...measurement,
        points: cachedLine,
      })
    );
  }, [cachedLine, reportEditor.report.plan, reportEditor.report.gridMode]);

  const midPoint = React.useMemo(() => {
    if (cachedLine.length % 2 === 0) {
      return getMidPointBetweenPoints(
        cachedLine[cachedLine.length / 2 - 1],
        cachedLine[cachedLine.length / 2]
      );
    } else {
      return cachedLine[Math.floor(cachedLine.length / 2)];
    }
  }, [cachedLine]);

  const handleRemovePoint = (point: GeoJSON.Point) => {
    reportEditor.updateMeasurement(measurement.uid, {
      ...measurement,
      points: points.filter((e) => e !== point),
    });
  };

  function handleOnAdd(
    point: NearestPointOnLine['geometry'],
    endIndex: number
  ): void {
    const copy = [
      ...cachedLine.slice(0, endIndex),
      point,
      ...cachedLine.slice(endIndex),
    ];
    setCachedLine(copy);
  }

  return (
    <>
      <MapLabel
        point={midPoint}
        onClick={() => focusMeasurement(measurement.uid)}
      >
        <MapMarkerToolbar>
          <Label
            alignChildren="middle"
            flex="vertical"
            size="x1"
            backgroundColor="light-shade-3"
            borderColor="dark-shade-1"
            borderStyle="dashed"
          >
            <Box
              textColor="dark-shade-1"
              flex="horizontal"
              alignChildrenHorizontal="middle"
            >
              <RulerIcon size="0.75rem" />

              <Text weak size="x1">
                {length !== null && length !== undefined ? (
                  <Distance inline type="long" value={round(length, 2)} />
                ) : (
                  <Text>No scale set</Text>
                )}
              </Text>
            </Box>
          </Label>
        </MapMarkerToolbar>
      </MapLabel>
      {focusedMeasurementUid !== measurement.uid && (
        <MapLine points={cachedLine} strokeColor="green" layer={9} />
      )}
      {focusedMeasurementUid === measurement.uid &&
        cachedLine.map((e, i) => (
          <Box key={i}>
            {i < cachedLine.length - 1 && (
              <ReportMapMeasurementSegment
                key={measurement.uid + '-' + i + '-segment'}
                points={[cachedLine[i], cachedLine[i + 1]]}
                onAdd={(p) => handleOnAdd(p, i + 1)}
              />
            )}
            <MapMarker
              key={measurement.uid + '-' + i + '-marker'}
              point={e}
              onDragEnd={
                hasFeature('Elements:move') ? handleDragEnd(i) : undefined
              }
              onDrag={hasFeature('Elements:move') ? handleDrag(i) : undefined}
            >
              <MapMarkerToolbar
                enabled={true}
                tools={[
                  {
                    icon: <Icons.Trash2 />,
                    onClick: () => handleRemovePoint(e),
                  },
                ]}
              >
                <Box backgroundColor="dark-shade-3" borderRadius="full">
                  <Icons.Circle size="0.5rem" />
                </Box>
              </MapMarkerToolbar>
            </MapMarker>
          </Box>
        ))}
    </>
  );
};

export default ReportMapMeasurement;
