import React from 'react';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import MeasurementDeleteButton from '../../../Measurement/MeasurementDeleteButton';
import MeasurementSummary from '../../../Measurement/MeasurementSummary';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';

const ReportMapSidebarMeasurement = () => {
  const { reportEditor } = useReportEditorContext();
  const { activeSidebar, focusedMeasurementUid, unfocus, setActiveSidebar } =
    useReportMapContext();

  const measurement = reportEditor.getMeasurementByUid(focusedMeasurementUid);

  const handleBack = () => {
    unfocus();
    setActiveSidebar('plan');
  };

  return (
    <MapSidebarContentItem
      active={activeSidebar === 'info' && !!focusedMeasurementUid}
      onBack={handleBack}
      title={reportEditor.getMeasurementName(measurement)}
    >
      <MapSidebarContentItemSection>
        <MeasurementSummary measurement={measurement} />
      </MapSidebarContentItemSection>

      <MapSidebarContentItemSection>
        <MeasurementDeleteButton measurementUid={measurement?.uid} />
      </MapSidebarContentItemSection>
    </MapSidebarContentItem>
  );
};

export default ReportMapSidebarMeasurement;
