import bboxPolygon from '@turf/bbox-polygon';
import transformScale from '@turf/transform-scale';
import React from 'react';
import PlanLocationHack from '../../../components/Node/PlanLocationHack';
import { useReportEditorContext } from '../../../components/Report/ReportEditorProvider';
import Page from '../Layout/Page';
import PageHeader from '../Layout/PageHeader';

const SiteMapPage = () => {
  const { reportEditor } = useReportEditorContext();
  return (
    <Page>
      <PageHeader title="Site map" />
      <PlanLocationHack
        onUpdateBounds={(e) => {
          const shrinkCoords = transformScale(
            {
              type: 'Polygon',
              coordinates: bboxPolygon(e.bbox!).geometry.coordinates,
            },
            0.9
          );
          reportEditor.updateBounds({
            type: 'Polygon',
            coordinates: shrinkCoords.coordinates,
          });
        }}
        onChange={() => {}}
      />
    </Page>
  );
};

export default SiteMapPage;
