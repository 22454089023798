import { Box } from 'preshape';
import React from 'react';
import DataTable, { DataTableProps } from '../DataTable/DataTable';
import DataTableGroup from '../DataTable/DataTableGroup';
import DataTableItem from '../DataTable/DataTableItem';
import DataTableMessage from '../DataTable/DataTableMessage';
import { useReportContext } from '../Report/ReportProvider';
import SearchResult from '../Search/SearchResult';
import BoundaryLineView from './BoundaryLineView';

type Props = DataTableProps & {
  disabledUids?: string[];
  onItemClick?: (boundaryLineUid: string) => void;
  selectedUids?: string[];
};

const BoundaryLinesList = ({
  disabledUids,
  onItemClick,
  selectedUids,
  ...rest
}: Props) => {
  const { report } = useReportContext();

  if (!report) {
    return (
      <DataTable {...rest}>
        <DataTableGroup>
          {Array.from({ length: 5 }).map((_, index) => (
            <DataTableItem key={index}>
              <BoundaryLineView />
            </DataTableItem>
          ))}
        </DataTableGroup>
      </DataTable>
    );
  }

  if (!report.boundaryLines.length) {
    return (
      <DataTable {...rest}>
        <DataTableGroup>
          <DataTableItem>
            <DataTableMessage>
              There are no boundary lines to show here
            </DataTableMessage>
          </DataTableItem>
        </DataTableGroup>
      </DataTable>
    );
  }

  return (
    <DataTable {...rest}>
      <DataTableGroup>
        <SearchResult>
          {report.boundaryLines.map((boundaryLine) => (
            <DataTableItem
              key={boundaryLine.uid}
              disabled={disabledUids?.includes(boundaryLine.uid)}
              onClick={onItemClick && (() => onItemClick(boundaryLine.uid))}
              selected={selectedUids?.includes(boundaryLine.uid)}
            >
              <Box flex="horizontal" alignChildrenHorizontal="between">
                <Box grow>
                  <BoundaryLineView boundaryLine={boundaryLine} />
                </Box>
              </Box>
            </DataTableItem>
          ))}
        </SearchResult>
      </DataTableGroup>
    </DataTable>
  );
};

export default BoundaryLinesList;
