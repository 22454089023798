import { MapLayerMultiLine, MapLayerReturn, MapLayerUpdateFn } from '../useMap';

const getPathsFromMultiLineString = (
  multiLineString: GeoJSON.MultiLineString
) => {
  return multiLineString.coordinates.flatMap((line) =>
    line.map(([lng, lat]) => new google.maps.LatLng(lat, lng))
  );
};

const getGeometryMultiLine = (
  map: google.maps.Map,
  layer: MapLayerMultiLine
): MapLayerReturn<MapLayerMultiLine> => {
  const line = new google.maps.Polyline({
    map,
    path: layer.geometry ? getPathsFromMultiLineString(layer.geometry) : [],
    ...layer.style,
  });

  let prevLayer = layer;

  const set = (layer: MapLayerMultiLine) => {
    line.setOptions({
      path: layer.geometry ? getPathsFromMultiLineString(layer.geometry) : [],
      ...layer.style,
    });

    prevLayer = layer;
  };

  const update: MapLayerUpdateFn<MapLayerMultiLine> = (value) => {
    if (typeof value === 'function') {
      set(value(prevLayer));
    } else if (value) {
      set({
        ...prevLayer,
        ...value,
      });
    }
  };

  const remove = () => {
    line.setMap(null);
  };

  return { update, remove };
};

export default getGeometryMultiLine;
