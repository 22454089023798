import { getProjectLocation } from '@drainify/utils';
import { Box, Button, Icons, Text } from 'preshape';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useBooking from '../../../../hooks/useBooking';
import useProject from '../../../../hooks/useProject';
import useProjectBookings from '../../../../hooks/useProjectBookings';
import ConfirmationDeleteModal from '../../../../utils/ConfirmationDeleteModal';
import BookingModal from '../../../Booking/BookingModal';
import JobActionButtons from '../../../Booking/JobActionButtons';
import JobAdditionalSummary from '../../../Booking/JobAdditionalSummary';
import CustomerModal from '../../../Customer/CustomerModal';
import CustomerView from '../../../Customer/CustomerView';
import DocumentAddButton from '../../../Document/DocumentAddButton';
import DocumentList from '../../../Document/DocumentList';
import EmailLog from '../../../Emails/EmailLog';
import FeatureFlag from '../../../FeatureFlag/FeatureFlag';
import HiddenContent from '../../../HiddenContent/HiddenContent';
import Address from '../../../Location/Address';
import MapSidebarContentItem from '../../../Map/MapSidebar/MapSidebarContentItem';
import MapSidebarContentItemSection from '../../../Map/MapSidebar/MapSidebarContentItemSection';
import OCRExtractionToolButton from '../../../OCRExtractionTool/OCRExtractionToolButton';
import { useHasPermission } from '../../../Permissions/Permissions';
import ProjectModal from '../../../Project/ProjectModal';
import { useProjectContext } from '../../../Project/ProjectProvider';
import SummaryCard from '../../../SummaryCard/SummaryCard';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportMapContext } from '../ReportMapProvider';

const ReportMapSidebarJob = () => {
  const { activeSidebar } = useReportMapContext();
  const setSearchParams = useSearchParams()[1];
  const { project, activeBooking, setActiveBookingId } = useProjectContext();
  const { query, update, remove } = useBooking(activeBooking?.uid);
  const { query: projectQuery } = useProject({
    projectId: project?.uid,
  });
  const searchParams = useSearchParams()[0];
  const activeBookingIdParam = searchParams.get('activeBookingId');

  const handleRemove = async () => {
    await remove.mutateAsync();
    navigate('/');
  };
  const { reportEditor } = useReportEditorContext();
  const [devDelete, setDevDelete] = React.useState(false);

  const [bookingModalVisible, setBookingModalVisible] = React.useState(
    activeBookingIdParam === null
  );
  const [customerModalVisible, setCustomerModalVisible] = React.useState(false);
  const [projectModalVisible, setProjectModalVisible] = React.useState(false);
  const { create } = useProjectBookings(project?.uid);

  const hasUpdateProjectPermission =
    useHasPermission('projects:update') && !!!activeBooking?.completedAt;
  const navigate = useNavigate();

  if (activeBookingIdParam === null) {
    return (
      <MapSidebarContentItem
        active={activeSidebar === 'job'}
        title={'No job selected'}
      >
        <MapSidebarContentItemSection>
          <Button onClick={() => setBookingModalVisible(true)}>
            Create job
          </Button>
        </MapSidebarContentItemSection>
        <BookingModal
          onClose={() => setBookingModalVisible(false)}
          onDone={create}
          visible={bookingModalVisible}
        />
      </MapSidebarContentItem>
    );
  }

  if (!query.data || !activeBooking) {
    return null;
  }

  if (query.data.assignedTo?.length === 0) {
    return (
      <>
        <MapSidebarContentItem
          active={activeSidebar === 'job'}
          title={query.data.jobId}
        >
          Job is unassigned.
          <Button color="accent" onClick={() => setBookingModalVisible(true)}>
            Click here to assign
          </Button>
        </MapSidebarContentItem>
        <BookingModal
          visible={bookingModalVisible}
          initialActiveStepId={'assignedTo'}
          booking={activeBooking}
          onClose={() => setBookingModalVisible(false)}
          onDone={update}
          onRemove={handleRemove}
        />
      </>
    );
  }

  if (activeSidebar !== 'job') {
    return null;
  }

  return (
    <>
      <MapSidebarContentItem
        active={activeSidebar === 'job'}
        title={
          <Box flex="vertical" gap="x2" alignChildrenHorizontal="start">
            <Text>{activeBooking.jobId}</Text>
          </Box>
        }
        action={
          hasUpdateProjectPermission ? (
            <Button
              variant="tertiary"
              color="accent"
              onClick={() => setBookingModalVisible(true)}
              flex="horizontal"
              gap="x2"
            >
              Edit
              <Icons.Edit2 />
            </Button>
          ) : undefined
        }
      >
        {(activeBooking?.childJobId || activeBooking?.parentJobId) && (
          <MapSidebarContentItemSection title="History">
            {activeBooking?.parentJobId && (
              <Button
                color="accent"
                variant="tertiary"
                flex="horizontal"
                gap="x1"
                onClick={() => {
                  setActiveBookingId(activeBooking.parentJobId);
                  setSearchParams({
                    ['activeBookingId']: activeBooking.parentJobId!,
                  });
                }}
              >
                <Icons.Upload />
                Go to parent
              </Button>
            )}
            {activeBooking?.childJobId && (
              <Button
                color="accent"
                variant="tertiary"
                flex="horizontal"
                gap="x1"
                onClick={() => {
                  setActiveBookingId(activeBooking.childJobId);
                  setSearchParams({
                    ['activeBookingId']: activeBooking.childJobId!,
                  });
                }}
              >
                <Icons.Download />
                Go to child
              </Button>
            )}
          </MapSidebarContentItemSection>
        )}

        <MapSidebarContentItemSection title="Job actions">
          <Box flex="horizontal" wrap alignChildrenHorizontal="start" gap="x1">
            <JobActionButtons grow />
          </Box>
        </MapSidebarContentItemSection>

        <MapSidebarContentItemSection
          title="Documents"
          action={<DocumentAddButton job={activeBooking} />}
        >
          <DocumentList job={activeBooking} />
        </MapSidebarContentItemSection>

        <MapSidebarContentItemSection
          title="Customer"
          action={
            <Button
              variant="tertiary"
              color="accent"
              onClick={() => setCustomerModalVisible(true)}
              gap="x2"
            >
              Edit
              <Icons.Edit2 />
            </Button>
          }
        >
          <SummaryCard>
            <CustomerView customer={project?.customer} />
          </SummaryCard>
        </MapSidebarContentItemSection>
        <MapSidebarContentItemSection
          title="Site"
          action={
            <Button
              variant="tertiary"
              color="accent"
              onClick={() => setProjectModalVisible(true)}
              gap="x2"
            >
              Edit
              <Icons.Edit2 />
            </Button>
          }
        >
          <SummaryCard>
            <Address location={getProjectLocation(project)} />
          </SummaryCard>
        </MapSidebarContentItemSection>

        <MapSidebarContentItemSection title="Email log">
          <EmailLog job={activeBooking} />
        </MapSidebarContentItemSection>

        <MapSidebarContentItemSection>
          <HiddenContent>
            <JobAdditionalSummary job={activeBooking} />
          </HiddenContent>
        </MapSidebarContentItemSection>
        <FeatureFlag>
          <HiddenContent content="Super-user actions">
            <MapSidebarContentItemSection title="Report version number">
              <Text>{reportEditor.report.version}</Text>
            </MapSidebarContentItemSection>
            <MapSidebarContentItemSection title="Reset report">
              <Button
                onClick={() => setDevDelete(true)}
                color="negative"
                variant="tertiary"
                flex="horizontal"
                gap="x1"
              >
                <Icons.Trash />
                Reset ALL job data
              </Button>
              <ConfirmationDeleteModal
                action={() => reportEditor.testReset()}
                content={'Delete ALL report data'}
                subheading={
                  'Are you sure?...like...really really really sure? Everything will be gone from this report'
                }
                visible={devDelete}
                onClose={() => setDevDelete(false)}
              />
            </MapSidebarContentItemSection>
            <MapSidebarContentItemSection title="OCR extraction tool">
              <OCRExtractionToolButton />
            </MapSidebarContentItemSection>
          </HiddenContent>
        </FeatureFlag>
      </MapSidebarContentItem>

      <BookingModal
        visible={bookingModalVisible}
        booking={activeBooking}
        onClose={() => setBookingModalVisible(false)}
        onDone={update}
        onRemove={handleRemove}
      />
      <CustomerModal
        onClose={() => {
          projectQuery.refetch();
          setCustomerModalVisible(false);
        }}
        visible={customerModalVisible}
        customer={project?.customer}
        onSave={() => {}}
      />
      <ProjectModal
        initialActiveStepId="location"
        onClose={() => setProjectModalVisible(false)}
        project={project}
        visible={projectModalVisible}
        onSave={() => {}}
      />
    </>
  );
};

export default ReportMapSidebarJob;
