import { PlanType } from '@drainify/types';
import { colorDarkShade1, colorWhite } from 'preshape';
import React, { useMemo } from 'react';
import useDrawGeometry from '../../../Map/useDrawGeometry';
import { useReportEditorContext } from '../../ReportEditorProvider';
import { useReportContext } from '../../ReportProvider';
import ReportMapLineMeasurementMarker from '../ReportMapTools/ReportMapLineMeasurementMarker';

const metersToDegrees = (meters: number, latitude: number) => {
  const earthCircumference = 40075000;
  const latDegrees = meters / (earthCircumference / 360);
  const lonDegrees =
    meters /
    ((earthCircumference / 360) * Math.cos((latitude * Math.PI) / 180));
  return { latDegrees, lonDegrees };
};

type Props = {
  polygon?: GeoJSON.Polygon;
};

const ReportMapGrid = ({ polygon }: Props) => {
  let { bounds } = useReportContext();
  if (polygon) {
    bounds = polygon;
  }
  const { reportEditor } = useReportEditorContext();

  const boundingBox = useMemo(() => {
    if (!bounds || !bounds.coordinates || bounds.coordinates.length === 0)
      return null;

    let minLat = Infinity;
    let minLng = Infinity;
    let maxLat = -Infinity;
    let maxLng = -Infinity;

    bounds.coordinates[0].forEach(([lng, lat]) => {
      if (lat < minLat) minLat = lat;
      if (lat > maxLat) maxLat = lat;
      if (lng < minLng) minLng = lng;
      if (lng > maxLng) maxLng = lng;
    });

    return [minLng, minLat, maxLng, maxLat];
  }, [bounds]);

  const horizontalPath = useMemo(() => {
    if (!boundingBox) return [];

    const [minLng, minLat, maxLng, maxLat] = boundingBox;

    const gridSpacingMeters = 1;
    const { latDegrees, lonDegrees } = metersToDegrees(
      gridSpacingMeters,
      (minLat + maxLat) / 2
    );

    const path = [];

    for (let lat = minLat; lat <= maxLat; lat += latDegrees) {
      for (let lng = minLng; lng <= maxLng; lng += lonDegrees) {
        path.push([lng, lat]);
      }
      for (let lng = maxLng; lng >= minLng; lng -= lonDegrees) {
        path.push([lng, lat + latDegrees]);
      }
    }

    return path;
  }, [boundingBox]);

  const verticalPath = useMemo(() => {
    if (!boundingBox) return [];

    const [minLng, minLat, maxLng, maxLat] = boundingBox;

    const gridSpacingMeters = 1;
    const { latDegrees, lonDegrees } = metersToDegrees(
      gridSpacingMeters,
      (minLat + maxLat) / 2
    );

    const path = [];

    for (let lng = minLng; lng <= maxLng; lng += lonDegrees) {
      for (let lat = minLat; lat <= maxLat; lat += latDegrees) {
        path.push([lng, lat]);
      }
      for (let lat = maxLat; lat >= minLat; lat -= latDegrees) {
        path.push([lng + lonDegrees, lat]);
      }
    }

    return path;
  }, [boundingBox]);

  useDrawGeometry(
    useMemo(
      () => ({
        geometry: bounds,
        style: {
          fillColor: colorWhite,
          fillOpacity: 1,
          zIndex: -1,
        },
      }),
      [horizontalPath]
    )
  );

  useDrawGeometry(
    useMemo(
      () => ({
        geometry: getGeo(horizontalPath),
        style: {
          strokeColor: colorDarkShade1,
          strokeOpacity: 0.5,
          strokeWeight: 0.5,
          zIndex: 0,
        },
      }),
      [horizontalPath]
    )
  );

  useDrawGeometry(
    useMemo(
      () => ({
        geometry: getGeo(verticalPath),
        style: {
          strokeColor: colorDarkShade1,
          strokeOpacity: 0.5,
          strokeWeight: 0.5,
          zIndex: 0,
        },
      }),
      [verticalPath]
    )
  );
  const gridMode = reportEditor.report?.gridMode;

  if (
    gridMode === undefined ||
    reportEditor.report.planType !== PlanType.BLOCK_PLAN
  ) {
    return null;
  }

  return (
    <>
      {gridMode.isToScale && bounds && (
        <>
          <ReportMapLineMeasurementMarker
            points={[
              { type: 'Point', coordinates: bounds.coordinates[0][0] },
              { type: 'Point', coordinates: bounds.coordinates[0][1] },
            ]}
            padding={-10}
            label
          />
          <ReportMapLineMeasurementMarker
            points={[
              { type: 'Point', coordinates: bounds.coordinates[0][0] },
              { type: 'Point', coordinates: bounds.coordinates[0][3] },
            ]}
            padding={10}
            label
          />
        </>
      )}
    </>
  );
};

const getGeo = (path: number[][]): GeoJSON.MultiLineString => ({
  type: 'MultiLineString',
  coordinates: [path],
});

export default ReportMapGrid;
