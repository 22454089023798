export type MeasurementPostBody = {
  points: GeoJSON.Point[];
  length?: number;
  name?: string;
  jobId?: string;
};

export type Measurement = MeasurementPostBody & {
  uid: string;
  index: number;
};
