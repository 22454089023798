import { getPointBetweenPoints, getAdjustedPoint } from '@drainify/utils';
import bearing from '@turf/bearing';
import distance from '@turf/distance';
import { Label, sizeX2Px, Text } from 'preshape';
import React from 'react';
import { useMapContext } from '../../../Map/Map';
import MapMarker from '../../../Map/MapMarker/MapMarker';
import useGeometryStoreRegister from '../../../Map/useGeometryStore/useGeometryStoreRegister';

const ReportMapLineMeasurementMarker = ({
  points,
  padding = 0,
  label,
}: {
  points: [GeoJSON.Point, GeoJSON.Point];
  padding?: number;
  label?: boolean;
}) => {
  const { getZoom } = useMapContext();
  const zoom = getZoom() || 0;
  const length = distance(points[0], points[1], { units: 'meters' });
  const midpoint = getPointBetweenPoints(points[0], points[1], length / 2);
  const b = Math.round(bearing(points[0], points[1]) + 90);
  const paddedMidpoint = getAdjustedPoint(midpoint, padding, zoom, b);
  useGeometryStoreRegister({
    geometry: points[0],
    opts: {
      anchor: 'center',
      id: 'MapBoundsHandle',
      keepInFocus: true,
      padding: sizeX2Px,
    },
  });
  if (label) {
    return (
      <MapMarker point={paddedMidpoint}>
        <Label
          backgroundColor="light-shade-1"
          borderStyle="dashed"
          borderColor="dark-shade-1"
        >
          <Text size="x1" strong textColor="dark-shade-1">
            {length.toFixed(2)}m
          </Text>
        </Label>
      </MapMarker>
    );
  }
  return (
    <MapMarker point={paddedMidpoint}>
      <Text size="x1" strong>
        {length.toFixed(2)}m
      </Text>
    </MapMarker>
  );
};

export default ReportMapLineMeasurementMarker;
