import { Drawing, DrawingPoint } from '@drainify/types';
import centroid from '@turf/centroid';
import distance from '@turf/distance';
import { Box } from 'preshape';
import React from 'react';
import MapMarker from '../../../../Map/MapMarker/MapMarker';
import { useReportEditorContext } from '../../../ReportEditorProvider';

type Props = {
  drawing: Drawing;
  updateDimensions: (points: DrawingPoint[]) => void;
};

const ReportMapDrawingEditOperationScale = ({
  drawing,
  updateDimensions,
}: Props) => {
  const { reportEditor } = useReportEditorContext();
  const polygon: GeoJSON.Feature<GeoJSON.Polygon> = {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [drawing.points.map((e) => e.point.coordinates)],
    },
    properties: {},
  };

  const performScaling = (
    originalPoint: GeoJSON.Point,
    newPoint: GeoJSON.Point
  ) => {
    const center = centroid(polygon).geometry.coordinates;

    const originalDistance = distance(originalPoint, center);

    const newDistance = distance(newPoint, center);

    const scalingFactor = newDistance / originalDistance;

    const scaledCoordinates = polygon.geometry.coordinates[0].map(([x, y]) => {
      return [
        center[0] + (x - center[0]) * scalingFactor, // Scale X
        center[1] + (y - center[1]) * scalingFactor, // Scale Y
      ];
    });

    const scaledPolygon = {
      ...polygon,
      geometry: {
        ...polygon.geometry,
        coordinates: [scaledCoordinates],
      },
    };

    const newCache = drawing.points.map((pointObj, index) => {
      const newCoordinates = scaledCoordinates[index];
      return {
        ...pointObj,
        point: {
          ...pointObj.point,
          coordinates: newCoordinates,
        },
      };
    });

    updateDimensions(newCache);

    return scaledPolygon;
  };

  return (
    <>
      {' '}
      {drawing.points.map((point, i) => (
        <Box key={i + `drawing`}>
          <MapMarker
            point={point.point}
            onDrag={(p) => performScaling(point.point, p)}
            onDragEnd={() => {
              reportEditor.updateDrawing(drawing.uid, {
                points: drawing.points,
              });
            }}
          >
            <Box
              height={10}
              width={10}
              borderRadius="full"
              backgroundColor="white"
            />
          </MapMarker>
        </Box>
      ))}
    </>
  );
};

export default ReportMapDrawingEditOperationScale;
