import { Annotation, AnnotationPostBody } from '@drainify/types';
import { ReportError } from '@drainify/utils';
import { FormValidateFn, useForm } from 'preshape';
import { useCallback, useMemo } from 'react';
import { useProjectContext } from '../../components/Project/ProjectProvider';
import { useReportEditorContext } from '../../components/Report/ReportEditorProvider';

const useAnnotationForm = (annotation?: Annotation | AnnotationPostBody) => {
  const { reportEditor } = useReportEditorContext();
  const { activeBookingId } = useProjectContext();
  const initial = useMemo<Annotation | AnnotationPostBody>(
    () =>
      annotation || {
        jobId: activeBookingId!, // todo fix
      },
    [annotation, activeBookingId]
  );

  const validate = useCallback<
    FormValidateFn<Annotation | AnnotationPostBody, ReportError>
  >(
    (annotation, setError) => {
      setError(reportEditor.validateAnnotation(annotation));
    },
    [reportEditor]
  );

  return useForm({
    initial,
    validate,
  });
};

export default useAnnotationForm;
