import { Annotation } from './Annotation';
import { WithTracking } from './Api';
import { BoundaryLine } from './BoundaryLine';
import { Drawing } from './Drawing';
import { Inspection } from './Inspection';
import { Measurement } from './Measurement';
import { Node } from './Node';
import { Observation } from './Observation';
import { Org } from './Org';
import { Plan } from './Plan';
import { Project } from './Project';
import { Scale } from './Scale';
import { Section } from './Section';
import { SitePhoto } from './SitePhoto';

export type ReportPostBody = {
  bounds?: GeoJSON.Polygon;
  nodes: Node[];
  annotations: Annotation[];
  observations: Observation[];
  sections: Section[];
  measurements: Measurement[];
  boundaryLines: BoundaryLine[];
  drawings: Drawing[];
  scale?: Scale | null;
  inspections: Inspection[];
  plan?: Plan | null;
  sitePhotos: SitePhoto[];
  surveyType?: SurveyType;
  planType?: PlanType;
  version: number;
  gridMode?: GridMode;
};

export interface GridMode {
  isToScale: boolean;
}

export type Report = WithTracking &
  ReportPostBody & {
    uid: string;
    org: Org;
    project: Project;
  };

export enum SurveyType {
  RAPID_REPORT = 'RAPID_REPORT',
  VIDEO = 'VIDEO',
}

export enum PlanType {
  GOOGLE_MAPS = 'GOOGLE_MAPS',
  BLOCK_PLAN = 'BLOCK_PLAN',
  UPLOADED_PLAN = 'UPLOADED_PLAN',
  NO_PLAN = 'NO_PLAN',
}

export enum BlockPlanTemplateType {
  CUSTOM = 'CUSTOM',
  TERRACED_HOUSE_3 = 'TERRACED_HOUSE_3',
  SEMI_DETACHED_HOUSE_2 = 'SEMI_DETACHED_HOUSE_2',
  DETACHED_HOUSE = 'DETACHED_HOUSE',
}

export interface BlockPlanTemplate {
  template: BlockPlanTemplateType;
  x?: number;
  y?: number;
}
