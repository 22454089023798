import { isBoundaryLine } from '@drainify/utils';
import {
  FormProvider,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
} from 'preshape';
import React, { useEffect } from 'react';
import useMeasurementForm from '../../hooks/forms/useMeasurementForm';
import { isDesktop, isMobile } from '../../utils/client';
import { FULL_SCREEN_MODAL_WIDTH } from '../App/App';
import { useHasPermission } from '../Permissions/Permissions';
import { useProjectContext } from '../Project/ProjectProvider';
import { useReportEditorContext } from '../Report/ReportEditorProvider';
import SearchProvider from '../Search/SearchProvider';
import Wizard from '../Wizard/Wizard';
import WizardControls from '../Wizard/WizardControls/WizardControls';
import WizardReviewStep from '../Wizard/WizardReviewStep';
import WizardSearch from '../Wizard/WizardSearch';
import WizardStep from '../Wizard/WizardStep';
import WizardStepError from '../Wizard/WizardStepError';
import MeasurementLocationhack from './MeasurementLocationHack';

type Props = ModalProps & {
  initialActiveStepId?: string;
  onClose: () => void;
  onCreate?: (boundaryLineUid: string) => void;
  boundaryLineUid?: string;
};

const BoundaryLineModal = ({
  initialActiveStepId,
  onClose,
  onCreate,
  boundaryLineUid,
  visible,
  ...rest
}: Props) => {
  const { reportEditor } = useReportEditorContext();
  const { activeBooking } = useProjectContext();
  const measurement = reportEditor.getBoundaryLineByUid(boundaryLineUid);
  const hasUpdateProjectPermission = useHasPermission('projects:update');
  const form = useMeasurementForm(measurement, activeBooking?.uid);

  const handleRemove = async () => {
    if (boundaryLineUid) {
      reportEditor.removeBoundaryLine(boundaryLineUid);
    }

    onClose();
  };

  const handleSetPoints = (points: GeoJSON.Point[]) => {
    form.setState((s) => ({
      ...s,
      points,
    }));
  };

  const handleSave = async () => {
    if (boundaryLineUid) {
      reportEditor.updateBoundaryLine(boundaryLineUid, form.state);
      onClose();
    } else {
      const { uid } = reportEditor.addBoundaryLine(form.state);
      onCreate?.(uid);
      onClose();
    }
  };

  useEffect(() => {
    if (visible) {
      form.reset();
    }
  }, [visible]);

  return (
    <SearchProvider>
      <FormProvider form={form}>
        <Wizard
          flow={boundaryLineUid ? 'update' : 'create'}
          initialActiveStepId={initialActiveStepId}
          onSave={hasUpdateProjectPermission ? handleSave : undefined}
          onRemove={hasUpdateProjectPermission ? handleRemove : undefined}
          onCancel={onClose}
          reset={visible}
        >
          <Modal
            {...rest}
            animation="FadeSlideUp"
            margin="x4"
            fullscreen={!isDesktop()}
            onClose={onClose}
            maxWidth={isMobile() ? '100vw' : FULL_SCREEN_MODAL_WIDTH}
            overlayBackgroundCloseOnClick={false}
            visible={visible}
          >
            <ModalHeader>
              <ModalTitle>
                {isBoundaryLine(measurement)
                  ? 'Editing boundary line'
                  : 'New boundary line'}
              </ModalTitle>
            </ModalHeader>

            <ModalBody>
              <WizardStep id="points">
                <MeasurementLocationhack
                  onChange={handleSetPoints}
                  markType="measurement-point"
                />
              </WizardStep>
              <WizardReviewStep></WizardReviewStep>
            </ModalBody>

            <ModalFooter>
              <WizardStepError />
              <WizardSearch />
              <WizardControls />
            </ModalFooter>
          </Modal>
        </Wizard>
      </FormProvider>
    </SearchProvider>
  );
};

export default BoundaryLineModal;
