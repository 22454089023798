import { Annotation, AnnotationPostBody } from '@drainify/types';
import { Box, Icons, Text, TextProps } from 'preshape';
import React from 'react';

type Props = TextProps & {
  annotation?: Annotation | AnnotationPostBody;
};

const AnnotationView = ({ annotation, ...rest }: Props) => {
  return (
    <Text {...rest} size="x3">
      <Box
        alignChildrenVertical="middle"
        flex="horizontal"
        gap="x4"
        grow
        margin="x2"
      >
        <Box alignChildrenVertical="middle" flex="horizontal" gap="x2" grow>
          <Box>
            <Icons.Info />
          </Box>
          <Box grow>
            <Text strong>{annotation?.name || 'Unnamed annotation'}</Text>
          </Box>
        </Box>
      </Box>
    </Text>
  );
};

export default AnnotationView;
