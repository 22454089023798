import { Project, Report } from '@drainify/types';
import { GeoJSONPointBlank } from '@drainify/utils';
import React, { createContext, PropsWithChildren, useContext } from 'react';
import { MapBounds } from '../Map/useMap';
import NodeMap from './NodeMap';

type Props = {
  isCustomerReport: boolean;
};

type Context = {
  visible: boolean;
  projectId?: string;
  setVisible: (visible: boolean) => void;
  setProjectId: (projectId: string) => void;
  onChange?: (point: GeoJSON.Point) => void;
  setOnChange: (f: (point: GeoJSON.Point) => void) => void;
  onChangePoints?: (point: GeoJSON.Point[]) => void;
  setOnChangePoints: (f: (point: GeoJSON.Point[]) => void) => void;
  onUpdateBounds?: (mb: MapBounds) => void;
  setOnUpdateBounds: (f: (mb: MapBounds) => void) => void;
  project?: Project;
  report?: Report;
  setProject: (project: Project) => void;
  setReport: (report: Report) => void;
  markType: MarkType;
  setMarkType: (markType: MarkType) => void;
  setStartPoint: (point: GeoJSON.Point) => void;
  setEndPoint: (point: GeoJSON.Point) => void;
  startPoint: GeoJSON.Point;
  endPoint: GeoJSON.Point;
  initialPoints: GeoJSON.Point[];
  setInitialPoints: (point: GeoJSON.Point[]) => void;
  isCustomerReport: boolean;
  precacheProject?: Project;
  precacheReport?: Report;
  setPrecacheProject: (project: Project) => void;
  setPrecacheReport: (report: Report) => void;
};

export const NodeMapContext = createContext<Context>({
  visible: false,
  projectId: undefined,
  setVisible: () => {},
  setProjectId: () => {},
  setOnChange: () => {},
  onChange: () => {},
  setStartPoint: () => {},
  setEndPoint: () => {},
  startPoint: GeoJSONPointBlank,
  endPoint: GeoJSONPointBlank,
  initialPoints: [],
  setInitialPoints: () => {},
  setOnChangePoints: () => {},
  onChangePoints: () => {},
  onUpdateBounds: () => {},
  setOnUpdateBounds: () => {},
  project: undefined,
  report: undefined,
  setProject: () => {},
  setReport: () => {},
  precacheProject: undefined,
  precacheReport: undefined,
  setPrecacheProject: () => {},
  setPrecacheReport: () => {},
  markType: undefined,
  setMarkType: () => {},
  isCustomerReport: false,
});

export const useReportContext = () => useContext(NodeMapContext);

export type MarkType =
  | 'node'
  | 'measurement-point'
  | 'drawing-point'
  | 'plan'
  | 'route'
  | 'rectangle'
  | undefined;

const NodeMapProvider = ({
  isCustomerReport = false,
  children,
}: PropsWithChildren<Props>) => {
  const [visible, setVisible] = React.useState(false);
  const [project, setProject] = React.useState<Project>();
  const [report, setReport] = React.useState<Report>();
  const [precacheProject, setPrecacheProject] = React.useState<Project>();
  const [precacheReport, setPrecacheReport] = React.useState<Report>();
  const [projectId, setProjectId] = React.useState<string>();
  const [onChange, setOnChange] =
    React.useState<(point: GeoJSON.Point) => void>();

  const [onChangePoints, setOnChangePoints] =
    React.useState<(point: GeoJSON.Point[]) => void>();

  const [onUpdateBounds, setUpdateBounds] =
    React.useState<(mb: MapBounds) => void>();

  const [initialPoints, setInitialPoints] = React.useState<GeoJSON.Point[]>([]);

  const [markType, setMarkType] = React.useState<MarkType>('node');
  const [startPoint, setStartPoint] =
    React.useState<GeoJSON.Point>(GeoJSONPointBlank);
  const [endPoint, setEndPoint] =
    React.useState<GeoJSON.Point>(GeoJSONPointBlank);

  const handleSetOnChange = (a: (point: GeoJSON.Point) => void) => {
    setOnChange(() => (p: GeoJSON.Point) => a(p));
  };

  const handleSetOnChangePoints = (a: (point: GeoJSON.Point[]) => void) => {
    setOnChangePoints(() => (p: GeoJSON.Point[]) => a(p));
  };

  const handleSetOnUpdateBounds = (a: (mb: MapBounds) => void) => {
    setUpdateBounds(() => (p: MapBounds) => a(p));
  };

  return (
    <NodeMapContext.Provider
      value={{
        visible,
        setVisible,
        projectId,
        setProjectId,
        onChange,
        setOnChange: handleSetOnChange,
        onChangePoints,
        setOnChangePoints: handleSetOnChangePoints,
        onUpdateBounds,
        setOnUpdateBounds: handleSetOnUpdateBounds,
        report,
        project,
        initialPoints,
        setInitialPoints,
        setProject,
        setReport,
        markType,
        setMarkType,
        isCustomerReport,
        precacheProject,
        precacheReport,
        setPrecacheProject,
        setPrecacheReport,
        startPoint,
        setStartPoint,
        endPoint,
        setEndPoint,
      }}
    >
      <NodeMap />
      {children}
    </NodeMapContext.Provider>
  );
};

export default NodeMapProvider;
