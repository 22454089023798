import {
  isCircular,
  Ownership,
  PipeType,
  SectionMaterialCode,
  SectionShapeCode,
  SectionUseCode,
} from '@drainify/types';
import {
  getLandOwnershipCodeSchema,
  getMaterialSchema,
  getShapeSchema,
  getUsesSchema,
} from '@drainify/utils';
import {
  Box,
  Button,
  Buttons,
  CheckBox,
  Icons,
  InputLabel,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text,
} from 'preshape';
import React from 'react';
import { FULL_SCREEN_MODAL_WIDTH } from '../../../App/App';
import DistanceInput from '../../../DistanceInput/DistanceInput';
import { useProjectContext } from '../../../Project/ProjectProvider';
import SectionDiameterQuickOptions from '../../../Section/SectionDiameterQuickOptions';
import { useLanguageContext } from '../../../Usage/LanguageProvider';
import { useReportEditorContext } from '../../ReportEditorProvider';

const ReportMapSidebarSectionsBulkUpdate = () => {
  const [editOwnershipVisable, setEditOwnershipVisable] = React.useState(false);
  const [editPipeTypeVisable, setEditPipeTypeVisable] = React.useState(false);
  const [editShapeVisable, setEditShapeVisable] = React.useState(false);
  const [editMaterialVisable, setEditMaterialVisable] = React.useState(false);
  const [editUseVisible, setEditUseVisible] = React.useState(false);
  const [editDiameterVisible, setEditDiameterVisible] = React.useState(false);
  const [editHeightVisible, setEditHeightVisible] = React.useState(false);
  const [editWidthVisible, setEditWidthVisible] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const { language } = useLanguageContext();
  const { activeBooking } = useProjectContext();
  const [stagedOwnership, setStagedOwnership] = React.useState<Ownership>();
  const [stagedPipeType, setStagedPipeType] = React.useState<PipeType>();
  const [stagedShape, setStagedShape] = React.useState<SectionShapeCode>();
  const [stagedMaterial, setStagedMaterial] =
    React.useState<SectionMaterialCode>();
  const [stagedDiameter, setStagedDiameter] = React.useState<number>();
  const [stagedHeight, setStagedHeight] = React.useState<number>();
  const [stagedWidth, setStagedWidth] = React.useState<number>();
  const [stagedUse, setStagedUse] = React.useState<SectionUseCode>();
  const { reportEditor } = useReportEditorContext();

  React.useEffect(() => {
    setStagedOwnership(undefined);
    setStagedPipeType(undefined);
    setStagedShape(undefined);
    setStagedMaterial(undefined);
    setStagedUse(undefined);
    setStagedDiameter(undefined);
    setStagedHeight(undefined);
    setStagedWidth(undefined);
    // diameter: project?.siteDefaults?.section?.diameter,
    // width: project?.siteDefaults?.section?.width,
    // height: project?.siteDefaults?.section?.height,
  }, [
    editOwnershipVisable,
    editPipeTypeVisable,
    editShapeVisable,
    editMaterialVisable,
    editUseVisible,
  ]);

  if (reportEditor.report.sections.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        variant="tertiary"
        flex="horizontal"
        onClick={() => setModalVisible(true)}
        gap="x1"
        color="accent"
        alignChildrenVertical="end"
      >
        <Text>Update all sections</Text>
        <Icons.Upload />
      </Button>

      {modalVisible && (
        <>
          <Modal
            visible={true}
            maxWidth={FULL_SCREEN_MODAL_WIDTH}
            onClose={() => setModalVisible(false)}
          >
            <ModalHeader>Site detail update</ModalHeader>
            <ModalBody>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {' '}
                      <Text> Section ownership </Text>{' '}
                    </TableCell>
                    <TableCell
                      flex="horizontal"
                      alignChildrenHorizontal="middle"
                    >
                      {' '}
                      <Button
                        variant="tertiary"
                        color="accent"
                        onClick={() => setEditOwnershipVisable(true)}
                      >
                        <Label>
                          {reportEditor.report.sections.length > 0 &&
                          new Set(
                            reportEditor.report.sections
                              .map(
                                (section) => section.attributes.sectionOwnership
                              )
                              .filter((e) => !!e)
                          ).size === 1
                            ? getLandOwnershipCodeSchema(
                                reportEditor.report.sections
                                  .map(
                                    (section) =>
                                      section.attributes.sectionOwnership
                                  )
                                  .filter((e) => !!e)[0] as string
                              ).name[language]
                            : 'Mixed'}
                        </Label>
                      </Button>{' '}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      {' '}
                      <Text>Pipe type</Text>{' '}
                    </TableCell>
                    <TableCell
                      flex="horizontal"
                      alignChildrenHorizontal="middle"
                    >
                      {' '}
                      <Button
                        variant="tertiary"
                        color="accent"
                        onClick={() => setEditPipeTypeVisable(true)}
                      >
                        <Label>
                          {reportEditor.report.sections.length > 0 &&
                          new Set(
                            reportEditor.report.sections
                              .map((section) => section.attributes.pipeType)
                              .filter((e) => !!e)
                          ).size === 1
                            ? reportEditor.report.sections
                                .map((section) => section.attributes.pipeType)
                                .filter((e) => !!e)[0]
                            : 'Mixed'}
                        </Label>
                      </Button>{' '}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      {' '}
                      <Text>Shape</Text>{' '}
                    </TableCell>
                    <TableCell
                      flex="horizontal"
                      alignChildrenHorizontal="middle"
                    >
                      {' '}
                      <Button
                        variant="tertiary"
                        color="accent"
                        onClick={() => setEditShapeVisable(true)}
                      >
                        <Label>
                          {reportEditor.report.sections.length > 0 &&
                          new Set(
                            reportEditor.report.sections
                              .map((section) => section.attributes.shape)
                              .filter((e) => !!e)
                          ).size === 1
                            ? getShapeSchema(
                                reportEditor.report.sections
                                  .map((section) => section.attributes.shape)
                                  .filter((e) => !!e)[0] as string
                              ).name[language]
                            : 'Mixed'}
                        </Label>
                      </Button>{' '}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {' '}
                      <Text>Material</Text>{' '}
                    </TableCell>
                    <TableCell
                      flex="horizontal"
                      alignChildrenHorizontal="middle"
                    >
                      {' '}
                      <Button
                        variant="tertiary"
                        color="accent"
                        onClick={() => setEditMaterialVisable(true)}
                      >
                        <Label>
                          {reportEditor.report.sections.length > 0 &&
                          new Set(
                            reportEditor.report.sections
                              .map((section) => section.attributes.material)
                              .filter((e) => !!e)
                          ).size === 1
                            ? getMaterialSchema(
                                reportEditor.report.sections
                                  .map((section) => section.attributes.material)
                                  .filter((e) => !!e)[0] as string
                              ).name[language]
                            : 'Mixed'}
                        </Label>
                      </Button>{' '}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Text>Use</Text>
                    </TableCell>
                    <TableCell
                      flex="horizontal"
                      alignChildrenHorizontal="middle"
                    >
                      <Button
                        variant="tertiary"
                        color="accent"
                        onClick={() => setEditUseVisible(true)}
                      >
                        <Label>
                          {reportEditor.report.sections.length > 0 &&
                          new Set(
                            reportEditor.report.sections
                              .map((section) => section.attributes.use)
                              .filter((e) => !!e)
                          ).size === 1
                            ? getUsesSchema(
                                reportEditor.report.sections
                                  .map((section) => section.attributes.use)
                                  .filter((e) => !!e)[0] as string
                              ).name[language]
                            : 'Mixed'}
                        </Label>
                      </Button>
                    </TableCell>
                  </TableRow>
                  {reportEditor.report.sections.some((e) =>
                    isCircular(e.attributes.shape)
                  ) && (
                    <TableRow>
                      <TableCell>
                        <Text>Diameter</Text>
                      </TableCell>
                      <TableCell
                        flex="horizontal"
                        alignChildrenHorizontal="middle"
                      >
                        <Button
                          variant="tertiary"
                          color="accent"
                          onClick={() => setEditDiameterVisible(true)}
                        >
                          <Label>
                            {reportEditor.report.sections.length > 0 &&
                            new Set(
                              reportEditor.report.sections
                                .filter((e) => isCircular(e.attributes.shape))
                                .map((section) => section.attributes.diameter)
                            ).size === 1
                              ? reportEditor.report.sections.filter((e) =>
                                  isCircular(e.attributes.shape)
                                )[0].attributes.diameter === undefined
                                ? 'Unknown'
                                : `${
                                    reportEditor.report.sections.filter((e) =>
                                      isCircular(e.attributes.shape)
                                    )[0].attributes.diameter! * 1000
                                  }mm`
                              : 'Mixed'}
                          </Label>
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}

                  {reportEditor.report.sections.some(
                    (e) =>
                      e.attributes.shape !== undefined &&
                      !isCircular(e.attributes.shape)
                  ) && (
                    <TableRow>
                      <TableCell>
                        <Text>Height</Text>
                      </TableCell>
                      <TableCell
                        flex="horizontal"
                        alignChildrenHorizontal="middle"
                      >
                        <Button
                          variant="tertiary"
                          color="accent"
                          onClick={() => setEditHeightVisible(true)}
                        >
                          <Label>
                            {reportEditor.report.sections.length > 0 &&
                            new Set(
                              reportEditor.report.sections
                                .filter(
                                  (e) =>
                                    e.attributes.shape !== undefined &&
                                    !isCircular(e.attributes.shape)
                                )
                                .map((section) => section.attributes.height)
                            ).size === 1
                              ? reportEditor.report.sections.filter(
                                  (e) =>
                                    e.attributes.shape !== undefined &&
                                    !isCircular(e.attributes.shape)
                                )[0].attributes.height === undefined
                                ? 'Unknown'
                                : `${
                                    reportEditor.report.sections.filter(
                                      (e) =>
                                        e !== undefined &&
                                        !isCircular(e.attributes.shape)
                                    )[0].attributes.height! * 1000
                                  }mm`
                              : 'Mixed'}
                          </Label>
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}

                  {reportEditor.report.sections.some(
                    (e) =>
                      e.attributes.shape !== undefined &&
                      !isCircular(e.attributes.shape)
                  ) && (
                    <TableRow>
                      <TableCell>
                        <Text>Width</Text>
                      </TableCell>
                      <TableCell
                        flex="horizontal"
                        alignChildrenHorizontal="middle"
                      >
                        <Button
                          variant="tertiary"
                          color="accent"
                          onClick={() => setEditWidthVisible(true)}
                        >
                          <Label>
                            {reportEditor.report.sections.length > 0 &&
                            new Set(
                              reportEditor.report.sections
                                .filter(
                                  (e) =>
                                    e.attributes.shape !== undefined &&
                                    !isCircular(e.attributes.shape)
                                )
                                .map((section) => section.attributes.width)
                            ).size === 1
                              ? reportEditor.report.sections.filter(
                                  (e) =>
                                    e.attributes.shape !== undefined &&
                                    !isCircular(e.attributes.shape)
                                )[0].attributes.width === undefined
                                ? 'Unknown'
                                : `${
                                    reportEditor.report.sections.filter(
                                      (e) =>
                                        e.attributes.shape !== undefined &&
                                        !isCircular(e.attributes.shape)
                                    )[0].attributes.width! * 1000
                                  }mm`
                              : 'Mixed'}
                          </Label>
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Box padding="x2">
                <Text size="x1">Manage common site details</Text>
                <Text size="x1">
                  Update here to apply to all sections/laterals
                </Text>
              </Box>
            </ModalBody>
          </Modal>

          <Modal
            visible={editOwnershipVisable}
            maxWidth={FULL_SCREEN_MODAL_WIDTH}
            onClose={() => setEditOwnershipVisable(false)}
          >
            <ModalHeader>Edit ownership</ModalHeader>
            <ModalBody>
              <InputLabel>
                {Object.values(Ownership).map((sectionOwnership) => (
                  <CheckBox
                    checked={sectionOwnership === stagedOwnership}
                    key={sectionOwnership}
                    onChange={() => setStagedOwnership(sectionOwnership)}
                    borderSize="x1"
                    margin="x1"
                  >
                    {
                      getLandOwnershipCodeSchema(sectionOwnership).name[
                        language
                      ]
                    }
                  </CheckBox>
                ))}
              </InputLabel>
            </ModalBody>
            <ModalFooter>
              <Buttons>
                <Button
                  color="positive"
                  elevate={true}
                  disabled={stagedOwnership === undefined}
                  grow
                  onClick={() => {
                    reportEditor.report.sections.map((e) => {
                      reportEditor.updateSectionNoUpdate(e.uid, {
                        attributes: {
                          ...e.attributes,
                          sectionOwnership: stagedOwnership,
                        },
                      });
                    });
                    reportEditor.updateAsync?.(reportEditor.report);
                    setEditOwnershipVisable(false);
                  }}
                  variant="primary"
                  size="x3"
                >
                  <Text>Confirm</Text>
                </Button>
              </Buttons>
            </ModalFooter>
          </Modal>

          <Modal
            visible={editPipeTypeVisable}
            maxWidth={FULL_SCREEN_MODAL_WIDTH}
            onClose={() => setEditPipeTypeVisable(false)}
          >
            <ModalHeader>Edit pipe type</ModalHeader>
            <ModalBody>
              <InputLabel>
                {Object.values(PipeType).map((pipeType) => (
                  <CheckBox
                    checked={pipeType === stagedPipeType}
                    key={pipeType}
                    onChange={() => setStagedPipeType(pipeType)}
                    borderSize="x1"
                    margin="x1"
                  >
                    {pipeType}
                  </CheckBox>
                ))}
              </InputLabel>
            </ModalBody>
            <ModalFooter>
              <Buttons>
                <Button
                  color="positive"
                  elevate={true}
                  disabled={stagedPipeType === undefined}
                  grow
                  onClick={() => {
                    reportEditor.report.sections.map((e) => {
                      reportEditor.updateSectionNoUpdate(e.uid, {
                        attributes: {
                          ...e.attributes,
                          pipeType: stagedPipeType,
                        },
                      });
                    });
                    reportEditor.updateAsync?.(reportEditor.report);
                    setEditPipeTypeVisable(false);
                  }}
                  variant="primary"
                  size="x3"
                >
                  <Text>Confirm</Text>
                </Button>
              </Buttons>
            </ModalFooter>
          </Modal>

          <Modal
            visible={editShapeVisable}
            maxWidth={FULL_SCREEN_MODAL_WIDTH}
            onClose={() => setEditShapeVisable(false)}
          >
            <ModalHeader>Edit Shape</ModalHeader>
            <ModalBody>
              <InputLabel>
                {Object.values(SectionShapeCode)
                  .map((e) => getShapeSchema(e))
                  .filter((e) =>
                    e.projectTypes?.includes(activeBooking?.projectType!)
                  )
                  .map((shape) => (
                    <CheckBox
                      checked={shape.code === stagedShape}
                      key={shape.code}
                      onChange={() => setStagedShape(shape.code)}
                      borderSize="x1"
                      margin="x1"
                    >
                      {shape.name[language]}
                    </CheckBox>
                  ))}
              </InputLabel>
            </ModalBody>
            <ModalFooter>
              <Buttons>
                <Button
                  color="positive"
                  elevate={true}
                  disabled={stagedShape === undefined}
                  grow
                  onClick={() => {
                    reportEditor.report.sections.map((e) => {
                      reportEditor.updateSectionNoUpdate(e.uid, {
                        attributes: {
                          ...e.attributes,
                          shape: stagedShape,
                        },
                      });
                    });
                    reportEditor.updateAsync?.(reportEditor.report);
                    setEditShapeVisable(false);
                  }}
                  variant="primary"
                  size="x3"
                >
                  <Text>Confirm</Text>
                </Button>
              </Buttons>
            </ModalFooter>
          </Modal>

          <Modal
            visible={editMaterialVisable}
            maxWidth={FULL_SCREEN_MODAL_WIDTH}
            onClose={() => setEditMaterialVisable(false)}
          >
            <ModalHeader>Edit material</ModalHeader>
            <ModalBody>
              <InputLabel>
                {Object.values(SectionMaterialCode)
                  .map((e) => getMaterialSchema(e))
                  .filter((e) =>
                    e.projectTypes?.includes(activeBooking?.projectType!)
                  )
                  .map((material) => (
                    <CheckBox
                      checked={material.code === stagedMaterial}
                      key={material.code}
                      onChange={() => setStagedMaterial(material.code)}
                      borderSize="x1"
                      margin="x1"
                    >
                      {material.name[language]}
                    </CheckBox>
                  ))}
              </InputLabel>
            </ModalBody>
            <ModalFooter>
              <Buttons>
                <Button
                  color="positive"
                  elevate={true}
                  disabled={stagedMaterial === undefined}
                  grow
                  onClick={() => {
                    reportEditor.report.sections.map((e) => {
                      reportEditor.updateSectionNoUpdate(e.uid, {
                        attributes: {
                          ...e.attributes,
                          material: stagedMaterial,
                        },
                      });
                    });
                    reportEditor.updateAsync?.(reportEditor.report);
                    setEditMaterialVisable(false);
                  }}
                  variant="primary"
                  size="x3"
                >
                  <Text>Confirm</Text>
                </Button>
              </Buttons>
            </ModalFooter>
          </Modal>
          <Modal
            visible={editUseVisible}
            maxWidth={FULL_SCREEN_MODAL_WIDTH}
            onClose={() => setEditUseVisible(false)}
          >
            <ModalHeader>Edit use</ModalHeader>
            <ModalBody>
              <InputLabel>
                {Object.values(SectionUseCode)
                  .map((e) => getUsesSchema(e))
                  .filter((e) =>
                    e.projectTypes?.includes(activeBooking?.projectType!)
                  )
                  .map((use) => (
                    <CheckBox
                      checked={use.code === stagedUse}
                      key={use.code}
                      onChange={() => setStagedUse(use.code)}
                      borderSize="x1"
                      margin="x1"
                    >
                      {use.name[language]}
                    </CheckBox>
                  ))}
              </InputLabel>
            </ModalBody>
            <ModalFooter>
              <Buttons>
                <Button
                  color="positive"
                  elevate={true}
                  disabled={stagedUse === undefined}
                  grow
                  onClick={() => {
                    reportEditor.report.sections.map((e) => {
                      reportEditor.updateSectionNoUpdate(e.uid, {
                        attributes: {
                          ...e.attributes,
                          use: stagedUse,
                        },
                      });
                    });
                    reportEditor.updateAsync?.(reportEditor.report);
                    setEditUseVisible(false);
                  }}
                  variant="primary"
                  size="x3"
                >
                  <Text>Confirm</Text>
                </Button>
              </Buttons>
            </ModalFooter>
          </Modal>

          <Modal
            visible={editDiameterVisible}
            maxWidth={FULL_SCREEN_MODAL_WIDTH}
            onClose={() => setEditDiameterVisible(false)}
          >
            <ModalHeader>Edit diammeter</ModalHeader>
            <ModalBody>
              <InputLabel>
                <DistanceInput
                  value={stagedDiameter}
                  onChange={(dia) => setStagedDiameter(dia)}
                  type={'short'}
                />
              </InputLabel>

              <SectionDiameterQuickOptions
                value={stagedDiameter}
                onChange={(e) => setStagedDiameter(e)}
              />
            </ModalBody>
            <ModalFooter>
              <Buttons>
                <Button
                  color="positive"
                  elevate={true}
                  disabled={stagedDiameter === undefined}
                  grow
                  onClick={() => {
                    reportEditor.report.sections
                      .filter((e) => isCircular(e.attributes.shape))
                      .map((e) => {
                        reportEditor.updateSectionNoUpdate(e.uid, {
                          attributes: {
                            ...e.attributes,
                            diameter: stagedDiameter,
                          },
                        });
                      });
                    reportEditor.updateAsync?.(reportEditor.report);
                    setEditDiameterVisible(false);
                  }}
                  variant="primary"
                  size="x3"
                >
                  <Text>Confirm</Text>
                </Button>
              </Buttons>
            </ModalFooter>
          </Modal>

          <Modal
            visible={editHeightVisible}
            maxWidth={FULL_SCREEN_MODAL_WIDTH}
            onClose={() => setEditHeightVisible(false)}
          >
            <ModalHeader>Edit height</ModalHeader>
            <ModalBody>
              <InputLabel>
                <DistanceInput
                  value={stagedHeight}
                  onChange={(height) => setStagedHeight(height)}
                  type={'short'}
                />
              </InputLabel>
            </ModalBody>
            <ModalFooter>
              <Buttons>
                <Button
                  color="positive"
                  disabled={stagedHeight === undefined}
                  grow
                  onClick={() => {
                    reportEditor.report.sections
                      .filter((e) => !isCircular(e.attributes.shape))
                      .map((e) => {
                        reportEditor.updateSectionNoUpdate(e.uid, {
                          attributes: {
                            ...e.attributes,
                            height: stagedHeight,
                          },
                        });
                      });
                    reportEditor.updateAsync?.(reportEditor.report);
                    setEditHeightVisible(false);
                  }}
                  variant="primary"
                  size="x3"
                >
                  <Text>Confirm</Text>
                </Button>
              </Buttons>
            </ModalFooter>
          </Modal>

          <Modal
            visible={editWidthVisible}
            maxWidth={FULL_SCREEN_MODAL_WIDTH}
            onClose={() => setEditWidthVisible(false)}
          >
            <ModalHeader>Edit width</ModalHeader>
            <ModalBody>
              <InputLabel>
                <DistanceInput
                  value={stagedWidth}
                  onChange={(width) => setStagedWidth(width)}
                  type={'short'}
                />
              </InputLabel>
            </ModalBody>
            <ModalFooter>
              <Buttons>
                <Button
                  color="positive"
                  disabled={stagedWidth === undefined}
                  grow
                  onClick={() => {
                    reportEditor.report.sections
                      .filter((e) => !isCircular(e.attributes.shape))
                      .map((e) => {
                        reportEditor.updateSectionNoUpdate(e.uid, {
                          attributes: {
                            ...e.attributes,
                            width: stagedWidth,
                          },
                        });
                      });
                    reportEditor.updateAsync?.(reportEditor.report);
                    setEditWidthVisible(false);
                  }}
                  variant="primary"
                  size="x3"
                >
                  <Text>Confirm</Text>
                </Button>
              </Buttons>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  );
};

export default ReportMapSidebarSectionsBulkUpdate;
